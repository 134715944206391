import React, { useEffect, useState } from "react";
import Selector from "../../../components/Selector/Selector";
import Search from "../../../components/Inputs/Search/Search";
import "./FunnelsTable.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { SizeType } from "antd/es/config-provider/SizeContext";
import editIcon from "../../../images/_edit.svg";
import minusIcon from "../../../images/minus.svg";
import documentIcon from "../../../images/document.png";
import eyeIcon from "../../../images/eye.png";
import deleteIcon from "../../../images/trashpink.svg";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPopUp from "../../../components/PopUp/ConfirmPopUp/ConfirmPopUp";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import useMessage from "../../../hooks/useMessage";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import { PAGE_OPTIONS } from "utils/constants/selectorOptions";
import centralApi from "services/centralApi";
import { getToken } from "redux/features/app/authTokenSlice";

type propTypes = {
  pageSize?: number;
  tableSize?: SizeType;
};
const FunnelsTable: React.FC<propTypes> = ({ tableSize }) => {
  const navigate = useNavigate();
  const searchLabel = t("searchLabel");
  const loginUser = useSelector((state: any) => state.loginUser);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [funnelData, setFunnelData] = useState([]);
  const [funnelDataCopy, setFunnelDataCopy] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useMessage();
  
  const [pageData, setPageData] = useState({
    pageSize: PAGE_OPTIONS.DEFAULT_VALUE,
    simple: PAGE_OPTIONS.PAGINATION.simple,
  });
  const [dateRange, setDateRange] = useState<any>(null);
  const dispatch: any = useDispatch();

  //get the funnels data from api and set the state
  async function getEventFunnelsData() {
    try {
      const apiKey = loginUser.data.api_key;
      if (apiKey) {
        const url: string = API_ENDPOINTS.GET_EVENT_FUNNELS_URL;
        const payload = {
          d:dateRange,
          app_id: appId,
          api_key:apiKey,
        };
        setLoading(true);
        const res = await centralApi("GET", url, null, payload);
        const data = res.map((fun: any) => ({
          Key: fun._id,
          Funnel_Name: fun.name,
          Funnel_Description: fun.description,
          Action: fun._id,
          PlatForm: fun.platform,
          createdOn: fun.createdOn,
          modifiedOn: fun.modifiedOn,
        }));
        setFunnelData(data);
        filterData();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  //on  popup confirm
  const funnelDeleteConfirm = async (funnelId: string) => {
    try {
      const url: string = API_ENDPOINTS.DELETE_FUNNEL_URL; 
      const payload = {
        app_id: appId,
        id: funnelId,
        api_key: loginUser.data.api_key,
      };
      const response = await centralApi("GET", url, null, payload);

      if(response){
        showSuccess(t("funneldeletedSuccessfullyLabel"));
        getEventFunnelsData(); 
      }
      
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };
  const funnelCopyConfirm = async (funnelId: string) => {
    try {
      const url: string = API_ENDPOINTS.COPY_FUNNEL_URL;
      const payload = {
        id:funnelId,
        app_id :appId,
        api_key:loginUser.data.api_key,
      };
      const response = await centralApi("GET", url, null, payload);
      if(response){
        showSuccess(t("copiedMsg"));
        getEventFunnelsData();
        dispatch(getToken())
      }     
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  };
  useEffect(() => {
    const apiKey = loginUser.data.api_key;
    if (apiKey && appId) {
      getEventFunnelsData();
    }
  }, [loginUser]);
  const filterData = (search = "") => {
    const searchRegex = new RegExp(search, "i");

    setFunnelDataCopy(
      funnelData?.filter((fun: any) => {
        // Check if the date range is specified and valid
        const isWithinDateRange =
          (!dateRange?.startDate_C ||
            fun.modifiedOn >= dateRange.startDate_C) &&
          (!dateRange?.endDate_C || fun.modifiedOn <= dateRange.endDate_C);

        // Check if the search term matches
        const isSearchMatch = searchRegex.test(fun.Funnel_Name);

        // Return true if both conditions are met
        return isWithinDateRange && isSearchMatch;
      })
    );
  };
  useEffect(() => {
    if (dateRange) {
      filterData();
    }
  }, [dateRange, funnelData]);
  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };
  const columns: any = [
    {
      title: t("funnelNameLabel"),
      dataIndex: "Funnel_Name",
    },
    {
      title: t("funnelDescriptionLabel"),
      dataIndex: "Funnel_Description",
    },
    {
      title: t("actionLabel"),
      dataIndex: "Action",
      width: 180,
      className: "item-align",

      render: (id: string) => {
        const imgStyle = {
          width: 16,
        };
        return (
          <span className="d-flex justify-content-between campaign-action-btns">
            
            <ConfirmPopUp
              title={t("copyFunnelMsg")}
              onConfirm={funnelCopyConfirm}
              id={id}
              icon={documentIcon}
              description={t("areYouSureMsg")}
              placement="leftTop"
            >
              <PrimaryButton title={t("copyLabel")} className="action-btn mx-1">
                <img
                  src={documentIcon}
                  alt={"document_icon"}
                  style={imgStyle}
                ></img>
              </PrimaryButton>
            </ConfirmPopUp>
            <PrimaryButton
              title={t("editLabel")}
              className="action-btn mx-1"
              onClick={() => navigate("create", { state: { id } })}
            >
              <img src={editIcon} alt={"edit_icon"} style={imgStyle}></img>
            </PrimaryButton>
            <ConfirmPopUp
              title={t("deleteFunnelMsg")}
              onConfirm={funnelDeleteConfirm}
              id={id}
              icon={deleteIcon}
              placement="leftTop"
            >
              <PrimaryButton
                title={t("deleteLabel")}
                className="action-btn mx-1"
              >
                <img src={minusIcon} alt={"minus_icon"} style={imgStyle}></img>
              </PrimaryButton>
            </ConfirmPopUp>
            <Link to={`${ROUTES.DASHBOARD_FUNNEL_DETAIL.replace(":id", id)}`}>
              <PrimaryButton title={t("viewLabel")} className="action-btn mx-1">
                <img src={eyeIcon} alt={"eye_icon"} style={imgStyle}></img>
              </PrimaryButton>
            </Link>
          </span>
        );
      },
    },
  ];

  return (
    <div className="container-fluid p-sm-2 p-1 ">
      <Helmet>
        <title>Appice | Funnels</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-center py-3 flex-wrap">
        <div>
          <h4>{t("funnelsDataLabel")}</h4>
        </div>
        <div className="d-flex">
      
          <div>
            <strong>{t("periodLabel")} </strong>{" "}
            <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              numDaysToShow={30} // Example value
            />
          </div>
        </div>
      </div>
      <div className="px-sm-3 px-1 funnel-table-container">
        <div className="py-3 m-0 d-flex flex-row w-100">
          <div className="w-100 d-flex h-100 ">
            <Search
              placeholder={searchLabel}
              onChange={(e) => {
                filterData(e.target.value);
              }}
              size="middle"
              className="table-search"
            />
            <Selector
              onChange={(value) => {
                setPageData({ ...pageData, pageSize: value });
              }}
              className="page-selector"
              size="middle"
              defaultValue={PAGE_OPTIONS.DEFAULT_VALUE}
              options={PAGE_OPTIONS.DATA}
            />
          </div>
          <div className="">
            <PrimaryButton
              type="primary"
              onClick={() => {
                navigate("create");
              }}
            >
              <PlusOutlined />
              <span className="mx-1">{t("funnelLabel")}</span>
            </PrimaryButton>
          </div>
        </div>

        <CustomTable
          columns={columns}
          dataSource={funnelDataCopy.map((fun: any) => ({
            ...fun,
            key: fun.Key,
          }))}
          totalEntries={funnelData.length}
          size={tableSize}
          loading={loading}
          pagination={pageData}
          scroll={{ x: 500 }}
        />
      </div>
    </div>
  );
};

export default FunnelsTable;

