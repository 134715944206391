import { Divider } from "antd";
import "./UtmSetting.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";
import { getToken } from "redux/features/app/authTokenSlice";

interface UtmSettingProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const UtmSetting: React.FC<UtmSettingProps> = ({ currentAppKey ,updateCurrentAppKey}) => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();

  const [loading, setLoading] = useState(false);

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      ...values,
      app_id:appId,
      api_key: api_key,
    };
    savePostBaseUrl(payload);
    dispatch(getToken())
  };

  async function savePostBaseUrl(formData: any) {
    const url: string = `/i/apps/postbaseUrl`;

    try {
      setLoading(true);
      await centralApi("GET", url, null, formData);
      setShowMessage({
        type: "success",
        message: t("sucessfullySavedLabel"),
        show: true,
      });
      setLoading(false);
      updateCurrentAppKey();

    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };



  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      baseUrl: currentAppKey[0].appsettingsBaseUrl,
    };
    form.setFieldsValue(updatedFormValues);
  }, [currentAppKey, form]);
  return (
    <div className="utm-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="utm-setting-container-heading">
          {t("utmSettingLabel")}
        </h6>
        <Divider className="utm-setting-custom-divider" />
        <div>
          <div className="  mt-4 align-items-center utm-setting-form ">
            <div className="mb-4">
              <p>{t("appBaseUrlLabel")}</p>
              <div className="col-sm-6 ">
                {showMessage.show && (
                  <ShowMessage
                    type={showMessage.type}
                    content={showMessage.message}
                  />
                )}
                {loading ? (
                  <Spinner />
                ) : (
                  <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
                    <Form.Item
                      name={"baseUrl"}
                      rules={[
                        {
                          required: true,
                          message: t("plzInputUrl") as string,
                        },
                        {
                          type: "url",
                          message: t("plzEnterValidUrl") as string,
                        },
                      ]}
                    >
                      <TextInput />
                    </Form.Item>

                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                      style={{ border: "none", width: "70px" }}
                    >
                      {t("saveLabel")}
                    </PrimaryButton>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UtmSetting;
