import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "services/centralApi";
import { UserRoleEnum } from "utils/enums";
import { SignatureService } from "src_typescript_appice-core/dist/utils/security/signatureservice"
export const logoutUser = createAsyncThunk(
  "loginUser/logout",
  async () => {
    return centralApi(
      "GET",
      "/i/logout",
    ).then((data) => { 
      return data;
    });
  }
);
export const fetchLoginUser = createAsyncThunk(
  "loginUser/getUser",
  async () => {
    return centralApi(
      "GET",
      "/o/users/me",
    ).then((data) => {
      const signatureServices = new SignatureService(data.api_key);
      const isValid = signatureServices.verify(data);
      return { ...data, isValid }
    });
  }
);
const loginUserSlice = createSlice({
  name: "loginUser",
  initialState: {
    data: {},
    status: STATUS.IDLE,
  },
  extraReducers: (builder) => {
    //for logout  ********
    
    builder.addCase(logoutUser.pending, (state:any) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(logoutUser.fulfilled, (state:any) => {
      state.status = STATUS.LOGGEDOUT;
      state.data={}
    });
    builder.addCase(logoutUser.rejected, (state:any) => {
      state.status = STATUS.LOGGEDOUT;
      state.data={}
    });
    //******** */

    //login user *****
    builder.addCase(fetchLoginUser.pending, (state:any) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchLoginUser.fulfilled, (state,action) => {
      state.status =STATUS.IDLE
      state.data = action.payload;

      // if(action.payload.isValid){
        // state.data = action.payload
      // }else{
        // state.data = { isValid : action.payload.isValid}
      // }
    });
    builder.addCase(fetchLoginUser.rejected, (state:any) => {
      state.status = STATUS.ERROR;
      state.data={}
    });
    //******** */
  },
  reducers: {
    /**
     * setActiveAppUserRole sets the  role of the user of that particular active app 
     * @param state it will get login user from which we  will get global admin info and user_info
     * @param action we will get the appid of that active app to check if the user is admin ,manager or marketer of that app so that we will assign role in that way
     */
    setActiveAppUserRole(state : any, action : any) {
      const { user_role } = state.data;
      const {global_admin} = state.data;
      const  appId  = action.payload;


      const roleCheckers = [
        { role: UserRoleEnum.GLOBAL_ADMIN, check: () => global_admin },
        { role: UserRoleEnum.APP_ADMIN, check: () => user_role?.admin?.includes(appId) },
        { role: UserRoleEnum.APP_MANAGER, check: () => user_role?.manager?.includes(appId) },
        { role: UserRoleEnum.APP_MARKETER, check: () => user_role?.marketer?.includes(appId) },
        { role: UserRoleEnum.APP_INFOSEC, check: () => user_role?.infosec?.includes(appId) },
        { role: UserRoleEnum.APP_REVIEWER, check: () => user_role?.reviewer?.includes(appId) },
        { role: UserRoleEnum.APP_CONTACT_CENTER, check: () => user_role?.contact_center?.includes(appId) }
    ];

    // Find the first matching role
    const matchedRole = roleCheckers.find(roleChecker => roleChecker.check());

    // Set the active role or an empty string if no match is found
    state.data.activeAppUserRole = matchedRole ? matchedRole.role : '';

  }
  },
});
export const {setActiveAppUserRole}=loginUserSlice.actions
export default loginUserSlice.reducer;
