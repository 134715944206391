import { Divider, Form } from "antd";
import { useForm } from "antd/es/form/Form";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import { t } from "i18next";
import { FC, useState } from "react";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { Conversion } from "../CampaignDetail";


// Define props interface for the CampaignExport component
interface CampaignExportProps {
  campaignExportState: boolean; // Controls the visibility of the drawer
  onClose: () => void; // Function to close the drawer
  onSave: (values: any) => void; // Function to handle save action
  conversion:Conversion // Conversion object passed as a prop
}

// Functional component definition
const CampaignExport: FC<CampaignExportProps> = ({
  campaignExportState,
  onClose,
  onSave,
  conversion
}) => {
  const [form] = useForm();
  const [isAnyChecked, setIsAnyChecked] = useState(false);

    // Handler for checkbox changes
  const handleCheckboxChange = () => {
    const values = form.getFieldsValue();

    // If the "Click to Conversion" checkbox is checked, check both checkboxes
    if (values.clickedToConversion) {
      form.setFieldsValue({ campaignClicked: true, clickedToConversion: true });
    }

    // Update state if any checkbox is checked
    setIsAnyChecked(values.campaignClicked || values.clickedToConversion);
  };

    // Handler for form submission
  const handleSubmit = (values: any) => {
    onSave(values);
    form.resetFields();
  };

  return (
    <CustomDrawer
      footer={
        <div>
          <PrimaryButton htmlType="submit" onClick={() => form.submit()} disabled={!isAnyChecked} >
            {t("saveLabel")}
          </PrimaryButton>
          <PrimaryButton onClick={onClose} style={{ marginLeft: '8px' }}>
            {t("cancelLabel")}
          </PrimaryButton>
        </div>
      }
      open={campaignExportState}
      placement="right"
      closable={false}
    >
      <div className="mt-1 funnel-export-container">
        <h5 className="fw-semibold">Export Campaign data</h5>
        <Form
          colon={false}
          labelAlign="left"
          labelCol={{ flex: "25px" }}
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
        >
          <Divider />

          <Form.Item name="campaignClicked" valuePropName="checked">
            <CustomCheckbox onChange={handleCheckboxChange} // Track change
            label="Campaign Clicked" />
          </Form.Item>
          

          {conversion.state && (
            <Form.Item name="clickedToConversion" valuePropName="checked">
              <CustomCheckbox onChange={handleCheckboxChange} // Track change
              label="Click to Conversion" />
            </Form.Item>
          )}

        </Form>
      </div>
    </CustomDrawer>
  );
};

export default CampaignExport;
