import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DrawingManager,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import TextInput from "components/Inputs/TextInput/TextInput";
import FormItem from "antd/es/form/FormItem";
import { Form } from "antd";
import config from "config";
import { GeoFencingModel } from "utils/models";
import { t } from "i18next";
import { GoogleMapShapes } from "utils/enums";
import Spinner from "components/Spinner/Spinner";

/**
 * GeoFencing Component
 * 
 * This component allows users to define geographic regions (geo-fencing) using Google Maps.
 * It provides functionalities to draw circles, polygons, and rectangles on the map,
 * search for places, and save geo-fencing details with a name and description.
 * 
 * Props:
 * - setGeoInfo: Function to update geo fencing information in the parent component.
 */
interface GeoFencingPropsType {
  setGeoInfo: (data: GeoFencingModel) => void;
}

// Main component function
const GeoFencing: React.FC<GeoFencingPropsType> = ({ setGeoInfo }) => {
  // Load Google Maps API using useJsApiLoader hook
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_MAP_KEY, // API key from configuration
    libraries: ["places", "drawing"], // Additional libraries required
  });

  // State variables to manage component state
  const [data, setData] = useState<GeoFencingModel>({ name: "", desc: "", details: [] });
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(null);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [geo, setGeo] = useState<any[]>([]);
  const [shapes, setShapes] = useState<(google.maps.Circle | google.maps.Polygon | google.maps.Rectangle | undefined)[]>([]);

  const overlaysRef = useRef([]);  // Using an empty array to store overlays

  // Effect hook to get the user's current location and set it on the map
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = { lat: position.coords.latitude, lng: position.coords.longitude };
          if (infoWindow && map) {
            infoWindow.open(map); // Open info window at current location
            map.setCenter(pos); // Center map at current location
          }
        },
        () => { handleLocationError(true); } // Handle error if location cannot be determined
      );
    } else {
      handleLocationError(false); // Handle case where browser doesn't support geolocation
    }
  }, [infoWindow, map]);

  /**
   * Handles errors related to geolocation.
   * @param {boolean} browserHasGeolocation - Indicates if the browser supports geolocation.
   */
  const handleLocationError = (browserHasGeolocation: boolean) => {
    const pos = map?.getCenter();
    if (pos && infoWindow) {
      infoWindow.setPosition(pos); // Set position of info window at map center
      infoWindow.setContent(browserHasGeolocation ? t("geoLocServiceFailedMsg") : t("geoLocBrowserErrorMsg"));
      infoWindow.open(map); // Open info window with error message
    }
  };

  /**
   * Event handler for when the map loads.
   * @param {google.maps.Map} map - The loaded Google Map instance.
   */
  const onMapLoad = (map: google.maps.Map) => {
    setMap(map); // Set map state
    setInfoWindow(new google.maps.InfoWindow()); // Initialize info window
  };

  // Event handler for when places change in the search box
  const onPlacesChanged = () => {
    const places = searchBox?.getPlaces();
    if (places?.length === 0) return;

    const newMarkers: google.maps.Marker[] = [];
    const bounds = new google.maps.LatLngBounds();
    places?.forEach((place) => {
      if (!place.geometry) return;
      const location = place.geometry.location;
      if (location) {
        const icon = {
          url: place.icon!,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place
        const marker = new google.maps.Marker({
          position: location,
          icon,
          title: place.name,
          map: map,
        });

        newMarkers.push(marker);

        // Adjust map bounds to include this place
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(location);
        }
      }
    });
    setMarkers(newMarkers); // Update markers state
    map?.fitBounds(bounds); // Fit map to the new bounds
  };

  /**
   * Event handler for when an overlay (circle, polygon, rectangle) is completed.
   * @param {google.maps.drawing.OverlayCompleteEvent} event - Event object containing information about the completed overlay.
   */
  
  const onOverlayComplete = (event: google.maps.drawing.OverlayCompleteEvent) => {
    drawingManagerRef.current?.setDrawingMode(null); // Disable drawing mode

    let newShape: google.maps.Circle | google.maps.Polygon | google.maps.Rectangle | undefined=undefined
    let marker: google.maps.Marker | undefined = undefined; // To store the marker for the shape
    let geoData: any = undefined; // To store the geo data for the shape


    if (event.type === GoogleMapShapes.CIRCLE.toString()) {
      const circle = event.overlay as google.maps.Circle;
      const radius = circle.getRadius();
      const path = circle.getCenter();
        if (path)
          //  placeMarker(path, radius); // Place marker at circle center
        marker = placeMarker(path, radius); // Place marker at circle center
        geoData = {
          type: event.type,
          coordinates: { lat: path?.lat(), lng: path?.lng() },
          radius,
        };
    
        setGeo((prev) => [...prev, geoData]);
    
    
      newShape = circle;
    }

    if (event.type === GoogleMapShapes.POLYGON.toString() || event.type === GoogleMapShapes.RECTANGLE.toString()) {
      const polygon = event.overlay as google.maps.Polygon;
      const path = polygon.getPath().getArray();
     
      geoData = {
        type: event.type,
        coordinates: path.map((coord) => ({ lat: coord.lat(), lng: coord.lng() })),
      };
  
      setGeo((prev) => [...prev, geoData]);
      newShape = polygon;
    }

    if (newShape) {
      setShapes((prev) => [...prev, newShape]); // Store the shape for later removal
      // addDeleteButtonToShape(newShape); // Add delete button after shape is drawn
      addDeleteButtonToShape(newShape, marker,geoData); // Add delete button after shape is drawn

    }
  };
// Function to add a delete button to a shape (Circle or Polygon)
const addDeleteButtonToShape = (
  shape: google.maps.Circle | google.maps.Polygon,
  marker?: google.maps.Marker,  // Optional marker that could be associated with the shape
  geoData?: any                 // Optional geo-data associated with the shape
) => {
  // Create the delete button element
  const deleteButton = document.createElement("div");
  deleteButton.className = "delete-overlay-button";  // Assign a class to the button for styling
  deleteButton.innerText = "X";  // Set the button text to "X" for deletion
  deleteButton.style.position = "absolute";  // Make the button absolutely positioned
  deleteButton.style.cursor = "pointer";  // Set the cursor to pointer on hover
  deleteButton.style.backgroundColor = "red";  // Set the background color to red
  deleteButton.style.color = "white";  // Set the text color to white
  deleteButton.style.padding = "5px";  // Add some padding around the text
  deleteButton.style.borderRadius = "50%";  // Make the button circular
  deleteButton.style.zIndex = "9999";  // Set a high z-index to ensure the button is on top of map layers
  
  // Create an OverlayView to manage the position of the delete button
  const overlayView = new google.maps.OverlayView();
  overlaysRef.current.push(overlayView);  // Store the overlay in a reference array

  // Attach the overlay to the map
  overlayView.setMap(map);
  
  // Draw method ensures the button is correctly positioned
  overlayView.draw = function () {
    const projection = overlayView.getProjection();  // Get the projection of the map
    if (!projection) return;  // If no projection exists, exit the draw method
  
    let pixelPosition;

    // If the shape is a circle
    if (shape instanceof google.maps.Circle) {
      const center = shape.getCenter();  // Get the center of the circle
      const radius = shape.getRadius();  // Get the radius of the circle

      if (center) {
        // Calculate a point on the circumference of the circle (right side of the circle)
        const angle = 0;  // 0 degrees means the right side of the circle
  
        // Compute the offset point on the circumference from the center
        const circumferencePoint = google.maps.geometry.spherical.computeOffset(center, radius, angle);
  
        // Convert the circumference point from LatLng to pixel coordinates
        pixelPosition = projection.fromLatLngToDivPixel(circumferencePoint);
  
        // Position the delete button slightly off the circumference (adjust button placement)
        deleteButton.style.top = `${pixelPosition.y - 15}px`;  // Adjust the button position
        deleteButton.style.left = `${pixelPosition.x + 15}px`;  // Adjust the button position
      }
    } else if (shape instanceof google.maps.Polygon) {
      // If the shape is a polygon
      const path = shape.getPath();  // Get the path (vertices) of the polygon
  
      // Get the first vertex of the polygon to position the button near it
      const firstVertex = path.getAt(0);
      if (firstVertex) {
        pixelPosition = projection.fromLatLngToDivPixel(firstVertex);  // Convert the first vertex to pixel coordinates
        deleteButton.style.top = `${pixelPosition.y - 20}px`;  // Adjust button position above the first vertex
        deleteButton.style.left = `${pixelPosition.x + 20}px`;  // Adjust button position to the right
      }
    }
  
    // Ensure the delete button is added to the overlay's pane (layer) on the map
    const panes = overlayView.getPanes();
    if (panes && panes.floatPane) {
      panes.floatPane.appendChild(deleteButton);  // Attach the delete button to the floatPane
    }
  };

  // Add event listener to the delete button to remove the shape when clicked
  google.maps.event.addDomListener(deleteButton, "click", () => {
    // Call the removeSelectedShape function to remove the shape, marker, and associated data
    removeSelectedShape(shape, marker, geoData, deleteButton, overlayView);
  });
};

// Function to remove the selected shape, marker, geo-data, and associated delete button
const removeSelectedShape = (
  shape: google.maps.Circle | google.maps.Polygon | google.maps.Rectangle,  // Shape to remove
  marker: any,  // Optional marker to remove
  geoData: { type: string; coordinates: any },  // Geo data associated with the shape
  deleteButton: HTMLElement,  // Delete button to remove
  overlayView: google.maps.OverlayView  // OverlayView that holds the button
) => {
  // Remove the shape from the map
  if (shape) {
    shape.setMap(null);  // Set the shape's map to null to remove it
  }

  // Remove the marker from the map if it exists
  if (marker) {
    marker.setMap(null);  // Set the marker's map to null to remove it
  }

  // Remove the shape and marker from the state arrays
  setMarkers((prevMarkers) => prevMarkers.filter((m) => m !== marker));  // Remove the marker from the state
  setShapes((prevShapes) => prevShapes.filter((s) => s !== shape));  // Remove the shape from the state
  
  // Remove the corresponding geo entry from the state
  setGeo((prevGeo) => prevGeo.filter((geo) => {
    // Check if the geo data matches and remove it
    const isMatch = geo.type === geoData?.type && JSON.stringify(geo.coordinates) === JSON.stringify(geoData?.coordinates);
    return !isMatch;  // Keep the geo data if it doesn't match
  }));
  
  // Remove the delete button from the DOM
  if (deleteButton) {
    deleteButton.remove();  // Remove the delete button from the map
  }

  // Detach the overlay from the map to clean up
  if (overlayView) {
    overlayView.setMap(null);  // Remove the overlay from the map
  }
};

  
  
  // Functiofremoveshafren to save geo details when the form is submitted
  const handleSaveGeoDetails = async () => {
    setGeoInfo({ ...data, details: geo }); // Set geo info with updated data
  };

  const placeMarker = (position: google.maps.LatLng, radius: number) => {
    const marker = new google.maps.Marker({
      position,
      label: `${position.toUrlValue(5)} ( Radius: ${parseInt(radius.toString(), 10)} )`,
      map,
    });
  
    setMarkers((prev) => [...prev, marker]); // Update the markers state
  
    return marker; // Return the marker
  };
  

  
  // Options for drawing shapes
  const shapeOptions = {
    fillColor: "#f0f0f0",
    fillOpacity: 0.2,
    strokeWeight: 2,
    clickable: true,
    editable: true,
    zIndex: 1,
  };

  // Return loading state if the API is not loaded
  if (!isLoaded) return <div className="d-flex justify-content-center align-item-center w-100 h-100"><Spinner/></div>;

  // Main return of the component
  return (
    <div className="position-relative">
      <Form className="row" onFinish={handleSaveGeoDetails}>
        <div className="col-6">
          {/* Search Box for entering location names */}
          <StandaloneSearchBox
            onLoad={(ref) => setSearchBox(ref)}
            onPlacesChanged={onPlacesChanged}
          >
            <FormItem
              label={t("nameLabel")}
              name="name"
              rules={[{ required: true }]}
            >
              <TextInput
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData({ ...data, name: e.target.value }); // Update name in state
                }}
              />
            </FormItem>
          </StandaloneSearchBox>
        </div>
        <FormItem
          className="col-6"
          label={t("descriptionLabel")}
          name="description"
          rules={[{ required: true }]}
        >
          <TextInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setData({ ...data, desc: e.target.value }); // Update description in state
            }}
          />
        </FormItem>

        {/* Google Map Component */}
        <GoogleMap
          id="map"
          mapContainerStyle={{ width: "100%", height: "75vh" }}
          zoom={8}
          onLoad={onMapLoad}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={marker.getPosition()!}
              icon={marker.getIcon() as google.maps.Icon}
              title={marker.getTitle() || ""}
            />
          ))}
          {/* Drawing Manager for drawing shapes on the map */}
          <DrawingManager
            onLoad={(drawingManager) => {
              drawingManagerRef.current = drawingManager;
            }}
            onOverlayComplete={onOverlayComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                  google.maps.drawing.OverlayType.CIRCLE,
                  google.maps.drawing.OverlayType.POLYGON,
                ],
              },
              circleOptions: shapeOptions,
              polygonOptions: shapeOptions,
              rectangleOptions: shapeOptions,
            }}
          />
        </GoogleMap>

        <div className="d-flex w-100 justify-content-end gap-2 pt-3">
          <PrimaryButton type="primary" disabled={!geo?.length} htmlType="submit">{t("saveLabel")}</PrimaryButton>

        </div>
      </Form>
    </div>
  );
};

export default GeoFencing;
