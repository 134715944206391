import React from "react";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import CustomModal from "../../../components/Models/CustomModel/CustomModel";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../services/centralApi";
import { useNavigate } from "react-router-dom";
import { getToken } from "redux/features/app/authTokenSlice";
import { API_ENDPOINTS } from "utils/constants";
import { t } from "i18next";
type propTypes = {
  openModal?: any;
  setModalOpen?: any;
  eventNames?: any;
  journeyData?:any;
};
const CreateFunnelModal: React.FC<propTypes> = ({
  openModal,
  setModalOpen,
  eventNames,
}) => {
  const navigate = useNavigate(); interface RootState {
    loginUser: {
      data: {
        api_key: string;
      };
    };
  }
  interface ActiveAppState {
    activeApp: {
      appName: string;
      appId: string;
    };
  }
  const dispatch:any = useDispatch();

  const loginUser = useSelector((state: RootState) => state.loginUser);
  const { appId } = useSelector((state: ActiveAppState) => state.activeApp);
  const api_key = loginUser.data.api_key;
 
  

  const { TextArea } = Input;
  const [form] = Form.useForm(); // Create a form instance
  const allEvents = eventNames.map((eventName: any) => ({
    eventName: eventName,
  }));
  async function createFunnel(formData: any, authToken:any) {
    try {
      await centralApi("POST", API_ENDPOINTS.CREATE_FUNNEL, null, formData, false, authToken);
      
    } catch (error) {

     console.log("error")
    }
  }
  const handleConfirm = async (values: any) => {
    const { name, description } = values;
      const authToken = await dispatch(getToken()).unwrap();

    const payload = {
      args: JSON.stringify({
        name,
        description,
        duration: "",
        unit: "This Month",
        customunit: "",
        platform: ["Android", "iOS", "Web"],
        events: [...allEvents],
      }),
      app_id: appId,
      api_key: api_key,
      
    };
    createFunnel(payload,authToken);
    navigate("/dashboard/funnels");
    setModalOpen(false);
  };
  const handleCancel = () => {
    setModalOpen(false);
  };

  async function validateFunnelName(payload: any) {

    if (appId && api_key) {
      try {
        const data = await centralApi("GET", API_ENDPOINTS.VALIDATE_FUNNEL_NAME, null, payload);
        return data;
      } catch (error) {
     }
    }
  }

  const handleDescriptionClick = async () => {
    const funnelNameValue = form.getFieldValue("name");
    if (funnelNameValue) {
      const payload = {
        app_id: appId,
        api_key: api_key,
        funnelName: funnelNameValue,
      };

      try {
        const response = await validateFunnelName(payload);

        if (response == true) {
          // Proceed without errors and use the API response data
          form.setFields([
            {
              name: "name",
              errors: [
                t("funnelNameValidationLabel")
              ],
            },
          ]);
        }
      } catch (error) {
        // Handle API call errors
      }
    }
  };

  return (
    <div>
      <CustomModal
        title="Create Funnel"
        open={openModal}
        setVisibility={setModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="horizontal"
          onFinish={handleConfirm}
        >
          <Form.Item
            label={t("funnelNameLabel")}
            name="name"
            rules={[
              {
                required: true,
                message: t("inputFunnelNameMsg") as string,
              },
            ]}
          >
            <TextInput />
          </Form.Item>
          <Form.Item
            label={t("descriptionLabel")}
            name="description"
            rules={[
              {
                required: true,
                message: "Please input a Description",
              },
            ]}
          >
            <TextArea
              style={{ marginLeft: "10px" }} // Add the style here
              rows={4}
              onClick={handleDescriptionClick}
            />
          </Form.Item>
        </Form>
      </CustomModal>
    </div>
  );
};

export default CreateFunnelModal;
