import { useEffect, useState, Fragment } from "react";
import { Typography } from "antd";
import React from "react";
import CustomTable from "../../components/CustomTable/CustomTable";
import Search from "../../components/Inputs/Search/Search";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import download from "../../images/acquisition.svg";
import minus from "../../images/minus.svg";
import { useSelector } from "react-redux";
import centralApi from "../../services/centralApi";
import { t } from "i18next";
import "./Exports.css";
import useMessage from "../../hooks/useMessage";
import ConfirmPopUp from "components/PopUp/ConfirmPopUp/ConfirmPopUp";
import {
  copyTextToClipboard,
  downloadCsv,
  downloadFile,
  generateRandomAlphanumericString,
} from "helperFunctions/common";
import CustomModal from "components/Models/CustomModel/CustomModel";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import { API_ENDPOINTS } from "utils/constants";
import removeIcon from "images/remove.svg";
import { dateToLocaleString } from "helperFunctions/date";

const Exports: React.FC = () => {
  const pageSize = 5;
  const { Text } = Typography;
  const loginUser = useSelector((state: any) => state.loginUser);
  const { appId } = useSelector((state: any) => state.activeApp);
  const { showError, showSuccess } = useMessage();

  const api_key = loginUser.data.api_key;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [entries, setEntries] = useState(pageSize);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [randomString, setRandomString] = useState("");
  const [csvFileInfo, setCsvFileInfo] = useState<any>(null);

  async function downloadCsvFile(csvFileInfo: any) {
    const randomString = generateRandomAlphanumericString(12);

    setRandomString(randomString);
    setCsvFileInfo(csvFileInfo);

    if (data.panelExport) {
      setOpenDownloadModal(true);
    } else {
      handleDownload(csvFileInfo, randomString);
    }
  }
  //handle search
  const filteredData = searchText
    ? data?.list?.filter((item: any) =>
        item.filename.toLowerCase().includes(searchText.toLowerCase())
      )
    : data?.list;
  async function handleDownload(csvFileInfo: any, randomString: string) {
    const args = {
      export_id: csvFileInfo._id,
      app_id: appId,
      num: window.btoa(randomString),
    };
    const payload = {
      app_id: appId,
      api_key: api_key,
      args: JSON.stringify(args),
    };

    try {
      const response = await centralApi(
        "GET",
        API_ENDPOINTS.DOWNLOAD_EXPORT_CSV,
        null,
        payload,
        true
      );
      // if panel export is enabled , download from xlsx
      if (data.panelExport) {
        const blob = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        downloadFile(blob, csvFileInfo.filename + ".xlsx");
      } else {
        // Download exisitng csv format
        downloadCsv(response, csvFileInfo.filename);
      } 
      showSuccess(t("downloadedSuccessfullyLabel"));
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  }

  const columns = [
    {
      title: t("fileNameLabel"),
      dataIndex: "filename",
      className: "fix",
      width: 10,
    },
    {
      title: t("statusLabel"),
      dataIndex: "status",
      width: 20,
    },
    {
      title: t("exportDateLabel"),
      dataIndex: "createdOn",
      width: 70,
      render: (createdOn: string) => {
        const timestamp = parseInt(createdOn);
       return  dateToLocaleString(timestamp)
       
      },
    },
    {
      title: t("processedDateLabel"),
      dataIndex: "processeddate",
      width: 50,
      render: (processeddate: string) => {
        if (!processeddate) {
          return "Available Soon"; //Display when date is not available
        }
        const timestamp = parseInt(processeddate); // Parse the Unix timestamp
        return  dateToLocaleString(timestamp)
      }
    },
    {
      title: t("actionLabel"),
      dataIndex: "",
      width: 100,
      className: "item-align",
      render: (csv: any) => {
        return (
          <Fragment>
              <PrimaryButton
               title="Download"
                className="action-btn mx-1"
                disabled={!csv.processeddate}
                onClick={() => {
                  downloadCsvFile(csv);
                }}
              >
                <img
                  src={download}
                  alt={"download_icon"}
                  style={{ width: 16 }}
                ></img>
              </PrimaryButton>
            <ConfirmPopUp
              title={t("doYouWantToDeleteCsvLabel")}
              onConfirm={() => delCsv(csv._id)}
              id={csv._id}
              icon={removeIcon}
            >
                <PrimaryButton title="Delete" className="action-btn mx-1">
                  <img
                    src={minus}
                    alt={"view details"}
                    style={{ width: 16 }}
                  ></img>
                </PrimaryButton>
            </ConfirmPopUp>
          </Fragment>
        );
      },
    },
  ];

  async function getListCsv() {
    const payload = {
      app_id: appId,
      api_key: api_key,
      d: Date.now(),
    };
    const url: string = `o/csv/getList`;

    try {
      setLoading(true);
      const data = await centralApi("GET", url, null, payload);
      console.log("🚀 ~ getListCsv ~ data:", data)
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const handleSearch = (value: string) => {
    setSearchText(value);
  };
  async function delCsv(id: string) {
    const payload = {
      app_id: appId,

      api_key: api_key,

      id: id,
    };
    const url: string = `/i/csv/delete`;

    try {
      setLoading(true);
      await centralApi("GET", url, null, payload);

      setLoading(false);
      showSuccess(t("deletedCsvFileLabel"));

      getListCsv();
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  }
  useEffect(() => {
    if (api_key) {
      getListCsv();
    }
  }, [loginUser]);

  return (
    <Fragment>
      <CustomModal
        open={openDownloadModal}
        closable={false}
        title={t("passwordForDownloadFileLabel")}
        onCancel={() => setOpenDownloadModal(false)}
        okText={t("copyPasswordLabel")}
        onOk={() => {
          copyTextToClipboard(randomString);
          showSuccess(t("copiedToClipboardLabel"));
          handleDownload(csvFileInfo, randomString);
          setOpenDownloadModal(false);
        }}
      >
        <TextInput value={randomString} />
      </CustomModal>
      <h4 className="my-4">What do users do - In our App</h4>
      <div className="px-sm-3 px-1 exports-table-container">
        <div className="d-flex flex-row w-100 py-4 ">
          <div>
            <Search
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)} // Call handleSearch on input change
              size="middle"
              style={{
                width: "100%",
                maxWidth: "300px",
                marginRight: "10px",
              }}
            />
          </div>
          <Text className="my-auto ms-1">Show </Text>
          <Selector
            className="export-table-selector"
            onChange={(value) => {
              setEntries(value);
            }}
            size="middle"
            defaultValue={pageSize}
            options={[
              {
                value: 4,
                label: 4,
              },
              {
                value: 5,
                label: 5,
              },
              {
                value: 6,
                label: 6,
              },
              {
                value: 8,
                label: 8,
              },
              {
                value: 10,
                label: 10,
              },
            ]}
          />
          <Text className="my-auto">Entries </Text>
        </div>

        <CustomTable
          columns={columns}
          dataSource={filteredData?.map((user: any) => ({
            ...user,
            key: user._id,
          }))}
          pagination={{ pageSize: entries, simple: false }}
          loading={loading}
        />
      </div>
    </Fragment>
  );
};

export default Exports;
Exports.defaultProps = {
  tableSize: "small",
  pageSize: 5,
};
