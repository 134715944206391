import React, { useEffect, useMemo, useState } from "react";
import filterIcon from "../../../images/_filter.svg";
import { Form, MenuProps, Space, Tag } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./AudienceCreate.css";
import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import downloadIcon from "../../../images/downloadIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "redux/constant";
import {
  audienceItems,
  changeAudienceDataFormat,
  getCriteria,
} from "helperFunctions/audience";
import { Trans, useTranslation } from "react-i18next";
import centralApi from "services/centralApi";
import {
  fetchAudienceSegmentById,
  resetSegment,
  setSegmentData,
} from "redux/features/audience/audienceSegmentDataSlice";
import { fetchCustomVars } from "redux/features/app/customMetaVarsSlice";
import { fetchEventList } from "redux/features/event/eventListSlice";

import CustomBreadCrumb from "components/CustomBreadCrumb/CustomBreadCrumb";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import Counter from "components/Counter/Counter";
import useMessage from "hooks/useMessage";
import Spinner from "components/Spinner/Spinner";
import { getObjectId } from "services/getObjectId";
import useDebouncedValue from "hooks/useDebouncedState";
import { AudienceEnum, AudienceManagementPageType, SuccessErrorEnum } from "utils/enums";
import QuerySteps from "./components/QuerySteps/QuerySteps";
import { fetchComputedTraitList } from "redux/features/audience/computedTraitListSlice";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import {useCustomNotification} from "hooks/useNotification";
import { FeatureSettingsFlagTypes, METHOD, roleCheckTypes } from "utils/constants/constants";
import { getToken } from "redux/features/app/authTokenSlice";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import PageLayout from "layout/PageLayout/PageLayout";
import { CreateHeader } from "./components/CreateHeader/CreateHeader";
// import { SegmentInfo ,SegmentPayload} from "src_typescript_appice-core/dist/models/audience/audience.js";
// import { JsonSerializer } from "typescript-json-serializer";
type propTypes = {
  afterSaveClick?: (saved: boolean) => void;
};
const AudienceCreate: React.FC<propTypes> = ({ afterSaveClick }) => {
  const [form] = Form.useForm();
  const [loading,setLoading]=useState({count:false,export:false})
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const [authToken, setToken] = useState('');
  const { appId } = useSelector((state: any) => state.activeApp);
  const [segmentNameRepeated, setSegmentNameRepeated] = useState(false);
  const { showError, showSuccess } = useMessage();
  const {openNotification } = useCustomNotification();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const segment = useSelector((state: any) => state.audienceSegmentData.data);
  const segmentStatus = useSelector(
    (state: any) => state.audienceSegmentData.status
  );
  const [inputValues, setInputValues] = useDebouncedValue<{
    name: string;
    description: string;
  }>({ name: "", description: "" }, 300);
  const eventListStatus = useSelector((state: any) => state.eventList.status);
  const customVarStatus = useSelector(
    (state: any) => state.customMetaVars.status
  );
  const { who, what, when,cTrait = [] } = segment.segmentinfo;
  const cTraitListStatus = useSelector((state: any) => state.computedTraitList.status);
  const isValidForm = useMemo(() => {
    // The form is considered valid if at least one segment exists and all 'what' segments have an 'operand'.
    if ([...who, ...when, ...what,...cTrait].length) {
      if (what.length) {
        // Return false if any 'what' segment has no 'operand'
        const hasNoOperand = what.some((item: any) => !item.operand);
        if (hasNoOperand) {
          return false;
        }
      }
      // Return true if the form is valid.
      return true;
    }
    // Return false if any segment ('who', 'when', or 'what') is empty.
    return false;
  }, [segment?.segmentinfo]);
  const setObjectId = async () => {
    try {
      const objectid = await getObjectId(appId, loginUser.api_key);
      dispatch(setSegmentData({ objectid }));
    } catch (error) {
      console.error(error)
    }
  };
  //fetch the segment size
  const fetchSegmentSize = async (criteria: any[]) => {
    // Initialize info object with default values
    let info = { count: 0, q: "", userQ: "" };
    try {
      // Set loading state
      setLoading((prevState)=>({...prevState,count:true}))

      // Define selectors for the API request
      const selectors = {
        generate: true,
        countOrData: true,
        execute: true,
        column: ["did"],
      };

      // Prepare data for the API request
      const data = {
        api_key: loginUser.api_key,
        app_id: appId,
        criteria: JSON.stringify(criteria),
        method: METHOD.COUNTAUDIENCEREACH,
        selectors: JSON.stringify(selectors),
        authToken
      };

      // Make the API request to fetch segment size
      const { result } = await centralApi(
        "POST",
        API_ENDPOINTS.COUNT_AUDIENCE_REACH_URL,
        data,
        null
      );
      // Update info if the API response contains q and userQ values
      if (result.q && result.userQ) {
        info = { count: result.count, q: result.q, userQ: result.userQ };
      }

      // Reset loading state
      setLoading((prevState)=>({...prevState,count:false}))
    } catch (error) {
      // Reset loading state in case of error
      setLoading((prevState)=>({...prevState,count:false}))
    }

    // Update segment data in Redux state
    dispatch(
      setSegmentData({
        ...segment,
        range: info.count,
        segmentinfo: { ...segment.segmentinfo, q: info.q, userQ: info.userQ },
      })
    );

    // Return the fetched info
    return info;
  };

  useEffect(() => {
    //update in redux
    dispatch(setSegmentData(inputValues));
  }, [inputValues]);
  useEffect(() => {
    if (state?.id) {
      //if edit fetch audience segment
      dispatch(
        fetchAudienceSegmentById({
          api_key: loginUser.api_key,
          app_id: appId,
          aud_id: state.id,
        })
      );
    }
    if (loginUser.api_key && appId) {
      if (!state?.id) {
        setObjectId();
      }
      const payload={ api_key: loginUser.api_key, app_id: appId }
      dispatch(fetchCustomVars(payload));
      dispatch(fetchEventList(payload));
      dispatch(fetchComputedTraitList(payload))
    }
    return () => {
      dispatch(resetSegment());
    };
  }, [loginUser, state]);

  const getSerializedPayload = (segmentData: any) => {
    // const segmentInfo = new SegmentInfo(payload["segmentinfo"]);
    // const defaultSerializer = new JsonSerializer();
    // const deserializedData=defaultSerializer.deserialize(segmentInfo,segmentInfo)
    // if(deserializedData){
    // const serializedData=defaultSerializer.serialize(deserializedData)
    const segmentinfo = JSON.stringify(segmentData["segmentinfo"]);
    return {
      ...segmentData,
      segmentinfo: segmentinfo,
    };
  };
  const exportSegment=async(q:string,isAud=true)=>{
    try {
      const params={
        app_id:appId,
        api_key:loginUser.api_key,
        args:JSON.stringify({q,isAud,name:segment?.name})
      }
      if(q){
        setLoading((prevState)=>({...prevState,export:true}))
        await centralApi("GET",API_ENDPOINTS.START_EXPORT,null,params)
        openNotification(
          `${t("audienceExportInitiatedMsg")}`,
          <Trans i18nKey="visitTheExportsSectionMsg" components={{ 1: <Link to={ROUTES.DASHBOARD_EXPORTS} /> }} />,SuccessErrorEnum.SUCCESS,4
        )
      }
      
    } catch (error) {
      
    }
    setLoading((prevState)=>({...prevState,export:false}))

  }
  //save audience
  const saveAudience = async (segmentData: any,token:string) => {
    const serializedPayload: any = getSerializedPayload(segmentData);
    
    try {
      const params: any = {
        api_key: loginUser.api_key,
        app_id: appId,
        id: segment?.id,
      };
      const data = new FormData();
      for (const key in serializedPayload) {
        if (serializedPayload[key] !== undefined) {
          data.set(key, serializedPayload[key]);
        }
      }
      for (const key in params) {
        data.set(key, params[key]);
      }

      const resAud = await centralApi("POST", API_ENDPOINTS.CREATE_AUDIENCE_URL, data, params, false, token);
      const newToken = await dispatch(getToken()).unwrap();
      setToken(newToken)
      if (resAud?.name) {
        if(serializedPayload.file){
          openNotification(
            `${t("uploadStartedMsg")}`,
           `${t("willNotifyOnUploadMsg")}`)
        }
        else{
          showSuccess(t("segmentCreatedSuccessfullyMsg"));
        }

      } else {
        throw new Error();
      }
      form.resetFields();
    } catch (error) {
      const newToken = await dispatch(getToken()).unwrap();
      setToken(newToken)
      if(serializedPayload.file){
        openNotification(
          `${t("uploadFailedMsg")}`,
          `${t("errorWhileUploadingMsg")}`,
          SuccessErrorEnum.ERROR
        )}
      else{
        showError(t("somethingWrongLabel"));
      }
    }
    // Check if the `afterSaveClick` callback is provided in props.
    // If provided, call the callback with `true` to indicate a successful save.
    // This callback is provided when AudienceCreate is integrated into another page.
    // If the callback is not provided, navigate to the "/dashboard/audience" route.
    if (afterSaveClick) {
      afterSaveClick(true);
    } else {
      navigate(ROUTES.DASHBOARD_AUDIENCE);
    }
  };
  //update the audience
  const updateAudience = async (segmentData: any,token:string) => {

    const serializedPayload: any = getSerializedPayload(segmentData);
    const data : any = {
      ...serializedPayload,
      objectid : serializedPayload._id
    }
    try {
      const params: any = {
        api_key: loginUser.api_key,
        app_id: appId,   
      };
      const formData = new FormData();
      for (const key in data) {
        if (data[key] !== undefined) {
          formData.set(key, data[key]);
        }
      }
      for (const key in params) {
        formData.set(key, params[key]);
      }
  
      const resAud = await centralApi("POST",  API_ENDPOINTS.UPDATE_AUDIENCE_URL, formData, params,false,token);
      const newToken = await dispatch(getToken()).unwrap();
      setToken(newToken)
      if (resAud?.name) {
        if(serializedPayload.file){
          openNotification(
            `${t("uploadStartedMsg")}`,
           `${t("willNotifyOnUploadMsg")}`)
        }
        else{
          showSuccess(t("segmentUpdatedSuccessfullyMsg"));
        }
      } else {
        throw new Error();
      }
      form.resetFields();
    } catch (error) {
      const newToken = await dispatch(getToken()).unwrap();
      setToken(newToken)
      if(serializedPayload.file){
        openNotification(
          `${t("uploadFailedMsg")}`,
          `${t("errorWhileUploadingMsg")}`,SuccessErrorEnum.ERROR
        )}
      else{
        showError(t("somethingWrongLabel"));
      }
    }
    // };
    navigate(ROUTES.DASHBOARD_AUDIENCE);
  };
  //submit the form
  async function onFormSubmit(_?:any,action: "save" | "count" | "export" = AudienceEnum.SAVE) {
    // Validate the form fields
    form
      .validateFields()
      .then(async () => {
        // Check if the segment name is repeated
        if (segmentNameRepeated) {
          // Set an error message for the "name" field
          form.setFields([
            {
              name: "name",
              errors: [t("segmentNameAlreadyExistsMsg")],
            },
          ]);
          return;
        }

        // Convert segmentinfo to the outgoing format
        const segmentinfo = changeAudienceDataFormat(
          segment.segmentinfo,
          AudienceEnum.OUTGOING
        );

        // Check if user is loggedin
        if (appId && loginUser.api_key) {
          try {
            // Fetch segment size
            const info = await fetchSegmentSize(getCriteria(segmentinfo));
            const newToken = await dispatch(getToken()).unwrap();
            setToken(newToken)

            // Update segmentData with range and updated segmentinfo
            const segmentData = {
              ...segment,
              range: info.count,
              segmentinfo: { ...segmentinfo, q: info.q, userQ: info.userQ },
            };
           if(action == AudienceEnum.EXPORT){
            exportSegment(info.q)
           }

            // Perform action based on the specified action type
            // If the action is to save the audience...
            if (action == AudienceEnum.SAVE) {
              // Check if the audience already exists (state.id exists)
              if (state?.id) {
                // If the audience exists, update it
                updateAudience(segmentData,newToken);
              } else {
                // If the audience is new, save it
                saveAudience(segmentData,newToken);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch((error) => {
        console.error(error)
      });
  }

  //validates the name
  const validateSegmentName = async (value: string) => {
    try {
      // Check if the segment name  is not empty
      if (value?.length > 0) {
        // Prepare parameters for the API request
        const params = {
          api_key: loginUser.api_key,
          app_id: appId,
          method: "validateSegmentName",
          segmentName: value,
        };

        // Make an API request to validate the segment name
        const { result } = await centralApi(
          "GET",
          API_ENDPOINTS.VALIDATE_SEGMENT_NAME_URL,
          null,
          params
        );

        // Set the state to indicate if the segment name is repeated
        setSegmentNameRepeated(result);

        // If the segment name is repeated, show an error message in the form
        if (result) {
          form.setFields([
            {
              name: "name",
              errors: [t("segmentNameAlreadyExistsMsg")],
            },
          ]);
        }
      }
    } catch (error) {}
  };
  return (
      <PageLayout title={!afterSaveClick?"Audience | Create":undefined}
      className="audience-create-container"
      topLeftItem={
        //  {/* dont show this title and breadcrumb when the page is integrated in another page */}
        !afterSaveClick?
        <CustomBreadCrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard/audience"}>{t("audienceLabel")}</Link>
                ),
              },
              {
                title: (
                  <Link to={""}>
                    {state?.id ? t("editLabel") : t("createLabel")}
                  </Link>
                ),
              },
            ]}
          />:undefined
      }
      >
        {segmentStatus !== STATUS.LOADING &&
        customVarStatus !== STATUS.LOADING &&
        cTraitListStatus!==STATUS.LOADING &&
        eventListStatus !== STATUS.LOADING ? (
          <Form colon={false} form={form} onFinish={onFormSubmit} disabled={loading.count}>
            <CreateHeader
              type={AudienceManagementPageType.SEGMENT}
              onNameChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputValues({
                  name: e.target.value,
                  description: segment?.description,
                })
              }
              onDescriptionChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputValues({
                  name: segment?.name,
                  description: e.target.value,
                })
              }
              onNameBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                validateSegmentName(e.target.value)
              }
              initialValues={{
                name: segment?.name || undefined,
                description: segment?.description || undefined,
              }}
            headerRightItem={ 
              <div className="d-flex justify-content-between py-3  flex-wrap-reverse gap-2 ">
              <div className="segment-size-container">
                <strong className="form-item-custom-label">
                  {t("segmentSizeLabel")}
                </strong>
                <div className="d-flex  mt-2">
                  <Counter
                    className="segment-size-counter"
                    end={segment?.range}
                    duration={1.5}
                  />
                      <AccessControl accessControl={{role:[roleCheckTypes.Checker , roleCheckTypes.Maker],features:[FeatureSettingsFlagTypes.AUDIENCE_EXPORTS]}}>
                      <PrimaryButton size="small"  disabled={!isValidForm || loading.export || loading.count } loading={loading.export}  onClick={() => {
                         onFormSubmit({},AudienceEnum.EXPORT);
                         }} className="mx-1" >
                         <DownloadOutlined/>
                  </PrimaryButton>
                      </AccessControl>
                  <PrimaryButton
                    size="small"
                    disabled={!isValidForm}
                    onClick={() => {
                      onFormSubmit({},AudienceEnum.COUNT);
                    }}
                  >
                    <SyncOutlined spin={loading.count} />
                  </PrimaryButton>
                </div>
              </div>
            </div>
            }
            />
            <div className="py-4">
              <QuerySteps form={form} />
            </div>
          </Form>
        ) : (
          <div className="d-flex align-items-center audience-create-loader-container justify-content-center h-100 w-100">
            <Spinner />
          </div>
        )}
      </PageLayout>
   
  );
};

export default AudienceCreate;
