import TextInput from "components/Inputs/TextInput/TextInput";
import "./NudgeScreen.css";
interface NudgeScreenType {
  index: number;
  screen:any[]
  onScreenClick: (index: number) => void;
  onScreenInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const NudgeScreen: React.FC<NudgeScreenType> = ({
  index,
  onScreenClick,
  screen,
  onScreenInputChange,
}) => {
  return (
    <div className="nudge-screen-main-container cursor-pointer d-flex flex-column justify-content-between">
      <div
        onClick={() => onScreenClick(index)}
        className="nudge-screen container d-flex flex-column align-items-center justify-content-center"
      >
        <div className="text-center ">
          {/* <h1 className="mb-5">{index + 1}</h1>
          <strong>{"Upload App Page"}</strong> */}
          <img width={280} height={520} src={screen?.[index]?.file}/>
        </div>
      </div>
      <div className="nudge-input-field">
       {onScreenInputChange &&  <TextInput
          onChange={onScreenInputChange}
          className="mt-3"
          type="text"
          placeholder="Enter App screen name here."
        />}
      </div>
    </div>
  );
};

export default NudgeScreen;
