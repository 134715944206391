import userIcon from "../../images/user2.png";
import briefcaseIcon from "../../images/briefcase2.png";
import carIcon from "../../images/car2.png";
import homeIcon from "../../images/home2.png";
import desktopIcon from "../../images/computer.svg";
import mobileIcon from "../../images/cell-phone.svg";
export const data = {
  name: "GOLLA PEDDA NARAYANAMMA",
  icon: "GN",
  cifId: "2840284029202243",
  c360Id: "X8203dod302nd202",
  lastSeen: "01 Jan 2025",
  firstSeen: "18 July 2008",
  status: "Hgh value",
  loyalty_points: "238490",
  portfolio: [
    {
      title: "Assets",
      color: ["#F61584"],
      subTitle: "SBA | CAA | TD",
      value: 15373274,
    },
    {
      title: "Liabilities",
      color: ["#689DFF"],
      subTitle: "Loans | Credit Card",
      value: 23409303,
    },
    {
      title: "Securities",
      color: ["#5CD8B8"],
      subTitle: ".",
      value: 35622722,
    },
  ],
  engagement: {
    visits: [
      {
        title: "Average Visits per month",
        value: "8",
      },
      {
        title: "Average Pages visited",

        value: "5",
      },
      {
        title: "Average Duration of visit",

        value: "13 minutes",
      },
      {
        title: "Last visit",

        value: "14 Mar 2022",
      },
    ],
    data: [2, 4, 6, 10, 20, 40, 80, 120, 130, 150, 160, 165],
  },
  offers: [
    {
      title: "Home",
      subtitle: "New offer",
      amount: "₹15,00,000",
      icon: homeIcon,
      updated: "14-Sep-2020",
    },
    {
      title: "Personal loan",
      subtitle: "New offer",
      icon: userIcon,
      amount: "₹15,00,000",
      updated: "14-Sep-2020",
    },
    {
      title: "Vehicle loan",
      subtitle: "New offer",
      icon: carIcon,
      amount: "₹5,00,000",
      updated: "14-Sep-2020",
    },
    {
      title: "Business loan",
      subtitle: "New offer",
      icon: briefcaseIcon,
      amount: "₹15,00,000",
      updated: "14-Sep-2020",
    },
  ],
  recentActivity: [
    {
      color: "green",
      label: "2024-08-01",
      children: "Campaign_deleted,b207f8ee-1a68-49cf-870f-e4480873ad8a",
    },
    {
      color: "blue",
      label: "2024-08-01,09:12:11",
      children: "Session_start,b207f8ee-1a68-49cf-870f-e4480873ad8a",
    },
    {
      color: "red",
      label: "2024-08-02,10:00:00",
      children: "Session_end,b207f8ee-1a68-49cf-870f-e4480873ad8a",
    },
    {
      color: "gray",
      label: "2024-08-02,10:05:00",
      children: "Ping_location,b207f8ee-1a68-49cf-870f-e4480873ad8a",
    },
    {
      color: "green",
      label: "2024-08-02,10:10:00",
      children: "Session_end,b207f8ee-1a68-49cf-870f-e4480873ad8a",
    },
    {
      color: "blue",
      label: "2024-08-02,10:15:00",
      children: "Session_start,b207f8ee-1a68-49cf-870f-e4480873ad8a",
    },
    {
      color: "red",
      label: "2024-08-02,10:20:00",
      children: "Install,b207f8ee-1a68-49cf-870f-e4480873ad8a",
    },
],
  preferences: [
    {
      platform: "Web",
      icon: desktopIcon,
      value: 80,
      color: "#418AFE",
    },
    {
      platform: "Mobile web",
      icon: mobileIcon,
      value: 0,
      color: "#418AFE",
    },
    {
      platform: "App",
      icon: mobileIcon,
      value: 0,
      color: "#418AFE",
    },
  ],
  campaigns: {
    ts: "35",
    lc: "Need loan+high propensity",
    ch: "Push",
    rs: "Clicked",
    push: "18",
    inApp: "3",
    web: "0",
    email: "12",
    WA: "2",
  },
  matchedSegments:[
    {
        title:"High value home loan",
        value:60 
    },
    {
        title:"Card limit upgrade",
        value:90 
    },{
        title:"Gold loan",
        value:40 
    }
  ]
};
