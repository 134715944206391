import {configureStore} from "@reduxjs/toolkit"
import dateRangeReducer from "../features/dateRange/dateRangeSlice"
import loginUserReducer from "../features/user/loginUserSlice"
import activeAppReducer from "../features/app/activeAppSlice"
import authTokenReducer from "../features/app/authTokenSlice"
import customMetaVarsReducer from "../features/app/customMetaVarsSlice"
import eventListReducer from "../features/event/eventListSlice"
import layoutInfoReducer from "../features/layout/layoutInfoSlice"
import campaignCreateCurrentStepReducer from "../features/campaign/campaignCreateCurrentStepSlice"
import  campaignDataReducer  from "../features/campaign/campaignDataSlice"
import audienceSegmentsReducer from "../features/app/audienceSegmentsSlice"
import childAppsReducer from "../features/app/childAppsSlice"
import templatesByTypeReducer from "../features/templates_/templatesByTypeSlice"
import templateByIdReducer from "../features/templates_/templateByIdSlice"
import variantsReducer from "redux/features/templates_/variantsSlice"
import audienceSegmentDataReducer from "redux/features/audience/audienceSegmentDataSlice"
import currentAppKeysReducer from "redux/features/app/currentAppKeysSlice"
import featuresSettingReducer from "redux/features/app/featuresSettingSlice"
import computedTraitListReducer from "../features/audience/computedTraitListSlice"
import computedTraitReducer from "redux/features/audience/computedTraitSlice"
import geoFencingListReducer from "redux/features/audience/geoFencingListSlice"


const store = configureStore({
    reducer:{
        dateRange:dateRangeReducer,
        loginUser:loginUserReducer,
        activeApp:activeAppReducer,
        authToken:authTokenReducer,
        customMetaVars:customMetaVarsReducer,
        eventList:eventListReducer,
        layoutInfo:layoutInfoReducer,
        campaignCreateCurrentStep:campaignCreateCurrentStepReducer,
        campaignData:campaignDataReducer,
        audienceSegments:audienceSegmentsReducer,
        childsApps:childAppsReducer,
        templatesList:templatesByTypeReducer,
        templateById:templateByIdReducer,
        templateMetaData:variantsReducer,
        audienceSegmentData:audienceSegmentDataReducer,
        featuresSetting: featuresSettingReducer,
        currentAppKeys: currentAppKeysReducer,
        computedTraitList:computedTraitListReducer,
        computedTrait:computedTraitReducer,
        geoFencingList:geoFencingListReducer



    },
    devTools:false
})
export default store
