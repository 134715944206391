import React, { useEffect, useState } from "react";
import "./TimeWindow.css";
import { Form, FormInstance } from "antd";
import Selector from "components/Selector/Selector";
import { generateNumbersInRange } from "helperFunctions/number";
import { audienceItems } from "helperFunctions/audience";
import { t } from "i18next";
type valueType = {
  t_operand?: string;
  type: string;
  time: string;
  event?: undefined | string;
};
interface TimeWindowProps {
  form: FormInstance;
  value?: valueType;
  eventList:any[];
  setValue: (data: valueType) => void;
  id: string | number;
}

const TimeWindow: React.FC<TimeWindowProps> = ({
  value,
  form,
  id,
  setValue,
  eventList
}) => {
  const [timeWindow, setTimeWindow] = useState({
    t_operand: value?.t_operand || (value?.type && value?.time) ? "since" : "",
    type: value?.type || "",
    time: value?.time || "",
    event: value?.event || undefined,
  });
  const [showTimeWindow, setShowTimeWindow] = useState(false);
  const [timeString, setTimeString] = useState<any>();
  //get the label of type/unit e.g(for m it will return Minutes)
  const getTypeLabel = (type: string) => {
    const unit = audienceItems["units"].find((unit: any) => unit.value == type);
    return unit ? unit.label : type;
  };
  const handleInputChange = (data: any) => {
    setTimeWindow((prevState) => {
      const modifiedData = { ...prevState, ...data };
      if (modifiedData.t_operand && modifiedData.type && modifiedData.time) {
        setValue(modifiedData);
      }
      return modifiedData;
    });
  };
  useEffect(() => {
    if (timeWindow.t_operand && timeWindow.type && timeWindow.time && timeWindow.event) {
      // setValue(timeWindow)
      setTimeString(
        <span className="color-time-window-disabled-strong">
          <span className="mx-1">{timeWindow.t_operand}</span>
          <span className="mx-1 color-time-window-black">
            {timeWindow.time}
          </span>
          <span>{getTypeLabel(timeWindow.type)}</span>
          {timeWindow?.event && (
            <>
              <span className="mx-1"> of </span>
              <span className="mx-1 color-time-window-black">
                {timeWindow?.event}
              </span>
            </>
          )}
        </span>
      );
    } else {
      setTimeString(undefined);
    }
  }, [timeWindow]);
  useEffect(() => {
    //reset fields on component unmount
    return () => {
      form.resetFields([
        `t_operand[${id}]`,
        `time[${id}]`,
        `type[${id}]`,
        `event[${id}]`,
      ]);
    };
  }, []);
  return (
    <div className="time-window-parent-container">
      <div
        onMouseLeave={() => setShowTimeWindow(false)}
        className={`d-flex ${
          !timeString || showTimeWindow ? "time-window-container py-1" : ""
        }  align-items-center `}
      >
        <Form.Item
          hidden={timeString && !showTimeWindow}
          initialValue={timeWindow.t_operand}
          name={`t_operand[${id}]`}
        >
          <Selector
            className="w-100 mwpx-120"
            value={timeWindow.t_operand}
            onChange={(value) => {
              handleInputChange({ t_operand: value });
            }}
            options={audienceItems.timeWindowOperators}
          />
        </Form.Item>
        <Form.Item
          hidden={timeString && !showTimeWindow}
          name={`time[${id}]`}
          initialValue={timeWindow.time}
        >
          <Selector
            onChange={(value) => {
              handleInputChange({ time: value });
            }}
            className="w-100 mwpx-60"
            options={generateNumbersInRange(1, 360).map((number) => ({
              label: number,
              value: number,
            }))}
          />
        </Form.Item>
        <Form.Item
          hidden={timeString && !showTimeWindow}
          name={`type[${id}]`}
          initialValue={timeWindow.type}
        >
          <Selector
            onChange={(value) => {
              handleInputChange({ type: value });
            }}
            className="w-100 mwpx-120"
            options={audienceItems["units"]}
          />
        </Form.Item>
        <Form.Item
          name={`event[${id}]`}
          initialValue={timeWindow?.event}
          label={`${t("ofLabel")}`}
          hidden={timeString && !showTimeWindow}
          className="input-size-md"
          // initialValue={segment?.since?.event}
        >
          <Selector
            placeholder={t("selectEventMsg")}
            showSearch
            onChange={
              (value: string) =>
              handleInputChange({event: value})
            }
            options={eventList}
          />
        </Form.Item>
      </div>
      {timeString && !showTimeWindow && (
        <div>
          <div
            onClick={() => setShowTimeWindow(true)}
            className="input-disable"
          >
            {timeString}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeWindow;
