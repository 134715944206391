import './SmartLinkDetails.css'
import QRCode from 'qrcode';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import meem from '../../../../images/Meemlogo.png';
import { Card,} from 'antd';
import DetailsOverview from '../components/DetailsOverview/DetailsOverview';
import { formatDate } from 'helperFunctions/date';
import { useEffect, useState } from 'react';
import downloadIcon from "../../../../images/downloadNew.svg";
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';


export const SmartLinkDetails: React.FC = () => {
    const location = useLocation();
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [downloadLink, setDownloadLink] = useState('');
    const [format, setFormat] = useState<'jpeg' | 'png'>('jpeg'); // You can change this to png or jpeg based on preference

    const { data } = location.state || {};
    const url = data?.click_url
    

    const firstRowData = [
        {
           label:"Name",
           value:data?.name
        },
        {
            label:"App Group",
            value:data?.app_group_name 
        },
        {
            label:"Media Partner",
            value:data?.network_name 
        },
        {
            label:"Domain",
            value:data?.subdomain
        }
    ]

      // Format the data for Descriptions component
  const formatteOverviewData = {
    "Campaign": data?.app_group_campaign_name,
    "Click URL":data?.click_url,
    "Impression URL":data?.impression_url,
    "Total clicks": data?.click_count,
    "Total installs": data?.install_count,
    "Created at": formatDate(data?.created_dttm ,'DD-MM-YYYY hh:mm A'), // Convert timestamp to human-readable format
    "Updated at":formatDate(data?.last_updated_dttm,'DD-MM-YYYY hh:mm A') // Convert timestamp to human-readable format
  };

  const formattedDefaultData = {
    "Default App":data?.default?.app_name,
    "Total Clicks": data?.default?.click_count,
    "Total Installs":data?.default?.install_count,
    "Destination url":data?.default?.destination_url
  }

  const transformData = (dataArray: Array<any>) => {
    return dataArray?.map((item) => {
      // For each route, create dynamic labels and values
      const routesData = item.routes.map((route: any, index: number) => ({
        [`Tracker ${index + 1}`]: route.name, // Tracker name (e.g., Tracker 1, Tracker 2)
        [`Tracker ${index + 1} Clicks`]: route.click_count, // Tracker clicks (e.g., Tracker 1 Clicks)
        [`Tracker ${index + 1} Installs`]: route.install_count, // Tracker installs (e.g., Tracker 1 Installs)
        [`Destination url ${index + 1} `]:route.destination_url
      }));
  
      return {
        title: item.app_name, // app_name as title
        data: {
          Events: item.events.join(", "), // join multiple events if any
          // Spread each tracker data dynamically
          ...Object.assign({}, ...routesData),
        },
      };
    });
  };
  

   const transformedData = transformData(data?.routes_by_app);
   

     // Function to generate QR code with logo
  const generateQRCodeWithLogo = async () => {
    try {
      // Correct MIME type based on format selected
      const mimeType = format === 'jpeg' ? 'image/jpeg' : 'image/png';

      // Generate the QR code as a Data URL (JPEG format)
      const qrCodeDataUrl = await QRCode.toDataURL(url, {
        errorCorrectionLevel: 'H', // High error correction for better durability
        type: mimeType, // Use dynamic type based on format selected (PNG or JPEG)
        width: 500, // Increased QR code width for better quality
        margin: 4, // Increased margin for cleaner image
      });

      // Create an image element for the logo
      const logo = new Image();
      logo.src = meem;

      logo.onload = () => {
        // Create a canvas to draw the QR code with the logo
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set the canvas size based on the QR code size
        const qrSize = 250; // Increased QR size for better resolution
        canvas.width = qrSize;
        canvas.height = qrSize;

        // Draw the QR code onto the canvas
        const qrImage = new Image();
        qrImage.src = qrCodeDataUrl;

        qrImage.onload = () => {
          // Draw the QR code
          ctx?.drawImage(qrImage, 0, 0, qrSize, qrSize);

          // Adjust logo size (larger width, shorter height)
          const logoWidth = qrSize / 4; // Width of logo
          const logoHeight = qrSize / 4; // Reduced height of logo
          const logoX = (qrSize - logoWidth) / 2;
          const logoY = (qrSize - logoHeight) / 2;
          ctx?.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

          // Use canvas.toBlob() for better image handling and downloading
          canvas.toBlob(
            (blob) => {
              if (blob) {
                // Create a URL for the Blob
                const blobUrl = URL.createObjectURL(blob);
                setQrCodeUrl(blobUrl);
                setDownloadLink(blobUrl);
              } else {
                console.error('Error creating blob from canvas');
              }
            },
            mimeType,
            0.95 // Set quality for JPEG (0.95 is high quality)
          );
        };
      };

      logo.onerror = (e) => {
        console.error('Error loading logo image:', e);
        // Optionally, load a fallback logo if needed
        logo.src = '/path/to/fallback/logo.png'; // Fallback logo URL
      };
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

    // Handle download once QR code is ready
    const handleDownload = () => {
      if (downloadLink) {
        const link = document.createElement('a');
        link.href = downloadLink;
        link.download = `qr-code.${format}`;
        link.click();
      }
    };

  useEffect(()=>{
    generateQRCodeWithLogo()
  },[data])


 return(
    <div className="container-fluid p-sm-2 p-2 ">
      <Helmet>
        <title>Appice | SmartLink detail</title>
      </Helmet>
    <div className="w-100 h-100 d-flex justify-content-between flex-wrap my-3">
       <h4>SmartLink Details</h4>
    </div>

    <div className="smartlinks-details-container p-1">
    <div className="d-flex gap-3 m-3">
      <div className="d-flex flex-column gap-3 w-75">
    <div className="d-flex gap-4">
        {firstRowData.map((item, index) => (
        <Card key={index} hoverable className="smartlinks-details-container-card" style={index === 0 ? { width: "300px" } : {}}>
            <div className="d-flex justify-content-between text-overflow-ellipsis">
            <div>
                <p className="fw-bold mb-2">{item?.label}</p>
                <p> {item?.value}</p>
            </div>
            </div>
        </Card>
        ))}
    </div>

     <div className="d-flex flex-column w-100">
            <div>
            <DetailsOverview
            title="Overview"
            data={formatteOverviewData}
            labelStyle={{ fontWeight: "bold" }} // Optional label styling
            />
            </div>
     </div>
    </div>

     <div className="d-flex  flex-column w-25">
      <CustomAvatar shape="square" size={250}>
         <img src={qrCodeUrl} alt="QR Code with Logo" style={{ maxWidth: '100%', height: 'auto' }} />
       </CustomAvatar>
       <div className="mt-2 d-flex w-100 align-items-center justify-content-center">
      <PrimaryButton  onClick={handleDownload}>
        Download QR code
        <img className='ms-1' src={downloadIcon} alt="download icon" />
      </PrimaryButton>
      </div>

     </div>
    </div>
    {transformedData?.map((item, index) => (
      <div className=" m-3">
        <DetailsOverview
          key={index}
          title={item.title}
          data={item.data}
          labelStyle={{ fontWeight: "bold" }} // Optional label styling
        />
       </div>
      ))}
       <div className=" m-3">
        <DetailsOverview
          title={"Default"}
          data={formattedDefaultData}
          labelStyle={{ fontWeight: "bold" }} // Optional label styling
        />
       </div>
   
    </div>
    </div>
 )
};
