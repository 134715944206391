import { Form, FormInstance} from "antd";
import { useEffect, useState } from "react";
import "./AttributeWindow.css";
import { CloseOutlined } from "@ant-design/icons";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import { audienceItems } from "helperFunctions/audience";
import TextInput from "components/Inputs/TextInput/TextInput";
import { eOperatorType } from "@customTypes/audience";
import { useDispatch } from "react-redux";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import { E_OPERATOR_OPTIONS } from "utils/constants/selectorOptions";
type attributeDataType = {
  attr: string;
  operator: string;
  e_operator: eOperatorType
  value: string | boolean | number;
  attributeName?:string
};
type attributeType = {
  name: string;
  type: string;
};
type propType = {
  id: string | number;
  attributes: attributeType[];
  index: number;
  form: FormInstance;
  value: attributeDataType;
  totalAttributes: number;
  setValue: (data: attributeDataType | undefined) => void;
};
function AttributeWindow({
  id,
  attributes,
  index,
  setValue,
  form,
  value,
  totalAttributes,
}: propType) {
  const [showForm, setShowForm] = useState(false);
  const [valid, setValid] = useState(false);
  const [selectedAttributeType, setSelectedAttributeType] = useState("");
  const dispatch: any = useDispatch();
  const [fieldData, setFieldData] = useState({
    attr: value.attr || value.attributeName,
    operator: value.operator,
    e_operator: value.e_operator,
    value: value.value,
  });
  const handleInputChange = (data: any) => {
    setFieldData((prevState) => {
      const modifiedData={ ...prevState, ...data }
      setValue(modifiedData)
      return modifiedData;
    });
  }
  useEffect(() => {
    // if the attribute is selected , find its type(e.g string,number) and set it
    if (fieldData.attr) {
      attributes?.map(({ name, type }: attributeType) => {
        if (name == fieldData.attr) {
          setSelectedAttributeType(type);
        }
      });
    }
    // Check if all required fields (attribute, operator, and value) are filled
    if (fieldData.attr && fieldData.operator && fieldData.value) {
      // If all fields are filled, mark the form as valid and invoke callback function
      setValid(true);
    } else {
      // If any required field is empty, mark the form as invalid
      setValid(false);
    }
  }, [fieldData, attributes]);
  useEffect(() => {
    //reset fields on component unmount
    return () => {
      form.resetFields([
        `operator[${index}]_${id}`,
        `attribute[${index}]_${id}`,
        `value[${index}]_${id}`,
      ]);

    };
  }, []);

  return (
    <div className="attribute-window-parent-container">
      <div>
        {value?.e_operator && index !== 0 && (
          <div>
            <SegmentedCustom size={"small"} value={value.e_operator} options={E_OPERATOR_OPTIONS} onChange={(value:eOperatorType)=>handleInputChange({e_operator:value})}/>
          </div>
        )}
      </div>
      <div
        className="d-flex p-0 "
        onMouseLeave={() => setShowForm(valid ? false : true)}
      >
        <Form.Item
          hidden={valid && !showForm}
          name={`attribute[${index}]_${id}`}
          rules={[{ required: true, message: `${t("selectAttributeMsg")}` }]}
          className="m-0 wpx-218 me-1"
          initialValue={fieldData.attr}
        >
          <Selector
          data-testid="attribute-selector"
            
            placeholder={`${t("selectAttributeMsg")}`}
            onChange={(value) => {
              handleInputChange({ attr: value });
            }}
            options={attributes?.map((item: attributeType) => ({
              label: item.name,
              value: item.name,
            }))}
          ></Selector>
        </Form.Item>
        <Form.Item
          hidden={valid && !showForm}
          rules={[{ required: true, message: `${t("selectOperatorMsg")}` }]}
          name={`operator[${index}]_${id}`}
          className="m-0 wpx-218 me-1"
          initialValue={fieldData.operator}
        >
          <Selector
          data-testid="operator-selector"

            placeholder={`${t("selectOperatorMsg")}`}
            onChange={(value) => handleInputChange({ operator: value })}
            options={audienceItems[selectedAttributeType]}
          ></Selector>
        </Form.Item>{" "}
        {selectedAttributeType ? (
          <Form.Item
            hidden={valid && !showForm}
            rules={[{ required: true, message: `${t("valueLabel")}` }]}
            className="m-0 wpx-100 me-1"
            name={`value[${index}]_${id}`}
            initialValue={fieldData.value}
          >
            {selectedAttributeType !== "boolean" ? (
              <TextInput
              data-testid="attribute-value-input"
                value={fieldData.value}
                onChange={(e: any) =>
                  handleInputChange({ value: e.target.value })
                }
                placeholder={`${t("valueLabel")}`}
              />
            ) : (
              <Selector
              data-testid="attribute-value-selector"

                onChange={(value) => {
                  handleInputChange({ value:value })

                }}
                placeholder={`${t("valueLabel")}`}
                options={audienceItems[`${selectedAttributeType}list`]}
              ></Selector>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </div>
      {/* Display selected attribute, operator, and value preview if the form is valid and showform is false  */}
      {valid && !showForm && (
        <div className="d-flex align-items-center">
          <div
            className="result-window me-2 d-flex gap-2 text-overflow-ellipsis"
            onClick={() => {
              setShowForm(true);
            }}
          >
            <span>{fieldData.attr}</span>{" "}
            <span className="color-attribute-window-disabled-strong">
              {
                audienceItems[selectedAttributeType]?.find(
                  ({ value }: { value: string }) => value == fieldData.operator
                )?.label
              }
            </span>{" "}
            <span>{fieldData.value}</span>
          </div>
          {index + 1 == totalAttributes && (
            <CloseOutlined
            data-testid="attribute-delete-btn"
              onClick={() => {
                setValue(undefined);
              }}
            />
            
          )}
        </div>
      )}
    </div>
  );
}

export default AttributeWindow;
