import { useEffect, useState, Fragment } from "react"
import { Row } from "antd"
import Search from "../../components/Inputs/Search/Search"
import type { MenuProps } from "antd"
import closeIcon from "../../images/_close.svg"
import documentIcon from "../../images/document.png"
import Selector from "../../components/Selector/Selector"
import eyeIcon from "../../images/eye.png"
import CustomTable from "../../components/CustomTable/CustomTable"
import { Typography } from "antd"
import "./TransactionalCampaigns.css"
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton"
import { useSelector } from "react-redux"
import InnerNav from "../../components/InnerNav/InnerNav"
import centralApi from "../../services/centralApi"
import useMessage from "../../hooks/useMessage"
import { t } from "i18next"
import { API_ENDPOINTS } from "utils/constants"
import { downloadCsv } from "../../helperFunctions/common";
import downloadIcon from "../../images/downloadNew.svg";
import { CampaignStatusEnum } from "utils/enums"
import { PAGE_OPTIONS } from "utils/constants/selectorOptions"
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector"
import { DateRangeData } from "@customTypes/dateRange"


type dataType = {
  key: number
  nm: string
  Audience_Segment: string
  Reachable_Audience: string
  Sent: string
  Clicked: string
  CTR: string
  Start_Date: string
  End_Date: string
  Action: string
}

const { Text } = Typography

const TransactionalCampaigns: React.FC = () => {
  const [campaignTabFilter, setCampaignTabFilter] = useState("ACTIVE")
  const [usersData, setUsersData] = useState<any>([])
  const [usersDataCopy, setUsersDataCopy] = useState<any>([])
  const [pageData, setPageData] = useState(PAGE_OPTIONS.PAGINATION)
  const [loading, setLoading] = useState(false)
  const { showError, showSuccess } = useMessage()
 

  const loginUser = useSelector((state: any) => state.loginUser)
  const activeApp = useSelector((state: any) => state.activeApp)
  const appId = activeApp.appId
  const api_key = loginUser.data.api_key
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);

  const columns: any = [
    {
      title: t("typeLabel"),
      dataIndex: "t",
      className: "fix",
      width: 10,
    },
    {
      title: t("nameLabel"),
      dataIndex: "nm",
      width: 20,
    },
    {
      title: t("audienceSegmentLabel"),
      dataIndex: "segmentName",
      width: 120,
    },
    {
      title: t("reachableAudienceLabel"),
      dataIndex: "reach",
      width: 120,
    },
    {
      title: t("sentLabel"),
      dataIndex: "pushedTo",
      width: 10,
    },
    {
      title: t("clickedLabel"),
      dataIndex: "clicked",
      width: 10,
    },
    {
      title: t("CTRLabel"),
      dataIndex: "ctr",
      width: 10,
    },
    {
      title: t("conversionLabel"),
      dataIndex: "conversion",
      width: 10,
    },
    {
      title: t("startDateLabel"),
      dataIndex: "sd",
      width: 10,
      render: (startDate: string) => {
        const date = new Date(startDate)
        const formattedDate = date.toLocaleDateString("en-GB")
        return formattedDate
      },
    },
    {
      title: t("endDateLabel"),
      dataIndex: "ed",
      width: 10,
      render: (endDate: string) => {
        const date = new Date(endDate)     
        const formattedDate = date.toLocaleDateString("en-GB")
        return formattedDate
      },
    },
    {
      title: t("actionLabel"),
      dataIndex: "",
      width: 100,
      className: "item-align",
      render: (campaign: any) => {
        return (
          <Fragment>
              <PrimaryButton
              title="Delete"
                className="action-btn mx-1"
                onClick={() => {
                  setCampStatus(campaign?.cid, campaign?.active, "DRAFT")
                }}
              >
                <img
                  src={closeIcon}
                  alt={"close_icon"}
                  style={{ width: 16 }}
                ></img>
              </PrimaryButton>
              <PrimaryButton
              title="Copy"
                className="action-btn mx-1"
                onClick={() => {
                  copyCamp(campaign?.cid, campaign?.active)
                }}
              >
                <img
                  src={documentIcon}
                  alt={"document_icon"}
                  style={{ width: 16 }}
                ></img>
              </PrimaryButton>
                <PrimaryButton title="View Details" className="action-btn mx-1">
                  <img
                    src={eyeIcon}
                    alt={"view details"}
                    style={{ width: 16 }}
                  ></img>
                </PrimaryButton>
          </Fragment>
        )
      },
    },
  ]

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.toLowerCase()
    const filteredData = usersData.filter((user: dataType) => {
      return user?.nm && user?.nm?.toLowerCase().includes(searchText)
    })
    setUsersDataCopy(filteredData)
  }

  useEffect(() => {
    setUsersDataCopy(usersData)
  }, [usersData])

  /**
   * getTransactionalCampaignData will get us campaigns by status
   * @param status will describe the status of campaign i.e active,draft or past 
   */
  async function getTransactionalCampaignData(status: string) {
    const payload = {
      status: status,
      app_id: appId,
      api_key: api_key,
      cmpType: "t",
      sd: "",
      ed: "",
    }
    const url: string = API_ENDPOINTS.GET_CAMPAIGNS_BY_STATUS

    try {
      setLoading(true)
      const data = await centralApi("GET", url, null, payload)
      setUsersData(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onTabClick: MenuProps["onClick"] = (e) => {
    setCampaignTabFilter(e.key)
  }

  async function setCampStatus(cid: string, status: string, st: string) {
    try {
      const payload = {
        args: JSON.stringify({
          a: status,
          ud: Date.now(),
          st: st,
        }),
        cid,
        app_id: appId,
        api_key: api_key,
      }
      setLoading(true)
       await centralApi("GET", API_ENDPOINTS.SET_ACTIVE_CAMPAIGN_URL, null, payload)

     showSuccess(t("copiedIntoDraftMsg"))
      getTransactionalCampaignData(campaignTabFilter)
    } catch (error) {
      showError(t("somethingWrongLabel"));

    }
  }

  async function copyCamp(cid: string, status: string) {
    try {
      const payload = {
        args: JSON.stringify({
          d: status,
          ud: Date.now(),
        }),
        cid,
        app_id: appId,
        api_key: api_key,
      }
      await centralApi("GET", API_ENDPOINTS.COPY_CAMPAIGN_URL, null, payload)

      showSuccess(t("copiedIntoDraftMsg"))
    } catch (error) {
      showError(t("somethingWrongLabel"));

    }
  }

  useEffect(() => {
    if (api_key) {
      getTransactionalCampaignData(campaignTabFilter)
    }
  }, [dateRange, loginUser, campaignTabFilter])

  const filename = t('TransactionalCampCsvFileName'); 
  /**
   * downloadTranCampaign will download the csv file for transactional campaigns 
   */
  const downloadTranCampaign = async () => {
    const payload = {
      api_key: api_key,
      app_id: appId,
      cy : "t",
      status : campaignTabFilter,
      filename : filename

    };
    const url: string = API_ENDPOINTS.DOWNLOAD_TRANSACTIONAL_CAMPDATA;

    try {
      const response = await centralApi("GET", url, null, payload, true);
      downloadCsv(response, filename);
      showSuccess(t("downloadedSuccessfullyLabel"));
    } catch (error: any) {
      showError(error.message);
    }
  };

  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };

  return (
    <Fragment>
      <Row className="transactional-campaigns-header py-2">
        <h4>{t("transactionalCampaignLabel")}</h4>
        <Row>
          <strong className="my-auto me-1">Period </strong>
          <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="years"

            />        </Row>
      </Row>

      <div className="py-3 px-1 ">
        <InnerNav
          theme="light"
          onTabClick={onTabClick}
          selected={campaignTabFilter}
          menu={[
            {
              label: t("activeLabel"),
              key: CampaignStatusEnum?.ACTIVE,
            },
            {
              label:t("draftLabel"),
              key: CampaignStatusEnum?.DRAFT,
            },
            {
              label: t("pastLabel"),
              key: CampaignStatusEnum?.PAST,
            },
          ]}
        />
      </div>

      <div className="px-sm-3 px-1 transactional-campaign-table-container">
        <div className="d-flex flex-row w-100 py-4 ">
          <div>
            <Search
              placeholder="Search"
              onChange={handleSearch}
              size="middle"
              style={{
                width: "100%",
                maxWidth: "300px",
                marginRight: "10px",
              }}
            />
          </div>
          <Text className="my-auto ms-2 me-2">Show </Text>
          <Selector
                  onChange={(value) => {
                    setPageData({ ...pageData, pageSize: value })
                  }}

                  defaultValue={PAGE_OPTIONS.DEFAULT_VALUE}
                  size="middle"
                  options={PAGE_OPTIONS.DATA}
                />
          <Text className="my-auto ms-2">Entries </Text>
          <PrimaryButton title={t("downloadCsvLabel")}className="ms-auto" onClick={downloadTranCampaign}>
            <img src={downloadIcon}  alt="download icon"></img>
          </PrimaryButton>
        </div>

        <div>
          <CustomTable
            columns={columns}
            dataSource={
              typeof usersDataCopy === "function"
                ? usersDataCopy?.map((user: any) => ({
                    ...user,
                    key: user._id,
                  }))
                : []
            }
            totalEntries={usersData?.length}
            responsive
            loading={loading}
            pagination={{ pageSize: pageData, simple: false }}
            scroll={{ x: 1500 }}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default TransactionalCampaigns

TransactionalCampaigns.defaultProps = {
  tableSize: "small",
  pageSize: 10,
}
