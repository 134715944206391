import { useEffect, useState, Fragment } from "react"
import netcore from "../../../../../images/netcore_email.png"
import aws from "../../../../../images/aws-ses-logo.png"
import "./EmailSetting.css"
import InnerNav from "components/InnerNav/InnerNav"
import { Button, MenuProps, Space } from "antd"
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton"
import { Form, Input } from "antd"
import { useDispatch, useSelector } from "react-redux"
import centralApi from "services/centralApi"
import Spinner from "components/Spinner/Spinner"
import TextInput from "components/Inputs/TextInput/TextInput"
import Selector from "components/Selector/Selector"
import { t } from "i18next"
import { getToken } from "redux/features/app/authTokenSlice"
import {MinusCircleOutlined,PlusOutlined} from "assets/icons"
import useMessage from "hooks/useMessage"
interface EmailSettingProps {
  currentAppKey: any
  updateCurrentAppKey: () => void
}

const EmailSetting: React.FC<EmailSettingProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm()
  const dispatch: any = useDispatch();
  const emailValues = currentAppKey[0]?.emailCfg
  const loginUser = useSelector((state: any) => state.loginUser)
  const activeApp = useSelector((state: any) => state.activeApp)
  const authToken = useSelector((state: any) => state.authToken.token);
  const appKey = useSelector((state: any) => state.app_keys)
  const [appTabFilter, setAppTabFilter] = useState("email")
  const [loading, setLoading] = useState(false)
  const appId = activeApp.appId
  const api_key = loginUser.data.api_key
  const {showError,showSuccess}=useMessage()
  const onTabClick: MenuProps["onClick"] = (e) => {
    setAppTabFilter(e.key)
  }
  
  const onFormSubmit = (values: any) => {
    const { partner, smtp_port, ...rest } = values;
    const currentDateTime = new Date();
    const isoDateTime = currentDateTime.toISOString();
    const port = parseInt(smtp_port, 10); // Assuming base 10
    // Filter out empty or null values from rest
    const filteredRest = Object.fromEntries(
      Object.entries(rest).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    );
    const payload = {
      args: JSON.stringify({
        ...(appTabFilter === "email" && { smtp_port: port }),
        ...filteredRest, // Only include non-empty/defined values
        app_id: appId,
        partner: partner || "Netcore",
        api_key: api_key,
        appKey: api_key,
        date: isoDateTime,
        active: appTabFilter === "generic"
          ? "generic"
          : appTabFilter === "email"
          ? "smtp"
          : "aws_ses",
      }),
      app_id: appId,
      api_key: api_key,
      authToken
    };
  
    // Call the function to send the data
    sendEmailData(payload);
  
    // Dispatch actions after sending the data
    dispatch(getToken());
    updateCurrentAppKey();
  }
  
  async function sendEmailData(formData: any) {
    const url: string = `/i/apps/saveEmailConfiguration`

    try {
      setLoading(true)
      await centralApi("POST", url, formData, null)
      showSuccess(t("sucessfullySavedLabel"))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      showError(t("somethingWrongLabel"))
    }
  }

  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      smtp_username: emailValues?.smtp?.smtp_un,
      smtp_host: emailValues?.smtp?.smtp_h,
      smtp_password: emailValues?.smtp?.smtp_ps,
      smtp_port: emailValues?.smtp?.smtp_po,
      smtp_fromAddress: emailValues?.smtp?.smtp_fromAddress,
      smtp_fromName: emailValues?.smtp?.smtp_fromName,
    }
    form.setFieldsValue(updatedFormValues)
  }, [currentAppKey, form])
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      aws_accessKeyId: emailValues?.aws_ses?.aws_accessKeyId,
      aws_secretAccessKey: emailValues?.aws_ses?.aws_secretAccessKey,
      aws_region: emailValues?.aws_ses?.aws_region,
      aws_sourceEmail: emailValues?.aws_ses?.aws_sourceEmail,
    }
    form.setFieldsValue(updatedFormValues)
  }, [currentAppKey, form])
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      httpRequest: emailValues?.generic?.Verb,
      callBackUrl: emailValues?.generic?.callBackUrl,
      url: emailValues?.generic?.URL,
      emailProviderType: emailValues?.generic?.emailProviderType,
      partner: emailValues?.generic?.partner,
      keyValue: emailValues?.generic?.key_value,
    }
    form.setFieldsValue(updatedFormValues)
  }, [currentAppKey, form])
  return (
    <Fragment>
      <div className="email-setting-container ">
        <div className="email-setting-nav mt-4">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            menu={[
              {
                label: (
                  <Fragment>
                    <img
                      src={netcore}
                      alt="NetCore Icon"
                      className={`email-setting-img ${
                        appTabFilter === "email" ? "email-selected-tab" : ""
                      }`}
                    />
                  </Fragment>
                ),
                key: "email",
              },
              {
                label: (
                  <Fragment>
                    <img
                      src={aws}
                      alt="AWS Icon"
                      className={`email-setting-img ${
                        appTabFilter === "budget" ? "email-selected-tab" : ""
                      }`}
                    />
                  </Fragment>
                ),
                key: "budget",
              },
              {
                label: (
                  <Fragment>
                    <img
                      src={aws}
                      alt="AWS Icon"
                      className={`email-setting-img ${
                        appTabFilter === "budget" ? "email-selected-tab" : ""
                      }`}
                    />
                  </Fragment>
                ),
                key: "generic",
              },
            ]}
          />
        </div>
          <div className="d-flex flex-column gap-4 mt-3">
        {appTabFilter === "email" && (
            <div className="align-items-center email-setting-form ">
              {loading ? (
                <Spinner />
              ) : (
                <Form
                  form={form}
                  layout="horizontal"
                  labelCol={{flex:"150px"}}
                  labelAlign="left"
                  onFinish={onFormSubmit}
                >
                  <Form.Item
                    label="SMTP User Name"
                    name={"smtp_username"}
                    >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="SMTP Password"
                    name={"smtp_password"}
                 
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Host"
                    name={"smtp_host"}
                    rules={[{ required: true, message: "Please input Host" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Port"
                    name={"smtp_port"}
                    rules={[{ required: true, message: "Please input Port" }]}
                  >
                    <TextInput type="number" />
                  </Form.Item>
                  <Form.Item
                    label="From Name"
                    name={"smtp_fromName"}
                
                  >
                    <TextInput />
                  </Form.Item>
                  <Form.Item
                    label="From Address"
                    name={"smtp_fromAddress"}
                    rules={[
                      {
                        required: true,
                        message: "Please input From Address",
                        type: "email",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>

                  <div className="">
                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </div>
        )}
        {appTabFilter === "budget" && (
            <div className="align-items-center email-setting-form ">
              {loading ? (
                <Spinner />
              ) : (
                <Form form={form}
                layout="horizontal"
                labelCol={{flex:"150px"}}
                labelAlign="left"
                 onFinish={onFormSubmit}>
                  <Form.Item
                    label="Access key ID"
                    name={"aws_accessKeyId"}
                    rules={[
                      {
                        required: true,
                        message: "Please input Access Key ID!",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>
                  <Form.Item
                    label="Secret Access KeyID"
                    name={"aws_secretAccessKey"}
                    rules={[
                      {
                        required: true,
                        message: "Please input Secret Access KeyID",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>
                  <Form.Item
                    label="Region"
                    name={"aws_region"}
                    rules={[{ required: true, message: "Please input Region" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Source Email"
                    name={"aws_sourceEmail"}
                    rules={[
                      {
                        required: true,
                        message: "Please input Source Email",
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <div className="">
                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </div>
        )}
        {appTabFilter === "generic" && (
            <div className="align-items-center email-setting-form ">
              {loading ? (
                <Spinner />
              ) : (
                <Form  form={form} layout="horizontal"
                labelCol={{flex:"150px"}}
                labelAlign="left"
                onFinish={onFormSubmit}>
                  <Form.Item
                    label="Destination"
                    name={"callBackUrl"}
                    rules={[
                      {
                        required: true,
                        message: "Please input Destination ",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>
                  <Form.Item
                    label="Url"
                    name={"url"}
                    rules={[
                      {
                        required: true,
                        message: "Please input url",
                      },
                    ]}
                  >
                    <TextInput />
                  </Form.Item>

                  <Form.Item
                    label="HTTP method"
                    name={"httpRequest"}
                    rules={[{ required: true, message: "Select method" }]}
                  >
                    <Selector
                      // onChange={handleDisableButton}
                      size="middle"
                      style={{ width: "100%" }}
                      // defaultValue={"NONE"}
                      options={[
                        {
                          value: "GET",
                          label: "GET",
                        },
                        {
                          value: "POST",
                          label: "POST",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Partner"
                    name={"partner"}
                    rules={[{ required: true, message: "Select method" }]}
                  >
                    <Selector
                      // onChange={handleDisableButton}
                      size="middle"
                      style={{ width: "100%" }}
                      // defaultValue={"NONE"}
                      options={[
                        {
                          value: "Netcore",
                          label: "NETCORE",
                        },
                        {
                          value: "Gupshup",
                          label: "GUPSHUP",
                        },
                        {
                          value: "Sinch",
                          label: "SINCH",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email provider type"
                    name={"emailProviderType"}
                    rules={[{ required: true, message: "Select method" }]}
                  >
                    <Selector
                      // onChange={handleDisableButton}
                      size="middle"
                      style={{ width: "100%" }}
                      // defaultValue={"NONE"}
                      options={[
                        {
                          value: "smtp",
                          label: "SMTP",
                        },
                        {
                          value: "ses",
                          label: "SES",
                        },
                        {
                          value: "generic",
                          label: "GENERIC",
                        },
                        {
                          value: "cns",
                          label: "CNS",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label={t("valuesLabel") as string}>
                    <Form.List
                      name="keyValue"
                      initialValue={
                        emailValues?.generic?.key_value || [
                          { key: "", value: "" },
                        ]
                      }
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "key"]}
                                rules={[
                                  {
                                    required:
                                      form.getFieldValue("httpMethod") ===
                                        "GET" ||
                                      form.getFieldValue("httpMethod") ===
                                        "POST",
                                    message: t(
                                      "missingKeyValueLabel"
                                    ) as string,
                                  },
                                ]}
                              >
                                <TextInput maxLength={100} placeholder="Key" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                rules={[
                                  {
                                    required:
                                      form.getFieldValue("httpMethod") ===
                                        "GET" ||
                                      form.getFieldValue("httpMethod") ===
                                        "POST",
                                    message: t("plzInputLabel") as string,
                                  },
                                ]}
                              >
                                <TextInput
                                  maxLength={100}
                                  placeholder="Value"
                                />
                              </Form.Item>
                              {fields.length > 1 && (
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              )}
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              {t("addFieldLabel")}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Form.Item>

                  <div className="">
                    <PrimaryButton
                      size="middle"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </div>
        )}
        </div>
      </div>
    </Fragment>
  )
}
export default EmailSetting
