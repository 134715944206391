import React, { useState } from "react"
import { useEffect } from "react"
import CustomHighChart from "../../../components/Charts/CustomHighChart/CustomHighChart"
import centralApi from "../../../services/centralApi"
import Spinner from "components/Spinner/Spinner"
import { API_ENDPOINTS } from "utils/constants"
import { formatDateToReadable } from "helperFunctions/date"
import { t } from "i18next"
type propTypes = {
  api_key?: string
  app_id?: string
  cid?: string
  sd?: string
  ed?: string
  type?:string
}
const CampaignDetailChart: React.FC<propTypes> = ({
  api_key,
  app_id,
  cid,
  sd,
  ed,
  type
}) => {
  const [loading, setLoading] = useState(false)
  const [statsData, setStatsData] = useState<any | []>([]) // Change the initial state to null
  // const dummySeries =[Delivered,Open,Click,Bounce, CTR%]

  async function getCampaignStatsDateWise() {
    const payload = {
      api_key: api_key,
      app_id: app_id,
      cid: cid,
      endDate :ed,
      startDate: sd,

    }
    try {
      setLoading(true)
      const data = await centralApi("GET", API_ENDPOINTS.GET_CAMPAIGN_STATS_DATEWISE, null, payload)
      setStatsData(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getCampaignStatsDateWise()
  }, [])
 
  //get platformName
  const platform = statsData?.platformWiseData?.[0]?.platformName;
  const getDataForChartMetrics = (metric:any) => 
    statsData?.daywiseData?.[platform]
      ? Object.keys(statsData.daywiseData[platform]).map(date => statsData.daywiseData[platform][date]?.[metric] || 0)
      : [''];
      const seriesData = [
        { name: t("clickedLabel"), data: getDataForChartMetrics("Campaign_ClickedTo"), pointWidth: 40, yAxis: 1 },
        { name: t("sentLabel"), data: getDataForChartMetrics("Campaign_PushedTo"), pointWidth: 40, yAxis: 1 },
        { name: t("reachLabel") , data: getDataForChartMetrics("Campaign_PushedTo"), pointWidth: 40, yAxis: 1 },
        { name: t("receivedLabel"), data: getDataForChartMetrics("Campaign_ReceivedTo"), pointWidth: 40, yAxis: 1 },
        { 
          name: t("ctrLabel"), 
          type: "spline", 
          data: statsData?.campaign?.ctr 
            ? [parseFloat(statsData.campaign.ctr.replace('%', ''))] 
            : [], 
          yAxis: 0 
        }
      ];
  const options = {
    chart: {
      type: "column", // Use 'column' for bars
    },
    title: {
      text: "", 
    },
    plotOptions: {
      column: {
        plotBackgroundColor: "rgba(0, 0, 0, 0.02)", // Background color of the plot area
      },
    },
    xAxis: {
      categories: statsData?.daywiseData?.[platform]
      ? Object.keys(statsData.daywiseData[platform]).map(formatDateToReadable) // Format dates for categories
      : [''], // Fallback if no data exists
      title: {
        text: "Dates",
      },
    },
    yAxis: [
      {
        title: {
          text: "CTR %", // Title for the left y-axis
        },
        labels: {
          format: "{value}",
        },
        opposite: false, // Left side
      },
      {
        title: {
          text: "Reach", // Title for the right y-axis
        },
        labels: {
          format: "{value}",
        },
        opposite: true,
      },
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',          // Keep "View in Full Screen"
            'printChart',              // Keep "Print Chart"
            'separator',               // Add separator line
            'downloadCSV',             // Keep "Download CSV"
            'downloadXLS',             // Keep "Download XLS"
            'viewData',                // Keep "View Data Table"
          ]
        }
      }
    },
    
    series:seriesData
  };
  
  return (
    <div>
      {loading?<Spinner/>:
      <CustomHighChart options={options} />}
    </div>
  )
}

export default CampaignDetailChart
