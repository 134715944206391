import { Divider } from "antd";
import "./AppKeys.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../../../../components/Spinner/Spinner";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import { t } from "i18next";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { FeatureSettingsFlagTypes } from "utils/constants/constants";

interface AppKeysProps {
  currentAppKey: any;
}

const AppKeys: React.FC<AppKeysProps> = ({ currentAppKey }) => {
  const [form] = Form.useForm();
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const handleCopyClick = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setShowMessage({
        type: "success",
        message: t("sucessfullyCopiedLabel"),
        show: true,
      });
    } catch (error) {
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }

    hideMessage();
  };

  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      appKey: currentAppKey[0]?.key,
    };
    form.setFieldsValue(updatedFormValues);
  }, [currentAppKey, form]);
  return (
    <div className="app-keys-setting-container">
      {showMessage.show && (
        <ShowMessage type={showMessage.type} content={showMessage.message} />
      )}
      <div className="mt-4 mb-4  ">
        <div className="d-flex justify-content-between">
        <h6 className="app-keys-setting-container-heading">
          {t("appKeysSettingLabel")}
        </h6>
        </div>
        <Divider className="app-keys-setting-custom-divider" />

        <div>
          <div className=" col-sm-8 mt-4 align-items-center app-keys-setting-form ">
            {!currentAppKey[0]?.key ? (
              <Spinner />
            ) : (
              <Form form={form} layout="horizontal">
                <Form.Item label={t("appIdLabel")}>
                  <TextInput
                    value={appId}
                    maxLength={100}
                    addonAfter={
                      <div>
                        <PrimaryButton
                          type="primary"
                          onClick={() => handleCopyClick(appId)}
                        >
                          {t("copyLabel")}
                        </PrimaryButton>
                      </div>
                    }
                  />
                </Form.Item>
                <Form.Item label={t("appKeyLabel")} name="appKey">
                  <TextInput
                    maxLength={100}
                    addonAfter={
                      <div>
                        <PrimaryButton
                          type="primary"
                          onClick={() => handleCopyClick(currentAppKey[0]?.key)}
                        >
                          {t("copyLabel")}
                        </PrimaryButton>
                      </div>
                    }
                  />
                </Form.Item>

                <Form.Item label={t("apiKeyLabel")}>
                  <TextInput
                    value={api_key}
                    maxLength={100}
                    addonAfter={
                      <div>
                        <PrimaryButton
                          type="primary"
                          onClick={() => handleCopyClick(api_key)}
                        >
                          {t("copyLabel")}
                        </PrimaryButton>
                      </div>
                    }
                  />
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
        <AccessControl
          accessControl={{
            features: [FeatureSettingsFlagTypes.DOWNLOAD_SDK],
          }}
        >
        <div className="mt-4">
          <h6 className="app-keys-setting-container-heading">
            {" "}
            Download SDK's
          </h6>
          <Divider className="app-keys-setting-custom-divider" />
          <div className="d-flex gap-3 mt-4">
            <div>
              <p className=" m-0">IOS SDK</p>
            </div>
            <div>
              <Link to="" style={{ fontWeight: 600 }}>
                Download
              </Link>
            </div>
          </div>
        </div>
        </AccessControl>
      </div>
    </div>
  );
};
export default AppKeys;
