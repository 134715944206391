import "./Kochava.css"
import { Form } from "antd"
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import Spinner from "components/Spinner/Spinner";
import useMessage from "hooks/useMessage";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "redux/features/app/authTokenSlice";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { serialize ,deserialize} from "utils/models/serializer";
import {KochavaSettings} from "utils/models/index"

interface KochavaProps {
    currentAppKey: Record<string, any>;
    updateCurrentAppKey: () => void;
  }


const Kochava: React.FC<KochavaProps> = ({
    currentAppKey,
    updateCurrentAppKey,
  }) => {
    const loginUser = useSelector((state: any) => state.loginUser);
    const activeApp = useSelector((state: any) => state.activeApp);
    const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
    const dispatch: any = useDispatch();
    const authToken = useSelector((state: any) => state.authToken.token);
    const kochavaAppSettingsData = currentAppKey[0]?.attribution?.kochava;
    const [form] = Form.useForm();
    const appId = activeApp.appId;
    const api_key = loginUser.data.api_key;
    const { showError, showSuccess } = useMessage();  

    //setting kochava data in use State
    const [kochava, setKochava] = useState(
      {
        apiKey:kochavaAppSettingsData?.apiKey,
        appguid:kochavaAppSettingsData?.appguid,
        deliveryMethod:kochavaAppSettingsData?.deliveryMethod,
        deliveryMethodDetails:{
          region:kochavaAppSettingsData?.deliveryMethodDetails?.region,
          bucket:kochavaAppSettingsData?.deliveryMethodDetails?.bucket,
          accessKey:kochavaAppSettingsData?.deliveryMethodDetails?.accessKey,
          secretKey:kochavaAppSettingsData?.deliveryMethodDetails?.secretKey
        }
      }
    )

    //submits  kochava data and saves it in db
    const onFormSubmit = async() => {
        //Serializing and deserializing scheduler Payload
        const deserializedData = deserialize(kochava, KochavaSettings );
        const serializedData =  serialize(deserializedData as KochavaSettings );
        const url: string = API_ENDPOINTS.UPDATE_ATTRIBUTION_URL;
        try {
            const payload = {
                args: JSON.stringify({
                  app_id: appId,
                  kochava:serializedData
                }),
                app_id: appId,
                api_key: api_key,
                authToken
              };
              setLoading(BOOLEAN_OPTIONS.TRUE);
             const response = await centralApi("POST", url, payload, null);
             if(response){
              setLoading(BOOLEAN_OPTIONS.FALSE); 
              showSuccess(t("sucessfullySavedLabel"));
              updateCurrentAppKey()
              dispatch(getToken());
             }
        } catch (error) {
           dispatch(getToken());
           showError(t("somethingWrongLabel")) 
        }    
    }

    // handleFormChange handling form data change
    const handleFormChange = (key: string, value: any) => {
      setKochava((prevState: typeof kochava) => ({
        ...prevState,
        [key]: value,
       }));
      };
  
  return (
    <>
     {loading ? (
         <div className="smartlinks-create-spinner">
            <Spinner />
         </div>      
        ):(
    <div className="attribution-main-container mt-3">
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFormSubmit}
            >
              <Form.Item
                     label={t("apiKeyLabel")}
                     name={"apiKey"}
                     initialValue={kochava?.apiKey}
                  >      
                  <TextInput
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleFormChange("apiKey", e.target.value);
                  }}
                  />           
              </Form.Item>
              <Form.Item
                     label={t("appGuidLabel")}
                     name={"appguid"}
                     initialValue={kochava?.appguid}
                  >      
                  <TextInput 
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleFormChange("appguid", e.target.value);
                  }}
                  />           
              </Form.Item>
              <Form.Item
                     label={t("deliverMethodLabel")}
                     name={"deliveryMethod"}
                     initialValue={kochava?.deliveryMethod}
                  >      
                  <Selector
                    onChange={(value) => {
                      handleFormChange("deliveryMethod", value);
                    }}
                    options={[
                      {
                        label:"AWS S3 ",
                        value:"s3"
                      }
                    ]}
                  />           
              </Form.Item>
              {kochava?.deliveryMethod !== undefined && (
              <>
              <Form.Item
                  label={t("regionLabel")}
                  name={"region"}
                  initialValue={kochava?.deliveryMethodDetails?.region}
                  >      
                  <TextInput 
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleFormChange("deliveryMethodDetails", {
                      ...kochava.deliveryMethodDetails,
                      region: e.target.value, 
                  });
                  }}
                  />         
              </Form.Item>
              <Form.Item
                  label={t("bucketLabel")}
                  name={"bucket"}
                  initialValue={kochava?.deliveryMethodDetails?.bucket}

                  >      
                  <TextInput 
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleFormChange("deliveryMethodDetails", {
                      ...kochava.deliveryMethodDetails,
                      bucket: e.target.value, 
                  });
                  }}
                  />         
              </Form.Item>
              <Form.Item
                  label={t("accessKeyLabel")}
                  name={"accessKey"}
                  initialValue={kochava?.deliveryMethodDetails?.accessKey}

                  >      
                  <TextInput 
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleFormChange("deliveryMethodDetails", {
                        ...kochava.deliveryMethodDetails,
                        accessKey: e.target.value, 
                    });
                    }}
                  />         
              </Form.Item>
              <Form.Item
                  label={t("secretKeyLabel")}
                  name={"secretKey"}
                  initialValue={kochava?.deliveryMethodDetails?.secretKey}
                  >      
                  <TextInput 
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleFormChange("deliveryMethodDetails", {
                      ...kochava.deliveryMethodDetails,
                      secretKey: e.target.value, 
                  });
                  }}
                  />         
              </Form.Item>
              </>
              )}
              <div >
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                </div>
            </Form>
    </div>
        )}
    </>
  )
}

export default Kochava