"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PushPayload = exports.ActiveCampaigns = exports.campaignData = exports.Actions = exports.NativeData = exports.InAppData = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const base_1 = require("../../models/common/base");
/**
 * Converts an array of objects into a single object by extracting key-value pairs.
 *
 * Each object in the array is expected to have a 'type' key and at least one other key.
 * The value of the non-'type' key is decoded and assigned to the new object. If the type is "string",
 * the value is URI decoded as a string. If the type is not "string", the value is JSON parsed after URI decoding.
 *
 * @param {Array<Record<string, any>>} array - The array of objects to convert. Each object should contain a 'type' key and at least one other key.
 * @returns {Record<string, any>} A new object constructed from the key-value pairs in the array.
*/
function convertArrayToObject(array) {
    let cdataObj = {};
    array.forEach(obj => {
        const keys = Object.keys(obj);
        const keyWithoutType = keys.find(key => key !== "type");
        if (keyWithoutType) {
            cdataObj[keyWithoutType] = (obj.type == "string")
                ? decodeURIComponent(obj[keyWithoutType])
                : JSON.parse(decodeURIComponent(obj[keyWithoutType]));
        }
    });
    return cdataObj;
}
// Define a TypeScript class named InAppData
class InAppData {
    constructor(mode, ni, ii, et, aurl, sa, at, atc, htc, dtc, bgc, cdata) {
        this.mode = mode;
        this.ni = ni;
        this.ii = ii;
        this.et = et;
        this.aurl = aurl;
        this.sa = sa;
        this.at = at;
        this.atc = atc;
        this.htc = htc;
        this.dtc = dtc;
        this.bgc = bgc;
        this.cdata = cdata;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "mode", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "ni", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "ii", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "nh", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "nd", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "et", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "aurl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], InAppData.prototype, "sa", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "at", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "atc", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "htc", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "dtc", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "bgc", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "did", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "userid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "fcmid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], InAppData.prototype, "hmsid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], InAppData.prototype, "cdata", void 0);
exports.InAppData = InAppData;
// Define a TypeScript class named NativeData
class NativeData {
    constructor(externalUrl, notificationType, cdata) {
        this.eurl = externalUrl;
        this.nt = notificationType;
        this.cdata = cdata;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'eurl' }),
    __metadata("design:type", String)
], NativeData.prototype, "eurl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'camp_id' }),
    __metadata("design:type", String)
], NativeData.prototype, "camp_id", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'did' }),
    __metadata("design:type", String)
], NativeData.prototype, "did", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'userid' }),
    __metadata("design:type", String)
], NativeData.prototype, "userid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'nt' }),
    __metadata("design:type", String)
], NativeData.prototype, "nt", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('ts'),
    __metadata("design:type", Number)
], NativeData.prototype, "ts", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'fcmid' }),
    __metadata("design:type", String)
], NativeData.prototype, "fcmid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'hmsid' }),
    __metadata("design:type", String)
], NativeData.prototype, "hmsid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'templateData' }),
    __metadata("design:type", Object)
], NativeData.prototype, "templateData", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({
        name: 'cdata',
        beforeDeserialize: (data) => convertArrayToObject(data)
    }),
    __metadata("design:type", Object)
], NativeData.prototype, "cdata", void 0);
exports.NativeData = NativeData;
// Class representing Frequency
class Frequency {
    // Constructor
    constructor(show, rangeStatus, rangeValue, pastDays, interval, iUnit, e, s, d, l) {
        this.show = show;
        this.rangeStatus = rangeStatus;
        this.rangeValue = rangeValue;
        this.pastDays = pastDays;
        this.interval = interval;
        this.iUnit = iUnit;
        this.e = e;
        this.s = s;
        this.d = d;
        this.l = l;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Frequency.prototype, "show", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'rangeStatus' }),
    __metadata("design:type", String)
], Frequency.prototype, "rangeStatus", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'rangeValue' }),
    __metadata("design:type", String)
], Frequency.prototype, "rangeValue", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'pastDays' }),
    __metadata("design:type", String)
], Frequency.prototype, "pastDays", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], Frequency.prototype, "interval", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'iUnit' }),
    __metadata("design:type", String)
], Frequency.prototype, "iUnit", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Frequency.prototype, "e", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Frequency.prototype, "s", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Frequency.prototype, "d", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Frequency.prototype, "l", void 0);
// Class representing And
class And {
    // Constructor
    constructor(b, i, d, l, g, f, m) {
        this.b = b;
        this.i = i;
        this.d = d;
        this.l = l;
        this.g = g;
        this.f = f;
        this.m = m;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], And.prototype, "b", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], And.prototype, "i", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], And.prototype, "d", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], And.prototype, "l", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], And.prototype, "g", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], And.prototype, "f", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], And.prototype, "m", void 0);
// Class representing Aud
class Audience {
    // Constructor
    constructor(who, what, where, when) {
        this.who = who;
        this.what = what;
        this.where = where;
        this.when = when;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Audience.prototype, "who", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Audience.prototype, "what", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Audience.prototype, "where", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Audience.prototype, "when", void 0);
/**
 * Actions class represents actions related to a Call-To-Action (CTA).
*/
class Actions {
    /**
     * Constructor to initialize the Actions object with values.
     * @param abt - The 'abt (Action button title)' value for the action.
     * @param acu - The 'acu (Action URL)' value for the action.
     * @param act - The 'act (Action Type)' value for the action.
    */
    constructor(abt, acu, act) {
        this.abt = abt; // Set the 'abt' property
        this.acu = acu; // Set the 'acu' property
        this.act = act; // Set the 'act' property
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'abt' }),
    __metadata("design:type", String)
], Actions.prototype, "abt", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'acu' }),
    __metadata("design:type", String)
], Actions.prototype, "acu", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'act' }),
    __metadata("design:type", String)
], Actions.prototype, "act", void 0);
exports.Actions = Actions;
// Class representing Data
class campaignData {
    // Constructor
    constructor(nh, nd, ni, end, eni, and, ty, eurl, et, actions, sound, vibrate, badge, cdata) {
        this.nh = nh;
        this.nd = nd;
        this.ni = ni;
        this.end = end;
        this.eni = eni;
        this.and = and;
        this.ty = ty;
        this.eurl = eurl;
        this.et = et;
        this.actions = actions;
        this.sound = sound;
        this.vibrate = vibrate;
        this.badge = badge;
        this.cdata = cdata;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'nh' }),
    __metadata("design:type", String)
], campaignData.prototype, "nh", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'camp_id' }),
    __metadata("design:type", String)
], campaignData.prototype, "camp_id", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'nd' }),
    __metadata("design:type", String)
], campaignData.prototype, "nd", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'ni' }),
    __metadata("design:type", String)
], campaignData.prototype, "ni", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'end' }),
    __metadata("design:type", String)
], campaignData.prototype, "end", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'eni' }),
    __metadata("design:type", String)
], campaignData.prototype, "eni", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", And)
], campaignData.prototype, "and", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], campaignData.prototype, "ty", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], campaignData.prototype, "eurl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], campaignData.prototype, "et", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], campaignData.prototype, "actions", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], campaignData.prototype, "sound", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], campaignData.prototype, "vibrate", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], campaignData.prototype, "badge", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('did'),
    __metadata("design:type", String)
], campaignData.prototype, "did", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('category'),
    __metadata("design:type", String)
], campaignData.prototype, "category", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('userid'),
    __metadata("design:type", String)
], campaignData.prototype, "userid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('nid'),
    __metadata("design:type", String)
], campaignData.prototype, "nid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('mid'),
    __metadata("design:type", String)
], campaignData.prototype, "mid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('t'),
    __metadata("design:type", Number)
], campaignData.prototype, "t", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('fc'),
    __metadata("design:type", Number)
], campaignData.prototype, "fc", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('ts'),
    __metadata("design:type", Number)
], campaignData.prototype, "ts", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('pa'),
    __metadata("design:type", Boolean)
], campaignData.prototype, "pa", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('fcmid'),
    __metadata("design:type", String)
], campaignData.prototype, "fcmid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('hmsid'),
    __metadata("design:type", String)
], campaignData.prototype, "hmsid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('ai_tag'),
    __metadata("design:type", String)
], campaignData.prototype, "ai_tag", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('campid'),
    __metadata("design:type", String)
], campaignData.prototype, "campid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('templateData'),
    __metadata("design:type", Object)
], campaignData.prototype, "templateData", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('customFields'),
    __metadata("design:type", Object)
], campaignData.prototype, "customFields", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({
        name: 'cdata',
        beforeDeserialize: (data) => convertArrayToObject(data)
    }),
    __metadata("design:type", Object)
], campaignData.prototype, "cdata", void 0);
exports.campaignData = campaignData;
// Class representing ActiveCampaigns
class ActiveCampaigns {
    // Constructor
    constructor(_id, nm, sd, ed, st, t, d, fre, timeSelector, time, delays, delayi, delayu, data, allweek, allday, aud) {
        this._id = _id;
        this.nm = nm;
        this.sd = sd;
        this.ed = ed;
        this.st = st;
        this.t = t;
        this.d = d;
        this.fre = fre;
        this.timeSelector = timeSelector;
        this.time = time;
        this.delays = delays;
        this.delayi = delayi;
        this.delayu = delayu;
        this.data = data;
        this.allweek = allweek;
        this.allday = allday;
        this.aud = aud;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: '_id' }),
    __metadata("design:type", String)
], ActiveCampaigns.prototype, "_id", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ActiveCampaigns.prototype, "nm", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ActiveCampaigns.prototype, "p", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ActiveCampaigns.prototype, "ma", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], ActiveCampaigns.prototype, "sd", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], ActiveCampaigns.prototype, "ed", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ActiveCampaigns.prototype, "st", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ActiveCampaigns.prototype, "t", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], ActiveCampaigns.prototype, "d", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Frequency)
], ActiveCampaigns.prototype, "fre", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'timeSelector' }),
    __metadata("design:type", String)
], ActiveCampaigns.prototype, "timeSelector", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], ActiveCampaigns.prototype, "time", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], ActiveCampaigns.prototype, "delays", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], ActiveCampaigns.prototype, "delayi", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ActiveCampaigns.prototype, "delayu", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], ActiveCampaigns.prototype, "data", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], ActiveCampaigns.prototype, "allweek", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], ActiveCampaigns.prototype, "allday", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Audience)
], ActiveCampaigns.prototype, "aud", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('templateData'),
    __metadata("design:type", Object)
], ActiveCampaigns.prototype, "templateData", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('customFields'),
    __metadata("design:type", Object)
], ActiveCampaigns.prototype, "customFields", void 0);
exports.ActiveCampaigns = ActiveCampaigns;
// Class representing Message
class Message {
    // Constructor
    constructor(ai_tag, activeCampaigns = [], sp) {
        this.ai_tag = ai_tag;
        this.activeCampaigns = activeCampaigns;
        this.sp = sp;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'ai_tag' }),
    __metadata("design:type", String)
], Message.prototype, "ai_tag", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'activeCampaigns' }),
    __metadata("design:type", Array)
], Message.prototype, "activeCampaigns", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Message.prototype, "sp", void 0);
// Class representing combined FCM and APNS payloads
class PushPayload extends base_1.BaseModel {
    // Constructor
    constructor(data, aps, registrationIds) {
        super();
        this.data = data;
        this.aps = aps;
        this.registrationIds = registrationIds;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'registration_ids' }),
    __metadata("design:type", Array)
], PushPayload.prototype, "registrationIds", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ beforeDeserialize: (data) => convertData(data) }),
    __metadata("design:type", Object)
], PushPayload.prototype, "data", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: 'aps' }),
    __metadata("design:type", Object)
], PushPayload.prototype, "aps", void 0);
exports.PushPayload = PushPayload;
/**
 * Decodes the URI components of all values in a given object.
 *
 * @param {Record<string, any>} cdata - The object containing key-value pairs where the values may be URI encoded.
 * @returns {Record<string, any>} A new object with the same keys but with decoded URI components as values.
*/
function decodeCdataObject(cdata) {
    let cdataObj = { ...cdata };
    for (let key of Object.keys(cdataObj)) {
        try {
            cdataObj[key] = decodeURIComponent(cdataObj[key]);
        }
        catch (error) {
            cdataObj[key] = cdataObj[key]; // no need for decoding
        }
    }
    return cdataObj;
}
/**
 * Converts the data in the provided message before deserialization.
 *
 * @param {Object} data - The object containing the message to be processed.
 * @param {Message} data.message - The message object that contains campaign data.
 * @returns {Object} An object with the converted message.
*/
function convertData(data) {
    const message = data.message;
    message.activeCampaigns?.forEach(campaign => {
        if (campaign.data && Array.isArray(campaign.data.cdata)) {
            campaign.data.cdata = convertArrayToObject(campaign.data.cdata);
        }
        else if (campaign.data && typeof campaign.data.cdata === 'object') {
            campaign.data.cdata = decodeCdataObject(campaign.data.cdata);
        }
    });
    return { message };
}
