import './AuditLogs.css'
import { Divider, Form, Space } from 'antd'
import Spinner from 'components/Spinner/Spinner'
import { t } from 'i18next'
import dayjs from "dayjs";
import  { Fragment, useEffect, useState } from 'react'
import { BOOLEAN_OPTIONS, PAGE_OPTIONS } from 'utils/constants/selectorOptions'
import Search from "components/Inputs/Search/Search";
import Selector from 'components/Selector/Selector'
import useDebouncedValue from 'hooks/useDebouncedState'
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton'
import { CopyTwoTone, FilterOutlined , InfoCircleOutlined } from '@ant-design/icons'
import CustomTable from 'components/CustomTable/CustomTable'
import CustomDrawer from 'components/CustomDrawer/CustomDrawer'
import TextInput from 'components/Inputs/TextInput/TextInput'
import { DateRangeSelector } from 'components/DateTimeSelectors/DateRangeSelector/DateRangeSelector'
import { API_ENDPOINTS } from 'utils/constants'
import centralApi from 'services/centralApi'
import { useSelector } from 'react-redux'
import { copyText } from 'helperFunctions/string';
import useMessage from 'hooks/useMessage';



const AuditLogs = () => {
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const [pageData, setPageData] = useState(PAGE_OPTIONS.PAGINATION);
  const loginUser = useSelector((state: any) => state.loginUser);
  const [data, setData] = useState<{ auditLogs: any[], auditLogCounts:string }| null>(null);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [search, setSearch] = useDebouncedValue<string | undefined>(undefined, 500);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [filter , setFilter] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [form] = Form.useForm();
  const [dateRange, setDateRange] = useState<any | undefined>();
  const {showError,showSuccess}=useMessage();

  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;


  //handles filter date range in filter option
  const handleDateRange = (dateRangeData: any) => {
    setDateRange({
      start : new Date(dateRangeData.startDate_CNew),
      end : new Date(dateRangeData.endDate_CNew)
    })
  };

   // When the page number of the table changes
   const onPageChange = (pagination: any,) => {
    setPageData({
      ...pageData,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
 
  //handles filter option
const handleFilter = (values:any) => {
  setFilter({...values , date_range:dateRange})
  form.resetFields();
}

  /**
   * 
   * @param text takes email string and copies it from copyTxt helper function
   */
  const handleTextCopy = async (text: string) => {
    const res = await copyText(text)
    showSuccess(res.message)
  }

 useEffect(() => {
  if (filter) {
    // Fetch logs whenever the filter is updated
    getAuditLogs();
    setFilter(undefined);
  }
 }, [filter]);

 /**
  * gets audit logs as per the payload
  */
  async function getAuditLogs() {
    const url: string = API_ENDPOINTS.GET_AUDIT_LOGS_URL;
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        page: pageData.current,
        length: pageData.pageSize,
        value: search,
        filter
      }),
      api_key: api_key,
    };

    try {
      setLoading(BOOLEAN_OPTIONS.TRUE);
      const response = await centralApi("GET", url, null, payload);
      setData(response);
      setLoading(BOOLEAN_OPTIONS.FALSE);
    } catch (e: any) {
      setLoading(BOOLEAN_OPTIONS.FALSE);
    }
  }

  useEffect(() => {
    getAuditLogs();
  }, [pageData,search,form]);

  const columns = [
    { title: t("userNameLabel"),className: "fix", dataIndex: "UserName", key: "UserName", render: (email: string) => {
      return ( 
        <span className="d-flex align-items-center justify-content-between">
           {email}
           <CopyTwoTone onClick={() => handleTextCopy(email)} />
        </span>
      );
    } },
    { title: t("actionLabel"), dataIndex: "Action", key: "Action" },
    { title: t("actionTypeLabel"), dataIndex: "ActionType", key: "ActionType" },
    { title: t("moduleLabel"), dataIndex: "Module", key: "Module" },
    { title: t("clientIpLabel"), dataIndex: "ClientIP", key: "ClientIP" },
    { title: t("hostNameLabel"), dataIndex: "HostName", key: "HostName" },
    { title: t("descriptionLabel"), dataIndex: "Description", key: "Description",width: 400, },
    { title: t("dateTimeLabel"), dataIndex: "DateTime", key: "DateTime", render: (text: string) => dayjs(new Date(text)).format('DD-MM-YYYY HH:mm') },
  ];
  

  return (
    <Fragment>
    <div className="audit-logs-container">
      <div className="mt-4 mb-4">
        <h6 className="audit-logs-container-heading">{t("auditLogsLabel")}</h6>
        <Divider className="audit-logs-custom-divider" />
      </div>
  
      <div className="col-sm-8 mt-4 mb-4 align-items-center audit-logs-setting-form">
        {loading ? (
          <Spinner />
        ) : (
          <div className="audit-logs-inner-container">
             <div className="audit-logs-table mt-3">
                  <div className="audit-logs-search mb-3">
                    <Search
                      placeholder={"user name, action or module"}
                      value={inputValue}  // Bind to inputValue
                      onChange={(e: any) => {
                      setInputValue(e.target.value);  // Update inputValue for immediate feedback
                      setSearch(e.target.value);      // Update debounced search
                    }}
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                        marginRight: "10px",
                        marginBottom: "10px",
                        height: "100%",
                      }}
                    />
                    <PrimaryButton className={"me-2"} onClick={() => {setShowDrawer(true)}}>
                      <FilterOutlined/>
                    </PrimaryButton>
                    <Selector
                      onChange={(value) => {
                        setPageData({ ...pageData, pageSize: value });
                      }}
                      options={PAGE_OPTIONS.DATA}
                      defaultValue={pageData.pageSize}
                    />
                  </div>
                  <CustomTable
                    rowKey="DateTime"
                    columns={columns}
                    dataSource={data?.auditLogs}
                    loading={loading}
                    size="small"
                    scroll={ {x: 1500 }}
                    onChange={onPageChange}
                    pagination={{
                      pageSize: pageData.pageSize,
                      current: pageData.current,
                      simple: pageData.simple,
                      total:  data?.auditLogCounts ? parseInt(data.auditLogCounts, 10) : 0,
                    }}  
                    />
                    
                     <CustomDrawer
                      title={
                        <span>
                        <FilterOutlined style={{ marginRight: 8 }} /> Filter
                        </span>
                        } 
                        placement="right"
                        onClose={() => setShowDrawer(BOOLEAN_OPTIONS.FALSE)}
                        open={showDrawer}
                        closable={BOOLEAN_OPTIONS.FALSE}
                        footer={
                            <Space className="d-flex  justify-content-end gap-2 ">
                               <Form
                               form={form}
                               onFinish={handleFilter}
                               layout="vertical"
                                >
                              <PrimaryButton
                                onClick={() => {
                                    setShowDrawer(BOOLEAN_OPTIONS.FALSE);
                                    form.resetFields();
                                }}
                               >
                                {t("cancelLabel")}
                              </PrimaryButton>
                              <PrimaryButton
                                type="primary"
                                className="ms-2"
                                htmlType="submit"
                                onClick={() => {
                                 setShowDrawer(BOOLEAN_OPTIONS.FALSE);
                                }}
                              >
                                {t("applyLabel")}
                              </PrimaryButton>
                              </Form>
                            </Space>
                          }
                          height={"90%"}
                        >
                       <div className='audit-log-filter-drawer'>
                       <Form
                        form={form}
                        onFinish={handleFilter}
                        layout="vertical"
                        >
                        <Form.Item
                            label="User Name"
                            name={"user_name"}
                            tooltip={{ title: t('optionalLabel'), icon: <InfoCircleOutlined /> }}
                        >
                            <TextInput  />
                        </Form.Item>

                        <Form.Item
                            label="Action"
                            name={"action"}
                            tooltip={{ title: t('optionalLabel'), icon: <InfoCircleOutlined /> }}
                        >
                            <TextInput  />
             
                        </Form.Item>
                        <Form.Item
                            label="Date range"
                            name={"date_range"}
                            tooltip={{ title: t('optionalLabel'), icon: <InfoCircleOutlined /> }}
                        >
                         <DateRangeSelector
                            className='w-100'
                            onDateChange={handleDateRange}
                            setInitialDate={false}
                          />

                      </Form.Item>
                      </Form>
                        

                       </div>
                        
                    </CustomDrawer>
                </div>
          </div>
        )}
      </div>
    </div>
  </Fragment>
  )
}

export default AuditLogs