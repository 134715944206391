import config from "config";
import { EventItem } from "pages/Campaign/CampaignDetail/CampaignDetail";
import { EncryptionService } from "src_typescript_appice-core/dist/utils/security/signatureservice";
import { CampaignChannelTypeEnum, PlatformEnum,UserRoleEnum  } from "utils/enums";

type SelectedImageType = "Header" | "Footer" | "Mini"|"Fullscreen"
export function downloadFileFromUrl(fileUrl: string, fileName: string) {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const concatDayWiseDauDuplicateDate = (data: [string, number][]) => {
  const modifiedData: [string, number][] = [];
  const keyValues: any = {};
  data?.forEach((dateData) => {
    const key = dateData[0];
    const value = dateData[1];
    if (keyValues[key]) {
      keyValues[key] += value;
    } else {
      keyValues[key] = value;
    }
  });
  Object.keys(keyValues).forEach((key) => {
    modifiedData.push([key, keyValues[key]]);
  });
  return modifiedData;
};
export function generateCSV(data: any, filename: string, columns?: string[]) {
  if (!data || data.length === 0) {
    console.error("No data to export.");
    return;
  }

  const csvRows = [];

  // If columns are provided, use them; otherwise, extract columns from the first item in the data
  const dataColumns = columns || Object.keys(data[0]);
  csvRows.push(dataColumns.join(","));

  data.forEach((item: any) => {
    const rowData = dataColumns.map((column) => item[column]);
    csvRows.push(rowData.join(","));
  });

  const csvData = csvRows.join("\n");
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  URL.revokeObjectURL(url);
}
// Function to download CSV using API
export function downloadCsv(csvData: string, fileName: string) {
  const blob = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}
export function downloadFile(fileData: Blob, fileName: string) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(fileData);
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

type DateRange = {
  startDate: string;
  endDate: string;
};

type DateArray = string[];

export function getDatesRange({ startDate, endDate }: DateRange): DateArray {
  const dateArray: DateArray = [];
  const currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}
export function timestampToFormattedDate(timestamp: any) {
  // Check if the timestamp is in milliseconds and convert to seconds if needed
  if (timestamp > 1000000000000) {
    timestamp = Math.floor(timestamp / 1000);
  }

  const milliseconds = timestamp * 1000;
  const date = new Date(milliseconds);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const ampm = hours >= 12 ? "AM" : "PM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  return `${month} ${day}, ${year} ${formattedHours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${ampm}`;
}
export function formatISODateString(formatISODateString: string | undefined) {
  if (!formatISODateString) {
    return "Invalid date"; // Or handle the undefined case accordingly
  }

  const date = new Date(formatISODateString)
  // Array of month names
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Get month, day, year, hours, minutes, and seconds from the date
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Format hours to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Add leading zero for minutes and seconds if less than 10
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  // Construct the formatted date string
  return `${month} ${day}, ${year} ${hours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
}

export function capitalizeFirstLetter(str: string | undefined) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";
}
// Function to return the default attributes based on the campaign channel type
const getDefaultAttributes = (campType: CampaignChannelTypeEnum, cid: string) => {
  switch (campType) {
    case CampaignChannelTypeEnum.WHATSAPP:
      return [
        {
          attributeName: "eventType",
          type: "string",
          value: 'URL_SHORTENER',
          operator: "eq",
        },
        {
          attributeName: "mtag1",
          type: "string",
          value: cid,
          operator: "eq",
        }
      ];
    
    case CampaignChannelTypeEnum.SMS:
    case CampaignChannelTypeEnum.EMAIL:
      return [
        {
          attributeName: "utm_id",
          type: "string",
          value: cid,
          operator: "eq",
        }
      ];
    
    default:
      return [
        {
          attributeName: "campid",
          type: "string",
          value: cid,
          operator: "eq",
        }
      ];
  }
};

// Function to return the default event name based on the campaign channel type
export const getDefaultEvent = (campType: CampaignChannelTypeEnum) => {
  switch (campType) {
    case CampaignChannelTypeEnum.WHATSAPP:
      return "Campaign_Response";
    
    case CampaignChannelTypeEnum.SMS:
    case CampaignChannelTypeEnum.EMAIL:
      return "utm";
    
    default:
      return "Campaign_Clicked";
  }
};



export function getInAppBazelInsetValues(selectedImage: SelectedImageType) {
  switch (selectedImage) {
    case "Header":
      return { top: 40, bottom: 30, left: 16, right: 16 };
    case "Footer":
      return { top: 380, bottom: 30, left: 16, right: 16 };
    case "Mini":
      return { top: 96, bottom: 204, left: 24, right: 25 };
    default:
      return { top: 28, bottom: 31, left: 24, right: 25 };
  }
}
export function replacePlaceholdersWithAttributes(
  messageText: any,
  attributeMappings: any[]
): string {
  if (!messageText || !attributeMappings) {
    return messageText; // If messageText or attributeMappings is not available, return the original messageText
  }

  // Replace placeholders with attribute names dynamically
  const replacedMessageText = messageText.replace(
    /{{(\d+)}}/g,
    (match: string, index: string) => {
      const attributeObject = attributeMappings.find((obj: any) => obj[match]); // Find the object with the matching placeholder
      if (attributeObject) {
        return attributeObject[match].attribute_name;
      } else {
        return match; // If no matching attribute object found, return the original placeholder
      }
    }
  );
  return replacedMessageText;
}
//remove the duplicate url example: https://www.google.comhttps://www.google.com -> https://www.google.com
export const removeDuplicateUrl = (image: string | undefined) => {
  if (image && image?.includes("https://")) {
    const index=image?.split("https://")?.length - 1
    return "https://" + image?.split("https://")[index];
  } else {
    return undefined;
  }
};



  // Common event structure with dynamic parameters
  export const createEvent = (eventItems: EventItem[], sd: string, ed: string, cid: string,campType :CampaignChannelTypeEnum) => {
  // Get default attributes based on campaign type
  const defAttrs = getDefaultAttributes(campType, cid);
    const createAttribute = (item: EventItem) => {
      if (item.attr && item.type && item.operator && item.value) {
        return {
          attributeName: item.attr,
          type: item.type,
          operator: item.operator,
          value: item.value,
        };
      }
      return null; // Return null if any value is missing
    };
  
    return eventItems.map(item => {
      const additionalAttr = createAttribute(item);
      const attributes = additionalAttr ? [...defAttrs, additionalAttr] : defAttrs;
      return {
        users: 0,
        key: item.event,    // Use individual event names here
        timeStamp: sd,
        lastTimeStamp: ed,
        customUnit: '',
        events: {
          eventName: item.event,    // Use individual event names here
          list: [],
          attributes,
        },
        p: [PlatformEnum.ANDROID, PlatformEnum.IOS, PlatformEnum.WEB],
        attribute: attributes,
      };
    });
  };
  export const createCriteria = (operand: string) => ({
    operand,
    status: 1,
    category: "Events",
    since: { have: "h" }
  });
  interface Column {
    col: string;
    alias: string;
    type?: string;
    event?: string; // Make event optional
    index?: string; // Make index optional
  }
  export const createAttributeColumns = (eventItems: EventItem[] | null , attributeColumns: Column[]) => {
   
    if (!eventItems) {
      return []; // Return an empty array if input is null
    }
    
    // Determine the next step based on the second last column in attributeColumns
  let currentStep = 1; // Default step
  if (attributeColumns.length > 0) {
    // Check for the last step key column
    for (let i = attributeColumns.length - 1; i >= 0; i--) {
      const col = attributeColumns[i].col;
      const match = col.match(/step(\d+)key/);
      if (match) {
        currentStep = parseInt(match[1]) + 1; // Increment the step number
        break; // Stop once the last key column is found
      }
    }
  }


    return eventItems.flatMap((eventItem, index) => {
      const { event, attr } = eventItem;
      const step = currentStep ;        // Step number starts at 1
  
      // Base columns for the event
      const columns :Column[] = [
        {
          col: `step${step}key`,
          alias: event,
          type: "event"
        },
        {
          col: `step${step}date`,
          alias: `${event}_Date`
        }
      ];
  
      // Check if the attribute exists and add related columns if it does
      if (attr) {
        columns.push(
          {
            col: `'${attr}'`,
            alias: `${event}_${attr}`,
            type: "attribute",
            event: event,
            index: "1"
          },
          {
            col: `step${step}Segment->> '${attr}'`,
            alias: `${event}_${attr}Value`,
            type: "value",
            index: '1'
          }
        );
      }  
      return columns;
    });
  };    
export const removeTrailingNumber = (name:string) => {
  return name.replace(/_\d+$/, '');
};
export const getTrailingNumber = (str:string) => {
  const match = str.match(/_(\d+)$/);
  return match ? parseInt(match[1], 10) : null;
};

export const generateRandomAlphanumericString = (length:number) => {
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digitChars = '0123456789';
  const specialChars = '@#.';
  const allChars = uppercaseChars + digitChars + specialChars;

  // Custom random number generator (LCG-like algorithm)
  let seed = Date.now();
  function random() {
    seed = (seed * 1664525 + 1013904223) & 0x7fffffff;
    return seed;
  }

  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = random() % allChars.length;
    result += allChars.charAt(randomIndex);
  }
  return result;
};
// clipboardUtils.js
export const copyTextToClipboard = async (textToCopy:string) => {
  navigator.clipboard.writeText(textToCopy);
   
};
export const removeUnderscoreAddSpace = (key: any) => {
  return key
    .replace(/_/g, " ") // Replace all underscores with spaces
    .replace(/^\w/, (c : any) => c.toUpperCase()); // Capitalize the first character of the string
};


// Create role checks
export const roleChecks = {
  Checker: (role: UserRoleEnum) => [
    UserRoleEnum.GLOBAL_ADMIN,
    UserRoleEnum.APP_ADMIN,
    UserRoleEnum.APP_MANAGER,
  ].includes(role),
  
  Maker: (role: UserRoleEnum) => role === UserRoleEnum.APP_MARKETER,
  
  Reviewer: (role: UserRoleEnum) => role === UserRoleEnum.APP_REVIEWER,
  
  Infosec: (role: UserRoleEnum) => role === UserRoleEnum.APP_INFOSEC,
  
  NonContributor: (role: UserRoleEnum) => [
    UserRoleEnum.APP_INFOSEC,
    UserRoleEnum.APP_CONTACT_CENTER
  ].includes(role),

  Contributor: (role: UserRoleEnum) => [
    UserRoleEnum.GLOBAL_ADMIN,
    UserRoleEnum.APP_ADMIN,
    UserRoleEnum.APP_MANAGER,
    UserRoleEnum.APP_MARKETER,
    UserRoleEnum.APP_REVIEWER,
  ].includes(role),
  
  SuperUser: (role: UserRoleEnum) => [ 
    UserRoleEnum.GLOBAL_ADMIN,
    UserRoleEnum.APP_ADMIN,
  ].includes(role),

  UserAdminRights: (role: UserRoleEnum) => [
    UserRoleEnum.GLOBAL_ADMIN,
    UserRoleEnum.APP_ADMIN,
    UserRoleEnum.APP_INFOSEC,
  ].includes(role),

  GlobalAdmin: (role: UserRoleEnum) => [
    UserRoleEnum.GLOBAL_ADMIN,
  ].includes(role),

  AppAdmin: (role: UserRoleEnum) => [
    UserRoleEnum.APP_ADMIN,
  ].includes(role),

  Manager: (role: UserRoleEnum) => [
    UserRoleEnum.APP_MANAGER,
  ].includes(role),

  Marketer: (role: UserRoleEnum) => [
    UserRoleEnum.APP_MARKETER,
  ].includes(role),

  ContactCenter: (role: UserRoleEnum) => [
    UserRoleEnum.APP_CONTACT_CENTER,
  ].includes(role),
};



/**
 * 
 * @param currentRole will take the role of user of current app 
 * @param checkType will compare that role with the role check 
 * @returns true or false 
 */
export const checkUserRole = (currentRole: UserRoleEnum, checkType: keyof typeof roleChecks): boolean => {
  const checkFunction = roleChecks[checkType];

  // Throw an error if the checkType is invalid
  if (!checkFunction) {
    throw new Error(`Invalid check type: ${checkType}`);
  }

  // Execute the appropriate role check function and return the result
  return checkFunction(currentRole);
};
export const decryptData=(api_key:string,data:{[key: string]:any})=>{
  const encryptedKeys = config.encryptedKeys;     // get the encypted keys from the FE 
      const encryptionService = new EncryptionService(api_key);
        const updatedDoc = { ...data }; // Create a shallow copy of each document
        Object.keys(encryptedKeys).forEach((key) => {
          if (encryptedKeys[key as keyof typeof encryptedKeys] === 0 && updatedDoc.hasOwnProperty(key)) {
            updatedDoc[key] = encryptionService.decrypt(updatedDoc[key]);
          }
        });
  return updatedDoc
}