import { useState, Fragment } from "react";
import InnerNav from "components/InnerNav/InnerNav";
import type { MenuProps } from "antd";
import "./AttributionNav.css";
import { t } from "i18next";
import Kochava from "../Kochava/Kochava";

interface AttributionNavProps  {
    currentAppKey: any;
    updateCurrentAppKey: () => void;
  }


const AttributionNav: React.FC<AttributionNavProps> = ({
    currentAppKey,
    updateCurrentAppKey,
  }) => {
  // Default tab and component mapping
  const tabComponents = {
    kochava: <Kochava
              currentAppKey={currentAppKey}
              updateCurrentAppKey={updateCurrentAppKey}
    />,
  };

  // Default selected tab
  const [appTabFilter, setAppTabFilter] = useState<keyof typeof tabComponents>("kochava");

  // Handle tab click
  const onTabClick: MenuProps["onClick"] = (e) => {
    setAppTabFilter(e.key as keyof typeof tabComponents);
  };

  return (
        <div className="mt-4 attribution-nav-container">
          <div className="app-setting-nav">
            <InnerNav
              theme="light"
              onTabClick={onTabClick}
              selected={appTabFilter}
              menu={[
                {
                  label: t("kochavaSettingsLabel"),
                  key: "kochava",
                }
              ]}
            />
          </div>
          {/* Render the selected component */}
          {tabComponents[appTabFilter]}
        </div>
  );
};

export default AttributionNav;
