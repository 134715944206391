import { FC, ReactNode, useMemo } from "react";
import "./PageLayout.css";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Spinner from "components/Spinner/Spinner";
interface PageLayoutProps {
  loading?:boolean;
  title?: string;
  topLeftItem?: ReactNode;
  topRightItem?: ReactNode;
  children?: ReactNode;
  className?:string
}

const PageLayout: FC<PageLayoutProps> = ({
  loading,
  topLeftItem,
  topRightItem,
  title,
  children,
  className
}) => {
  return (
    <div className="container-fluid p-0 page-layout-main-container d-flex flex-column">
     <div className={className}>
     {title? <Helmet>
        <title>Appice | {title}</title>
      </Helmet>:<></>}
      {/* Header */}
      <header className="row align-items-center py-2 my-1">
        <div className={topRightItem?`col-6`:`col-12`}>
          {typeof topLeftItem == "string" ? (
            <h5 className="m-0 p-0">{topLeftItem}</h5>
          ) : (
            topLeftItem
          )}
        </div>
        <div className={topLeftItem?`col-6 text-end`:`col-12 text-end` }>{topRightItem}</div>
      </header>

      {/* Body */}
      <main  className={`flex-grow-1 p-4  page-layout-body-container`}>
       {loading && <div className="page-layout-loader">
         <Spinner/>
        </div>}
        <div  className={`${loading?"page-layout-body-disable":""}`}>
        {children}

        </div>
      </main>
      </div>
    </div>
  );
};

export default PageLayout;
