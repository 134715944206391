import { useState } from "react";
import { SmartLinkCreate } from "./SmartLinks/SmartLinkCreate/SmartLinkCreate";
import { SmartLinks } from "./SmartLinks/SmartLinks";
import { Dropdown, Menu, MenuProps } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { PlusOutlined } from "@ant-design/icons";
import InnerNav from "components/InnerNav/InnerNav";
import { useNavigate } from "react-router-dom";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { roleCheckTypes } from "utils/constants/constants";


export const Links = () => {
    const navigate = useNavigate();

    // Define the menu options
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={() => navigate('/dashboard/links/smartlinks/create')}>
          SmartLinks
        </Menu.Item>
        {/* <Menu.Item key="2" onClick={() => navigate('/dashboard/links/tracker/create')}>
          Tracker
        </Menu.Item> */}
      </Menu>
    );
  
// Default tab and component mapping
  const tabComponents = {
    SmartLinks: <SmartLinks/>,
    Tracker: <SmartLinkCreate/>,
  };

   // Default selected tab
   const [appTabFilter, setAppTabFilter] = useState<keyof typeof tabComponents>("SmartLinks");

   // Handle tab click
   const onTabClick: MenuProps["onClick"] = (e) => {
     setAppTabFilter(e.key as keyof typeof tabComponents);
   };
  return (
    <div className="links-nav-container mt-4">
    
    <div className="w-100 h-100 d-flex justify-content-between flex-wrap my-3 p-3">
     <AccessControl accessControl={{role:[roleCheckTypes.Checker ,roleCheckTypes.Maker]}}> 
      <>
 
        <InnerNav
        theme="light"
        onTabClick={onTabClick}
        selected={appTabFilter}
        menu={[
            {
            label: "SmartLinks",
            key: "SmartLinks",
            },
            // {
            //     label:"Tracker",
            //     key: "Tracker",
            //     },
          
        ]}
        />
        <div>
        <Dropdown overlay={menu} trigger={['click']}>
        <PrimaryButton type="primary" icon={<PlusOutlined />} className="mx-3 px-3">
            New Link
        </PrimaryButton>
        </Dropdown>
        </div>
        </>
      </AccessControl>
    </div>

   {/* Render the selected component */}
   {tabComponents[appTabFilter]}
 </div>

  )
}
