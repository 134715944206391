import { Upload } from "antd";
import { FC } from "react";
import Spinner from "../../../../../../components/Spinner/Spinner";
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea";
import uploadIcon from "../../../../../../images/uploadImageCustom.svg";
import "./InAppContainer.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { replacePersonlizedVariable } from "../../Personalization/Personalization";
import { InAppImageUploadType, ScreenTypes } from "utils/enums";
import selfRenderedImage from "images/SH_Iphone.svg"
import { QueryPayload } from "utils/models";
import { removeDuplicateUrl } from "helperFunctions/common";
//props comming from parent component
interface InAppContainerProps {
  type?: "IOS" | "Android";
  headerImage?: string;
  imageLoading?: {
    notificationImage: { loading: boolean };
    miniScreen: { loading: boolean };
    iconImage: { loading: boolean };
  };
  descriptionText?: string;
  uploadImage?: any;
  background?: string;
  textColor?: string;
  miniScreenImage?: string;
  fullScreenImage?: string;
  selectedImage?: string;
  actionTitle?: string;
  actionTitleColor?: string;
  actionTitleBackground?: string;
  callToAction?: boolean;
  disabled?: string;
  variableInfo?: QueryPayload;
  expandedImage?:string;
  isExpanded?:boolean; 
  mediaType?:string

}
const InAppContainer: FC<InAppContainerProps> = ({ ...props }) => {
  //check the type of display to show header footer mini or fullscreen
  return props.selectedImage == ScreenTypes.HEADER || props.selectedImage == ScreenTypes.FOOTER ? (
    <HeaderFooter {...props} />
  ) : props.selectedImage == ScreenTypes.MINI ? (
    <Mini {...props} />
  ) : props.selectedImage == ScreenTypes.SELF_RENDERED ? (
    <SelfRendered {...props} />
  ): (
    <Fullscreen {...props} />
  );
};
export default InAppContainer;
// if type header or footer is selected
const HeaderFooter: FC<InAppContainerProps> = ({
  
  headerImage,
  imageLoading,
  uploadImage,
  descriptionText = "",
  background,
  textColor,
  variableInfo,
  expandedImage,
  isExpanded
}) => {
  return (
    <div
      className="mx-2 mt-2 in-app-main-container py-2"
      style={background ? { background: background } : {}}
    >
      <div className="row m-0 p-0  g-0">
        <div className={` d-flex align-items-center justify-content-center ${!isExpanded ? 'col-sm-3' : ''}`}>

          <Upload
            showUploadList={false}
            multiple={false}
            beforeUpload={() => false}
            onChange={(value: any) => uploadImage(value, isExpanded ? InAppImageUploadType.IN_APP_EXPANDED : InAppImageUploadType.ICON_IMAGE)}

            accept="image/*" // Accept only image files

            disabled={!uploadImage}
          >
            <div>
              {imageLoading?.iconImage?.loading ? (
                <Spinner />
              ) : (
                <img
                  loading="lazy"
                  src={
                    isExpanded
                      ? expandedImage || uploadIcon  // Show expanded image or upload icon if not present
                      : headerImage || uploadIcon      // Show header image or upload icon if not present
                  }
                  
                  className="w-100"
                  style={{
                    maxHeight:isExpanded?150: 40,
                    maxWidth:isExpanded? "": 40,
                    borderRadius: "var(--border-radius-lg)",
                  }}
                />
                
              )}
            </div>
          </Upload>
        </div>
        <div className={` d-flex gap-1  flex-column align-items-center ${!isExpanded ? 'col-8' : 'mt-1'}`}>
          <CustomTextArea
            disabled={true}
            className="px-1"
            value={replacePersonlizedVariable(descriptionText, variableInfo)}
            style={{
              height: 50,
              lineHeight: 1.2,
              color: textColor ? textColor : "black",
              resize: "none",
              fontSize: "var(--font-size-sm)",
            }}
          />
        </div>
        </div>
       </div>
  );
};
//if type is MINI
const Mini: FC<InAppContainerProps> = ({
  imageLoading,
  uploadImage,
  miniScreenImage,
  actionTitle,
  actionTitleBackground,
  actionTitleColor,
  callToAction,
  mediaType
}) => {
  return (
    <div className="position-relative">
      <div
        className="d-flex"
        style={{
          position: "absolute",
          top: 0,
          left: 20,
          right: 0,
          // bottom: 50,
        }}
      ></div>
      <div className=" mx-1  d-flex align-items-center  justify-content-center">
        <Upload
          disabled={!uploadImage}
          showUploadList={false}
          multiple={false}
          beforeUpload={() => false}

          onChange={(value: any) => uploadImage(value, InAppImageUploadType.MINI_SCREEN )}
        >
          <div>
            {imageLoading?.iconImage?.loading ? (
              <Spinner />
            ) : (
              mediaType === "video" ? (
                <div className="m-0">
                  <video  
                        key={miniScreenImage} 
                        style={{
                    height: 237,
                    objectFit: "cover",
                    width: 236,
                    borderRadius: "var(--border-radius-sm)",
                  }} 
                  controls>
                    <source src={removeDuplicateUrl(miniScreenImage) || uploadIcon} type="video/mp4"  />
                  </video>
                </div>
              ) :(
           
              <img
                loading="lazy"
                src={miniScreenImage || uploadIcon}
                className="w-100"
                style={{
                  maxHeight: 400,
                  maxWidth: 400,
                  padding: 5,
                }}
              />
            ))}
          </div>
          {callToAction && (
            <div
              className="mx-1 mb-2 d-flex align-items-center justify-content-center"
              style={{
                position: "absolute",
                left: "50%", // Set the left position to 50%
                transform: "translateX(-50%)", // Center horizontally using transform
                // bottom: "5px",
                top: "190px",
              }}
            >
              <PrimaryButton
                style={{
                  color: actionTitleColor,
                  background: actionTitleBackground,
                  minWidth:50               }}
              >
                {actionTitle}
              </PrimaryButton>
            </div>
          )}
        </Upload>
      </div>
    </div>
  );
};
//if type is Fullscreen
const Fullscreen: FC<InAppContainerProps> = ({
  imageLoading,
  uploadImage,
  fullScreenImage,
  actionTitleColor,
  actionTitleBackground,
  actionTitle,
  callToAction,
  mediaType
}) => {
  return (
    <div className=" mx-1 h-100 d-flex align-items-center  justify-content-center">
      <Upload
        disabled={!uploadImage}
        showUploadList={false}
        multiple={false}
        beforeUpload={() => false}
        onChange={(value: any) => uploadImage(value, InAppImageUploadType.NOTIFICATION_IMAGE)}
      >
        <div>
          {imageLoading?.notificationImage?.loading ? (
            <Spinner />
          ) : (
            mediaType === "video" ? (
              <div className="m-0">
                <video  
                      key={fullScreenImage} 
                      style={{
                  height: 450,
                  objectFit: "cover",
                  width: 236,
                  borderRadius: "var(--border-radius-sm)",
                }} 
                controls>
                  <source src={removeDuplicateUrl(fullScreenImage) || uploadIcon} type="video/mp4"  />
                </video>
              </div>
            ) :(
            <img
              loading="lazy"
              src={fullScreenImage || uploadIcon}
              className="w-100"
              style={{ maxHeight: 800, maxWidth: 400 }}
            />
          ))}
        </div>
        {callToAction && (
          <div
            className="mx-1 mb-2 d-flex align-items-center justify-content-center"
            style={{
              position: "absolute",
              left: "50%", // Set the left position to 50%
              transform: "translateX(-50%)", // Center horizontally using transform
              bottom: "50px",
            }}
          >
            <PrimaryButton
              style={{
                color: actionTitleColor,
                background: actionTitleBackground,
                minWidth:50

              }}
            >
              {actionTitle}
            </PrimaryButton>
          </div>
        )}
      </Upload>
    </div>
  );
};
//if type is Self rendered

const SelfRendered: FC<InAppContainerProps> = () => {
  return (
    <div className=" mx-1 h-100 d-flex align-items-center  justify-content-center">
            <img
              loading="lazy"
              src={selfRenderedImage}
              className="w-100"
              style={{ height: 100 }}
            />
               </div>
       
  );
};
