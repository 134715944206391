import {
  BgColorsOutlined,
  ColumnHeightOutlined,
  ManOutlined,
  MobileOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import React, { FC, useState } from "react";
import "./NudgeTemplate.css";
import Selector from "components/Selector/Selector";
import FormItem from "antd/es/form/FormItem";
import { t } from "i18next";
import TextInput from "components/Inputs/TextInput/TextInput";
import { Col, Form, Row, Tabs } from "antd";
import SwitchBtn from "components/Buttons/SwitchBtn/SwitchBtn";
import CustomCollapse from "components/CustomCollapse/CustomCollapse";
import CustomToolTip from "components/CustomToolTip/CustomToolTip";
import TabPane from "antd/lib/tabs/TabPane";
interface NudgeTemplateProps {
  screen: any[];
  currentScreen: number;
  setCurrentScreen: React.Dispatch<React.SetStateAction<number>>;
}

const NudgeTemplate: FC<NudgeTemplateProps> = ({
  screen,
  currentScreen,
  setCurrentScreen,
}) => {
  const [action, setAction] = useState("goToLink");
  return (
    <div className="nudge-template-main-container">
      <div>
        <strong>Nudge campaign</strong>
      </div>
      <Form colon={false}>
        <div className="py-2 d-flex justify-content-between">
          <ScreenSteps
            screen={screen}
            currentScreen={currentScreen}
            setCurrentScreen={setCurrentScreen}
          />
          <Selector placeholder="page #" />
        </div>
        <div className="py-2">
          <FormItem
            initialValue={""}
            className="w-100 m-0 mt-2  "
            label={t("templateNameLabel")}
            name={"templateName"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextInput
              onChange={(e: any) => {}}
              placeholder={t("templateNameLabel")}
              value={""}
            ></TextInput>
          </FormItem>
        </div>
        <div className="d-flex py-3 gap-1">
          <div className="tool-tip-container">
            <CustomCollapse
            defaultActiveKey={['1']}
              header={`Container`}
              body={
                <div style={{ minHeight: 280 }}>
                  <strong>Tooltip background</strong>
                  <div style={{ maxWidth: 400, margin: "0 auto" }}>
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Color" key="1">
                        <Row gutter={16}>
                          <Col span={24}>
                            <TextInput
                            type="color"
                              // prefix="#"
                              defaultValue="#ffffff"
                              style={{ marginBottom: 16 }}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab="Image" key="2">
                        <p>Image upload option</p>
                      </TabPane>
                    </Tabs>
                    <Row gutter={16}>
                      <Col span={12}>
                       <span>Border color</span>
                        <TextInput
                         type="color"
                          // prefix={<BgColorsOutlined className="m-0 p-0" />}
                          defaultValue="#ffffff"
                          className={"mb-3"}
                        />
                      </Col>
                      <Col span={12}>
                      <span>Corner radius</span>
                        <TextInput
                        label={"Corner radius"}
                          defaultValue={8}
                          min={0}
                          max={100}
                          style={{ width: "100%", marginBottom: 16 }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <TextInput
                          addonAfter={
                            <CustomToolTip title="Width in pixels">
                              <QuestionCircleOutlined />
                            </CustomToolTip>
                          }
                          defaultValue={300}
                          style={{ marginBottom: 16 }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              }
            ></CustomCollapse>
          </div>
          <div className="action-container">
            <CustomCollapse
            defaultActiveKey={['1']}
              header={`Actions`}
              body={
                <div style={{ minHeight: 280 }}>
                  <p>
                    When users interact with the target , the nudge can advance
                    dismiss the Flow , redirect users , or launch content
                  </p>
                  <strong>Target interaction actions</strong>
                  <div className="action-inner-container">
                    <Selector
                      className="w-100 mt-3"
                      defaultValue={"goToLink"}
                      onChange={(value) => {
                        setAction(value);
                      }}
                      options={[
                        { label: "Next Step", value: "nextStep" },
                        { label: "Go to Link", value: "goToLink" },
                        { label: "Trigger Flow", value: "triggerFlow" },
                        { label: "Dismiss Flow", value: "directFlow" },
                        { label: "Go to Custom Step", value: "customStep" },
                      ]}
                    />
                    {action == "goToLink" && (
                      <TextInput
                        className={"mt-3"}
                        placeholder={"e.g. myapp://account//settings"}
                      />
                    )}

                    {action == "triggerFlow" && (
                      <Selector
                        className="w-100 mt-3"
                        placeholder="Select Flow"
                        options={[
                          { label: "X-Test", value: "xTest" },
                          {
                            label: "SampleApp-AndriodTest",
                            value: "sampleTest",
                          },
                        ]}
                      />
                    )}
                    {action == "customStep" && (
                      <Selector
                        className="w-100 mt-3"
                        placeholder="Select Step"
                        options={[
                          { label: "Group-2 - Step 1", value: "s1" },
                          { label: "Group-2 - Step 2", value: "s2" },
                          { label: "Group-3 - Step 1", value: "s3" },
                          { label: "Group-4 - Step 1", value: "s4" },
                        ]}
                      />
                    )}
                    <div className="action-footer mt-3">
                      <div className="d-flex  justify-content-between">
                        <span className="mx-1">Open in external browser</span>
                        <SwitchBtn />
                      </div>
                      <div className="d-flex  justify-content-between mt-2 ">
                        <span className="mx-1">Mark flow complete</span>
                        <SwitchBtn />
                      </div>
                    </div>
                  </div>
                </div>
              }
            ></CustomCollapse>
          </div>
        </div>
        <div className="d-flex  gap-1 w-100">
          <div className=" w-50 target-position-container">
            <CustomCollapse
              header={`Target Position`}
              body={
                <>
                  <div className="container mt-4">
                    <div className="text-center mb-3">
                      <ManOutlined
                        className="me-2"
                        style={{ color: "#6c63ff" }}
                      />
                      <span style={{ color: "#6c63ff", fontWeight: "bold" }}>
                        Adjust target placement
                      </span>
                    </div>

                    <Row className="mb-3">
                      <Col>
                        <span>Distance from left</span>
                        <div className="input-group">
                          <TextInput
                            type="number"
                            defaultValue={15}
                            suffix={<ManOutlined />}
                          />
                        </div>
                      </Col>
                      <Col>
                        <span>Distance from top</span>
                        <div className="input-group">
                          <TextInput
                            type="number"
                            defaultValue={90}
                            suffix={<ColumnHeightOutlined />}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              }
            ></CustomCollapse>
          </div>
          <div className="w-50"></div>
        </div>
      </Form>
    </div>
  );
};

const ScreenSteps = ({
  screen,
  currentScreen,
  setCurrentScreen,
}: NudgeTemplateProps) => {
  return (
    <div className="d-flex align-items-center icon-container">
      {screen.map((item, index) => (
        <React.Fragment key={item.id}>
          <div
            className={`icon-box d-flex align-items-center cursor-pointer justify-content-center ${
              currentScreen === index ? "active" : ""
            }`}
            onClick={() => setCurrentScreen(index)}
          >
            <MobileOutlined className="m-0 icon-text" />
          </div>
          {index < screen.length - 1 && <span className="icon-arrow">→</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default NudgeTemplate;
