import React, { useEffect, useState } from "react";
import editIcon from "../../../images/_edit.svg";
import removeIcon from "../../../images/remove.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { PlusOutlined } from "@ant-design/icons";
import Selector from "../../../components/Selector/Selector";
import CustomTable from "../../../components/CustomTable/CustomTable";
import Search from "../../../components/Inputs/Search/Search";
import { useSelector } from "react-redux";
import minusIcon from "../../../images/minus.svg";
import ConfirmPopUp from "../../../components/PopUp/ConfirmPopUp/ConfirmPopUp";
import { Link, useNavigate } from "react-router-dom";
import CustomBreadCrumb from "../../../components/CustomBreadCrumb/CustomBreadCrumb";
import { t } from "i18next";
import useMessage from "hooks/useMessage";
import {PAGE_OPTIONS } from "utils/constants/selectorOptions";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { roleCheckTypes } from "utils/constants/constants";
import centralApi from "services/centralApi";
import { formatDate } from "helperFunctions/date";
import { AudienceManagementPageType, DateFormatEnum } from "utils/enums";
import PageLayout from "layout/PageLayout/PageLayout";
type tableType={
  type:AudienceManagementPageType
}
const AudienceTable: React.FC<tableType> = ({type}) => {
  const navigate = useNavigate();
  const loginUser = useSelector((state: any) => state.loginUser);
  const { showError, showSuccess } = useMessage();
  const {appId} = useSelector((state: any) => state.activeApp);
  const [data, setData] = useState<any>({raw:[],filtered:[],loading:true,search:""});
  const [pageData, setPageData] = useState({
    pageSize: PAGE_OPTIONS.DEFAULT_VALUE,
    simple: PAGE_OPTIONS.PAGINATION.simple
  })
  const page_data={
    dataUrl:type==AudienceManagementPageType.FUNCTION?API_ENDPOINTS.GET_FUNCTIONS_URL:type==AudienceManagementPageType.TRAIT?API_ENDPOINTS.GET_ALL_TRAITS_URL:API_ENDPOINTS.GET_AUDIENCE_SEGMENTS,
    deleteUrl:type==AudienceManagementPageType.FUNCTION?API_ENDPOINTS.DELETE_FUNCTION_URL:type==AudienceManagementPageType.TRAIT?API_ENDPOINTS.DELETE_TRAIT_URL:API_ENDPOINTS.DELETE_AUDIENCE_SEGMENT,
    deleteSuccessMsg:type==AudienceManagementPageType.FUNCTION?t("functionDeletedSuccessMsg"):type==AudienceManagementPageType.TRAIT?t("traitDeletedSuccessMsg"):t("segmentDeletedSuccessfullyMsg"),
    createRoute:type==AudienceManagementPageType.FUNCTION?ROUTES.DASHBOARD_AUDIENCE_FUNCTION_CREATE:type==AudienceManagementPageType.TRAIT?ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE:ROUTES.DASHBOARD_AUDIENCE_CREATE,
    dashboardRoute:type==AudienceManagementPageType.FUNCTION?ROUTES.DASHBOARD_AUDIENCE_FUNCTIONS:type==AudienceManagementPageType.TRAIT?ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS:ROUTES.DASHBOARD_AUDIENCE,
    label:type==AudienceManagementPageType.FUNCTION?t("functionLabel"):type==AudienceManagementPageType.TRAIT?t("traitLabel"):t("audienceLabel"),
    title:type==AudienceManagementPageType.FUNCTION?t("functionsLabel"):type==AudienceManagementPageType.TRAIT?t("traitsLabel"):t("audienceSegmentsLabel"),
  }
  const deleteConfirmation = async (id: string) => {
    try {
      const payload = {
        app_id: appId,
        api_key: loginUser.data.api_key,
        args: JSON.stringify({ _id: id }),
      }
      const res = await centralApi("GET",page_data.deleteUrl, null, payload); 
      if(res){
        showSuccess(page_data.deleteSuccessMsg)
        getAudienceData();
      }
    } catch (error) {
      showError(t("somethingWrongLabel"))
    }
  };
  const columns: any = [
    {
      title: t("nameLabel"),
      dataIndex: "name",
    },
    ...(type!==AudienceManagementPageType.TRAIT?[{
      title: t("descriptionLabel"),
      dataIndex: "description",
    }]:[]),
    ...(type!==AudienceManagementPageType.SEGMENT?[{
      title: t("statusLabel"),
      dataIndex: "status",
    }]:[])
   ,{
      title: t("rangeLabel"),
      dataIndex: "range",
    },
    {
      title: t("createdOnLabel"),
      dataIndex: type==AudienceManagementPageType.SEGMENT?"createdate":"createdOn",
      render:(createdate:number)=>{
      return <span>{formatDate(createdate || "", DateFormatEnum.ABBREVIATED_MONTH_TIME)}</span>
      }
    },
    {
      title: t("createdByLabel"),
      dataIndex: "createdBy",
    },
    {
      title: t("modifiedOnLabel"),
      dataIndex: "modifiedOn",
      render: (modifiedOn: number,{modifiedBy}:{modifiedBy:string | undefined}) => {
        return modifiedBy && modifiedOn && formatDate(modifiedOn, DateFormatEnum.ABBREVIATED_MONTH_TIME)
      },
    },
    {
      title: t("modifiedByLabel"),
      dataIndex: "modifiedBy",
    },
    {
      title: t("actionLabel"),
      dataIndex: "_id",
      className: "text-center",

      render: (id: string) => {
        const imgStyle = {
          width: 16,
        };
        return (
          <div className="d-flex justify-content-center  h-100">
            <AccessControl accessControl={{role:[roleCheckTypes.Checker ,roleCheckTypes.Maker]}}> 
            <ConfirmPopUp
              title={t("areYouSureToDeleteMsg")}
              icon={removeIcon}
              onConfirm={() => {
                deleteConfirmation(id);
              }}
              id={id}
            >
              <PrimaryButton title={t("deleteLabel")} className="mx-1">
                <img src={minusIcon} title={`${t("deleteLabel")}`} alt={"minus_icon"} style={imgStyle}></img>
              </PrimaryButton>
            </ConfirmPopUp>
            </AccessControl>
            <PrimaryButton
            title={t("editLabel")}
              className="mx-1"
              onClick={() => {
                navigate(page_data.createRoute,{state:{id}});
              }}
              >
              <img src={editIcon} title={`${t("editLabel")}`} alt={"edit_icon"} style={imgStyle}></img>
            </PrimaryButton>{" "}
          </div>
        );
      },
    },
  ];
  //get the audience data from api and set the state
  async function getAudienceData() {
    try {
      setData((prevState: typeof data)=>({...prevState,raw:[],filtered:[],loading:true}))
      const apiKey=loginUser.data.api_key
      if(apiKey && appId){
        const payload = {
          app_id: appId,
          api_key: apiKey,       
        }
        const res = await centralApi("GET", page_data.dataUrl, null, payload); 
        setData((prevState: typeof data)=>({...prevState,raw:res?.result,filtered:res?.result,loading:false}))
      }
    } catch (error) {
      setData((prevState:typeof data)=>({...prevState,loading:false}))
    }
  }
  const handleSearch = () => {
    const regex = new RegExp(data.search, "i");
     const filtered= data.raw?.filter(({name}:{name:string}) => {
        return name ? regex.test(name) : false;
      })
    setData((prevState:typeof data)=>({...prevState,filtered}))
  };
  useEffect(() => {
    setData((prevState:typeof data)=>({...prevState,search:undefined}))
    getAudienceData();
  }, [loginUser,type]);
  useEffect(()=>{
   handleSearch()
  },[data.search])
  return (
    <div className="container-fluid px-1">
      <PageLayout title="Audience" 
      topLeftItem={
        <CustomBreadCrumb
          items={[
            {
              title: <Link to={page_data.dashboardRoute}>{page_data.label}</Link>,
            }
          ]}
        />
      }
      >
        <div className="d-flex justify-content-between align-items-center">
          <h6>{page_data.title}</h6>
          <AccessControl accessControl={{role:[roleCheckTypes.Checker ,roleCheckTypes.Maker]}}> 
          <PrimaryButton type="primary" onClick={() => {navigate(page_data.createRoute)}}>
            <PlusOutlined />
            <span className="mx-2">{page_data.label}</span>
          </PrimaryButton>
          </AccessControl>
        </div>
        <div>
          <Search
            
              data-testid="search-audience"
              placeholder={`${t("searchLabel")}`}
              className="input-size-lg my-2 me-2"
              onChange={(e)=>{setData((prevState:typeof data)=>({...prevState,search:e.target.value}))}}
              value={data.search}
          />
          <Selector
           onChange={(value) => {
            setPageData({ ...pageData, pageSize: value })
            }}
            options={PAGE_OPTIONS.DATA} 
            defaultValue={pageData.pageSize}
          />
        </div>
        <CustomTable
          columns={columns}
          scroll={{ x: 1200 }}
          rowKey={({_id}:{_id:string}) => _id}
          dataSource={data.filtered}
          size="small"
          totalEntries={data.raw?.length}
          pagination={pageData}
          loading={data.loading}
        />
      </PageLayout>
    </div>
  );
};

export default AudienceTable;
