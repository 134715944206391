import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { beefree } from "../../helperFunctions/beefree";
import { CloseOutlined } from "@ant-design/icons";
import "./BeefreeEditor.css";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
interface Workspace {
  onSave?: (jsonFile: string, htmlFile: string) => void;
  onChange?: (jsonFile: string, response: any) => void;
  onSaveAsTemplate?: (jsonFile: string) => void;
  onAutoSave?: (jsonFile: string) => void;
  onSend?: (htmlFile: string) => void;
  onLoad?: (jsonFile: string) => void;
  onError?: (errorMessage: string) => void;
}
export interface beefreeOptionType {
  existingTemplate?: any;
  workspace?: Workspace;
}
interface BeeFreeEditorProps {
  style?: React.CSSProperties;
  isUpdate?:boolean
  onClose?: () => void;
  options?: beefreeOptionType;
}
const BeeFreeEditor: FC<BeeFreeEditorProps> = ({ style, onClose, options,isUpdate }) => {
  const [bee, setBee] = useState<any>(null);
  const isSave=useRef(true)
  const onSave=(jsonFile: string, htmlFile: string)=>{
    if(!isSave.current && options?.workspace?.onChange){
      options?.workspace?.onChange(jsonFile, htmlFile)
    }
    else if(options?.workspace?.onSave){
      options?.workspace?.onSave(jsonFile, htmlFile)
    }
    isSave.current = true;  // Reset save flag after saving
  }
   // onChange callback
   const onChange = (instance:typeof bee) => {
    isSave.current = false;
    instance.save();
   };
  const handleSaveBtnClick=useCallback(()=>{
    isSave.current=true
    if(bee){
      bee.save()
    }
  },[bee])
  useEffect(() => {
    beefree(options || {}).then(({instance,conf}:any) => {
      conf.onChange=()=>onChange(instance)
      conf.onSave=onSave
      setBee(instance);
    }).catch(()=>{});
    return () => {
      //remove the beefree builder
      document
        .getElementById("bee-plugin-container__bee-plugin-loader")
        ?.remove();
      document
        .getElementById("bee-plugin-container__bee-plugin-frame")
        ?.remove();
    };
  }, [options?.existingTemplate]);
  return (
    <div className="beefree-editor-main-container" style={style}>
      <div className="w-100 pb-2 d-flex justify-content-end"> 

      <PrimaryButton type="primary" disabled={!bee} onClick={handleSaveBtnClick}>
        {isUpdate?"Update Template":"Save Template"}
      </PrimaryButton>
      </div>
      {onClose && (
        <div className="d-flex justify-content-end mb-2">
          <CloseOutlined onClick={onClose} />
        </div>
      )}
      <div id="bee-plugin-container"></div>
    </div>
  );
};

export default React.memo(BeeFreeEditor);
