import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
export const fetchComputedTraitList = createAsyncThunk(
  "computedTrait/computedTraitList",
  async ({api_key, app_id}:{api_key:string, app_id:string}) => {
    const params = {
      api_key,
      app_id,
    };

    return centralApi(
      "GET",
      API_ENDPOINTS.GET_ALL_TRAITS_URL,
      null,
      params
    ).then(({result}) => { 
      return result;
    });
  }
);
const initialState:any= {
  data: [],
  status: STATUS.IDLE,
}
const computedTraitListSlice = createSlice({
  name: "computedTraitList",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchComputedTraitList.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchComputedTraitList.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchComputedTraitList.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
  reducers: {
  },
});
export default computedTraitListSlice.reducer;

