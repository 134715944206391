import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import moment from 'moment';


dayjs.extend(utc);
dayjs.extend(duration);

/**
 * formatDate function will convert both 10 digit or 13 digit epoch number to human readable form
 * @param value it would be an epoch number it can be in 10 digits without second and miliseconds or it can be 13 digit epoc number 
 * @param format format in whch we need date time
 * @returns formated date time in human readable form
 */
export const formatDate=(value:string | number | undefined,format:string)=>{
  if (value === undefined) {
    return ''; // Handle undefined case
  }

  let timestamp;

  // Check if value is a string and parse it
  if (typeof value === 'string') {
    timestamp = dayjs(value).isValid() ? dayjs(value).valueOf() : parseInt(value, 10);
  } else {
    timestamp = value;
  }

  // Convert to milliseconds if it's a 10-digit epoch timestamp
  if (timestamp.toString().length === 10) {
    timestamp *= 1000;
  }

  return dayjs(timestamp).format(format);
}

export const disabledDate = (current:any,disable:"past" | "future"="future") => {
  // Disable dates greater than the current 
  if(disable=="future"){

    return current && current > dayjs().endOf('day');
  }
  else if(disable=="past"){
    return current && current < dayjs().startOf('day');
  }
};
export const disableDateRange = (current:any, selectedDate:any, range:number , rangeType:any, disableType:string) => {
  
  let startDate, endDate;

  switch (disableType) {
    case 'future':
      // Disable future dates from today
      return current && current > dayjs().endOf('day');

    case 'past':
      // Disable past dates from today
      return current && current < dayjs().startOf('day');

    case 'beforeMonths':
      // Disable dates before a specified number of months from today
      startDate = dayjs().subtract(range, rangeType);
      return current && current < startDate.startOf('day');


    case 'year':
    case 'years':
      case 'months':

      // If a date is selected, calculate the start and end dates based on the range and selected date
      if (selectedDate) {
        startDate = dayjs(selectedDate).subtract(range, rangeType);
        endDate = dayjs(selectedDate).add(range, rangeType);
        return disableOutsideRange(current, startDate, endDate);
      }
      break;

    case 'futureFromSelected':
      // Disable future dates from the selected date
      if (selectedDate) {
        startDate = dayjs(selectedDate);
        endDate = dayjs(selectedDate).add(range, rangeType);
        return disableOutsideRange(current, startDate, endDate);
      }
      break;

    case 'pastFromSelected':
      // Disable past dates from the selected date
      if (selectedDate) {
        startDate = dayjs(selectedDate).subtract(range, rangeType);
        endDate = dayjs(selectedDate);
        return disableOutsideRange(current, startDate, endDate);
      }
      break;

    default:
      throw new Error("Invalid disableType.");
  }

  return false; // Default return false if no condition is met
};
const disableOutsideRange = (current:any, startDate:any, endDate:any) => {
  return current < startDate.startOf('day') || current > endDate.endOf('day');
};


export const parseDate=(date:any)=>{
    return Date.parse(date?.$d);
  
}
/**
 * convertToEpoc function
 * @param date it will recieve date object 
 * @returns 10 digit epoc value
 */
export const convertToEpoch =(date : any) => {
  return Date.parse(date?.$d) /1000;
}
/**
 * Function to convert time to colon-separated format;
 * @param timeStr it will reacive timstr in string format for eg for 8:00 am it would be 0800 
 * @returns it will return 0800 to 08:00 or 0 to 00:00 or 540 to 05:40
 */
const convertToColonSeparatedTime = (timeStr : string | number) => {
  // Convert number to string
  if (typeof timeStr === 'number') {
    timeStr = timeStr.toString();
  }
  // Check if timeStr is definedas string and if its less than 3 we have to make it 4 by adding 0 in the beggining
  if(typeof timeStr === 'string' && timeStr?.length <= 3){
    timeStr = timeStr?.length === 1 ? `0000` : `0${timeStr}`
    return timeStr.substr(0, 2) + ':' + timeStr.substr(2);
  }
  if (typeof timeStr === 'string' && timeStr.length === 4) {
    return timeStr.substr(0, 2) + ':' + timeStr.substr(2);
  } 
};

/**
 * Function to convert date and time to epoch value
 * @param date it will recieve date as an epoch number 
 * @param time time as string like "0800" ,"2000"
 * @returns adds both date and time and give us single epoch value
 */
export const dateTimeToEpoch = (date:number, time : string | number) => {
  const dateTimeStr = `${dayjs(date).format('YYYY-MM-DD')} ${convertToColonSeparatedTime(time)}`;
  return dayjs(dateTimeStr).valueOf();

};
/**
 * Function to convert epoch into formatted Date
 * @param date it will recieve date as an epoch number 
 * @returns returns in the form of YYYY_MM_DD
 */
export const convertEpochToFormattedDate = function (epoch:string ) {
  return moment(parseInt(epoch)).format('YYYY_MM_DD');
};

export const getCurrentDate=()=>{
  return dayjs().valueOf()

}



 /**
  *  Function to convert minutes  to HHmm format
  * @param minutes will be of number type and we will be converting these mintues into HHmm format
  * @param toolTip it will be a boolean when its true it means we are using it to show time in 12 hour clock on tooltip
  * @returns string formatted time in HHmm for eg if we have 165 and 1400 it converts it into '245', '2400' respectively which is equal to 2:45 and 24:00 in 24 hour clock format
  */
 export const minutesToHHmmString = (minutes : number , toolTip : boolean) : string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const formattedHours = hours.toString(); // Convert hours to string
  const formattedMins = mins >= 10 ? mins.toString() : `0${mins}`; // Ensure minutes always have leading zero if less than 10
  let formattedTime = `${formattedHours}${formattedMins}`

   // Check if formattedTime is '000' i.e 12 am then we are not sending minutes it only returns 0 
   if (formattedTime === '000') {
    formattedTime = formattedHours ;
  }

  if(toolTip){
    formattedTime = toHumanReadableTime(formattedTime);
  }

  return formattedTime;
};

// Function to convert a time string (e.g., '0800') to human-readable format (e.g., '8:00 am')
export const toHumanReadableTime = (timeStr:string | number) : string => {
  // Ensure timeStr is always treated as a string
  timeStr = typeof timeStr === 'number' ? timeStr.toString() : timeStr;

  if(timeStr?.length <= 3){
     timeStr = timeStr?.length === 1 ? `0000` : `0${timeStr}`
  }

  const hour = parseInt(timeStr?.substring(0, 2), 10);
  const minute = parseInt(timeStr?.substring(2), 10);
  
  // Handle cases where hour is 0 or less than 10 (single digit)
  let formattedHour = hour === 0 ? 12 : hour % 12; // Convert 0 to 12 for 12-hour format
  const period = hour < 12 ? 'am' : 'pm'; // Determine am/pm
  
  // Adjust formattedHour to ensure it's at least 1 digit when hour is not 0
  formattedHour = formattedHour === 0 ? 12 : formattedHour;

  // Return formatted time string
  return `${formattedHour}:${minute.toString().padStart(2, '0')} ${period}`;
};

/**
 * Converts a time string in HHmm format to total minutes from midnight.
 * @param timeStr The time string in HHmm format (e.g., "0800").
 * @returns Total minutes from midnight.
 */
export const HHmmStringtoMinutes = (timeStr: string | number): number => {
  // Ensure timeStr is always treated as a string
  timeStr = typeof timeStr === 'number' ? timeStr.toString() : timeStr;

  if(timeStr?.length <= 3){
    timeStr = timeStr?.length === 1 ? `0000` : `0${timeStr}`
 }

  const hours = parseInt(timeStr.substring(0, 2), 10);
  const minutes = parseInt(timeStr.substring(2), 10);

  if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    throw new Error("Invalid time format. Expected HHmm.");
  }
  return hours * 60 + minutes;
};

/**
 * Converts a date string in the format "MM_DD" to "Dec, 11" format.
 *
 * @param {string} input - The input date string in "MM_DD" format.
 * @returns {string} The formatted date string in "11, Dec" format.
 */
export function convertDateToAbbreviation(input:string,platform?:"all" | "android" | "ios" | "web") {
  // Split the input into month and day
  let [month, day] = input.split('_');
  if(platform!=="all"){
    const temp=day
    day=month
    month=temp
  }
  // Convert the month number to a three-letter abbreviation
  const monthAbbreviation = new Date(`${month} 01 2000`).toLocaleString('en-us', { month: 'short' });

  // Format the result as "Dec, 11"
  const formattedDate = `${day}, ${monthAbbreviation}`;

  return formattedDate;
}

//to get the offset value

export function addOffSet() {
  // const t = dayjs().toDate();
  const timestamp = dayjs().unix();
  const offsetMinutes = dayjs().utcOffset();
  
  return timestamp + offsetMinutes * 60;
}
export function currentEpochTime(){
  return Date.now();
}

//conversion to locate date string
export  const dateToLocaleString = (timestamp: any) => {
 
  const parsedTimestamp = parseInt(timestamp); // Parse the Unix timestamp
  const date = new Date(parsedTimestamp * 1000); // Convert to milliseconds
  return date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
export const getWeekdaysInRange = (startDate: any, endDate: any) => {
  // Initialize a Set to store unique day values (0-6, representing Sunday to Saturday)
  const days = new Set(); 

  // Create a new Date object for the starting date
  const currentDate = new Date(startDate);

  // Set the end date's time to the end of the day to ensure it is included in the range
  endDate.setHours(23, 59, 59, 999);

  // Loop through each date in the range until the currentDate exceeds the endDate
  while (currentDate <= endDate) {
      // Add the current day's weekday (0-6) to the Set for unique storage
      days.add(currentDate.getDay());

      // Move to the next day in the range
      currentDate.setDate(currentDate.getDate() + 1);
  }

  // Convert the Set to an array to return the unique days in range (0-6)
  return Array.from(days);
};
// Function to format date from "2024_11_12" to "Nov 12, 2024"
export const formatDateToReadable = (dateStr:any) => {
  const [year, month, day] = dateStr.split('_');
  const date = new Date(`${year}-${month}-${day}`);
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
};