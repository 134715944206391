import { Divider } from "antd";
import "./Storage.css";
import { Form } from "antd";
import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import { STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { API_ENDPOINTS } from "utils/constants";
import { t} from "i18next";
import Selector from "components/Selector/Selector";
import { FormValues,StorageSettingsProps} from "./interfaces";
import ShowMessage from "components/ShowMessage/ShowMessage";
import Spinner from "components/Spinner/Spinner";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { ConfigTypeEnum, StorageConfigTypeEnum } from "utils/enums";
import {InfrastructureSettings } from "utils/models";
import { deserialize, serialize } from "utils/models/serializer";
import centralApi from "services/centralApi";
import { getToken } from "redux/features/app/authTokenSlice";

const Storage: React.FC<StorageSettingsProps> = ({
  currentAppKey,
  updateCurrentAppKey
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const [ssl ,setSSL] = useState();

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const [providerType , setProviderType] = useState<string>( StorageConfigTypeEnum.MINIO );
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  
  /**
   * 
   * @param e will be an event and e.target.value will be a number string
   * @param fieldName will be the key of and object where we are setting the values
   * @returns key with number value
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const inputValue = e.target.value;
    const numberValue = Number(inputValue);
  
    // Validate number or use default values
    const isValid = !isNaN(numberValue) && inputValue.trim() !== "";
  
    // Update form field with the correct number value
    if (isValid) {
      form.setFieldsValue({
        [fieldName]: numberValue,
      });
    }
    else{
      return undefined
    }
  };


/**
 * 
 * @param values takes values from form and will do serialization and deserialization from infrastructure model class
 *  send payload to updateGCMKeys function which will update the infrastructure settings
 */
const onFormSubmit = async(values: FormValues) => {

   // Initialize formData based on providerType
   let formData = new InfrastructureSettings();
  if(providerType === StorageConfigTypeEnum.MINIO ){
           formData = {
            storageSettings : {
              minioConfig : {
                    ...values,
                    useSSL: ssl!
                  },
              }
      }
  }
  else if(providerType === StorageConfigTypeEnum.AZURE){
     formData ={
        storageSettings: {
          azureStorageConfig : {
                ...values
              }
          }
     }
  }
  // Deserialization
  const storageConfig = deserialize(formData, InfrastructureSettings);

  
  // // Serialization
  const serializedStorageData = serialize(storageConfig as InfrastructureSettings);
  
  const payload = {
    args: JSON.stringify({
      app_id: appId,
      configType: ConfigTypeEnum.STORAGE,
      ...serializedStorageData,
    }),
    app_id: appId,
    api_key: api_key,
    authToken
  };

  await updateGCMKeys(payload);
  dispatch(getToken());


};

/**
 * 
 * @param formData takes payload data and calls post route for saving the settings in apps collection
 */
  async function updateGCMKeys(formData: any) {
    const url: string = API_ENDPOINTS.UPDATE_GCM_KEYS;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
      updateCurrentAppKey();
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  }; 

 useEffect(() => {
    const provider =  providerType;
    const minioConfig = currentAppKey[0]?.infrastructureSettings?.storageSettings?.minioConfig;
    const azureConfig = currentAppKey[0]?.infrastructureSettings?.storageSettings?.azureStorageConfig;

    const updatedFormValues: any = {
        storageProviderType : provider ,
        accessKey : minioConfig?.accessKey ,
        secretKey : minioConfig?.secretKey,
        port : minioConfig?.port,
        useSSL : minioConfig?.useSSL,
        minioEndpoint : minioConfig?.minioEndpoint,
        endpoint : providerType === StorageConfigTypeEnum.MINIO ? minioConfig?.endpoint : azureConfig?.endpoint ,
        bucketName :providerType === StorageConfigTypeEnum.MINIO ? minioConfig?.bucketName : azureConfig?.bucketName  ,
        azureApiBlobSASURL : azureConfig?.azureApiBlobSASURL,
        azureApiStorageSASToken : azureConfig?.azureApiStorageSASToken,
   }
   setProviderType(provider)
   setSSL(updatedFormValues?.useSSL);
   form.setFieldsValue({...updatedFormValues , useSSL : JSON.stringify(updatedFormValues?.useSSL)});
 },[ currentAppKey, form, providerType])


  return (
    <Fragment>
      <div className="storage-notification-container">
        <div className="mt-4 mb-4  ">
          <h6 className="storage-notification-container-heading">
            Storage Service
          </h6>
          <Divider className="storage-notification-custom-divider" />
        </div>

        <div className=" col-sm-7 mt-4 mb-4 align-items-center storage-notification-form ">
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFormSubmit}
            >
              <Form.Item
                     label={t("providerLabel")}
                     name={"storageProviderType"}
                  >
                    <Selector
                     onChange={(value: string) => {
                        setProviderType(value)
                     }
                       
                    }

                      options={[
                        {
                          label:t("azureLabel"),
                          value: StorageConfigTypeEnum.AZURE,
                        },
                        {
                          label: t("minioLabel"),
                          value: StorageConfigTypeEnum.MINIO,
                        },
                      ]}
                     
                    />
              </Form.Item>
            {/* Conditional Rendering based on storageProviderType */}

            {providerType === StorageConfigTypeEnum.MINIO && (
             <>
              <Form.Item
                label={t("accessKeyLabel")}
                 name={"accessKey"}
                rules={[
                  { required: true, message: "Please Select Access Key" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("secreyKeyLabel")}
                name={"secretKey"}
                rules={[
                  { required: true, message: "Please Select Secrey Key" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("minioEndPointLabel")}
                name={"minioEndpoint"}
                rules={[
                  { required: true, message: "Please Select Minio End Point" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("portLabel")}
                name={"port"}
                rules={[
                  { required: true, message: "Please Select Port" },
                ]}
                initialValue={""}
              >
                <TextInput
                      onChange={(e : any) => handleChange(e, "port")}
                      type="number" 
                    />
              </Form.Item>
              <Form.Item
                label={t("sslLabel")}
                name={"useSSL"}
                rules={[
                  { required: true, message: "Please Select SSL" },
                ]}
                // initialValue={} 
              >
                <Selector
                     onChange={(value: string) =>
                        setSSL(JSON.parse(value))
                    }

                      options={[
                        {
                          label: t("TrueLabel"),
                          value: STRING_BOOLEAN_OPTIONS.TRUE,
                        },
                        {
                          label: t("FalseLabel"),
                          value: STRING_BOOLEAN_OPTIONS.FALSE,
                        },
                      ]}
                     
                    />
              </Form.Item>
              </>
              )}
              {(providerType  == StorageConfigTypeEnum.AZURE &&
              <>
              <Form.Item
                label={t("blobSASUrlLabel")}
                 name={"azureApiBlobSASURL"}
                rules={[
                  { required: true, message: "Please Select Blob SAS Url" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("sasTokenLabel")}
                 name={"azureApiStorageSASToken"}
                rules={[
                  { required: true, message: "Please Select SAS Token" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              </>
              )}
               <Form.Item
                label={t("endPointLabel")}
                name={"endpoint"}
                rules={[
                  { required: true, message: "Please Select End Point" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                     label={t("additionalSettingsLabel")}
                     name={"bucketName"}
                     rules={[
                        { required: true, message: "Please Select Additional Settings" },
                      ]}
                  >
                   <TextInput />
               </Form.Item>

                          

              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  Save
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default Storage;
