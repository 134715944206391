import { PlusOutlined } from "@ant-design/icons";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { FC, useState } from "react";
import "./Nudge.css";
import NudgeScreen from "./components/NudgeScreen/NudgeScreen";
import AddNudgeScreen from "./components/AddNudgeScreen/AddNudgeScreen";
import NudgeTemplate from "./components/NudgeTemplate/NudgeTemplate";
import CampaignDevice from "components/CampaignDevice/CampaignDevice";
import TemplateLayout from "pages/Campaign/CampaignCreate/layout/TemplateLayout/TemplateLayout";
import img1 from "images/nudgeimg2.jpeg"
interface NudgeProps {}

const Nudge: FC<NudgeProps> = ({}) => {
  const [screen, setScreen] = useState<any[]>([{file:img1}]);
  const [currentScreen, setCurrentScreen] = useState(-1);
  return (
    <div>
      {currentScreen == -1 ? (
        <AddNudgeScreen
          screen={screen}
          setScreen={setScreen}
          max={3}
          onClick={(value) => setCurrentScreen(value)}
        />
      ) : (
        <TemplateLayout
          footerItem={<></>}
          leftItems={<div className="ms-5 ps-5"><NudgeScreen screen={screen} index={currentScreen} onScreenClick={()=>{}}></NudgeScreen></div>}
          rightItems={
            <div>
              <NudgeTemplate
                screen={screen}
                setCurrentScreen={setCurrentScreen}
                currentScreen={currentScreen}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default Nudge;
