import { Select, SelectProps } from "antd";
import { useMemo, useState } from "react";
import "./Selector.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { SelectorModes } from "utils/enums";
import { t } from "i18next";
function Selector({ children, ...props }: SelectProps) {
  const [selectedValues, setSelectedValues] = useState<any[] | undefined>(
    props.mode == SelectorModes.MULTIPLE && props.value
  );
  /**
 * This useMemo function is used to filter the `options` prop and return a list of unique items 
 * based on the `value` property. The function ensures that:
 * - The `options` prop is validated: if it's not provided, an empty array is returned.
 * - Each item in the `options` array must have both a `value` and a `label`, and neither of them should be an object.
 * - Duplicate `value` entries are removed, keeping only the first occurrence of each unique `value`.
 * 
 * The memoization provided by useMemo ensures that the filtering logic is only recalculated when the `options` prop changes,
 * optimizing performance.
 */

const filteredOptions = useMemo(() => {
  // If there are no 'options' provided in props, return an empty array
  if (!props.options) return [];

  // Create a Set to keep track of unique 'value' entries. 
  // This will help us filter out duplicates later.
  const seenValues = new Set();

  // Filter the 'options' array to include only valid items:
  return props.options?.filter(item => {
    // For each 'item', check if it has both 'value' and 'label' properties,
    // and ensure that neither 'value' nor 'label' is an object type.
    if (item.value && item.label && typeof item.value !== "object" && typeof item.label !== "object") {
      
      // Determine if the current 'item.value' has already been seen (a duplicate).
      const duplicate = seenValues.has(item.value);

      // If the value is new (not a duplicate), add it to the 'seenValues' set for future checks.
      seenValues.add(item.value);

      // Only include the item if it's not a duplicate.
      return !duplicate;
    }

    // If the item does not meet the criteria (no value/label, or they are objects), exclude it.
    return false;
  });

}, [props.options]);

 //handle "select all" and "clear all" click
  const handleAction = (clear=false) => {
    // on clear all click
    if (clear) {
      setSelectedValues(undefined);
      if(props.onChange){
        props.onChange(undefined,[])
      }
      // on select all click
    } else if (filteredOptions) {
      let data=[]
      let options=props?.options
      if(props?.maxCount){
       options=options?.slice(0,props.maxCount)
      }
      data=options?.map((child: any) => child?.value) || []
      setSelectedValues(data);
      if(props.onChange){
        props.onChange(data,[])
      }
    }
  };

  return (
    <span className="custom-ant-selector-parent-container">
      <Select
        {...props}
        options={filteredOptions}
        value={props.mode == SelectorModes.MULTIPLE ? selectedValues : props.value}
        onChange={props.mode == SelectorModes.MULTIPLE?(values:any) => {
            setSelectedValues(values);
            if(props.onChange){
              props.onChange(values,[])
            
        }}:props.onChange} 
        allowClear={false}
        showSearch
        dropdownRender={
          props.mode == SelectorModes.MULTIPLE && filteredOptions?.length
            ? (menu) => (
                <>
                  <PrimaryButton
                    disabled={props?.maxCount?props.maxCount==selectedValues?.length:false}
                    className="px-2 mx-1"
                    type="link"
                    onClick={()=>handleAction(false)}
                  >
                    { t("selectAllLabel")}
                  </PrimaryButton> 
                  <PrimaryButton
                    className="px-2 mx-1"
                    type="link"
                    onClick={()=>handleAction(true)}
                  >
                    { t("clearAllLabel")}
                  </PrimaryButton>
                  {menu}
                </>
              )
            : props.dropdownRender
        }
      >
        {children}
      </Select>
    </span>
  );
}

export default Selector;
Selector.defaultProps = {
  size: "middle",
  width: "auto",
};
