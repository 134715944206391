import { MenuProps } from 'antd'
import InnerNav from 'components/InnerNav/InnerNav'
import { t } from 'i18next'
import React, { Fragment, useEffect, useState } from 'react'
import Storage from './Components/Storage/Storage'
import Queue from './Components/Queue/Queue'

interface  InfrastructureProps{
    currentAppKey: any;
    updateCurrentAppKey : any;
  }

const Infrastructure : React.FC<InfrastructureProps> = ({
    currentAppKey,
    updateCurrentAppKey
  }) => {
    const [appTabFilter, setAppTabFilter] = useState("storage")
    const [selectedComponent, setSelectedComponent] =
      useState<JSX.Element | null>(null)
  
    const onTabClick: MenuProps["onClick"] = (e) => {
      setAppTabFilter(e.key)
    }
  
    useEffect(() => {
      switch (appTabFilter) {
        case "storage":
          setSelectedComponent(<Storage 
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
            />)
          break
        case "queue":
          setSelectedComponent(<Queue
            currentAppKey={currentAppKey}
            updateCurrentAppKey={updateCurrentAppKey}
           />)
          break
           // Add cases for other tabs
      default:
        setSelectedComponent(<div></div>) // Default empty component
    }
  }, [appTabFilter])
  return (
    <Fragment>
        <div className="app-setting-container ">
        <div className="app-setting-nav">
          <InnerNav
            theme="light"
            onTabClick={onTabClick}
            selected={appTabFilter}
            menu={[
              {
                
                label: t("storageSettingLabel"),
                 key: "storage",
            
              },              
              {
                
                label: t("queueSettingLabel"),
                 key: "queue",
            
              }
            ]}
            />
          </div>
        </div>
        {selectedComponent}
      </Fragment>
    )
}

export default Infrastructure;