import {  Card, Divider,  Space } from "antd";
import "./ProviderSetting.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import useMessage from "hooks/useMessage";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";

interface ProviderSettingProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const ProviderSetting: React.FC<ProviderSettingProps> = ({currentAppKey,updateCurrentAppKey}) => {
 
 
  interface ProviderKey {
    key: string;
    value: string;
  }
  
  interface ProviderValue {
    provider: string;
    providerKeys: ProviderKey[];
  }
  
  interface Provider {
    type: string;
    values: ProviderValue[];
  }
  
  
  const providersData=currentAppKey[0]?.providers
  const [form] = Form.useForm();
  const [providers, setProviders] = useState<Provider[]>([]);
  const authToken = useSelector((state: any) => state.authToken.token);
  const dispatch: any = useDispatch();

  const [loading, setLoading] = useState(false);
  const { showError, showSuccess } = useMessage();
 
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;


  async function saveAppProvider(formData: any) {
    const payload = {
      args: JSON.stringify({
        ...formData,
      }), // Add formData and extra fields only when saving
      authToken,
      api_key: api_key,
      app_id: appId,
    };


    try {
      setLoading(true);
      await centralApi("POST", API_ENDPOINTS.SAVE_APP_PROVIDERS, payload, null);
      showSuccess(t("sucessfullySavedLabel"));
      updateCurrentAppKey()
      dispatch(getToken())

      setLoading(false);

    } catch (error) {
      setLoading(false);

      showError(t("somethingWrongLabel"));
    }
  }
  
  useEffect(() => {
    dispatch(getToken())
  }, [appId]);

 
  useEffect(() => {
    if (providersData) {
    
      setProviders(providersData.items);
        }
  }, [providersData,currentAppKey[0],form]);
  
  // Function to add a new provider to the list of providers
const addProvider = () => {
  // Update the state with a new provider
  setProviders([
    ...providers, // Spread the current providers to maintain existing ones
    { type: '', values: [] } // Add a new provider object with an empty type and an empty values array
  ]);
};

 // Function to remove a provider by its index
const removeProvider = (index: number) => {
  // Create a new array of providers, excluding the provider at the specified index
  const newProviders = providers.filter((_, idx) => idx !== index);
  // Update the state with the new array of providers
  setProviders(newProviders);
};

// Function to add a new value to a specific provider
const addValue = (providerIndex: any) => {
  // Create a new copy of the providers array
  const newProviders = [...providers];
  
  // Add a new value object with an empty provider name and an empty keys array
  newProviders[providerIndex].values.push({ provider: "", providerKeys: [] });
  
  // Update the state with the modified providers array
  setProviders(newProviders);
};

// Function to remove a value from a specific provider by its index
const removeValue = (providerIndex: any, valueIndex: number) => {
  // Create a new copy of the providers array
  const newProviders = [...providers];
  
  // Filter out the value at the specified index from the values array of the provider
  newProviders[providerIndex].values = newProviders[providerIndex].values.filter((_, idx) => idx !== valueIndex);
  
  // Update the state with the modified providers array
  setProviders(newProviders);
};

// Function to add a new attribute (key-value pair) to a specific value of a provider
const addAttribute = (providerIndex: any, valueIndex: number) => {
  // Create a new copy of the providers array
  const newProviders = [...providers];
  
  // Add a new key-value pair object with empty key and value
  newProviders[providerIndex].values[valueIndex].providerKeys.push({ key: '', value: '' });
  
  // Update the state with the modified providers array
  setProviders(newProviders);
};

// Function to remove an attribute from a specific value of a provider by its index
const removeAttribute = (providerIndex: any, valueIndex: number, attrIndex: number) => {
  // Create a new copy of the providers array
  const newProviders = [...providers];
  
  // Filter out the attribute at the specified index from the providerKeys array of the value
  newProviders[providerIndex].values[valueIndex].providerKeys = newProviders[providerIndex].values[valueIndex].providerKeys.filter((_, idx) => idx !== attrIndex);
  // Update the state with the modified providers array
  setProviders(newProviders);
};

// Function to handle changes in the provider type or values
const handleChange = (providerIndex: any, field: any, value: any) => {
  // Create a new copy of the providers array
  const newProviders: any = [...providers];
  
  // Update the specific field of the provider at the specified index with the new value
  newProviders[providerIndex][field] = value;
  
  // Update the state with the modified providers array
  setProviders(newProviders);
};

// Function to handle the submission of the provider data
const handleSubmitButton = () => {
  // Call the saveAppProvider function with the current providers data
  saveAppProvider({ items: providers });
};
 return (
    <div className="provider-setting-container">
      <div className=" mt-4 mb-4  ">

        <div>
          <div className="  mt-4 align-items-center provider-setting-form ">
            {loading ? (
              <Spinner />
            ) : (
  <Form layout="vertical" onFinish={handleSubmitButton}>
      <div className="d-flex justify-content-between align-items-baseline">
        <h6 className="provider-setting-container-heading">{t("addOrRemoveProviderLabel")}</h6>
        <PrimaryButton type="primary" onClick={addProvider}>
          {t("addTypesLabel")}
        </PrimaryButton>
      </div>
      <Divider className="provider-setting-custom-divider" />

      <div className="d-flex flex-wrap gap-2">
      {providers.map((provider, providerIndex) => (
  // Render a Card component for each provider
  <Card
    key={providerIndex} // Unique key for React to identify each element
    style={{ width: 370 }} // Set the width of the Card
    size="small" // Define the size of the Card
    title={`Type: ${providerIndex + 1}`} // Display the provider index as the title
    extra={<CloseOutlined onClick={() => removeProvider(providerIndex)} />} // Close button to remove the provider
  >
    <Form.Item>
      <TextInput
        value={provider.type} // Bind the TextInput value to the provider type
        onChange={(e: any) => handleChange(providerIndex, 'type', e.target.value)} // Handle changes to the provider type
        placeholder={t("typeLabel")} // Placeholder for the input field
      />
    </Form.Item>

    <div className="provider-setting-form-attributes">
      {provider.values.map((value: any, valueIndex: number) => (
        // Render a section for each value of the provider
        <div key={valueIndex} style={{ marginBottom: '10px' }}>
          <Space>
            <TextInput
              value={value.provider} // Bind the TextInput value to the provider name
              onChange={(e: any) => {
                // Handle changes to the provider name
                const newProviders = [...providers];
                newProviders[providerIndex].values[valueIndex].provider = e.target.value;
                setProviders(newProviders); // Update the state with the new provider name
              }}
              placeholder={t("providerNameLabel")} // Placeholder for the input field
            />
            <CloseOutlined onClick={() => removeValue(providerIndex, valueIndex)} /> {/* Button to remove this value */}
          </Space>

          {/* Nested Attributes Section */}
          <div className="  provider-setting-container-card-form-space">

            {value.providerKeys.map((attr: any, attrIndex: number) => (
              // Render each attribute for the value
              <Space className="provider-setting-container-card-form-space" key={attrIndex}>
                <TextInput
                  value={attr.key} // Bind the TextInput value to the attribute key
                  onChange={(e: any) => {
                    // Handle changes to the attribute key
                    const newProviders = [...providers];
                    newProviders[providerIndex].values[valueIndex].providerKeys[attrIndex].key = e.target.value;
                    setProviders(newProviders); // Update the state with the new attribute key
                  }}
                  placeholder={t("attributeKeyLabel")} // Placeholder for the input field
                />
                <TextInput
                  value={attr.value} // Bind the TextInput value to the attribute value
                  onChange={(e: any) => {
                    // Handle changes to the attribute value
                    const newProviders = [...providers];
                    newProviders[providerIndex].values[valueIndex].providerKeys[attrIndex].value = e.target.value;
                    setProviders(newProviders); // Update the state with the new attribute value
                  }}
                  placeholder={t("attributeValueLabel")} // Placeholder for the input field
                />
                <CloseOutlined onClick={() => removeAttribute(providerIndex, valueIndex, attrIndex)} /> {/* Button to remove this attribute */}
                <PlusOutlined onClick={() => addAttribute(providerIndex, valueIndex)} /> {/* Button to add a new attribute */}
              </Space>
            ))}
            {/* Button to add a new attribute for this value */}
            {value.providerKeys.length === 0 &&    <PrimaryButton className="provider-setting-container-card-form-space" type="dashed" onClick={() => addAttribute(providerIndex, valueIndex)} block>
              {t("addProviderKeysLabel")}
            </PrimaryButton>}
          </div>
        </div>
      ))}
      {/* Button to add a new value for this provider */}
      <PrimaryButton type="primary" onClick={() => addValue(providerIndex)}>
        {t("addProviderLabel")}
      </PrimaryButton>
    </div>
  </Card>
))}

      </div>

      <PrimaryButton className="mt-2" type="primary" htmlType="submit">
        {t("saveLabel")}
      </PrimaryButton>
    </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProviderSetting;
