import "./SmartLinks.css"
import { CopyTwoTone, PlusOutlined } from "@ant-design/icons"
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton"
import CustomToolTip from "components/CustomToolTip/CustomToolTip"
import Search from "components/Inputs/Search/Search"
import Selector from "components/Selector/Selector"
import { copyText } from "helperFunctions/string"
import useDebouncedValue from "hooks/useDebouncedState"
import useMessage from "hooks/useMessage"
import eyeIcon from "../../../images/eye.png"
import editIcon from "../../../images/_edit.svg"
import { t } from "i18next"
import { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useNavigate } from "react-router-dom"
import { BOOLEAN_OPTIONS, PAGE_OPTIONS, STRING_BOOLEAN_OPTIONS, TABLE_OPTIONS } from "utils/constants/selectorOptions"
import CustomTable from "components/CustomTable/CustomTable"
import { API_ENDPOINTS } from "utils/constants"
import centralApi from "services/centralApi"
import { useSelector } from "react-redux"


export const SmartLinks = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useDebouncedValue("", 500);
  const [selectedAppGroup, setSelectedAppGroup] = useState("All");
  const [selectedStatus , setSelectedStatus ] = useState(STRING_BOOLEAN_OPTIONS.TRUE)
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const [pageData, setPageData] = useState(PAGE_OPTIONS.PAGINATION)
  const [smartLinksData, setSmartLinksData] = useState<any>([]);
  const [appGroupData , setAppGroupData] = useState<any>([]);
  const { showError, showSuccess } = useMessage()
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const kochava= useSelector((state: any) => state.featuresSetting.data[0]?.attribution?.kochava);
  

  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;

  /**
   * 
   * @param text copies all the text passed as an argument
   */
  const handleTextCopy = async (text: string) => {
    const res = await copyText(text)
    showSuccess(res.message)
  }
   // when the page number of table changes
   const onPageChange = (value: any) => {
    setPageData(value)
  }

 /**
  * getSmartLinksData function for fetching the smart links from kochava 
  */
  const getSmartLinksData = async()=>{
    const url: string = API_ENDPOINTS.GET_SMART_LINKS;
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        is_active:JSON.parse(selectedStatus),
        search:search.length > 0 ? search : undefined,
        app_group_id:selectedAppGroup ==="All"? undefined : selectedAppGroup,
        page: pageData.current,
        page_size: pageData.pageSize,
        kochava_api_key:kochava?.apiKey
      }),
      api_key: api_key,
      app_id:appId
    };

    try {
      setLoading(BOOLEAN_OPTIONS.TRUE);
      const response = await centralApi("GET", url, null, payload);
      setSmartLinksData(response?.data)
      setLoading(BOOLEAN_OPTIONS.FALSE);
    } catch (e: any) {
      setLoading(BOOLEAN_OPTIONS.FALSE);
    }
  }

  /**
   * getAppGroupData function for fetching app groups data from kochava
   */
  const getAppGroupData = async()=>{
    const url: string = API_ENDPOINTS.GET_APP_GROUPS;
    const payload = {
        args: JSON.stringify({
          app_id: appId,
          is_active:BOOLEAN_OPTIONS.TRUE,
          kochava_api_key:kochava?.apiKey
        }),
        api_key: api_key,
        app_id:appId
      };
  
      try {
        const response = await centralApi("GET", url, null, payload);
        setAppGroupData(response?.data)
      } catch (e: any) {
        setLoading(BOOLEAN_OPTIONS.FALSE);
      }

  }

  /**
   * calling getSmartLinksData function everytime when dependency array changes 
   */
  useEffect(()=>{
    if(kochava?.apiKey && kochava?.apiKey.length > 0){
      getSmartLinksData()
    }
  },[search,selectedStatus,selectedAppGroup,kochava]);

  /**
   * calls getAppGroupData when navigating to this page
   */
  useEffect(()=>{
    if(kochava?.apiKey && kochava?.apiKey.length > 0){
      getAppGroupData() 
    }  
  },[])
  

  /**
   * appGroupOptions for creating options for app group dropdown
   */
  const appGroupOptions  = useMemo(()=>{
    if(appGroupData.length>0){
        // Transform the data and add "All" option
        const transformedOptions = [
        { label: 'All', value: "All" }, // Add "All" as the first option
        ...appGroupData?.map((group:any) => ({
            label: group.name,
            value: group.id
        }))];
        return transformedOptions;
     }
     return []
  },[appGroupData])

  //coulmns for the smart link table
  const tableColumns: any = [
    {
      title: (
        <div className="d-flex justify-content-between align-items-baseline">
          {"Name"}
        </div>
      ),
      render: (details: any) => (
        <div className="d-flex flex-column" style={{ minWidth: "300px" }}>
          {details.name}
          <span className="text-disabled d-flex align-items-center justify-content-between">
            <Link to="#">
              <span className="text-overflow-ellipsis link-container">{details.click_url}</span>
            </Link>
            <CopyTwoTone onClick={() => handleTextCopy(details.click_url)} />
          </span>
        </div>
      ),
    },
    {
      title: "App Group",
      dataIndex: "app_group_name",
    },
    {
      title: "Media / Agency Partner",
      dataIndex: "network_name",
    },
    {
      title: t("actionLabel"),
      width: "200px",
      className: "align-item text-center",
      render: (smartlink: any) => {
        const imgStyle = {
          width: 16,
        };
        return (
          <span className="d-flex justify-content-center campaign-action-btns">
              <CustomToolTip title="View SmartLinks">
                <PrimaryButton className="action-btn mx-1"  onClick={() =>
                  navigate("smartlinks/details", {
                    state: { data: smartlink},
                  })
                }
              >
                  <img src={eyeIcon} alt={"eye_icon"} style={{ width: 16 }}></img>
                </PrimaryButton>
              </CustomToolTip>
            {/* <CustomToolTip title={t("editLabel")}>
              <PrimaryButton
                className="action-btn mx-1"
                onClick={() =>
                  navigate("create", {
                    state: { id: smartlink.id },
                  })
                }
              >
                <img src={editIcon} alt={"edit_icon"} style={imgStyle}></img>
              </PrimaryButton>
            </CustomToolTip> */}
          </span>
        );
      },
    },
  ];
  

  return (
    <div className="container-fluid"> 
     <div className="smartlinks-reports-container">
      <Helmet>
        <title>Appice | SmartLinks</title>
      </Helmet>
        <div className="px-sm-2 px-1 smartlinks-reports-container m-1">
          <div className="py-3 m-0 d-flex  flex-row w-100">
           <div className="w-100 d-flex h-100 flex-wrap">
            <Search
              placeholder="Search"
              onChange={(e: any) => setSearch(e.target.value)}
              size="middle"
              className="smartlinks-reports-search"
            />
            <div className=" d-flex flex-wrap">
            <Selector
              onChange={(value) => setSelectedAppGroup(value)}
              style={{ width: 250 }}
              defaultValue={selectedAppGroup}
              showSearch
              options={appGroupOptions}
            />
              <Selector

                onChange={(value) => {
                  setSelectedStatus(value)
                }}
                defaultValue={selectedStatus}
                className="ms-2"
                style={{ width: 150 }}
                options={[
                    {
                      value: "true",
                      label: "Active" ,
                    },
                    {
                      value: "false",
                      label: "Archieved",
                    }]
                }
              />
            </div>
          </div>
          </div>
          <div>
          <CustomTable
            rowKey={"id"}
            columns={tableColumns}
            dataSource={smartLinksData}
            size={TABLE_OPTIONS.SIZE}
            loading={loading}
            onChange={onPageChange}
            pagination={{
                current: pageData.current,
                pageSize: pageData.pageSize,
                simple: pageData.simple,
                total: smartLinksData.length,
            }}
            
            />
          </div>
        </div>
     </div>
    </div>
  )
}
