import { Divider } from "antd";
import "./Huawei.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "services/centralApi";
import Spinner from "components/Spinner/Spinner";
import TextInput from "components/Inputs/TextInput/TextInput";
import React from "react";
import { HuaweiProps, FormValues, UpdateGCMKeysPayload } from "./interfaces";
import { PushNotificationMode } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import {
  MODE_OPTIONS,
  STRING_BOOLEAN_OPTIONS,
} from "utils/constants/selectorOptions";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import { getToken } from "redux/features/app/authTokenSlice";
import useMessage from "hooks/useMessage";

const Huawei: React.FC<HuaweiProps> = ({
  currentAppKey,
  Mode,
  fetchCurrentAppKey,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();
  const { showError, showSuccess } = useMessage();

  const mode =
    Mode === PushNotificationMode.DEVELOPMENT
      ? MODE_OPTIONS.DEV
      : Mode === PushNotificationMode.PRODUCTION
      ? MODE_OPTIONS.PROD
      : "";
  const [proxy, setProxy] = useState<string>(STRING_BOOLEAN_OPTIONS.FALSE);
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [form] = Form.useForm();

  const app_id = activeApp.appId;
  const api_key = loginUser.data.api_key;

  /**
   * it will save the latest huawei*/
  const onFormSubmit = async (values: FormValues) => {
    const { appId, appSecret, proxyEnabled, host, port, username, password } =
      values;
    const parsedDropdown: any = proxyEnabled ? JSON.parse(proxyEnabled) : ""; // Only parse if getAppDropdown exists
    const portNumber: any = port ? JSON.parse(port) : null; // Only parse if getAppDropdown exists

    const hms = {
      appId, // from values
      appSecret, // from values
      proxyEnabled: parsedDropdown, // parsed if it exists
      host, // from values
      port:portNumber,
      username: username || null, // null if empty string
      password: password || null, // null if empty string
    };
    const payload: UpdateGCMKeysPayload = {
      args: JSON.stringify({
        app_id: app_id,
        hms,
        mode,
      }),
      authToken,
      app_id: app_id,
      api_key: api_key,
    };
    await updateGCMKeys(payload);
    dispatch(getToken());
  };

  const updateGCMKeys = async (formData: UpdateGCMKeysPayload) => {
    const url: string = API_ENDPOINTS.UPDATE_GCM_KEYS;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);

      setLoading(false);
      showSuccess(t("sucessfullySavedLabel"));

      fetchCurrentAppKey();
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  };

  useEffect(() => {
    const hms = currentAppKey[0]?.hms || {};

    // Get the appropriate configuration based on the mode
    const currentConfig =
      hms[Mode === PushNotificationMode.DEVELOPMENT ? "dev" : "prod"] || {};

    // Simplified updatedFormValues
    const updatedFormValues = {
      appId: currentConfig.appId || "", // Default to empty string if undefined or null
      proxyEnabled: String(currentConfig.proxyEnabled),
      appSecret: currentConfig.appSecret || "", // Default to empty string if undefined or null
      host: currentConfig.host || "", // Default to empty string if undefined or null
      port: currentConfig.port || "", // Default to empty string if undefined or null
      username: currentConfig.username || null, // Default to empty string if undefined or null
      password: currentConfig.password || null, // Default to empty string if undefined or null

    };

    //checks if data is in http_proxy we set the enableProxy selector to true
    if (updatedFormValues.proxyEnabled) {
      setProxy(updatedFormValues.proxyEnabled);
    }

    form.setFieldsValue(updatedFormValues);
  }, [mode, currentAppKey, form]);

  return (
    <Fragment>
      <div className="huawei-container">
        <div className="mt-4 mb-4">
          <h6 className="huawei-container-heading">
            {t("huaweiLabel")} -- {Mode}
          </h6>
          <Divider className="huawei-custom-divider" />
        </div>

        <div className="col-sm-9 mt-4 mb-4 align-items-center huawei-form">
          {loading ? (
            <Spinner />
          ) : (
            <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label={t("appIdLabel")}
                name={"appId"}
              >
                <TextInput />
              </Form.Item>{" "}
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label={t("appSecretLabel")}
                name={"appSecret"}
              >
                <TextInput />
              </Form.Item>
              <Form.Item name="proxyEnabled" label={t("enableProxyLabel")}>
                <Selector
                  value={proxy}
                  onChange={(value: string) => setProxy(value)}
                  options={[
                    {
                      label: t("TrueLabel"),
                      value: STRING_BOOLEAN_OPTIONS.TRUE,
                    },
                    {
                      label: t("FalseLabel"),
                      value: STRING_BOOLEAN_OPTIONS.FALSE,
                    },
                  ]}
                />
              </Form.Item>
              {/* Conditional redering  for  proxy*/}
              {proxy === STRING_BOOLEAN_OPTIONS.TRUE && (
                <>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label={t("hostLabel")}
                    name={"host"}
                  >
                    <TextInput placeholder="Host" />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    name="port"
                    label={t("portLabel")}
                  >
                    <TextInput type="number" />
                  </Form.Item>
                  <Form.Item label={t("usernameLabel")} name={"username"}>
                    <TextInput />
                  </Form.Item>
                  <Form.Item label={t("passwordLabel")} name={"password"}>
                    <TextInput />
                  </Form.Item>
                </>
              )}
              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  {t("saveLabel")}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Huawei;
