import { Divider } from "antd";
import "./Security.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import Selector from "../../../../components/Selector/Selector";
import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";
import { getToken } from "redux/features/app/authTokenSlice";
import { STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";

interface SecurityProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const Security= ({
  currentAppKey,
  updateCurrentAppKey,
}:SecurityProps) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();

  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = async (values: any) => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        ...values,
      }),
      app_id: appId,
      api_key: api_key,
      authToken
    };
    await saveAppAuthState(payload);
    updateCurrentAppKey();
    dispatch(getToken())
  };

  async function saveAppAuthState(formData: any) {
    const url: string = `/i/apps/saveAppAuthState`;

    try {
      setLoading(true);
      const data = await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: t("sucessfullySavedLabel"),
        show: true,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      passwordResetMode: currentAppKey[0]?.ResetPassswordMode,
      concurrentLogin: currentAppKey[0]?.concurrentLogin,
      state: currentAppKey[0]?.email_auth,
      auditlog:currentAppKey[0]?.insertAuditLog
    };
    form.setFieldsValue(updatedFormValues);
  }, [currentAppKey, form]);
  return (
    <Fragment>
      <div className="security-setting-container">
        <div className="mt-4 mb-4  ">
          <h6 className="security-setting-container-heading">
            {t("securitySettingLabel")}
          </h6>
          <Divider className="security-setting-custom-divider" />
        </div>

        <div className=" col-sm-8 mt-4 mb-4 align-items-center security-setting-form ">
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
              <Form.Item
                label={t("passwordResetModeLabel")}
                name="passwordResetMode"
                rules={[
                  {
                    required: true,
                    message: "Please select Reset Password Mode",
                  },
                ]}
              >
                <Selector
                  onChange={(value) => {}}
                  size="middle"
                  style={{ width: "100%" }}
                  defaultValue={t("selectLabel")}
                  options={[
                    {
                      value: "",
                      label: t("selectLabel"),
                    },
                    {
                      value: "SMTP",
                      label: t("smtpLabel"),
                    },
                    {
                      value: "Admin",
                      label: t("adminLabel"),
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t("allowConcurrentLoginLabel")}
                name="concurrentLogin"
                rules={[
                  { required: true, message: "Please Select Concurrent Login" },
                ]}
              >
                <Selector
                  onChange={(value) => {}}
                  size="middle"
                  style={{ width: "100%" }}
                  defaultValue={t("selectLabel")}
                  options={[
                    {
                      value: "",
                      label:  t("selectLabel"),
                    },
                    {
                      value: "ON",
                      label:  t("onLabel"),
                    },
                    {
                      value: "OFF",
                      label:  t("offLabel"),
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t("emailAuthLabel")}
                name="state"
                rules={[
                  { required: true, message: "Please Select Email Auth" },
                ]}
              >
                <Selector
                  onChange={(value) => {}}
                  size="middle"
                  style={{ width: "100%" }}
                  defaultValue={t("selectLabel")}
                  options={[
                    {
                      value: "",
                      label:  t("selectLabel"),
                    },
                    {
                      value: "disable",
                      label: t("disableLabel"),
                    },
                    {
                      value: "enable",
                      label: t("enableLabel")
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t("insertAuditLogLabel")}
                name="auditlog"
              >
                <Selector
                  onChange={(value) => {}}
                  size="middle"
                  style={{ width: "100%" }}
                  defaultValue={t("selectLabel")}
                  options={[
                    {
                      value: "",
                      label:  t("selectLabel"),
                    },
                    {
                      value: STRING_BOOLEAN_OPTIONS.TRUE,
                      label:  t("onLabel"),
                    },
                    {
                      value: STRING_BOOLEAN_OPTIONS.FALSE,
                      label:  t("offLabel"),
                    },
                  ]}
                />
              </Form.Item>
              

              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "70px" }}
                >
                  {t("saveLabel")}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default Security;
