import { Col, Row } from "antd";
import "./AppsContent.css";
import Selector from "components/Selector/Selector";
import { useEffect, useState } from "react";
import TextInput from "components/Inputs/TextInput/TextInput";

interface AppsContentProps {
  apps: any[];
  onRoutesChange: (routesByApp: any[]) => void;
}

export const AppsContent = ({ apps, onRoutesChange }: AppsContentProps) => {
  const [routesByApp, setRoutesByApp] = useState<any[]>([]);

  useEffect(() => {
    // Initialize routes only once
    const initialRoutes = apps.map((app) => ({
      app_id: app.app_id,
      events: [],
      routes: [
        {
          name: "",
          destination_url: "",
          reengagement_url: "",
          meta: {
            destination_url: "",
            reengagement_url: "no_deep_link",
            destination_name: "",
          },
          native_link: app.platform,
          rules: {
            platform_in: [app.platform],
          },
        },
      ],
    }));
    setRoutesByApp(initialRoutes);
    onRoutesChange(initialRoutes); // Notify parent of the initial state
  }, [apps]); // Only run this when `apps` changes

  // Use a separate effect to avoid unnecessary parent updates
  useEffect(() => {
    onRoutesChange(routesByApp);
  }, [routesByApp, onRoutesChange]); // Only trigger when `routesByApp` changes


  const handleInputChange = async (
    appId: number,
    data: {
      [key: string]: string | string[] | boolean | undefined;
    },
    parentKey: string

  ) => {
    // Find the current app based on appId
    const currentAppIndex = routesByApp.findIndex((route) => route.app_id === appId);
    const updatedAppData = {...routesByApp[currentAppIndex],[parentKey]:[parentKey==="events"? data[parentKey] : {...routesByApp[currentAppIndex][parentKey][0],...data}]}
    
    const updatedRoutes = [...routesByApp];
    updatedRoutes[currentAppIndex] = updatedAppData;


    setRoutesByApp(updatedRoutes);

   
  };

  //getting fallback url when selecting default for selector
  const getFallbackUrl = (appId:string , value:string)=>{
    const currentApp = apps?.find((route) => route.app_id === appId);
    if (value === "default"){
      return currentApp?.fallback_url
    }
    return undefined
  }

  //handling input props on the basis of data
  const getTextInputProps = (appId: number) => {
    const currentApp = routesByApp.find((route) => route.app_id === appId);
    const destinationUrl = currentApp?.routes[0]?.destination_url || "";
    const isDisabled = currentApp?.routes[0]?.meta?.destination_url === "default";
  
    return { value: destinationUrl, disabled: isDisabled };
  };
  

  return (
     <>
     {apps.map((app:any) => (
       <div key={app?.app_id} className="apps-content-container my-3">
        <h6 className="d-flex gap-1">{app?.name}<small className="text-muted">({app.platform})</small></h6>
        <small className="text-muted">APP LINK:</small> <span>Yes</span>
        <Row gutter={50}>
          <Col span={8}>
            <div className="w-100 mt-3 d-flex flex-column">
              <span className="mb-1 font-size-xsm">
                Conversion Events
              </span>
              <Selector
                className="input-size-xlg"
                showSearch
                onChange={(value) => handleInputChange(app.app_id,{events:value} ,"events")}
                options={[
                  {
                    label: "_Install",
                    value: "_Install"
                  }
                ]}
              />
            </div>
          </Col>
          <Col span={16}>
            <div className="w-100 mt-3 d-flex flex-column">
              <span className="mb-1 font-size-xsm">
                Tracker Name
              </span>
              <TextInput
                placeholder={"Tracker Name"}
                style={{ width: '100%' }}
                onChange={(e:any) => handleInputChange(app.app_id, {"name": e.target.value},"routes")}
              />
            </div>
          </Col>
         </Row>
         <Row gutter={50}>
         <Col span={8}>
            <div className="w-100 mt-3 d-flex flex-column">
              <span className="mb-1 font-size-xsm">
                Destination if App is not installed
              </span>
              <Selector
                placeholder={"Please Select"}
                className="input-size-xlg"
                showSearch
                onChange={(value, option) =>{
                  const selectedOption:any = option;
                  const currentAppIndex = routesByApp.findIndex((route) => route.app_id === app.app_id );
                  const fallbackUrl = getFallbackUrl(app.app_id,value)
                  handleInputChange(
                     app.app_id,
                     {
                      destination_url:fallbackUrl,meta:{...routesByApp[currentAppIndex].routes[0].meta,destination_url: value,destination_name: selectedOption.label  }
                     },
                    "routes"
                    
                  )
                }
                }
                options={[
                  {
                    label: "Custom",
                    value: "custom"
                  },
                  {
                    label: "App Default",
                    value: "default"
                  }
                ]}
              />
            </div>
          </Col>
          <Col span={16}>
            <div className="w-100 mt-3 d-flex flex-column">
              <span className="mb-4 font-size-xsm">
              </span>
              <TextInput
                placeholder={"https://"}
                style={{ width: '100%' }}
                {...getTextInputProps(app.app_id)}
                onChange={(e:any) => handleInputChange(app.app_id, {"destination_url": e.target.value},"routes")}     
              />
            </div>
          </Col>
         </Row>


       </div>

      ))}
    </>

  )
}
