import React, { useEffect, useState } from 'react';
import MobilePushNotification from 'pages/Campaign/CampaignCreate/components/EngagementTool/MobilePushNotification/MobilePushNotification';
import './TemplateView.css';
import { fetchTemplateById } from 'redux/features/templates_/templateByIdSlice';
import SMS from 'pages/Campaign/CampaignCreate/components/EngagementTool/SMS/SMS';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from 'components/Spinner/Spinner';
import { CampaignChannelTypeEnum } from 'utils/enums';
import WebPush from 'pages/Campaign/CampaignCreate/components/EngagementTool/WebPush/WebPush';
import WhatsApp from 'pages/Campaign/CampaignCreate/components/EngagementTool/WhatsApp/WhatsApp';
import NativeDisplay from 'pages/Campaign/CampaignCreate/components/EngagementTool/NativeDisplay/NativeDisplay';
import Email from 'pages/Campaign/CampaignCreate/components/EngagementTool/Email/Email';
import InAppNotification from 'pages/Campaign/CampaignCreate/components/EngagementTool/InAppNotification/InAppNotification';
import WebPopUp from 'pages/Campaign/CampaignCreate/components/EngagementTool/WebPopUp/WebPopUp';
import { t } from 'i18next';

const TemplateView = () => {
  const dispatch: any = useDispatch();
  const { id } = useParams();
  const selectedTemplate = useSelector((state: any) => state.templateById.data);

  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);

  const [loading, setLoading] = useState(false);
  const { template_type } = selectedTemplate || {}; // Safe destructuring
  let content;
  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(
        fetchTemplateById({
          app_id: appId,
          api_key: loginUser.api_key,
          template_id: id,
        })
      ).then(() => {
        setLoading(false);
      });
    }
  }, [id, appId]);

 
  // Use a switch statement to decide which component to render
  switch (template_type) {
    case CampaignChannelTypeEnum.SMS:
      content = <SMS viewMode={true} />;
      break;
    case CampaignChannelTypeEnum.PUSH:
      content = <MobilePushNotification viewMode={true} />;
      break;
      case CampaignChannelTypeEnum.WEB_PUSH:
        content = <WebPush viewMode={true} />;
        break;
        case CampaignChannelTypeEnum.WHATSAPP:
        content = <WhatsApp type={CampaignChannelTypeEnum.WHATSAPP} viewMode={true} />;
        break;
        case CampaignChannelTypeEnum.NATIVE:
        content = <NativeDisplay viewMode={true} />;
        break;
        case CampaignChannelTypeEnum.RCS:
        content = <WhatsApp type={CampaignChannelTypeEnum.RCS} viewMode={true} />;
        break;
        case CampaignChannelTypeEnum.EMAIL:
        content = <Email type={CampaignChannelTypeEnum.EMAIL} viewMode={true} />;
        break;
        case CampaignChannelTypeEnum.IN_APP:
        content = <InAppNotification viewMode={true} />;
        break;
        case CampaignChannelTypeEnum.LANDING_PAGE:
          content = <Email type={CampaignChannelTypeEnum.LANDING_PAGE} viewMode={true} />;
          break;
          case CampaignChannelTypeEnum.WEB_POPUP:
            content = <WebPopUp  viewMode={true} />;
            break;
    default:
      content =
      <div className="d-flex justify-content-center h-100  ">
      <h4 className='mt-4'>{t("pleaseCheckTemplateLabel")}</h4>
      </div> // Optional fallback message
      break;
  }

  return(
    loading? <Spinner/>:
  
  <div className="template-view-disable-container">{content}</div>)
};

export default TemplateView;
