import { useEffect, useState, Fragment } from "react";
import "./PushNotification.css";
import PushNotificationNav from "./Components/PushNotificationNav/PushNotificationNav";
import ToggleBtn from "../../../../components/Buttons/ToggleBtn/ToggleBtn";
import { useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import { t } from "i18next";
import { PushNotificationMode } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import config from "config";
import { EncryptionService } from "src_typescript_appice-core/dist/utils/security/signatureservice";


  const PushNotification: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [mode, setMode] =  useState<string>("")
  const [isAppmodeProduction ,setIsAppmodeProduction] = useState<boolean>()
  const [currentAppKey, setCurrentAppKey] = useState("");
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;

/**
 * handles toggle for mode change and sends true value to getAppMode if the mode is production and updates the states
 */
const handleToggle = async() => {
    try {
      const newMode = mode === PushNotificationMode.DEVELOPMENT ? PushNotificationMode.PRODUCTION: PushNotificationMode.DEVELOPMENT;
      const newAppMode = newMode === PushNotificationMode.PRODUCTION;

       setMode(newMode);
       setIsAppmodeProduction(newAppMode);
      await getAppMode(newAppMode);
    } catch (error) {
      setLoading(false);
    }
  }
 
 /**
  * getAppMode saves the appmode on db that makes out toggle dynamic
  * @param appMode indicates true if we have selected prodcution on toggle and false for development
  */
 async function getAppMode(appMode: boolean){
  const payload = {
    app_id: appId,
    api_key: api_key,
    mode : appMode
  };
  const url: string = API_ENDPOINTS.SET_APP_MODE;
  try {
    setLoading(true);
    await centralApi("GET", url, null, payload);
    await getCurrentAppKeys()
    setLoading(false);
  }
  catch(error){
    setLoading(false);
  }
 }


/**
 * getCurrentAppKeys gets all the data for pushnotification
 */
 async function getCurrentAppKeys() {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      app_id: appId,

      api_key: api_key,
    };
    const url: string = API_ENDPOINTS.GET_CURRENT_APPKEY;

    try {
      setLoading(true);
      const data = await centralApi("GET", url, null, payload);
      const encryptedKeys = config.encryptedKeys;
      const encryptionService = new EncryptionService(loginUser.data.api_key);
      
      data?.result.forEach((doc: any) => {
        Object.keys(encryptedKeys).forEach((key) => {
          // Assert 'key' to be of type 'keyof typeof encryptedKeys'
          if (encryptedKeys[key as keyof typeof encryptedKeys] === 0 && doc.hasOwnProperty(key)) {
            // Encrypt the sensitive value
            doc[key] = encryptionService.decrypt(doc[key]);
          }
        });
      });

      setCurrentAppKey(data.result);
       // Set initial mode based on the fetched data
       if (data.result[0].isappmodeproduction) {
        setMode(PushNotificationMode.PRODUCTION);
        setIsAppmodeProduction(true);
      } else {
        setMode(PushNotificationMode.DEVELOPMENT);
        setIsAppmodeProduction(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const fetchCurrentAppKeys = async () => {
    getCurrentAppKeys();
  };
  useEffect(() => {
    if(appId && api_key){
      getCurrentAppKeys()
    }
  }, []);  
  return (
    <Fragment>
      <div className=" mt-4 push-notification-container ">
<div className=" col-sm-11 push-notification-para " >

      <p>{t("appSettingPushNotificationDesc")}
      </p>
</div>
<div >
      <ToggleBtn
        onChange={handleToggle}
        isApproved={mode === PushNotificationMode.DEVELOPMENT} // Set the button state based on the mode
        trueText={PushNotificationMode.DEVELOPMENT}
        falseText={PushNotificationMode.PRODUCTION}
        style={{ padding: "2px 8px" }}
      />
    </div>
      </div>
      <div>

    <PushNotificationNav currentAppKey={currentAppKey} mode={mode} fetchCurrentAppKeys={fetchCurrentAppKeys}/>
      </div>
    </Fragment>
  );
};
export default PushNotification;
