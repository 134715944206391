import { Space, Upload } from "antd";
import {
  CloseOutlined,
  FileImageFilled,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import CustomTable from "components/CustomTable/CustomTable";
import "./ConfiguratorTable.css";
import "./ConfiguratorTable.css";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { t } from "i18next";
import Selector from "components/Selector/Selector";
import { useEffect, useState } from "react";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { ValidatorOptionsEnum } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import centralApi from "services/centralApi";
import useMessage from "hooks/useMessage";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "redux/features/app/authTokenSlice";

// Defining the interface for the props passed to ConfiguratorTable component
interface ConfiguratorTableProps {
  tableData: any; // Data for populating the table
  setTableData: any; // Function for updating table data
  type: "request" | "response"; // Type of the table: request or response
}


// Main ConfiguratorTable component

const ConfiguratorTable = ({
  tableData,
  setTableData,
  type,
}: ConfiguratorTableProps) => {
  // Defining the structure of a new row based on the table type (request or response)

  const types = {
    [type]: {
      sourceKey: "",
      sourceValue: "",
      sourceDataType: "",
      validator: { type: "", value: "" },
      destinationKey: "",
      destinationValue: "",
      destinationDataType: "",
      isMandatory: false,
    },
  };
  const { showSuccess, showError } = useMessage();
  const activeApp = useSelector((state: any) => state.activeApp);
  const appId = activeApp.appId;
  const dispatch: any = useDispatch();
  const authToken = useSelector((state: any) => state.authToken.token);

  const [fileVisibility, setFileVisibility] = useState<Record<number, boolean>>(
    {}
  );

  const [regexVisibility, setRegexVisibility] = useState<
    Record<number, boolean>
  >({});
  const [customVisibility, setCustomVisibility] = useState<
    Record<number, boolean>
  >({});
  useEffect(() => {
    if (tableData) {
      initializeValidatorValues(tableData); // Initialize visibility for edit mode
    }
  }, [tableData]);

 
  interface DataItem {
    validator?: {
      type: string; // Validator type, could be 'regex', 'custom', or any other type
      value?: string; //  value for the validator
    };
  }
useEffect(() => {
    if (tableData) {
      initializeValidatorValues(tableData); // Initialize visibility for edit mode
    }
  }, [tableData]);

  //set values for validator
  const initializeValidatorValues = (data: DataItem[]) => {
    const regexVis: Record<number, boolean> = {};
    const customVis: Record<number, boolean> = {};
    const fileVis: Record<number, boolean> = {}; // For file visibility

    data.forEach((item, index) => {
      if (item.validator?.type === ValidatorOptionsEnum.REGEX) {
        regexVis[index] = true;
      } else if (item.validator?.type === ValidatorOptionsEnum.CUSTOM) {
        customVis[index] = true;
      } else if (item.validator?.type === ValidatorOptionsEnum.FILE) {
        fileVis[index] = true;
      }
    });

    setRegexVisibility(regexVis);
    setCustomVisibility(customVis);
    setFileVisibility(fileVis); // Set file visibility
  };

  // Handle changes in validator selection
  const handleValidatorChange = (index: number, value: string) => {
    const updatedTableData = [...tableData];
    updatedTableData[index] = {
      ...updatedTableData[index],
      validator: { type: value }, // Set the type (e.g., 'regex', 'custom', or 'file')
    };

    // Update visibility for all types (regex, custom, file)
    setRegexVisibility({
      ...regexVisibility,
      [index]: value === ValidatorOptionsEnum.REGEX,
    });

    setCustomVisibility({
      ...customVisibility,
      [index]: value === ValidatorOptionsEnum.CUSTOM,
    });

    setFileVisibility({
      ...fileVisibility,
      [index]: value === ValidatorOptionsEnum.FILE, // Assuming 'file' is a string
    });

    setTableData(updatedTableData);
  };

  /**
   * Handles the change for both 'custom' and 'regex' validator types.
   * Updates the corresponding row's validator field based on the provided type and value.
   *
   */

  const handleRegexCustomChange = async (
    value: string | File,
    index: number,
    type: ValidatorOptionsEnum
  ) => {
    const updatedTableData = [...tableData];

    // Handle file uploads
    if (type === "file") {
      try {
        const formData = new FormData();
        formData.append("image", value.file);
        formData.append("image_id", value.file.name);
        formData.append("appid", appId);
        const res = await centralApi(
          "POST",
          API_ENDPOINTS.SAVE_CAMPAIGN_IMAGES,
          formData,
          null,
          false,
          authToken
        );
        
        dispatch(getToken())
        // Update the table data with the URL from the API response
        updatedTableData[index] = {
          ...updatedTableData[index],
          validator: { type, value: res.baseURL + res.url }, // Set the image URL in the validator
          sourceValue: value.file.name,
        };

        showSuccess(t("sucessfullyUploadedLabel"));
      } catch (error) {
        showError((error as Error).message);
      }
      // Create an object to store the file metadata
    } else {
      // For custom or regex, handle as before
      updatedTableData[index] = {
        ...updatedTableData[index],
        validator: { type, value },
      };
    }

    setTableData(updatedTableData);
  };

  // Function to handle adding a new row to the table
  const handleAddRow = () => {
    // Spread the existing table data and add a new empty row
    setTableData([...tableData, types[type]]);
  };

  // Function to handle deleting a row from the table
  const handleDeleteRow = (index: number) => {
    const newData = [...tableData]; // Copy the current table data
    newData.splice(index, 1); // Remove the row at the specified index
    setTableData(newData); // Update the table data state
  };

  // Function to handle changes in input fields within a specific row
  const handleActionChange = (key: string, index: number, value: string) => {
    const data = [...tableData]; // Copy the current table data
    data[index] = { ...data[index], [key]: value }; // Update the changed field at the specified index
    setTableData(data); // Update the table data state
  };

  // Function to generate a text input column for the table

  const textInputColumn = (
    title: string, // Column title
    dataIndex: string, // Data index (field name in the row)
    width?: number // Optional width for the column
  ) => ({
    title: t(title), // Translate the column title using i18next
    dataIndex, // Set the data index for the column
    key: dataIndex, // Unique key for React rendering
    width: width,
    render: (_: any, record: any, index: number) => (
      <TextInput
        value={record?.[dataIndex]} // Set the current value of the field in the row
        onChange={(e: any) =>
          handleActionChange(dataIndex, index, e.target.value)
        } // Handle changes in the input field
        style={{ width: width || "" }} // Set width from the parameter or default to auto
        className={`configurator-table-text-size `}
      />
    ),
  });

  const validatorOptions = [
    { label: t("regexLabel"), value: ValidatorOptionsEnum.REGEX },
    { label: t("customLabel"), value: ValidatorOptionsEnum.CUSTOM },
    { label: t("fileLabel"), value: ValidatorOptionsEnum.FILE },

    // Add more options as needed
  ];

  // Function to generate a dropdown input column with conditional regex and custom textareas
  const dropdownInputColumn = (
    title: string,
    dataIndex: string,
    options: any
  ) => ({
    title: t(title),
    dataIndex,
    key: dataIndex,
    align: "center", // Center align this column
    render: (_: any, record: any, index: number) => (
      <div className="d-flex gap-2 align-items-center">
        <Selector
          value={record?.[dataIndex]?.type || ''} // Display current selection
          
          onChange={(value: string) => handleValidatorChange(index, value)}
          options={options}
          className="configurator-table-dropdown-width `configurator-table-text-size"
          style={{ width: 100 }}
        />
       
        {/* Show the Regex input if 'Regex' is selected */}
        {regexVisibility[index] && (
          <TextInput
            placeholder={t("enterRegexPatternLabel")}
            value={record?.[dataIndex]?.value || ""}
            onChange={(e: any) =>
              handleRegexCustomChange(e.target.value, index, ValidatorOptionsEnum.REGEX)
            }
            className="configurator-table-regex-input-width configurator-table-text-size configurator-table-validator-key-input-width"
            required
          />
        )}
        {/* Show the Textarea if 'Custom' is selected */}
        {customVisibility[index] && (
          <CustomTextArea
            value={record?.[dataIndex]?.value || ""}
            onChange={(e) =>
              handleRegexCustomChange(e.target.value, index, ValidatorOptionsEnum.CUSTOM)
            }
            className="configurator-table-custom-textarea-width configurator-table-text-size configurator-table-validator-key-input-width"
            rows={2}
            autoSize={false} // Disable resizing
          />
        )}
        {fileVisibility[index] && (
          <Upload

            showUploadList={false}
            multiple={false}
            beforeUpload={() => false}
            onChange={(value: any) =>
              handleRegexCustomChange(value, index, ValidatorOptionsEnum.FILE)
            }
          >
            <PrimaryButton className=" configurator-table-validator-key-input-width ">
              {record.sourceValue ? <FileImageFilled /> : <UploadOutlined />}

              <span className="mx-1 configurator-table-validator-upload-btn-name">
                {/* Display the file name if it exists, otherwise show the upload label */}
                {record.sourceValue ? record.sourceValue : t("uploadLabel")}
              </span>
            </PrimaryButton>
          </Upload>
        )}
      </div>
    ),
  });
  // Handle checkbox state change
  const handleCheckboxChange = (index: number, isMandatory: boolean) => {
    const updatedTableData = [...tableData]; // Create a shallow copy of the table data

    updatedTableData[index] = {
      ...updatedTableData[index], // Copy existing row data
      isMandatory, // Update the `isMandatory` field
    };

    setTableData(updatedTableData); // Update the state with the modified data
  };

  // Define a new column for the checkbox
  const checkboxColumn = {
    title: t("mandatoryLabel"), // Add a label for the checkbox column
    dataIndex: "isMandatory", // Data field to track the checkbox state
    key: "isMandatory",
    render: (_: any, record: any, index: number) => (
      <CustomCheckbox
        checked={record.isMandatory} // Checkbox is controlled by `isMandatory` value in the row
        onChange={(e: any) => handleCheckboxChange(index, e.target.checked)} // Handle change
      />
    ),
  };

  // Define the table columns using the textInputColumn function for input fields and a custom action column
  const columns = [
    textInputColumn("sourceKeyLabel", "sourceKey", 150), // Column for source key
    textInputColumn("sourceValueLabel", "sourceValue", 120), // Column for source value
    textInputColumn("sourceTypeLabel", "sourceDataType"), // Column for source data type
    dropdownInputColumn("validatorLabel", "validator", validatorOptions),

    textInputColumn("destinationKeyLabel", "destinationKey"), // Column for destination key
    textInputColumn("destinationValueLabel", "destinationValue"), // Column for destination value
    textInputColumn("destinationTypeLabel", "destinationDataType"), // Column for destination data type
    checkboxColumn,
    {
      title: t("actionLabel"),
      key: "action", // Unique key for React rendering
      render: (_: any, record: any, index: number) => (
        <Space>
          <PrimaryButton
            className="action-btn mx-1"
            onClick={() => handleDeleteRow(index)}
          >
            <CloseOutlined />
          </PrimaryButton>
          <PrimaryButton
            className="action-btn mx-1"
            onClick={() => handleAddRow()}
          >
            <PlusOutlined />
          </PrimaryButton>
        </Space>
      ),
    },
  ];
  return (
    <div className="configurator-table">
      {/* Show the 'Add Row' button when the table is empty */}

      {tableData.length === 0 && (
        <Space className="mb-2">
          <PrimaryButton type="primary" onClick={handleAddRow}>
            {t("addRowLabel")}
          </PrimaryButton>
        </Space>
      )}
      {/* Render the custom table with the data and columns */}

      <CustomTable
        dataSource={tableData}
        columns={columns}
        pagination={false}
        scroll={{ x: 1300 }}
        rowKey={(record: any, index: number) => `${index}`} // Ensure rowKey is unique
      />
    </div>
  );
};

export default ConfiguratorTable;
