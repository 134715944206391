import { FC } from "react";
import "./UsersInteractions.css";
import CustomTimeline from "components/CustomTimeline/CustomTimeline";
import { Card } from "antd";
import { t } from "i18next";

interface InteractionItem {
    title: string;
    eventData: []; // Array of timeline items
  }
  
  interface UserInteractionsProps {
    items: InteractionItem[];
  }
  

const UserInteractions :FC<UserInteractionsProps>  = ({ items }) => {
  return (
    <div className="c-three-sixty-main-container">
      <div className="pt-3 mt-2  border-bottom">
        <h6 className="m-2">{t("usersInterctionsLabel")}</h6>
      </div>

      {/* -----header container starts from here----- */}
      <div className="d-flex justify-content-between my-3">

        {/* -----recent activity container starts here----- */}
        {items.map((item: any, index :any) => (
          <div key={index} className="c-three-sixty-recent-activity c-three-sixty-recent-activity-width p-4">
            <div className="m-2 mb-4 pt-1">
              <h6 className="m-0">{item.title}</h6>
            </div>
            <Card>
              <div className="d-flex c-three-sixty-timeline-wrapper pt-2">
                <CustomTimeline mode="left" layout="event" items={item.eventData} />
              </div>
            </Card>
          </div>
        ))}
        {/* -----recent activity container ends here----- */}
      </div>
    </div>
  );
};

export default UserInteractions;

