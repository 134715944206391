import { Checkbox } from "antd";
import { memo, useEffect, useState } from "react";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import "./SelectAudience.css";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../../../../components/Inputs/Search/Search";
import CustomDrawer from "../../../../../components/CustomDrawer/CustomDrawer";
import { STATUS } from "../../../../../redux/constant";
import { fetchAudienceSegments } from "../../../../../redux/features/app/audienceSegmentsSlice";
import { setCampaignData } from "../../../../../redux/features/campaign/campaignDataSlice";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import AudienceCreate from "../../../../Audience/AudienceCreate/AudienceCreate";
import RadioButton from "components/Buttons/RadioButton/RadioButton";
function SelectAudience() {
  const [open, setOpen] = useState(false);
  const audienceSegments = useSelector(
    (state: any) => state.audienceSegments.data
  );
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<any>([]);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const dispatch: any = useDispatch();
  const handleSearch = (query: string) => {
    const filteredResults = audienceSegments?.filter((item: any) => {
      return item?.name
        ?.toString()
        .toLowerCase()
        .includes(query.toString().toLowerCase());
    });
    setSearchResults(filteredResults);
  };
  useEffect(() => {
    if (audienceSegments && campaignData) {
      const selected = audienceSegments?.find(
        (item: any) => item._id == campaignData.aud
      );
      setSelectedItem(selected);
    }
    handleSearch("");
  }, [audienceSegments]);
  const handleSelectItem = (item: any) => {
    setSelectedItem(item);
    const { segmentinfo, name, _id } = item;
    dispatch(
      setCampaignData({
        ...campaignData,
        segmentinfo,
        segmentname: name,
        aud: _id,
      })
    );
  };
  const afterAudienceSave = (saved: boolean) => {
    setOpen(false);
    if (saved) {
      dispatch(
        fetchAudienceSegments({
          api_key: loginUser.api_key,
          app_id: appId,
          method: "getAudienceSegments",
        })
      );
    }
  };
  const Row = ({ data, index, style }: any) => (
    <div
      key={data[index]._id}
      onClick={() => handleSelectItem(data[index])}
      style={{ cursor: "pointer",borderBottom:"0.5px solid var(--color-other-line)", ...style }}
      className={` ps-3 pe-4 d-flex align-items-center justify-content-between ${
        selectedItem?._id === data[index]._id ? "selected-item " : ""
      }`}
    >
      <span>{data[index].name}</span>
      <RadioButton
      checked={selectedItem?._id === data[index]._id}
         value={selectedItem?._id === data[index]._id ? true : false}
      />
    </div>
  );

  return (
    <div className="px-1 campaign-audience-select ">
      <div className="d-flex py-2 justify-content-between  align-items-center">
        <CustomDrawer
          open={open}
          onClose={() => setOpen(false)}
          width={"90%"}
          height={"100%"}
        >
          <div className="mx-md-3">
            <AudienceCreate afterSaveClick={afterAudienceSave} />
          </div>
        </CustomDrawer>
        <Search
          className="w-25"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <PrimaryButton onClick={() => setOpen(true)} type="primary">
          Create New Audience Segments
        </PrimaryButton>
      </div>
      <AutoSizer  style={{ height: "63vh"}}>
        {({ height, width }: { height: number; width: number }) => (
          <List
            height={height}
            style={{ border:"0.3px solid var(--color-other-line)"}}
            itemCount={searchResults?.length}
            itemSize={40}
            width={width}
            itemData={searchResults}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </div>
  );
}

export default memo(SelectAudience);
