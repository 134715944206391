import { Form } from "antd";
import { useSelector } from "react-redux";
import CustomModal from "../../../components/Models/CustomModel/CustomModel";
import centralApi from "../../../services/centralApi";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { t } from "i18next";
import { getDatesRange } from "../../../helperFunctions/common";
import { downloadCsv } from "../../../helperFunctions/common";
import useMessage from "../../../hooks/useMessage";
import { DateSelector } from "../../../components/DateTimeSelectors/DateSelector/DateSelector";
import React from "react";
type propTypes = {
  openModal?: any;
  setModalOpen?: any;
  formType?: any; // Add this prop
};
const Modals: React.FC<propTypes> = ({ openModal, setModalOpen, formType }) => {
  type DateRange = {
    startDate: string;
    endDate: string;
  };
  type DateArray = string[];

  interface RootState {
    loginUser: {
      data: {
        api_key: string;
      };
    };
  }
  interface ActiveAppState {
    activeApp: {
      appName: string;
      appId: string;
    };
  }
  const { showError, showSuccess , showWarning} = useMessage();

  const loginUser = useSelector((state: RootState) => state.loginUser);
  const { appId } = useSelector((state: ActiveAppState) => state.activeApp);
  const api_key = loginUser.data.api_key;
  const { endDate_C, endDate_CNew, startDate_C, startDate_CNew } = useSelector(
    (state: any) => state.dateRange
  );
  const [form] = Form.useForm(); // Create a form instance

  const dateRange: DateRange = {
    startDate: startDate_CNew,
    endDate: endDate_CNew,
  };
  const filename = formType == "newUser" ? "installs.csv" : "uninstall.csv";

  // const filename = "installs.csv";
  const type = formType === "newUser" ? "I" : "U";

  const allDatesRange: DateArray = getDatesRange(dateRange);
  const handleConfirm = async (values: any) => {
    const payload = {
      args: JSON.stringify({
        filter: {
          ...(values.all
            ? { all: true }
            : {
                dateRange: {
                  sd: startDate_C,
                  ed: endDate_C,
                  dates: allDatesRange,
                },
              }),
        },
      }),
      app_id: appId,
      api_key: api_key,
      type,
      filename: filename,
    };
    const url: string = `/o/metrices/downloadDataCustom`;

    try {
      const response = await centralApi("GET", url, null, payload, true);
      if (response?.size <= 3) {
        showSuccess(t("downloadInitiatedLabel"));  // if no data available in csv
      } else {
        downloadCsv(response, filename);  //download csv if data available
        showSuccess(t("downloadedSuccessfullyLabel"));
      }
      form.resetFields(); // Reset the form fields after successful download
    } catch (error: any) {
      showError(error.message);
    }

    setModalOpen(false);
  };
  const handleCancel = () => {
    form.resetFields(); // Reset the form fields after successful download

    setModalOpen(false);
  };

  const handleCheckboxChange = (e: any, otherCheckbox: any) => {
    const { checked } = e.target;

    // Update the other checkbox value
    form.setFieldsValue({ [otherCheckbox]: !checked });
  };

  const getRulesForCheckbox = (otherCheckbox: any) => {
    return [
      ({ getFieldValue }: { getFieldValue: (name: string) => any }) => ({
        validator(_: any, value: any) {
          const otherCheckboxValue = getFieldValue(otherCheckbox);
          if (!value && !otherCheckboxValue) {
            return Promise.reject(`Please select at least one checkbox`);
          }
          return Promise.resolve();
        },
      }),
    ];
  };
  return (
    <div>
      <CustomModal
        title="Download Data"
        open={openModal}
        setVisibility={setModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <div>
          <Form
            form={form}
            layout="horizontal"
            initialValues={{
              dateRange: null,
              all: false,
              selectedRange: false,
            }}
            onFinish={handleConfirm}
          >
            <Form.Item
              name="all"
              valuePropName="checked"
              rules={getRulesForCheckbox("selectedRange")}
              style={{ marginBottom: "2px" }}
            >
              <CustomCheckbox
                label="Download All"
                onChange={(e: any) => {
                  handleCheckboxChange(e, "selectedRange");
                }}
              />
            </Form.Item>

            <Form.Item
              name="selectedRange"
              valuePropName="checked"
              rules={getRulesForCheckbox("all")}
              style={{ marginBottom: "2px" }}
            >
              <CustomCheckbox
                label="Download users who have installed from"
                onChange={(e: any) => {
                  handleCheckboxChange(e, "all");
                }}
              />
            </Form.Item>

            <Form.Item name="dateRange">
              <DateSelector
              />
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </div>
  );
};

export default Modals;
