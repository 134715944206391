import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React from "react";
import "./CircularProgress.css";
import mobileIcon from "../../../images/mobile_icon.png";
import "react-circular-progressbar/dist/styles.css";
type propTypes = {
  image?: string;
  name?: string;
  persentage: number;
  size?: number;
  strokeWidth?: number;
  pathColor?:string;
  trailColor?:string;
};

function CircularProgress({
  image,
  name,
  persentage,
  size,
  strokeWidth,
  pathColor,
  trailColor
}: propTypes) {
  return (
    <div
      className="progress-inner-container p-1 d-flex flex-column align-items-center  justify-content-center" 
      style={{
        height: "auto",
        width: `${size}px`,
        maxWidth: "325px",
      }}
    >
      <strong className="my-1">{name}</strong>
      <div className="position-relative w-100 " >
        <div className="progress-image-div ">
          <img src={image} alt="icon" />
        </div>

        <CircularProgressbar
          value={persentage}
          maxValue={100}
          strokeWidth={strokeWidth}
          styles={buildStyles({
            pathColor: pathColor,
            trailColor:trailColor
          })}
        />
      </div>
      <p>{`${persentage}%`}</p>
    </div>
  );
}

export default CircularProgress;
CircularProgress.defaultProps = {
  name:"choose name",
  image: mobileIcon,
  size: 80,
  percentage: 90,
  strokeWidth:6
  

};
