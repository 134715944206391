import React, { useEffect, useState } from "react";
import "./NativeDisplay.css";
import Personalization from "../../Personalization/Personalization";
import { Col, Form, Row } from "antd";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { t } from "i18next";
import { v4 as uuid } from "uuid";
import { CustomData } from "../../CustomData/CustomData";
import { useForm } from "antd/es/form/Form";
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import CampaignDevice from "components/CampaignDevice/CampaignDevice";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import NativeDisplayContainer from "../../NotificationContainer/NativeDisplayContainer/NativeDisplayContainer";
import FormItem from "antd/es/form/FormItem";
import Selector from "components/Selector/Selector";
import { useDispatch, useSelector } from "react-redux";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import VariantList from "../../VariantList/VariantList";
import centralApi from "services/centralApi";
import { CampaignChannelTypeEnum } from "utils/enums";
import TextInput from "components/Inputs/TextInput/TextInput";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import CustomCollapse from "components/CustomCollapse/CustomCollapse";
import { CustomDataEnum } from "utils/enums";
import { validateName } from "services/validateName";
import useMessage from "hooks/useMessage";
import { formatDate, getCurrentDate } from "helperFunctions/date";

import { setCurrentStep } from "redux/features/campaign/campaignCreateCurrentStepSlice";
import { setCampaignData } from "redux/features/campaign/campaignDataSlice";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";
const NativeDisplay = ({viewMode}) => {
  const [form] = useForm();
  //by default hide personalization
  const [personalization, setPersonalization] = useState({
    open: false,
    field: "",
  });
  const { DEVICE, IMAGE } = CustomDataEnum;
  const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change
  const { showError } = useMessage();

  const loginUser = useSelector((state: any) => state.loginUser.data);
  const templates = useSelector((state: any) => state.templatesList.data);
  const authToken = useSelector((state: any) => state.authToken.token);
  const { appId } = useSelector((state: any) => state.activeApp);
  const languageVariants = useSelector(
    (state: any) => state.templateMetaData.data
  );
  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features; //getting features setting from redux
  const campaignData = useSelector((state: any) => state.campaignData.data); //checking campaign data in redux
   //saving current set in redux
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  ); 
  //initial  values
  const [formData, setFormData] = useState<any>({
    notificationType: "dl",
    externalUrl: "",
    cdata: [],
  });

  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const [deviceType, setDeviceType] = useState<"IOS" | "Android">("Android");
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const [variants, setVariants] = useState<any>([]);
  //initial state for key value pairs
  const [customDataState, setCustomDataState] = useState<any>({
    [uuid()]: {
      key: "",
      value: "",
      type: "string",
    },
  });
  //initial value for template
  const [template, setTemplate] = useState({
    template_name: "",
    template_type: CampaignChannelTypeEnum.NATIVE,
    isTemplateDeleted: "false",
  });
  const dispatch: any = useDispatch();
  useEffect(() => {
    saveCdata();
  }, [customDataState]);
  //if template selected or edit case fetch details to showin fields
  useEffect(() => {
    const { template, template_name, template_type } =
      selectedTemplate || campaignData.data || formData;

    if (template || selectedVariant) {
      //extracting values 
      const { externalUrl, notificationType, cdata } =
        Array.isArray(template) || selectedVariant
          ? selectedVariant
            ? selectedVariant
            : template[0]
          : template
          ? template
          : formData;
      setFormData((prevState: any) => ({
        ...prevState,
        externalUrl,
        cdata,
        notificationType,
      }));
      if (template_name) {
        setTemplate((prevState) => ({
          ...prevState,
          template_name,
          template_type,
        }));
      }
//if it contains cdata 
      if (cdata) {
        const custom_data: any = {};
        if (Array.isArray(cdata)) {
          // Handle array of objects
          cdata.forEach((obj: any) => {
            const [key, value] = Object.entries(obj)[0]; // Destructuring to get the first key-value pair
            custom_data[uuid()] = {
              key: key, // Key obtained from destructuring
              value: value, // Value obtained from destructuring
              type: obj?.type || "", // Assuming obj has a property called 'type'
            };
          });
        } 
        //if without type property
        else if (typeof cdata === "object" && cdata !== null) {
          // Handle plain object
          Object.keys(cdata).forEach((key: string) => {
            custom_data[uuid()] = { key, value: cdata[key] };
          });
        }
        setCustomDataState(custom_data);
      }
    }
//saving old templatename for validation so that user cannot give same name when creating new template
    setOldTemplateName(template_name); // save template name
  }, [selectedTemplate, selectedVariant, campaignData]);

  const handleSaveOrUpdate = async () => {
    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed
      if (template.template_name !== oldTemplateName) {
        try {
          //calling validate name APi to cehck if it exists
          isValidTemplateName = await validateName(
            template.template_name,
            CampaignChannelTypeEnum.NATIVE,
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
          showError(t("somethingWrongLabel"));
        }
      }

      // Validate the form fields for template name if it exists
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")],
          },
        ]);
      } else {
        //check other fields validation
        await form.validateFields();
        const payload = {
          api_key: loginUser.api_key,
          app_id: appId,
          authToken,
          template_id: selectedTemplate ? selectedTemplate._id : undefined, //template id if template is selected otherwise undefined

          args: JSON.stringify({
            ...template,
            template: variants.length > 0 ? variants : formData,
            createdOn: selectedTemplate
              ? undefined
              : formatDate(getCurrentDate(), "MM/DD/YYYY"),// if new template then add created on
            modifiedOn: selectedTemplate  //if existing template set modified on
              ? formatDate(getCurrentDate(), "MM/DD/YYYY")
              : undefined,
          }),
        };
        // Determine the endpoint and method based on the presence of a selected template
        const endpoint = selectedTemplate
          ? API_ENDPOINTS.UPDATE_CAMPAIGN_TEMPLATE
          : API_ENDPOINTS.CREATE_CAMPAIGN_TEMPLATE;
        //call the Api with the selected endpoint
        const response = await centralApi("POST", endpoint, payload);
        dispatch(getToken())
      // response from API setting its value in redux 
        dispatch(
          setCampaignData({
            ...campaignData,
            data: { ...response },
            tid: response._id,
            t: response.template_type,
          })
        );
        //moving to next step
        dispatch(setCurrentStep(currentStep + 1));
      }
    } catch (errors) {
      dispatch(getToken())
      showError(t("validationErrorLabel"));
    }
  };
  const handleVariantClick = (id: string) => {
    variants.forEach((variant: any) => {
      if (variant.variance_id == id) {
        setSelectedVariant(variant);
      }
    });
  };
  useEffect(() => {
    //fetch templates list of type native
    dispatch(
      fetchTemplatesList({
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: CampaignChannelTypeEnum.NATIVE,
      })
    );
//token APi call
    dispatch(getToken())
    if (campaignData.data) {
      //if data in redux set the template data
      dispatch(setSelectedTemplate(campaignData.data));
    }
  }, []);
  const handleCreateVariant = async (
    type: "variant" | "language" = "variant",
    language: any = {}
  ) => {
    const payload = {
      app_id: appId,
      api_key: loginUser.api_key,
    };
    const id =
      type == "variant"
        ? await centralApi("GET", "o/metrices/getObjectId", null, payload)
        : language?._id;
    const variance_top_name =
      type == "variant"
        ? `Variant ${
            variants.filter((variant: any) =>
              variant?.variance_top_name.includes("Variant")
            ).length + 1
          }`
        : language?.name;
    const payloadData: any = {
      variance_top_name,
      variance_id: id,
      variant_type: "Multivariate",
      ...formData,
      variance_name: template.template_name,
    };
    setVariants((prevState: any) => [...prevState, payloadData]);
  };
  //if delete variants
  const handleDeleteVariant = (id: string) => {
    const afterVariantDelete = variants.filter(
      (variant: any) => variant.variance_id !== id
    );
    setVariants(afterVariantDelete);
  };

  useEffect(() => {
    if (selectedTemplate) {
      !viewMode &&   dispatch(
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: selectedTemplate._id,
          t: selectedTemplate.template_type,
        })
      );
      if (Array.isArray(selectedTemplate?.template)) {
        setVariants(selectedTemplate.template);
        setSelectedVariant(selectedTemplate.template[0]);
      } else {
        setVariants([]);
        setSelectedVariant(null);
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    // Set initial values when template changes
    if (selectedTemplate) {
      form.setFieldsValue({
        templateName: template.template_name,
      });
    }
  }, [template, form]);   
  //set custom data in form state
  const saveCdata = () => {
    const cdata: any = [];
    //extract cdata values from customstate
    Object.values(customDataState).forEach((value: any) => {
      if (value.value && value.key) {
        const dataObject = {
          [value.key]: value.value,
          type: value.type === DEVICE ? IMAGE : value?.type,
        };
        cdata.push(dataObject);
      }
    });
    setFormData((prevState: any) => ({ ...prevState, cdata }));
  };
  return (
    
    <div className="native-display-parent-container container-fluid  ">
      <Personalization
        personalizationState={personalization}
        setFormData={setFormData}
        setPersonalizationState={setPersonalization}
      />

      <Form
        colon={false}
        labelAlign="left"
        labelCol={{ flex: "180px" }}
        layout="horizontal"
        form={form}
      >
        <TemplateLayout
          leftItems={
            <CampaignDevice
              setDevice={(value: "IOS" | "Android") => {
                setDeviceType(value);
              }}
            >
              <div className="h-100">
                <NativeDisplayContainer
                  type={deviceType}
                  message={t("nativePhoneLabel")}
                />
              </div>
            </CampaignDevice>
          }
          rightItems={
            <div>
              <Row className="pt-2">
                <Col span={24}>
                  <h5 className="mt-0">{t("nativeDisplayLabel")}</h5>
                </Col>
                <Col className="d-flex gap-1 justify-content-between" span={24}>
                  <FormItem
                    className="my-0 w-100"
                    name={"template_name"}
                    label=""
                  >
                    <Selector
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                  <div className="d-flex gap-1  align-items-center">
                    {!featuresSetting?.ab_testing_variant && (
                      <Selector
                        style={{ width: 110 }}
                        onChange={handleVariantClick}
                        disabled={
                          !template.template_name || variants.length >= 6
                        }
                        value={
                          selectedVariant?.variance_top_name?.includes(
                            "Variant"
                          ) && selectedVariant?.variance_id
                        }
                        placeholder={t("variantLabel")}
                        dropdownRender={(menu) => (
                          <>
                            <PrimaryButton
                              type="link"
                              className="w-100"
                              onClick={() => handleCreateVariant()}
                            >
                              <PlusOutlined />
                              <span className="ms-2 me-3">Create</span>
                            </PrimaryButton>
                            {menu}
                          </>
                        )}
                        options={variants
                          ?.filter((variant: any) =>
                            variant?.variance_top_name?.includes("Variant")
                          )
                          .map((variant: any) => ({
                            label: variant.variance_top_name,
                            value: variant?.variance_id,
                          }))}
                      />
                    )}
                    {!featuresSetting?.language_variant && (
                      <Selector
                        style={{ width: 110 }}
                        disabled={
                          !template.template_name || variants.length >= 6
                        }
                        onChange={(jsonData) => {
                          handleCreateVariant("language", JSON.parse(jsonData));
                        }}
                        options={languageVariants?.varaints?.map(
                          (langVariant: any) => ({
                            label: langVariant.name,
                            value: JSON.stringify(langVariant),
                          })
                        )}
                        placeholder={t("languageLabel")}
                      />
                    )}
                  </div>
                </Col>
                <Col
                  span={24}
                  className="d-flex aligm-items-center justify-content-between pt-2"
                >
                  <VariantList
                    variants={variants}
                    selectedVariant={selectedVariant}
                    handleDeleteVariant={handleDeleteVariant}
                    handleVariantClick={handleVariantClick}
                  />
                </Col>
              </Row>
              <Row className="pt-2 pb-2 position-relative d-flex justify-content-center">
                <Col className="d-flex gap-1 mb-4 " span={24}>
                  <FormItem
                    initialValue={template.template_name}
                    className="w-100 m-0 mt-2  "
                    label={t("templateNameLabel")}
                    name={"templateName"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}
                    ></TextInput>
                  </FormItem>
                </Col>

                <CustomCollapse
                  props={{ className: "w-100" }}
                  header={t("settingsLabel")}
                  defaultActiveKey="1" // Keep the panel open initially
                  body={
                    <>
                      <>
                        <div className="d-flex  w-100 justify-content-end">
                          <MoreOutlined
                            onClick={() => {
                              setPersonalization({
                                open: true,
                                field: "externalUrl",
                              });
                            }}
                          />
                        </div>
                        <Col className="d-flex mt-2" span={24}>
                          <FormItem
                            className="w-100 m-0 mt-2"
                            label={t("urlLabel")}
                          >
                            <TextInput
                              onChange={(e: any) =>
                                setFormData((prevState: any) => ({
                                  ...prevState,
                                  externalUrl: e.target.value,
                                }))
                              }
                              value={formData.externalUrl}
                              placeholder={t("externalURL/deepLinkURLLabel")}
                            />
                          </FormItem>
                        </Col>
                      </>
                      <Col className="mt-2" span={24}>
                        <div className="d-flex flex-wrap justify-content-between w-100">
                          <FormItem
                            className="m-0 w-100 mt-2"
                            label={t("URLTypeLabel")}
                          >
                            <SegmentedCustom
                              value={formData.notificationType || "dl"}
                              onChange={(value: "lp" | "dl") => {
                                setFormData((prevState: any) => ({
                                  ...prevState,
                                  notificationType: value,
                                }));
                              }}
                              options={[
                                {
                                  label: t("landingPageLabel"),
                                  value: "lp",
                                },
                                { label: t("deepLinkLabel"), value: "dl" },
                              ]}
                            />
                          </FormItem>
                          {formData?.notificationType == "lp" &&
                            !featuresSetting?.landingPageView && (
                              <FormItem
                                className=" my-0 mt-2"
                                label={t("openInLabel")}
                              >
                                <SegmentedCustom
                                  options={[
                                    {
                                      label: t("browserLabel"),
                                      value: "browser",
                                    },
                                    {
                                      label: t("webViewLabel"),
                                      value: "webview",
                                    },
                                  ]}
                                />
                              </FormItem>
                            )}
                        </div>
                      </Col>
                    </>
                  }
                ></CustomCollapse>
              </Row>
              <Row>
                <div className="key-paircontainer">
                  <div className="border-bottom mb-3 d-flex ">
                    <h6 className="mb-3 fw-bold text-secondary">
                      {t("customKeyValueLabel")}
                    </h6>
                  </div>

                  <CustomData
                    customDataState={customDataState}
                    onChange={(data:any)=>setCustomDataState(data)}
                    hideTreeSelector={true}
                  />
                </div>
              </Row>
            </div>
          }
          footerItem={
            <Row className="gap-2  d-flex justify-content-end">
             {!viewMode && <>
              <PrimaryButton
                disabled={!template.template_name}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  saveCdata();
                  handleSaveOrUpdate();
                }}
              >
                
                {selectedTemplate ? t("updateLabel") : t("saveLabel")}
              </PrimaryButton>
              <PrimaryButton
                 onClick={() => {
                  dispatch(setCampaignData({}));
                  dispatch(setSelectedTemplate(null));
                  dispatch(setCurrentStep(0));
                }}
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
              >
                {t("closeLabel")}
              </PrimaryButton>
              </>}
            </Row>
          }
        />
      </Form>
    </div>
  );
};

export default NativeDisplay;
