import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../../constant";
import centralApi from "../../../services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
export const fetchCustomVars = createAsyncThunk(
  "customMetaVars/fetchCustomVars",
  async ({api_key, app_id}:any) => {
    const params = {
      api_key,
      app_id,
      method: "get_metacustomvariables",
    };

    return centralApi(
      "GET",
      API_ENDPOINTS.GET_CUSTOM_VARIABLES,
      null,
      params
    ).then(({ result }) => {
      const modifiedResult: { label: string; value: string; type: string; }[] = [];

      // Use a Set to keep track of unique `value` properties
      const uniqueValues = new Set<string>();
      
      result?.forEach((item: { name: string; type: string; displayName: string }) => {
        // Check if `item` has `, `type`, and `name`
        if (item.type && item.name) {
          // Only add the item if its `value` (name) hasn't been added yet
          if (!uniqueValues.has(item.name)) {
            uniqueValues.add(item.name);  // Mark `name` as seen
      
            // Push the item into `modifiedResult`
            modifiedResult.push({
              label: item.displayName || item.name,
              value: item.name,
              type: item.type
            });
          }
        }
      });
      
      // Return the modified result with unique values
      return modifiedResult;
      
    });
  }
);
const initialState:any= {
  data: [],
  status: STATUS.IDLE,
}
const customMetaVarsSlice = createSlice({
  name: "customMetaVars",
 initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCustomVars.pending, (state) => {
      state.status = STATUS.LOADING;
    });
    builder.addCase(fetchCustomVars.fulfilled, (state,action) => {
      state.status = STATUS.IDLE;
      state.data=action.payload
    });
    builder.addCase(fetchCustomVars.rejected, (state) => {
      state.status = STATUS.ERROR;
    });
  },
  reducers: {},
});
export default customMetaVarsSlice.reducer;

