import { Divider } from "antd";
import "./SiteSettings.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { Form, Upload } from "antd";
import Selector from "components/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "services/centralApi";
import Spinner from "components/Spinner/Spinner";
import { getFeaturesSettings } from "redux/features/app/featuresSettingSlice"; //calling features setting from redux
import useMessage from "hooks/useMessage";
import { t } from "i18next";
import { API_ENDPOINTS } from "utils/constants";
import { EDStatusEnum,DataTypeEnum, SiteSettingsEnum } from "utils/enums";
import { getToken } from "redux/features/app/authTokenSlice";
const SiteSettings: React.FC = () => {
  const dispatch: any = useDispatch();
  const [form] = Form.useForm();
  const { showError, showSuccess } = useMessage();
  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.siteSettings;
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  
  const [formData, setFormData] = useState<any>();
  useEffect(() => {
    setFormData(featuresSetting);
 
  }, [featuresSetting]);

  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = () => {
 
    const updatedFormData = {
      is_sso: formData.is_sso,
      portal_name: formData.portal_name || undefined,
      sso_type: formData.sso_type,
      samlConfig: formData.samlConfig,
      ldapConfig: formData.ldapConfig,
      logo_image_url: formData?.logo_image_url || undefined,
      logo_image_name: formData?.logo_image_name || undefined,
      captcha: formData?.captcha,

    };
    if (formData.sso_type === SiteSettingsEnum.SAML_BASED) {
      updatedFormData.ldapConfig = {};
    } else if (formData.sso_type === SiteSettingsEnum.ACTIVE_DIRECTORY) {
      updatedFormData.samlConfig = {};
    } else if (formData.sso_type === SiteSettingsEnum.DISABLED) {
      updatedFormData.ldapConfig = {};
      updatedFormData.samlConfig = {};
    }

    // Now submit the updatedFormData

    const payload = {
      authToken,
      app_id: appId,
      api_key: api_key,
      args: JSON.stringify({
        app_id: appId,
        ...updatedFormData,
      }),
    };
    saveSiteSetting(payload);
    dispatch(getToken())
  };

  const handleImageUpload = async (value: any) => {
    try {
      const formData = new FormData();
      formData.append("image", value.file);
      formData.append("image_id", value.file.name);
      formData.append("appid", appId);
      formData.append("customLogo", "true");

      const res = await centralApi(
        "POST",
        API_ENDPOINTS.SAVE_CAMPAIGN_IMAGES,
        formData,
        null,
        false,
        authToken
      );     
      dispatch(getToken())  
      setFormData((prevData: any) => ({
        ...prevData,
        logo_image_url: res.baseURL + res.url,
        logo_image_name: value.file.name,
      }));
      showSuccess(t("sucessfullySavedLabel"));

      dispatch(getToken())
    } catch (error) {
      showError(t("invalidLabel"));
    }
  };
  //calling fetures setting
  useEffect(() => {
    if (activeApp.appId && loginUser.api_key) {
      dispatch(
        getFeaturesSettings({
          api_key: loginUser.api_key,
          app_id: activeApp?.appId,
        })
      );
    }
  }, [activeApp, loginUser]);
  async function saveSiteSetting(formData: any) {
    try {
      await centralApi("POST", API_ENDPOINTS.SAVE_SITE_SETTING, formData, null);
      showSuccess(t("sucessfullySavedLabel"));

    } catch (error) {
      showError(t("somethingWrongLabel"));

    }

  }

 
  const handleSsoType = (value: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      sso_type: value,
    }));
  };

  return (
    <div className="site-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="site-setting-container-heading">
          {t("siteSettingLabel")}
        </h6>
        <Divider className="site-setting-custom-divider" />

        <div>
          <div className=" col-sm-8 mt-4 align-items-center site-setting-form ">
            
            {formData == DataTypeEnum.UNDEFINED ? (
              <Spinner />
            ) : (
              <Form form={form} layout="horizontal" onFinish={onFormSubmit}>
                <Form.Item label={t("singleSignOnSSOLabel")}>
                  <Selector
                    onChange={(value) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        is_sso: value,
                      }));
                    }}
                    value={formData?.is_sso}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={EDStatusEnum.ENABLE}
                    options={[
                      {
                        value: EDStatusEnum.DISABLE,

                        label: t("disableLabel"),
                      },
                      {
                        value: EDStatusEnum.ENABLE,
                        label: t("enableLabel"),
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item label={t("ssoProviderLabel")}>
                  <Selector
                    onChange={(value) => {
                      handleSsoType(value);
                    }}
                    size="middle"
                    style={{ width: "100%" }}
                    value={formData?.sso_type}
                    options={[
                      {
                        value: SiteSettingsEnum.DISABLED,
                        label: t("noneLabel"),
                      },
                      {
                        value: SiteSettingsEnum.ACTIVE_DIRECTORY,
                        label: t("activeDirectoryLabel"),
                      },
                      {
                        value: SiteSettingsEnum.SAML_BASED,
                        label: t("samlBasedSSOLabel"),
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label={t("customLogoLabel")}
                  
                >
                  <TextInput
                    disabled={true}
                    value={formData?.logo_image_name || formData?.logo_name}
                    addonAfter={
                      <div>
                        <Upload
                          showUploadList={false}
                          multiple={false}
                          onChange={(value: any) => handleImageUpload(value)}
                          beforeUpload={() => false}
                          className="site-setting-form-add-button"
                        >
                          <PrimaryButton type="primary">
                            {t("uploadSimpleLabel")}
                          </PrimaryButton>
                        </Upload>
                      </div>
                    }
                  />
                </Form.Item>
                <Form.Item label={t("portalNameLabel")}>
                  <TextInput
                    value={formData?.portal_name}
                    onChange={(e: any) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        portal_name: e.target.value,
                      }));
                    }}
                  />
                </Form.Item>

                {formData?.sso_type == SiteSettingsEnum.ACTIVE_DIRECTORY ? (
                  <>
                    <Form.Item label={t("ldapUrlLabel")}>
                      <TextInput
                        value={formData?.ldapConfig?.url}
                        onChange={(e: any) => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            ldapConfig: {
                              ...prevData.ldapConfig,
                              url: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t("bindDnLabel")}>
                      <TextInput
                        value={formData?.ldapConfig?.bindDN}
                        onChange={(e: any) => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            ldapConfig: {
                              ...prevData.ldapConfig,
                              bindDN: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t("bindCredentialLabel")}>
                      <TextInput
                       type="password"
                        value={formData?.ldapConfig?.bindCredentials}
                        onChange={(e: any) => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            ldapConfig: {
                              ...prevData.ldapConfig,
                              bindCredentials: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t("searchBaseLabel")}>
                      <TextInput
                        value={formData?.ldapConfig?.searchBase}
                        onChange={(e: any) => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            ldapConfig: {
                              ...prevData.ldapConfig,
                              searchBase: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                  </>
                ) : null}
                {formData?.sso_type == SiteSettingsEnum.SAML_BASED && (
                  <>
                    <Form.Item
                      rules={[
                        {
                          required: true,

                        },
                      ]}
                      label={t("callBackUrlLabel")}
                    >
                      <TextInput
                        value={formData?.samlConfig?.saml_callbackURL}
                        onChange={(e: any) => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            samlConfig: {
                              ...prevData.samlConfig,
                              saml_callbackURL: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t("entryPointLabel")}>
                      <TextInput
                        value={formData?.samlConfig?.saml_entryPoint}
                        maxLength={500}
                        onChange={(e: any) => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            samlConfig: {
                              ...prevData.samlConfig,
                              saml_entryPoint: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t("issuerLabel")}>
                      <TextInput
                        value={formData?.samlConfig?.saml_issuer}
                        onChange={(e: any) => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            samlConfig: {
                              ...prevData.samlConfig,
                              saml_issuer: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t("certLabel")}>
                      <TextInput
                        value={formData?.samlConfig?.saml_cert}
                        maxLength={1500}
                        onChange={(e: any) => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            samlConfig: {
                              ...prevData.samlConfig,
                              saml_cert: e.target.value,
                            },
                          }));
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label={t("xmlFileLabel")}
                      className="auth-key-item" // Add this className
                    >
                      <TextInput
                        disabled={true}
                        value={formData?.xml_fileName}
                        addonAfter={
                          <div>
                            <Upload
                              showUploadList={false}
                              multiple={false}
                              beforeUpload={() => false}
                            >
                              <PrimaryButton type="primary">
                                {t("uploadSimpleLabel")}
                              </PrimaryButton>
                            </Upload>{" "}
                          </div>
                        }
                      />
                    </Form.Item>
                  </>
                )}
                 <Form.Item label={t("captchaNameLabel")}>
                  <Selector
                    onChange={(value) => {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        captcha: value,
                      }));
                    }}
                    value={formData?.captcha}
                    size="middle"
                    style={{ width: "100%" }}
                    options={[
                      {
                        value: EDStatusEnum.DISABLE,

                        label: t("disableLabel"),
                      },
                      {
                        value: EDStatusEnum.ENABLE,
                        label: t("enableLabel"),
                      },
                    ]}
                  />
              
                </Form.Item>
                <div className="">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SiteSettings;
