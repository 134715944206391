"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditColumns = exports.AuditModuleEnum = exports.AuditActionTypeEnum = exports.AuditActionEnum = exports.AuditLogProviderType = void 0;
// Enum definition for LogProviderType representing different types of log providers.
var AuditLogProviderType;
(function (AuditLogProviderType) {
    AuditLogProviderType["PG"] = "pg";
})(AuditLogProviderType = exports.AuditLogProviderType || (exports.AuditLogProviderType = {}));
var AuditActionEnum;
(function (AuditActionEnum) {
    AuditActionEnum["USER_LOGIN"] = "User Login";
    AuditActionEnum["USER_LOGOUT"] = "User Logout";
    AuditActionEnum["USER"] = "User";
    AuditActionEnum["USER_ROLE"] = "User Role";
    AuditActionEnum["USER_STATUS"] = "User Status";
    AuditActionEnum["USER_PASSWORD"] = "User Password";
    AuditActionEnum["CAMPAIGN"] = "Campaign";
    AuditActionEnum["FCM"] = "FCM";
    AuditActionEnum["APN"] = "APN";
    AuditActionEnum["WPN"] = "WPN";
    AuditActionEnum["SECURITY"] = "Security";
    AuditActionEnum["SITE_SETTING"] = "Site Setting";
    AuditActionEnum["FEATURES"] = "Features";
    AuditActionEnum["INFRASTRUCTURE"] = "Infrastructure";
    AuditActionEnum["ALLOW_LIST"] = "Allow List";
    AuditActionEnum["CAMP_CONFIGURATOR"] = "Campaign Configurator";
    AuditActionEnum["AUDIENCE"] = "Audience";
    AuditActionEnum["TRAIT"] = "Traits";
    AuditActionEnum["FUNNELS"] = "Funnels";
    AuditActionEnum["AUDIENCE_EXPORT"] = "Audience Export";
    AuditActionEnum["FUNNEL_EXPORT"] = "Funnel Export";
    AuditActionEnum["FUNNEL_REPORTS"] = "Funnel Report";
    AuditActionEnum["JOURNEY"] = "Journey";
    AuditActionEnum["EVENT"] = "Event";
    AuditActionEnum["TEMPLATE"] = "Template";
    AuditActionEnum["CUSTOMER_360"] = "Customer 360";
    AuditActionEnum["PERSONALISATION_MAPPING"] = "Personalisation mapping";
    AuditActionEnum["AuditLogsConfiguration"] = "Audit Logs Configuration";
    AuditActionEnum["APP_MODE"] = "App Mode";
    AuditActionEnum["PUSH_CERT"] = "Push certificate";
    AuditActionEnum["HUAWEI"] = "Huawei";
    AuditActionEnum["EMAIL"] = "Email";
    AuditActionEnum["SMS"] = "SMS";
    AuditActionEnum["KILL_SWITCH"] = "Kill switch";
    AuditActionEnum["APP_INBOX"] = "App inbox";
    AuditActionEnum["FILE_ENCRYPTION"] = "File encryption";
    AuditActionEnum["PUSH_NOTIFICATION"] = "Push Notificaion";
    AuditActionEnum["UTM_SETTING"] = "UTM Setting";
    AuditActionEnum["ATTRIBUTION"] = "Attribution";
    AuditActionEnum["PARTNER_SETTINGS"] = "Partner settings";
    AuditActionEnum["PROVIDER_SETTINGS"] = "Provider settings";
})(AuditActionEnum = exports.AuditActionEnum || (exports.AuditActionEnum = {}));
var AuditActionTypeEnum;
(function (AuditActionTypeEnum) {
    AuditActionTypeEnum["CREATE"] = "Create";
    AuditActionTypeEnum["UPDATE"] = "Update";
    AuditActionTypeEnum["DELETE"] = "Delete";
    AuditActionTypeEnum["REVOKE"] = "Revoke";
    AuditActionTypeEnum["RESET"] = "Reset";
    AuditActionTypeEnum["UNBLOCK"] = "Unblock";
    AuditActionTypeEnum["ADD"] = "Add";
})(AuditActionTypeEnum = exports.AuditActionTypeEnum || (exports.AuditActionTypeEnum = {}));
exports.AuditModuleEnum = {
    AUTHENTICATION: { name: "Authentication", value: "authentication" },
    APP_SETTINGS: { name: "App Settings", value: "app_settings" },
    ENGAGEMENT: { name: "Engagement", value: "engagement" },
    USER_MANAGEMENT: { name: "User Management", value: "user_management" },
    COMMENT: { name: "Comment", value: "comment" },
    AUDIENCE_SEGMENT: { name: "Audience Segment", value: "audience_segment" },
    COMPUTED_TRAITS: { name: "Computed Traits", value: "computed_traits" },
    ANALYTICS: { name: "Analytics", value: "analytics" },
    JOURNEY_MANAGEMENT: { name: "Journey Management", value: "journey_management" },
    DATA_MANAGEMENT: { name: "Data Management", value: "data_management" },
};
exports.AuditColumns = {
    USER_NAME: { name: "User Name", value: "user_name" },
    ACTION: { name: "Action", value: "action" },
    ACTION_TYPE: { name: "Action Type", value: "action_type" },
    MODULE: { name: "Module", value: "module" },
    CLIENT_IP: { name: "Client IP", value: "client_ip" },
    HOST_NAME: { name: "Host Name", value: "host_name" },
    HOST_IP: { name: "Host IP", value: "host_ip" },
    DESCRIPTION: { name: "Description", value: "description" },
    CREATED_AT: { name: "Created At", value: "created_at" },
};
