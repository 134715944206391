import { FC, useEffect, useMemo, useState } from "react"
import windowIcon from "../../images/Windows1.png"
import macIcon from "../../images/Mac.png"
import SegmentedCustom from "../SegmentedCustom/SegmentedCustom"
import {
  AndroidOutlined,
  AppleOutlined,
  WindowsOutlined,
} from "@ant-design/icons"
import SmsImage from "../../images/SMSFullNew.svg"
import whatsappImage from "../../images/WA_IOS_No_Bubble.svg"
import whatsappImageButton from "../../images/WAAndroidwithButton.svg"
import androidPhoneIcon from "../../images/AndriodDeviceNew.svg"
import iPhoneIcon from "../../images/iphoneDeviceNew.svg"

import { useSpring, animated } from "react-spring"
import Spinner from "../Spinner/Spinner"
interface CampaignDeviceProps {
  children: any
  bazel?: {
    top: number
    bottom: number
    right: number
    left: number
  }
  height?: number
  width?: number
  campType?: string
  setDevice?: any
  intialDeviceType?: "Android" | "IOS" | "windows" | "Mac"
  intialImageType?: "button" | "noButton"

  isPreview?: boolean
  setIsPreview?: (
    prevState: boolean | ((prevState: boolean) => boolean)
  ) => void
}
const CampaignDevice: FC<CampaignDeviceProps> = ({
  children,
  bazel,
  height,
  width,
  campType = "push",
  setDevice,
  intialDeviceType = "Android",
  intialImageType = "noButton",
  isPreview,
  setIsPreview,
}) => {
  const bazelValues = bazel
    ? bazel
    : { top: 40, bottom: 30, left: 12, right: 13 }
  const webPushBazels = {
    androidBazelValues: { top: 15, bottom: 15, left: 10, right: 10 },
    macBazelValues: { top: 125, bottom: 130, left: "11.5%", right: "11.5%" },
    windowBazelValues: { top: 70, bottom: 158, left: "1.3%", right: "1.3%" },
  }
  const webPopUpBazels = {
    windowBazelValues: { top: 70, bottom: 158, left: "24%", right: "24%" },
  }
  const [loading, setLoading] = useState(true)
  const [deviceType, setDeviceType] =
    useState<"Android" | "IOS" | "windows" | "Mac">(intialDeviceType)

  const reset = useMemo(() => true, [deviceType])
  const props = useSpring({
    from: { transform: "translateX(-10%)", opacity: 0 },
    to: { transform: "translateX(0%)", opacity: 1 },
    config: { duration: 300 },
    reset,
  })
  return (
    <div>
      {campType === "push" && (
        <div className="d-flex position-relative flex-column  align-items-end ">
          <div className="d-flex mt-2 position-relative ">
            <img
              onLoad={() => setLoading(false)}
              height={height || 600}
              width={width || 290}
              src={deviceType == "Android" ? androidPhoneIcon : iPhoneIcon}
            ></img>
            <div className="position-absolute pt-3" style={{ ...bazelValues }}>
              {loading ? <Spinner /> : children}
            </div>
          </div>
          <div
            className="mt-2 d-flex justify-content-center enable-interaction  "
            style={{ width: 290 }}
          >
            <SegmentedCustom
              value={deviceType}
              onChange={(value: "IOS" | "Android") => {
                setDeviceType(value)
                if (setDevice) {
                  setDevice(value)
                }
              }}
              options={[
                {
                  label: (
                    <span className="d-flex gap-1 justify-content-center align-items-center mx-2">
                      <AndroidOutlined className="m-0" /> Android
                    </span>
                  ),
                  value: "Android",
                },
                {
                  label: (
                    <span
                      style={{ width: 60 }}
                      className="d-flex gap-1 justify-content-center align-items-center mx-2"
                    >
                      <AppleOutlined className="m-0" /> IOS
                    </span>
                  ),
                  value: "IOS",
                },
              ]}
            />
          </div>
        </div>
      )}

      {campType === "sms" && (
        <div className=" d-flex position-relative  flex-column align-items-end ">
          <div className="d-flex mt-3  position-relative ">
            <img
              onLoad={() => setLoading(false)}
              // height={height || 300}
              width={width || 290}
              src={SmsImage}
            ></img>
            <div className="position-absolute pt-3" style={{ ...bazelValues }}>
              {loading ? <Spinner /> : children}
            </div>
          </div>
        </div>
      )}

      {campType === "webpush" && (
        <div className="d-flex position-relative flex-column  align-items-center">
          <div className="mt-2 enable-interaction">
            <SegmentedCustom
              value={deviceType}
              onChange={(value: "IOS" | "Android") => {
                setDeviceType(value)
                if (setDevice) {
                  setDevice(value)
                }
              }}
              options={[
                {
                  label: (
                    <span className="d-flex gap-1 justify-content-center align-items-center mx-2">
                      <AndroidOutlined className="m-0" /> Android
                    </span>
                  ),
                  value: "Android",
                },
                {
                  label: (
                    <span
                      style={{ width: 60 }}
                      className="d-flex gap-1 justify-content-center align-items-center mx-2"
                    >
                      <WindowsOutlined className="m-0" />
                      Windows
                    </span>
                  ),
                  value: "windows",
                },
                {
                  label: (
                    <span
                      style={{ width: 60 }}
                      className="d-flex gap-1 justify-content-center align-items-center mx-2"
                    >
                      <AppleOutlined className="m-0" /> Mac
                    </span>
                  ),
                  value: "Mac", 
                },
              ]}
            />
          </div>
          {/* <animated.div
           */}
          <div
            style={{ minHeight: 550,background: isPreview
              ? "transparent"
              : "var(--color-text-disabled)", }}
            className={`d-flex   align-items-center mt-2 position-relative ${isPreview?"":"px-md-4 px-2"}}`}
          >
            {isPreview ? (
              //  <animated.div style={props}>
              <>
                <div>
                  <img
                    onLoad={() => setLoading(false)}
                    height={
                      height || deviceType == "windows"
                        ? 400
                        : deviceType == "Mac"
                        ? 330
                        : 550
                    }
                    width={width || "100%"}
                    src={
                      deviceType == "windows"
                        ? windowIcon
                        : deviceType == "Android"
                        ? androidPhoneIcon
                        : macIcon
                    }
                  ></img>
                  {/* </animated.div> */}
                </div>
                <div
                  className="position-absolute p-1"
                  style={
                    deviceType == "Mac"
                      ? { ...webPushBazels.macBazelValues }
                      : deviceType == "windows"
                      ? { ...webPushBazels.windowBazelValues }
                      : { ...webPushBazels.androidBazelValues }
                  }
                >
                  {loading ? <Spinner /> : children}
                </div>
              </>
            ) : (
              <div>{children}</div>
            )}

            {/* </animated.div> */}
          </div>

          {setIsPreview && <div className="mt-2 ">
            <SegmentedCustom
              value={isPreview}
              onChange={(value: boolean) => {
                  setIsPreview(value)
              }}
              options={[
                {
                  label: (
                    <span className="d-flex gap-1 justify-content-center align-items-center mx-2">
                      {" "}
                      Edit{" "}
                    </span>
                  ),
                  value: false,
                },
                {
                  label: (
                    <span
                      style={{ width: 60 }}
                      className="d-flex gap-1 justify-content-center align-items-center mx-2"
                    >
                      Preview
                    </span>
                  ),
                  value: true,
                },
              ]}
            />
          </div>}
        </div>
      )}
      {campType === "facebook" && (
        <div className=" d-flex position-relative  flex-column align-items-end ">
          <div className="d-flex  position-relative ">
            <div className="position-absolute pt-3">{children}</div>
          </div>
        </div>
      )}
      {campType === "WHATSAPP" && (
        <div className=" d-flex position-relative  flex-column align-items-end ">
          <div className="d-flex mt-3  position-relative ">
            <img
              onLoad={() => setLoading(false)}
              // height={height || 300}
              width={width || 290}
              src={
                intialImageType == "button"
                  ? whatsappImageButton
                  : whatsappImage
              }
            ></img>
            <div className="position-absolute pt-3" style={{ ...bazelValues }}>
              {loading ? <Spinner /> : children}
            </div>
          </div>
        </div>
      )}
      {campType === "WEB_POPUP" && (
        <div className=" d-flex position-relative align-items-center "style={{ minHeight: 487 }}
        >
          <div className="d-flex mt-2 position-relative ">
            <div>
              <img
                height={height || 400}
                width={width || "100%"}
             
                src={windowIcon}
              ></img>
              {/* </animated.div> */}
            </div>
            <div
              className="position-absolute p-1 "
            
              style={{
                top: "41%", // Center vertically
                left: "50%", // Center horizontally
                transform: "translate(-50%, -50%)", // Centering
              }}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default CampaignDevice
