import type { MenuProps } from "antd";
import { Menu } from "antd";
import "./InnerNav.css";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { useState } from "react";
type propType = {
  theme?: "dark" | "light";
  onTabClick:(e:any)=>void;
  selected:any,
  style?:any,
  menu: MenuProps["items"]
};

const InnerNav = ({theme,onTabClick,selected,style,menu,...props}: propType) => {
  const [items,setItems]=useState([])
  return (
    <div className="inner-nav-container ">
      <AccessControl items={menu} getAccessedItems={(items:any)=>setItems(items)}><></></AccessControl>
    <Menu
      theme={theme}
      className="inner-nav"
      onClick={onTabClick}
      {...props}
      selectedKeys={[selected]}
      mode="horizontal"
      items={items}
      style={{...style,background:"none"}}
      />
      </div>
  );
};

export default InnerNav;
