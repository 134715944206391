"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const geo_1 = require("./geo"); // Assuming Geo is defined in a separate file
const permissions_1 = require("./permissions"); // Assuming Permissions is defined in a separate file
const deviceinfo_1 = require("./deviceinfo"); // Assuming DeviceInfo is defined in a separate file
const eventhistory_1 = require("./eventhistory"); // Assuming EventHistory is defined in a separate file
let Profile = class Profile {
    constructor(did, userId, gender, Interests, wifihulls, locationhull, video, attribution, geo, sc, tsd, pushInterval, refname, firstName, middleName, lastName, nationality, email, phone, identity, dob, isMarried, age, firstSeen, lastSeen, permissions, deviceInfo, eventHistory, x360, initTime, _custom, customProperties, Cards, CardTransactions, CardPayments) {
        this.did = did;
        this.userId = userId;
        this.gender = gender;
        this.Interests = Interests;
        this.wifihulls = wifihulls;
        this.locationhull = locationhull;
        this.video = video;
        this.attribution = attribution;
        this.geo = geo;
        this.sc = sc;
        this.tsd = tsd;
        this.pushInterval = pushInterval;
        this.refname = refname;
        this.name = [firstName, middleName, lastName].filter(Boolean).join(' ');
        this.nationality = nationality;
        this.email = email;
        this.phone = phone;
        this.identity = identity;
        this.dob = dob;
        this.isMarried = isMarried;
        this.age = age;
        this.firstSeen = firstSeen;
        this.lastSeen = lastSeen;
        this.permissions = permissions;
        this.deviceInfo = deviceInfo;
        this.eventHistory = eventHistory;
        this.x360 = x360;
        this.initTime = initTime;
        this._custom = _custom;
        this.customProperties = customProperties;
        this.Cards = Cards;
        this.CardTransactions = CardTransactions;
        this.CardPayments = CardPayments;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Profile.prototype, "did", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Profile.prototype, "userId", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Profile.prototype, "gender", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Profile.prototype, "Interests", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Profile.prototype, "wifihulls", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Profile.prototype, "locationhull", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Profile.prototype, "video", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], Profile.prototype, "attribution", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", geo_1.Geo)
], Profile.prototype, "geo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], Profile.prototype, "sc", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], Profile.prototype, "tsd", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], Profile.prototype, "pushInterval", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Profile.prototype, "refname", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Profile.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "fn" }),
    __metadata("design:type", String)
], Profile.prototype, "firstName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "mn" }),
    __metadata("design:type", String)
], Profile.prototype, "middleName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "ln" }),
    __metadata("design:type", String)
], Profile.prototype, "lastName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "n" }),
    __metadata("design:type", String)
], Profile.prototype, "nationality", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "e" }),
    __metadata("design:type", String)
], Profile.prototype, "email", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "p" }),
    __metadata("design:type", String)
], Profile.prototype, "phone", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "g" }),
    __metadata("design:type", String)
], Profile.prototype, "identity", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "d" }),
    __metadata("design:type", Number)
], Profile.prototype, "dob", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "m" }),
    __metadata("design:type", String)
], Profile.prototype, "isMarried", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "a" }),
    __metadata("design:type", Number)
], Profile.prototype, "age", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "fs" }),
    __metadata("design:type", Number)
], Profile.prototype, "firstSeen", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "ls" }),
    __metadata("design:type", Number)
], Profile.prototype, "lastSeen", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "permissions" }),
    __metadata("design:type", permissions_1.Permissions)
], Profile.prototype, "permissions", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "device_info" }),
    __metadata("design:type", deviceinfo_1.DeviceInfo)
], Profile.prototype, "deviceInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "event_history" }),
    __metadata("design:type", eventhistory_1.EventHistory)
], Profile.prototype, "eventHistory", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "x360" }),
    __metadata("design:type", String)
], Profile.prototype, "x360", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "inittime" }),
    __metadata("design:type", Number)
], Profile.prototype, "initTime", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], Profile.prototype, "_custom", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ name: "customProperties", type: () => Object }),
    __metadata("design:type", Object)
], Profile.prototype, "customProperties", void 0);
Profile = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, String, Array, Array, Array, Array, Object, geo_1.Geo, Boolean, Number, Number, String, String, String, String, String, String, String, String, Number, String, Number, Number, Number, permissions_1.Permissions,
        deviceinfo_1.DeviceInfo,
        eventhistory_1.EventHistory, String, Number, Object, Object, Object, Object, Object])
], Profile);
exports.Profile = Profile;
