import CustomBreadCrumb from 'components/CustomBreadCrumb/CustomBreadCrumb'
import { t } from 'i18next'
import  { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from 'utils/constants'
import filterIcon from "../../../images/_filter.svg";
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton'
import {  Card, Space } from 'antd'
import InnerNav from 'components/InnerNav/InnerNav'
import { audienceItems } from 'helperFunctions/audience'
import "./Models.css"
import Selector from 'components/Selector/Selector'
import { CheckCircleOutlined } from '@ant-design/icons'
import CustomTable from 'components/CustomTable/CustomTable'
import {modelChartData ,columns} from './dummyData';
import { MenuProps } from 'antd/lib'
import { AudienceEnum } from 'utils/enums'
import centralApi from 'services/centralApi'
import { useSelector } from 'react-redux'


interface ModelsProps {
  
}

const Models: FC<ModelsProps> = ({  }) => {
  const navigate = useNavigate();
  const [selectModel , setSelectModel] = useState<string>("Propensity home_loan");
  const [data,setData]=useState({data:[],loading:true})
  const [tabFilter, setTabFilter] = useState<string>("models");
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
   // Get the chart data based on the selected model
   const chartData = modelChartData[selectModel] || [];
   
   const onTabClick: MenuProps["onClick"] = ({ key }) => {
    setTabFilter(key);
    if (key == AudienceEnum.TRAIT) {
      navigate(ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE);
    }
    else if (key == AudienceEnum.MODELS) {
      navigate(ROUTES.DASHBOARD_AUDIENCE_MODELS);
    }
    if (key === AudienceEnum.QUERY) {
      // Navigate to the Audience Create page
      navigate(ROUTES.DASHBOARD_AUDIENCE_CREATE);
    }
  };
  const getData=async()=>{
    try {
    const res=await centralApi("GET","/o/models",null,{api_key:loginUser.api_key,app_id:appId})
    setData({data:res,loading:false})
  } catch (error) {
    setData({data:[],loading:false})
  }
  }
  useEffect(()=>{
    if(loginUser.api_key)
getData()
  },[loginUser.api_key])
  return (
    <div className="container-fluid px-1 audience-allmodels-main-container ">
      <Helmet>
        <title>Appice | Traits | All models</title>
      </Helmet>
      <div className="pb-4 pt-1">
        <CustomBreadCrumb
          items={[
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE}>{t("audienceLabel")}</Link>
              ),
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE_CREATE}>
                  {t("createLabel")}
                </Link>
              ),
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE_MODELS}>
                 All models
                </Link>
              )
            }
          ]}
        />
      </div>
      <div className='audience-allmodels-inner-container p-sm-4 p-2 py-4 d-flex flex-column'>
 
      <div className="d-flex position-relative gap-2  justify-content-between  align-items-center ">
              {/* for icon and title of header */}
              <div className="d-flex justify-content-between w-100 gap-3  flex-wrap">
                <div className="d-flex align-items-center ">
                  <div className="d-flex   align-items-center">
                    <span className="d-flex   filter-icon-container justify-content-center ">
                      <img
                        src={filterIcon}
                        height={"100%"}
                        width={"100%"}
                        alt="filter"
                      ></img>
                    </span>

                    <h4 className="m-0 mx-2 ">
                        Models
                    </h4>
                  </div>
                </div>

                {/* for buttons of header */}
                <Space className="d-flex  mb-auto">
                <PrimaryButton onClick={() => navigate("/dashboard/audience")}>
                 {t("discardLabel")}
                </PrimaryButton>
                </Space>
              </div>
              <div className="mb-auto audience-segment-save">
                <PrimaryButton           
                  htmlType="submit"
                  type="primary"
                >
                  Save as segment
                </PrimaryButton>
              </div>
            </div>
            <div className="d-flex justify-content-between py-3  flex-wrap-reverse gap-2 ">
              <strong>Select a computed model to use it as a segment</strong>
            </div>
             {/* Model selection and details */}
             <div className="d-flex flex-column mt-4 ">
              <div className="d-flex align-items-center gap-4">
              <span className="mr-2 fw-bold">Select a model</span>
              <Selector
               className="model-select"
                defaultValue={selectModel}
                onChange={(value: string) =>
                   setSelectModel(value)
                  }
                options={[
                    {
                        label : "Propensity home_loan",
                        value : "Propensity home_loan"
                    },
                    {
                        label : "High LTV Home Loan",
                        value : "High LTV Home Loan"
                    },
                    {
                        label : "Cross sell for FD",            
                        value : "Cross sell for FD"
                    }
                ] }/>
              </div>
              
            </div>
            <div className="d-flex  mt-4 model-details">
               <div>
                 <span className='fw-bold'>Model health</span>
                   <div className='d-flex justify-content-center  mt-3'>
                    <CheckCircleOutlined style={{ fontSize: '23px', color: '#16C79A' }}/> 
                  </div>    
               </div>
               <div>
                 <span className='fw-bold'>Created on</span>
                 <div className='mt-3'>
                  <span>Jun 27, 2024,<br/> 10:24:07 PM IST</span>
                 </div> 
                </div>
               <div>
                  <span className='fw-bold'>Updated on</span>
                  <div className='mt-3'>
                   <span>Jul 28, 2024,<br/> 12:10:07 PM IST</span>
                  </div> 
                </div>
                <div>
                  <span className='fw-bold'>Last Run date</span>
                  <div className='mt-3'>
                  <span>Aug 08, 2024,<br/> 11:10:07 AM IST</span>
                  </div>
                </div>
            </div>
            <div className="d-flex mt-5 gap-5">
              <Card className=" model-description p-2">
               <p>
                 The personal loan model is indicative of customers who have shown a high propensity. The model assumes ...
               </p>
              </Card>
              <Card className="model-chart p-5 ml-3">
                {/* You can replace this with a chart component */}
                    <div className="d-flex justify-content-around align-items-end">
                    {chartData.map((data : any, index : any) => (
                    <div key={index}>
                    <div className="bar" style={{ height: data.height, backgroundColor: '#6DA1F5' }}></div>
                    <span>{data.month}</span>
                    </div>
                ))}
                </div> 
             </Card>
            </div>

            <div className="mt-4 flex-column">
            <div className="mb-3" style={{borderBottom :"1px solid lightgrey"}}>
            <h6>Showing 50 results</h6>
            </div>
            <CustomTable columns={columns} dataSource={data.data} pagination={{ pageSize: 20, simple: false }} />
          </div>
        

      </div>
      </div>

  )
}

export default Models;