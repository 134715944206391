import { CloseOutlined } from "@ant-design/icons";
import { eOperatorType } from "@customTypes/audience";
import { Form, FormInstance } from "antd";
import SingleDateSelector from "components/DateTimeSelectors/SingleDateSelector/SingleDateSelector";
import NoData from "components/NoData/NoData";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  removeFilterCondition,
  setFilterInfoData,
} from "redux/features/audience/computedTraitSlice";
import {
  E_OPERATOR_OPTIONS,
  periodOptions,
} from "utils/constants/selectorOptions";
import {
  ComparisonEnum,
  DateFormatEnum,
  IntervalEnum,
  KeyboardKeysEnum,
  OperatorTypeEnum,
  PeriodEnum,
} from "utils/enums";
import { FilterInfo, RowData } from "utils/models";
import { OperatorManager, PgsqlOperators } from "utils/operators";
import { validateMinMax, validateNotEmpty } from "validator/antdValidator";
import dayjs, { Dayjs } from "dayjs";
import { generateNumbersInRange } from "helperFunctions/number";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { isString } from "highcharts";
interface TraitConditionNestedProps {
  condition: FilterInfo;
  group?: FilterInfo[];
  index: number;
  fetchTableInfo: (
    operand: string | undefined,
    jsonb: string | undefined
  ) => Promise<any>;
  parentIndex: number;
  form: FormInstance;
  metaData: any;
}

const TraitConditionNested: FC<TraitConditionNestedProps> = ({
  condition,
  index,
  fetchTableInfo,
  group,
  form,
  metaData,
  parentIndex,
}) => {
  const [values, setValues] = useState({ data: [], loading: false });
  const [manualValue, setManualValue] = useState("");
  //get trait form redux store
  const dispatch: any = useDispatch();
  // Use the useMemo hook to memoize the selected operand information
  const seletedOperandInfo: RowData = useMemo(() => {
    // Check if the condition has a defined operand
    if (condition.operand) {
      // Find the column in metaData that matches the condition's operand
      return metaData.columns?.find(
        // Destructure the column to get the value property
        ({ value }: { value: string }) => value === condition.operand
      );
    }
    // If condition.operand is not defined, return undefined
  }, [condition?.operand, metaData?.columns]); // Dependencies: re-compute if operand or columns change
  /**
   * Fetches column values based on the current operand(column) and updates the state accordingly.
   * This function performs the following actions:
   * 1. Sets the loading state to true before making the API request.
   * 2. Calls the `fetchTableInfo` function with the current operand to retrieve column values.
   * 3. Updates the state with the fetched column values and sets the loading state to false.
   */
  const getColumnValues = async () => {
    setValues((prevState) => ({ ...prevState, loading: true }));
    const values = await fetchTableInfo(condition.operand, condition.jsonb);
    setValues({ data: values, loading: false });
  };

  /**
   * Handles changes in the form data.
   * @param data - An object representing the  form data. Each key is a string, and values can be a string, array of strings, boolean, or undefined.
   */
  const handleFormChange = async (data: {
    [key: string]: string | string[] | boolean | undefined;
  }) => {
    await dispatch(
      setFilterInfoData({ data: { ...condition, ...data }, parentIndex, index })
    );
    // reset input fields if the value of field is undefined
    resetFields();
  };
  // This function resets specific fields in the form for a given parent and child index.
  const resetFields = () => {
    form.resetFields([
      `func[${parentIndex}][${index}]`,
      `operand[${parentIndex}][${index}]`,
      `operator[${parentIndex}][${index}]`,
      `value[${parentIndex}][${index}]`,
      `interval[${parentIndex}][${index}]`,
      `comparison[${parentIndex}][${index}]`,
      `period[${parentIndex}][${index}]`,
    ]);
  };
/**
 * Function to handle both label (enum) and date (Day.js object) input.
 * This function accepts either a predefined label from the `PeriodEnum`
 * (TODAY, YESTERDAY, TOMORROW) or a date input .
 * 
 * - If the input matches one of the labels (TODAY, YESTERDAY, TOMORROW), it returns the corresponding Day.js date.
 * - If the input is a date that matches today's, yesterday's, or tomorrow's date, it returns the matching label from `PeriodEnum`.
 * - If the input is a Day.js date object that doesn't match any special label, it returns the date formatted in `ISO_FORMAT`.
 * - If the input is neither a label nor a Day.js object, it tries to convert it into a Day.js object and returns it.
 * 
 * @param {Dayjs | PeriodEnum} input - Can be either a label from `PeriodEnum` or a Day.js date object.
 * @returns {Dayjs | PeriodEnum | string} - Returns a Day.js date, a label from `PeriodEnum`, or a formatted date string.
 */
  const getLabelOrDate = (input:PeriodEnum | Dayjs) => {
    const dayString = "day"; // Unit for day-based comparisons
    const today = dayjs(); // Current date
    const yesterday = today.subtract(1, dayString); // Date for yesterday
    const tomorrow = today.add(1, dayString); // Date for tomorrow

    // Switch case for handling predefined labels from PeriodEnum
    switch (input) {
      case PeriodEnum.TODAY:
        return today; // Return today's date if input is TODAY label
      case PeriodEnum.YESTERDAY:
        return yesterday; // Return yesterday's date if input is YESTERDAY label
      case PeriodEnum.TOMORROW:
        return tomorrow; // Return tomorrow's date if input is TOMORROW label
      default:
        // If input is a Day.js date, check if it matches today, yesterday, or tomorrow
        if (dayjs.isDayjs(input) && input.isSame(today, dayString))
          return PeriodEnum.TODAY;
        if (dayjs.isDayjs(input) && input.isSame(yesterday, dayString))
          return PeriodEnum.YESTERDAY;
        if (dayjs.isDayjs(input) && input.isSame(tomorrow, dayString))
          return PeriodEnum.TOMORROW;

        // If it's a valid Day.js date object but not a special label, return formatted date string
        return dayjs.isDayjs(input)
          ? input.format(DateFormatEnum.ISO_FORMAT)
          : dayjs(input);
    }
  };

  //get selected column/operand maxValue
  const maxValueCount = useMemo(() => {
    if (condition.type && condition.operator) {
      const option = OperatorManager.getPgSqlOperatorOptions(
        condition.type
      )?.options.find((option) => option.value == condition.operator);
      return option?.maxValues;
    }
  }, [condition.operator, condition.type]);

  useEffect(() => {
    //get column values if the operand is not the type of date
    if (condition.operand && condition?.type !== OperatorTypeEnum.DATE) {
      getColumnValues();
    }
  }, [condition.type,condition.operand]);
  useEffect(() => {
    return () => {
      //reset the form fields on component unmount
      resetFields();
    };
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center  w-100">
        <span className=" ctrait-w100px ps-1 ">
          {parentIndex == -1 ? (
            t("whereLabel")
          ) : (
            <div>
              <SegmentedCustom
                value={condition.e_operator}
                size="small"
                onChange={(value: eOperatorType) => {
                  handleFormChange({ e_operator: value });
                }}
                options={E_OPERATOR_OPTIONS}
              />
            </div>
          )}
        </span>
        <div className="my-2 ps-1  gap-2  d-flex  ">
          <div className={`gap-2  d-flex`}>
            <Form.Item
              name={`operand[${parentIndex}][${index}]`}
              className="mb-1 input-size-md"
              initialValue={condition.operand || undefined}
              rules={[
                {
                  validator: validateNotEmpty,
                  message: `${t("selectOperandMsg")}`,
                },
              ]}
            >
              <Selector
                options={metaData.columns}
                filterOption={(input, option) =>
                  typeof option?.label == "string"
                    ? option?.label
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    : false
                }
                onChange={(val) => {
                  const operandInfo = metaData?.columns?.find(
                    ({ value }: { value: string }) => val == value
                  );
                  const type = OperatorManager.getPgSqlOperatorOptions(
                    operandInfo.type
                  ).type;
                  handleFormChange({
                    operand: val,
                    type: type,
                    jsonb: operandInfo.jsonb || undefined,
                    jsonb_type:operandInfo.jsonb_type || undefined,
                    operator: undefined,
                    value: undefined,
                    interval: undefined,
                    comparison: undefined,
                    period: undefined,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              className="mb-1 input-size-md"
              name={`operator[${parentIndex}][${index}]`}
              initialValue={condition.operator || undefined}
              rules={[
                {
                  validator: validateNotEmpty,
                  message: `${t("selectOperatorMsg")}`,
                },
              ]}
            >
              <Selector
                options={
                  condition.type
                    ? OperatorManager.getPgSqlOperatorOptions(condition?.type)
                        ?.options
                    : []
                }
                onChange={async (value) => {
                  await handleFormChange({
                    operator: value,
                    value: undefined,
                    interval: undefined,
                    comparison: undefined,
                    period: undefined,
                  });
                }}
              />
            </Form.Item>
            <div className={`d-flex gap-2`}>
              {seletedOperandInfo?.type && condition.operator && (
                <Form.Item
                  className={`mb-1 ${
                    condition.operator == PgsqlOperators.IS
                      ? ""
                      : "input-size-lg"
                  }`}
                  name={`value[${parentIndex}][${index}]`}
                  initialValue={
                    condition?.value
                      ? seletedOperandInfo?.type == OperatorTypeEnum.DATE
                        ? condition?.value?.map((value) =>
                            dayjs(value, DateFormatEnum.ISO_FORMAT).isValid()
                              ? dayjs(value, DateFormatEnum.ISO_FORMAT)
                              : value
                          )
                        : condition?.value
                      : undefined
                  }
                  rules={[
                    {
                      validator: validateMinMax(maxValueCount, maxValueCount),
                    },
                  ]}
                >
                  {seletedOperandInfo?.type == OperatorTypeEnum.DATE &&
                  condition.operator !== PgsqlOperators.IS ? (
                    <SingleDateSelector
                      maxTagCount={2} //prevent overflow
                      multiple // Enable multiple date selection
                      onChange={(dates) => {
                        // Check if the dates is an array (multiple dates selected)
                        let formattedDates = Array.isArray(dates)
                          ? dates.map((date) =>
                              dayjs(date).format(DateFormatEnum.ISO_FORMAT)
                            ) // Format each date if it's an array
                          : []; // Handle case when no dates are selected
                        // Call your handler with the formatted array of dates
                        if (
                          maxValueCount &&
                          (!formattedDates ||
                            formattedDates.length == maxValueCount)
                        ) {
                          handleFormChange({
                            value: formattedDates,
                            interval: undefined,
                            comparison: undefined,
                            period: undefined,
                          });
                        }
                      }}
                    />
                  ) : (
                    <Selector
                      maxCount={maxValueCount}
                      loading={values.loading}
                      placeholder={t("selectValueMsg")}
                      notFoundContent={
                        <div className="text-center">
                          <NoData />
                          <strong>{t("pressEnterKeyMsg")}</strong>
                        </div>
                      }
                      options={
                        seletedOperandInfo?.type == OperatorTypeEnum.DATE
                          ? [
                              ...periodOptions(true),
                             
                              {
                                label: t("currentDayLabel"),
                                value: PeriodEnum.CURRENT_DAY,
                              },
                              {
                                label: t("currentMonthLabel"),
                                value: PeriodEnum.CURRENT_MONTH,
                              },
                              {
                                label: t("currentDateLabel"),
                                value: PeriodEnum.CURRENT_DATE,
                              },
                              ...generateNumbersInRange(1, 360).map(
                                (number: number) => ({
                                  label: number,
                                  value: number,
                                })
                              ),
                            ]
                          : values.data
                      }
                      onKeyDown={(event) => {
                        if (
                          event.key === KeyboardKeysEnum.ENTER &&
                          manualValue
                        ) {
                          setValues((prevState: any) => {
                            const newValue = {
                              label: manualValue,
                              value: manualValue,
                            };
                            return {
                              ...prevState,
                              data: [...prevState.data, newValue],
                            };
                          });
                        }
                      }}
                      mode="multiple"
                      onSearch={(value) => {
                        // Check if there are any search results
                        const results = values.data.filter((item: any) =>
                          item.value
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                        );
                        if (!results.length) {
                          setManualValue(value);
                        }
                      }}
                      onChange={(values) => {
                        const modifiedValues=values.flatMap((item:string | number) => typeof item ==="string"?item.split(','):item)
                        handleFormChange({
                          value:modifiedValues,
                          interval: undefined,
                          comparison: undefined,
                          period: undefined,
                        });
                      }}
                    />
                  )}
                </Form.Item>
              )}
              {seletedOperandInfo?.type == OperatorTypeEnum.DATE &&
                typeof condition?.value?.[0] === "number" && (
                  <div className="d-flex gap-1">
                    <Form.Item
                      className="mb-1 w-50"
                      initialValue={condition.interval}
                      name={`interval[${parentIndex}][${index}]`}
                      rules={[
                        {
                          validator: validateNotEmpty,
                          message: ``,
                        },
                      ]}
                    >
                      <Selector
                        placeholder={t("intervalLabel")}
                        onChange={(value) => {
                          handleFormChange({
                            interval: value,
                            comparison: undefined,
                            period: undefined,
                          });
                        }}
                        options={[{
                          label:t("dayLabel"),
                          value:IntervalEnum.DAYS
                        },
                        {
                          label:t("daysOrLessLabel"),
                          value:IntervalEnum.DAYS_OR_LESS
                        },{
                          label:t("daysOrMoreLabel"),
                          value:IntervalEnum.DAYS_OR_MORE
                        }]}
                      ></Selector>
                    </Form.Item>
                    <Form.Item
                      className="mb-1 input-size-sm"
                      initialValue={condition.comparison}
                      name={`comparison[${parentIndex}][${index}]`}
                      rules={[
                        {
                          validator: validateNotEmpty,
                          message: ``,
                        },
                      ]}
                    >
                      <Selector
                        onChange={(value) => {
                          handleFormChange({
                            comparison: value,
                            period: undefined,
                          });
                        }}
                        options={[
                          { label: t("fromLabel"), value: ComparisonEnum.FROM },
                          { label: t("beforeLabel"), value: ComparisonEnum.BEFORE },
                        ]}
                      ></Selector>
                    </Form.Item>
                    <Form.Item
                      className="mb-1  ctrait-w100px"
                      initialValue={
                        condition?.period
                          ? getLabelOrDate(condition.period)
                          : undefined
                      }
                      name={`period[${parentIndex}][${index}]`}
                      rules={[
                        {
                          validator: validateNotEmpty,
                          message: ``,
                        },
                      ]}
                    >
                      <SingleDateSelector
                        format={(date) => {
                          const value = getLabelOrDate(date); // Get the label or date value
                          // Check if the value is a string (label) or a Dayjs object
                          return typeof value === "string" ? value : "";
                        }}
                        showToday={false}
                        presets={[]}
                        renderExtraFooter={() => (
                          <div className="d-flex justify-content-between my-1">
                            {periodOptions(true).map(({value,label}) => (
                              <PrimaryButton
                              key={value}
                                onClick={() =>
                                  handleFormChange({ period: value })
                                }
                                type="link"
                              >
                                {label}
                              </PrimaryButton>
                            ))}
                          </div>
                        )}
                        onChange={(date) => {
                          const value = date.format(DateFormatEnum.ISO_FORMAT);
                          handleFormChange({ period: value });
                        }}
                      />
                    </Form.Item>
                  </div>
                )}
            </div>
          </div>
          {parentIndex !== -1 && group && group?.length - 1 == index && (
            <CloseOutlined
              onClick={() => {
                dispatch(removeFilterCondition({ index, parentIndex }));
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TraitConditionNested;
