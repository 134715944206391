import BeePlugin from "@mailupinc/bee-plugin";
import axios from "axios";
import { beefreeOptionType } from "../components/BeefreeEditor/BeefreeEditor";

export const beefree = async (options: beefreeOptionType , config :any ) => {
  try {
    const { endpoint, clientId, clientSecret, grantType }: any = config;
    const payload = {
      client_id: clientId, // Enter your client id
      client_secret: clientSecret, // Enter your secret key
      grant_type: grantType, // Do not change
    };
    const token: any = await axios.post(endpoint, payload);
    //simple Beefree application configuration...
    var conf: any = {
      uid: "22",
      container: "bee-plugin-container",
      trackChanges: true, // boolean
      mergeTags:options?.mergeTags || [],
      loadingSpinnerDisableOnSave: true
    };
    const bee = new BeePlugin(token, conf);
    await bee.getToken(clientId, clientSecret);
    bee.start(
      conf,
      options?.existingTemplate ? JSON.parse(options?.existingTemplate) : {}
    );
    conf.onSave = options.workspace?.onSave;
    conf.onChange = options.workspace?.onChange;
    conf.onSaveAsTemplate = options.workspace?.onSaveAsTemplate;
    conf.onAutoSave = options.workspace?.onAutoSave;
    conf.onSend = options.workspace?.onSend;
    conf.onLoad = options.workspace?.onLoad;
    conf.onError = options.workspace?.onError;
    return {instance:bee,conf:conf}
  } catch (error) {}
};
