import { SizeType } from "antd/es/config-provider/SizeContext";
import { useEffect, useState } from "react";
import Search from "../../components/Inputs/Search/Search";
import Selector from "../../components/Selector/Selector";
import "./AppUsers.css";
import AppUserChunks from "../../components/Cards/AppUserChunks/AppUserChunks";
import { useSelector } from "react-redux";
import CustomTable from "../../components/CustomTable/CustomTable";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { t } from "i18next";
import centralApi from "services/centralApi";
import useMessage from "hooks/useMessage";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { DateRangeData } from "@customTypes/dateRange";
import { API_ENDPOINTS } from "utils/constants";
type propTypes = {
  tableSize: SizeType;
  pageSize: number;
};
function AppUsers({ tableSize, pageSize }: propTypes) {
  const searchLabel = t("searchLabel");
  const loginUser = useSelector((state: any) => state.loginUser);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [noOfUsers, setNoOfUsers] = useState(0);
  const [search, setSearch] = useState("");
  const [dauWauMau, setDauWauMau] = useState({ dau: 0, wau: 0, mau: 0 });
  const [pageData, setPageData] = useState({
    current: 1,
    pageSize: pageSize,
    simple: false,
  });
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState<any>([]);
  // const { endDate_C } = useSelector(
  //   (state: any) => state.dateRange
  // );
  const [dateRange, setDateRange] =
  useState<DateRangeData | null>(null);

  const apiKey = loginUser.data.api_key;
  const { showError} = useMessage();
  const { endDate_C } = dateRange || {}

  interface Sorter {
    field: string;
    order: string;
  }
  interface ColumnIndexMap {
    User: number;
    User_Since: number;
    Last_Seen: number;
    // Add more columns if necessary
  }
  const [sorter, setSorter] = useState<Sorter>({ field: "User_Since", order: "desc" });
  const columnIndexMap: ColumnIndexMap = {
    User: 0,
    User_Since: 1,
    Last_Seen: 2,
    // Add mappings for other columns
  };
  const [type, setType] = useState("I");
  const [platform, setPlatform] =
    useState<"all" | "Android" | "IOS" | "Web">("all");
    const handleDateRange = (dateRangeData: any) => {
    
      setDateRange(dateRangeData);
    };
  async function getAppUsersData() {
    try {
     
      const payload = {
     
        app_id: appId,
        api_key: apiKey,
        draw: pageData.current,
        start: pageData.pageSize * (pageData.current - 1),
        length: pageData.pageSize,
        "search[value]": search,
        "search[regex]": false,
        "_": endDate_C,
        order: [
          {
            column: columnIndexMap[sorter.field as keyof ColumnIndexMap],
            dir: sorter?.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : 'desc',
          },
        ],
        type,
        platform,
      };
      if (apiKey && appId) {
        setLoading(true);
        const url: string = API_ENDPOINTS.GET_APP_USERS_URL;
        const response = await centralApi("GET", url, null, payload);
        const data = response?.data.map((user: any, index: number) => ({
          Key: index,
          User: user ,
          User_Since: user.fs || "", // Using logical OR to handle undefined user.fs
          Last_Seen: user.ls || "", // Using logical OR to handle undefined user.ls
          of_Sessions: user.sc || "", // Using logical OR to handle undefined user.sc
          Average_Duration: user.ad || "", // Using logical OR to handle undefined user.ad
          Client_ID: user.ci || "" // Using logical OR to handle undefined user.ci
        }));
        setUsersData(data);
        setNoOfUsers(response?.recordsTotal);
        setLoading(false);
      }
    } catch (error:any) {
      showError(error.message);

      setLoading(false);
    }
  }
  async function getDauWauMau({ value }: any) {
    const url: string = `/o/metrices/getDauWauMau`;
    const payload = {
      app_id: appId,
      args: JSON.stringify(value),
      api_key: apiKey
    };
    try {
      const response = await centralApi("GET", url, null, payload);
      return response;
    } catch (e:any) {

      showError(e.message);

    }     
  }
  async function getDauWauMauWithPlatform({ value}: any) {
    const url: string = `/o/metrices/getDauWauMauWithPlatform`;
    const payload = {
      app_id: appId,
      args: JSON.stringify(value),
      api_key: apiKey,
      platform_: platform,
    };
  
    try {
      const response = await centralApi("GET", url, null, payload);
      return response;
    } catch (e:any) {
      showError(e.message);
    }
  }
  
  
  const fetchDauWauMau = async (value: any) => {
    try {
      const apiKey = loginUser.data.api_key;
      if (apiKey && loginUser) {
        const new_data =await getDauWauMau({ value })
          
        setDauWauMau({
          dau: Math.floor(new_data.dau),
          mau: Math.floor(new_data.mau),
          wau: Math.floor(new_data.wau),
        });
      }
    } catch (e:any) {
      showError(e.message);

    }
  };
  useEffect(() => {
    getAppUsersData();
  }, [pageData, search, type, platform, loginUser]);
  useEffect(() => {
    if(dateRange){

      fetchDauWauMau(dateRange);
    }
  }, [dateRange, loginUser]);
  // when the page number of table changes
  const onPageChange = (value: any,filters:any,sorter:any) => {
   
    setPageData(value);
    setSorter(sorter)
  };
  const columns: any = [
    {
      title: t("userLabel"),
      dataIndex: "User",
      sorter: true,

      render: (user: any) => {
  // Construct the link URL based on the idToUse
  return <Link to={`/dashboard/appuser/${user.did}?type=did`}>{user.did}</Link>;
      },
    },
    {
      title: t("userSinceLabel"),
      dataIndex: "User_Since",
      sorter: true,
      defaultSortOrder: 'descend',

    },
    {
      title: t("lastSeenLabel"),
      dataIndex: "Last_Seen",
      sorter: true,
    },
    {
      title: t("hashOfSessionsLabel"),
      dataIndex: "of_Sessions",
    },
    {
      title: t("averageDurationLabel"),
      dataIndex: "Average_Duration",
    },
    {
      title: t("clientIdLabel"),
      dataIndex: "Client_ID",

      render: (clientId: string) => {
        return clientId ? (
          <Link to={`/dashboard/appuser/${clientId}?type=ci`}>{clientId}</Link>
        ) : (
          "" // Handle the case where `Client_ID` might be empty
        );
      },
    },
  ];
  const styleBtn = {
    width: "auto",
    marginRight: "10px",
    marginBottom: "10px",
  };
  return (
    <div className="container-fluid px-sm-3 p-1">
      <Helmet>
        <title>Appice | AppUser</title>
      </Helmet>
      <div className="d-flex justify-content-between flex-wrap">
        <div>
          <h4>{t("appUserLabel")}</h4>
        </div>
        <div className="d-flex">
          <div>
            <strong>{t("platformLabel")}</strong>
            <Selector
              onChange={(value) => {
                setPlatform(value);
              }}
              size="middle"
              defaultValue={platform}
              style={{ ...styleBtn, width: "90px" }}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "Android",
                  label: "Android",
                },
                {
                  value: "IOS",
                  label: "IOS",
                },
                {
                  value: "Web",
                  label: "Web",
                },
              ]}
            />
          </div>
          <div>
            <strong>{t("periodLabel")} </strong> 
            <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              numDaysToShow={30} // Example value
            />
          </div>
        </div>
      </div>
      <div
        className="py-3 px-sm-2 px-0 w-100 d-flex flex-lg-nowrap flex-wrap  align-content-stretch"
        style={{
          background: "var(--color-other-white)",
          border: "1px solid var(--color-other-divider)",
        }}
      >
        <AppUserChunks name={t("dauLabel")} data={dauWauMau.dau} />
        <AppUserChunks name={t("wauLabel")} data={dauWauMau.wau} />
        <AppUserChunks name={t("mauLabel")} data={dauWauMau.mau} />
      </div>
      <div className="px-sm-3 px-1 appuser-table-container">
        <div className="py-3 m-0 d-flex flex-row w-100">
          <div className="w-100 d-flex h-100 ">
            <Search
              placeholder={searchLabel}
              onChange={(e: any) => setSearch(e.target.value)}
              size="middle"
              className="appuser-search"
             
            />
            <Selector
              onChange={(value) => {
                setPageData({ ...pageData, pageSize: value });
              }}
              style={styleBtn}
              size="middle"
              defaultValue={pageSize}
              options={[
                {
                  value: 6,
                  label: 6,
                },
                {
                  value: 12,
                  label: 12,
                },
                {
                  value: 18,
                  label: 18,
                },
              ]}
            />
            <Selector
              onChange={(value) => {
                setType(value);
              }}
              size="middle"
              defaultValue={type}
              style={{ ...styleBtn }}
              options={[
                {
                  value: "",
                  label: "All",
                },
                {
                  value: "I",
                  label: "Active",
                },
                {
                  value: "U",
                  label: "InActive",
                },
              ]}
            />
          </div>
        </div>

        <CustomTable
          columns={columns}
          // dataSource={usersData}
          dataSource={usersData.map((user: any) => ({
            ...user,
            key: user.Key,
          }))}
          size={tableSize}
          loading={loading}
          onChange={onPageChange}
          pagination={{
            pageSize: pageData.pageSize,
            simple: false,
            total: noOfUsers,
          }}
          style={{ width: "100%" }}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
}

export default AppUsers;
AppUsers.defaultProps = {
  tableSize: "small",
  pageSize: 6,
};
