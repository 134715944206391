export const userProperties = [
    {
      col: "a.did",
      alias: "deviceId",
    },
    {
      col: "a.user_info->>'e'",
      alias: "email",
    },
    {
      col: "a.user_info->>'p'",
      alias: "phone",
    },
    {
      col: "a._custom_userid",
      alias: "userid",
    },
  ];