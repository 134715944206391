import "./Queue.css";
import {Divider } from "antd";
import { Form } from "antd";
import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import { t} from "i18next";
import Selector from "components/Selector/Selector";
import {FormValues, QueueSettingsProps} from "./interfaces";
import ShowMessage from "components/ShowMessage/ShowMessage";
import Spinner from "components/Spinner/Spinner";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { ConfigTypeEnum, QueueConfigTypeEnum } from "utils/enums";
import centralApi from "services/centralApi";
import {  InfrastructureSettings} from "utils/models";
import { deserialize, serialize } from "utils/models/serializer";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";


const Queue: React.FC<QueueSettingsProps> = ({
  currentAppKey,
  updateCurrentAppKey
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const [providerType , setProviderType] = useState<string>(QueueConfigTypeEnum.PG );
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  
 /**
   * 
   * @param e will be an event and e.target.value will be a number string
   * @param fieldName will be the key of and object where we are setting the values
   * @returns key with number value
   */
 const handleChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const inputValue = e.target.value;
    const numberValue = Number(inputValue);
  
    // Validate number or use default values
    const isValid = !isNaN(numberValue) && inputValue.trim() !== "";
  
    // Update form field with the correct number value
    if (isValid) {
      form.setFieldsValue({
        [fieldName]: numberValue,
      });
    }
    else{
      return undefined
    }
  };

  const onFormSubmit = async(values: FormValues) => {
   
    // Initialize formData based on providerType
    let formData = new InfrastructureSettings();
    if (providerType === QueueConfigTypeEnum.PG) {
        formData = {
          queueSettings :{
            pgQueueConfig : {
              ...values,
          },
          partitionCount : values.partitionCount
          },

        }
    } else if (providerType === QueueConfigTypeEnum.AZURE) {
        formData={
          queueSettings : {
            azureQueueConfig :{
              ...values,
          },
          partitionCount : values.partitionCount
          }
        }
    }

   
    // Deserialization
    const queueConfig = deserialize(formData, InfrastructureSettings);
    
    
    // Serialization
    const serializedQueueData = serialize(queueConfig as InfrastructureSettings);
    
    const payload = {
        args: JSON.stringify({
            app_id: appId,
            configType: ConfigTypeEnum.QUEUE,
            ...serializedQueueData,
        }),
        app_id: appId,
        api_key: api_key,
        authToken
    };

    await updateGCMKeys(payload);
    dispatch(getToken())
    
};

  async function updateGCMKeys(formData: any) {
    const url: string = API_ENDPOINTS.UPDATE_GCM_KEYS;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
      updateCurrentAppKey();
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  }; 



 useEffect(() => {
    const provider =  providerType;
    const pgQueueConfig = currentAppKey[0]?.infrastructureSettings?.queueSettings?.pgQueueConfig;
    const azureQueueConfig = currentAppKey[0]?.infrastructureSettings?.queueSettings?.azureQueueConfig;

    const updatedFormValues: any = {
        queueProviderType : provider ,
        partitionCount :currentAppKey[0]?.infrastructureSettings?.queueSettings?.partitionCount ,
        pgQueueUser : pgQueueConfig?.pgQueueUser,
        pgQueueHost : pgQueueConfig?.pgQueueHost,
        pgQueueDatabase : pgQueueConfig?.pgQueueDatabase,
        pgQueuePassword : pgQueueConfig?.pgQueuePassword,
        pgQueuePort : pgQueueConfig?.pgQueuePort,
        azureApiQueueSasUrl:azureQueueConfig?.azureApiQueueSasUrl,
        azureApiQueueSasToken:azureQueueConfig?.azureApiQueueSasToken
   }
   setProviderType(provider)
   form.setFieldsValue(updatedFormValues);
 },[ currentAppKey, form, providerType])


  return (
    <Fragment>
      <div className="queue-notification-container">
        <div className="mt-4 mb-4  ">
          <h6 className="queue-notification-container-heading">
            Queue Service
          </h6>
          <Divider className="queue-notification-custom-divider" />
        </div>

        <div className=" col-sm-7 mt-4 mb-4 align-items-center queue-notification-form ">
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFormSubmit}
            >
              <Form.Item
                     label={t("providerLabel")}
                     name={"queueProviderType"}
                  >
                    <Selector
                     onChange={(value: string) => {
                        setProviderType(value)
                     }
                       
                    }

                      options={[
                        {
                          label:t("azureLabel"),
                          value: QueueConfigTypeEnum.AZURE,
                        },
                        {
                          label: t("postgresLabel"),
                          value: QueueConfigTypeEnum.PG,
                        },
                      ]}
                     
                    />
              </Form.Item>

              <Form.Item
                label={t("partitionCountLabel")}
                name={"partitionCount"}
                rules={[
                  { required: true, message: "Please Select  Partition Count" },
                ]}
                initialValue={""}
              >
                <TextInput
                      onChange={(e : any) => handleChange(e, "partitionCount")}
                      type="number" 
                    />
              </Form.Item>


            {/* Conditional Rendering based on storageProviderType */}

            {providerType === QueueConfigTypeEnum.PG && (
             <>
              <Form.Item
                label={t("userlabel")}
                 name={"pgQueueUser"}
                rules={[
                  { required: true, message: "Please Select PG Queue User" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("hostLabel")}
                name={"pgQueueHost"}
                rules={[
                  { required: true, message: "Please Select PG Queue User" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("portLabel")}
                name={"pgQueuePort"}
                rules={[
                  { required: true, message: "Please Select PG Queue  Port" },
                ]}
                initialValue={""}
              >
                <TextInput 
                onChange={(e : any) => handleChange(e, "pgQueuePort")}
                type="number" 
                />
              </Form.Item>
              <Form.Item
                label={t("databaseLabel")}
                name={"pgQueueDatabase"}
                rules={[
                  { required: true, message: "Please Select PG Queue Database" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("passwordLabel")}
                name={"pgQueuePassword"}
                rules={[
                  { required: true, message: "Please Select PG Queue Password" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              </>
            )}
              {(providerType  == QueueConfigTypeEnum.AZURE &&
              <>
              <Form.Item
                label={t("azureApiQueueSasUrl")}
                 name={"azureApiQueueSasUrl"}
                rules={[
                  { required: true, message: "Please Select Azure Queue SAS Url" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              <Form.Item
                label={t("sasTokenLabel")}
                 name={"azureApiQueueSasToken"}
                rules={[
                  { required: true, message: "Please Select Azure Queue SAS Token" },
                ]}
                initialValue={""}
              >
                <TextInput />
              </Form.Item>
              </>
              )}
              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  Save
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default Queue;
