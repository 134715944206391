import deleteIcon from "images/trashpink.svg";
import editIcon from "images/_edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import CustomTable from "components/CustomTable/CustomTable";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./DataTransformation.css";
import React, { useEffect, useRef, useState } from "react";
import useMessage from "hooks/useMessage";
import centralApi from "services/centralApi";
import TextInput from "components/Inputs/TextInput/TextInput";
import { Space } from "antd";
import ConfirmPopUp from "components/PopUp/ConfirmPopUp/ConfirmPopUp";
import { API_ENDPOINTS } from "utils/constants";
import Spinner from "components/Spinner/Spinner";
import { removeUnderscoreAddSpace } from "helperFunctions/common";
import { getToken } from "redux/features/app/authTokenSlice";

const DataTransformation: React.FC = () => {
  const { showError, showSuccess } = useMessage();
  const dispatch: any = useDispatch();
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const authToken = useSelector((state: any) => state.authToken.token);

  const [loading, setLoading] = useState(false);
//saving table data
  const [tableData, setTableData] = useState([]);

  async function getCustomer360mapping(payload: {
    app_id: string;
    api_key: string;
  }) {
    try {
      setLoading(true);
          // Make an API call to retrieve customer 360 mapping data

      const res = await centralApi(
        "GET",
        API_ENDPOINTS.GET_CUSTOMER_360_MAPPING,
        null,
        payload
      );
          // Update the table data with the response from the API

      setTableData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (loginUser?.api_key) {
          // Make an function call to retrieve customer 360 mapping data

      getCustomer360mapping({
        app_id: appId,
        api_key: loginUser.api_key,
      });

    }
  }, [loginUser?.api_key]);

  async function updateCustomer360mappingRowById(formData: any) {
    const payload = {
      args: JSON.stringify({
        ...formData,
      }),
      authToken,
      app_id: appId,
      api_key: loginUser?.api_key,
    };

    try {
          // Set loading state to true to indicate that the update process has started

      setLoading(true);
//updating the table
      await centralApi(
        "POST",
        API_ENDPOINTS.UPDATE_CUSTOMER_360_MAPPING_ROW_BY_ID,
        payload,
        null
      );
      showSuccess(t("sucessfullyUpdatedLabel"));
      dispatch(getToken())

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  }
  const handleActionChange = (key: string, index: number, value: string) => {
      // Create a copy of the current table data to avoid mutating state directly

    const data: any = [...tableData];
      // Update the specific row at the given index with the new value for the specified key

    data[index] = { ...data[index], [key]: value };
      // Update the table data state with the modified array

    setTableData(data);
  };
  //Creates a render function for a TextInput component that can be used in a table.

 //common text input for editing
  const createRenderTextInput = (dataIndex: string, fontSize:number ) => {
    const RenderTextInputComponent = (_: any, record: any, index: number) => (

      <TextInput
            // Set the value of the input, defaulting to an empty string if record or dataIndex is undefined

        value={record?.[dataIndex] ?? ''}
        style={{ fontSize }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // Call handleActionChange when the input value changes, passing the dataIndex, row index, and new value

          handleActionChange(dataIndex, index, e.target.value);
        }}
      />
    );
    // Set a display name for the component
    RenderTextInputComponent.displayName = `RenderTextInput(${dataIndex})`;
    // Return the render function

    return RenderTextInputComponent;
  };
 
  const getColumnTitle = (key: string) => {
    ///check if key is destination_col_key remove replace with the new name
    const specialTitles: Record<string, string> = {
      destination_col_key: 'Destination column root key',
    };

    return specialTitles[key] || removeUnderscoreAddSpace(key);
  };
  
// Function to generate dynamic column configuration
const generateColumns = (data:any) => {
  // Get all the keys from the first item in the array
  const excludeKeys = ['id', 'description'];  // Keys to exclude
  const keys = Object?.keys(data[0]).filter(key => !excludeKeys.includes(key));

  //adding  font size and width for some rows
    const columnStyles: Record<string, {  width: number }> = {

    source_table: {  width: 130 },

    source_col: {  width: 320 },
    file_table: {  width: 130 },

    source_fields: {  width: 320 },
    destination_col:{width:140},
    display_name: {  width: 320 },  
    destination_col_child_key: {  width: 100 },
    };
  // Map the keys into column definitions
  return keys.map(key => ({
    title: getColumnTitle(key),   // Get title for the column
    dataIndex: key,         // Data index for the column
    key: key,               // Key for the column
    width: columnStyles[key]?.width || 130,  //  width or default to 130
    render: createRenderTextInput(key, 12),  // render text inputs

  }));
};

  // Adding the action column  for edit and update
const actionColumn = {
    // Title of the action column, translated using the provided key

  title: t("actionLabel"),
  key: "action",
  width: 150,
    // Render function for the column, providing action buttons for each record

  render: (_: any, record: any) => (
    <Space>
      <ConfirmPopUp
        title={t("deleteLabel")}
        onConfirm={() => deleteRowById(record.id)} // Function to call on confirm
        description={t("areYouSureMsg")}  // Message displayed in the confirmation popup
        placement="left"
        id={record._id} // Unique ID for the record
        icon={deleteIcon} // Icon to display in the popup
  >
        <PrimaryButton className="action-btn mx-1">
          <img src={deleteIcon} alt={"close"} style={{ width: 18 }}></img> {/* Icon for delete button */}
        </PrimaryButton>
      </ConfirmPopUp>
      <ConfirmPopUp
        title={t("updateLabel")}
        onConfirm={() => updateCustomer360mappingRowById(record)} // Function to call on confirm
        description={t("areYouSureMsg")} // Message displayed in the confirmation popup
        placement="left"
        id={record._id}
        icon={editIcon}
      >
        <PrimaryButton className="action-btn mx-1">
          {t("updateLabel")}
        </PrimaryButton>
      </ConfirmPopUp>
    </Space>
  ),
};
  // Generate dynamic columns based on the table data
const dynamicColumns = Array.isArray(tableData) && tableData.length > 0 // Check if tableData is an array and has items
? generateColumns(tableData) // Call generateColumns function to create columns if data exists
: []; // If no data, set dynamicColumns to an empty array

// Combine dynamic columns with the action column to create the complete set of columns for rendering
const columns = [...dynamicColumns, actionColumn]; // Spread operator to include all dynamic columns and append the actionColumn

  // Delete an item from the table

  async function deleteRowById(id: number) {
      // Create payload containing the ID and authentication details

    const payload = {
      args: JSON.stringify({
        id: id,
      }),
      app_id: appId,
      api_key: loginUser?.api_key,
    };

    try {
      setLoading(true); // Set loading state to true while the API call is in progress

      // Make an API call to delete the row by ID
        await centralApi(
        "GET",
        API_ENDPOINTS.DELETE_CUSTOMER_360_MAPPING_ROW_BY_ID,
        null,
        payload
      );
      showSuccess(t("sucessfullyDeletedLabel")); // Show success message after deletion
    setLoading(false); // Set loading state to false after the API call completes

    // Refresh the customer 360 mapping data to reflect the changes
    getCustomer360mapping({
        app_id: appId,
        api_key: loginUser.api_key,
      });
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap my-3">
        <h4 className="fw-semi-bold">{t("c360dataMappingLabel")}</h4>
      </div>
      <div className=" data-transformation-container">
        <div
          className={`${
            tableData.length == 0 ? "data-transformation-container-table" : ""
          } data-transformation-body p-3 mt-3`}
        >
              {dynamicColumns.length === 0 ? <Spinner/> :
          <CustomTable
            dataSource={tableData}
            columns={columns}
            loading={loading}
            rowKey="id"
            size="small"
            scroll={{ x: 2500, y: 500 }}     
          /> 
          }
        </div>
      </div>
    </div>
  );
};
export default DataTransformation;
