import { FC, useEffect, useState } from "react";
import { Col, Form, Row, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import { v4 as uuid } from "uuid";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector from "components/Selector/Selector";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./MobilePushNotification.css";
import { validateImageDimensions } from "helperFunctions/file";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import CampaignDevice from "components/CampaignDevice/CampaignDevice";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { setCurrentStep } from "redux/features/campaign/campaignCreateCurrentStepSlice";
import { setCampaignData } from "redux/features/campaign/campaignDataSlice";
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import useMessage from "hooks/useMessage";
import CustomCollapse from "components/CustomCollapse/CustomCollapse";
import { t } from "i18next";
import PushContainer from "../../NotificationContainer/PushContainer/PushContainer";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import VariantList from "../../VariantList/VariantList";
import { ActionButtons } from "components/Buttons/ActionButtons/ActionsButtons";
import { fetchTemplateMetaData } from "redux/features/templates_/variantsSlice";
import { formatDate, getCurrentDate } from "helperFunctions/date";
import Personalization from "../../Personalization/Personalization";
import { validateName } from "services/validateName";
import { CustomData } from "../../CustomData/CustomData";
import { pushActionButtonType } from "@customTypes/template";
import { Actions } from "utils/models";
import { getToken } from "redux/features/app/authTokenSlice";
import { CampaignChannelTypeEnum, CustomDataEnum, RTLLanguageEnum } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
interface MobilePushNotificationProps {}

const MobilePushNotification: FC<MobilePushNotificationProps> = () => {
  const andIntial = {
    b: "",
    i: [],
    d: 0,
    l: 0,
    g: 0,
    f: "",
    m: "",
  };
  const { appId } = useSelector((state: any) => state.activeApp);
  const [personalization, setPersonalization] = useState({
    open: false,
    field: "",
  });
  const [image, setImage] = useState({
    notificationImage: { loading: false },
    expandedNotificationImage: { loading: false },
  });
  const [deviceType, setDeviceType] = useState<"IOS" | "Android">("Android");
  const [form] = useForm();
  const [variants, setVariants] = useState<any>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const templates = useSelector((state: any) => state.templatesList.data);
  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features;
  const charLimit = features[0]?.pushNotificationCharLimit;

  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const languageVariants = useSelector((state: any) => state.templateMetaData.data);
  const authToken = useSelector((state: any) => state.authToken.token);

const{DEVICE,IMAGE}=CustomDataEnum;
  const [showDrawer, setShowDrawer] = useState(false);
  const [customDataState, setCustomDataState] = useState<any>({});
  const [template, setTemplate] = useState({
    template_name: "",
    template_type: "PUSH",
    isTemplateDeleted: "false",
  });
  const { showError } = useMessage();
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const [blobContainerCDN, setBlobContainerCDN] = useState(
    campaignData?.data?.blobContainerCDN || ""
  );
  const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change

  const dispatch: any = useDispatch();
  const [formData, setFormData] = useState<any>({
    actions: [],
    notificationType: "dl",
    cdata: {},
    notificationImage: "",
    notificationDescription: "",
    expandedNotificationDescription: "",
    expandedNotificationImage: "",
    notificationHeader: "",
    externalUrl: "",
    pushType: "",
    and: andIntial,
    variableInfo: undefined,
    sound: false,
    vibrate: false,
    badge: false,
    category:"",
    mediaType:""
  });
  //set custom data in form state
  const saveCdata = () => {
    const cdata: any = [];

    Object.values(customDataState).forEach((value: any) => {
      if (value.value && value.key) {
        const dataObject = {
          [value.key]: value.value,
          type: value.type === DEVICE ? IMAGE:value?.type,

        };
        cdata.push(dataObject);
      }
    });
    setFormData((prevState: any) => ({ ...prevState, cdata }));
  };
  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: selectedTemplate._id,
          t: selectedTemplate.template_type,
        })
      );
      if (Array.isArray(selectedTemplate?.template)) {
        setVariants(selectedTemplate.template);
        setSelectedVariant(selectedTemplate.template[0]);
      } else {
        setVariants([]);
        setSelectedVariant(null);
      }
    }
  }, [selectedTemplate]);
  useEffect(() => {
    const { template, template_name, template_type, blobContainerCDN } =
      selectedTemplate || campaignData.data || formData;
    if (blobContainerCDN) {
      setBlobContainerCDN(blobContainerCDN);
    }
    if (template || selectedVariant) {
      const {
        notificationDescription,
        notificationHeader,
        expandedNotificationDescription,
        expandedNotificationImage,
        notificationType,
        notificationImage,
        pushType,
        externalUrl,
        variableInfo,
        cdata,
        badge,
        actions,
        sound,
        vibrate,
        and,
        ty,
        category,
        mediaType,
      } =
        Array.isArray(template) || selectedVariant
          ? selectedVariant
            ? selectedVariant
            : template[0]
          : template
          ? template
          : formData;
      setFormData((prevState: any) => ({
        ...prevState,
        notificationDescription,
        notificationHeader,
        notificationImage,
        expandedNotificationDescription,
        expandedNotificationImage,
        notificationType,
        pushType,
        variableInfo,
        externalUrl,
        actions,
        badge,
        sound,
        vibrate,
        cdata,
        ty,
        category,
        mediaType
      }));
      if (template_name) {
        setTemplate((prevState) => ({
          ...prevState,
          template_name,
          template_type,
        }));
      }
      setFormData((prevState: any) => ({
        ...prevState,
        and: and ? and : andIntial,
      }));
     
      if (cdata) {
        const custom_data: any = {};
        if (Array.isArray(cdata)) {
          // Handle array of objects
          cdata.forEach((obj: any) => {
            const [key, value] = Object.entries(obj)[0]; // Destructuring to get the first key-value pair
            custom_data[uuid()] = {
              key: key, // Key obtained from destructuring
              value: value, // Value obtained from destructuring
              type: obj?.type || "", // Assuming obj has a property called 'type'
            };
          });
        } else if (typeof cdata === "object" && cdata !== null) {
          // Handle plain object
          Object.keys(cdata).forEach((key: string) => {
            custom_data[uuid()] = { key, value: cdata[key] };
          });
        }
        setCustomDataState(custom_data);
      }
    }
    setOldTemplateName(template_name); // save template name
  }, [selectedTemplate, selectedVariant, campaignData]);
  //upload image and validate dimensions
  const handleImageUplaod = async (
    value: any,
    type: "notificationImage" | "expandedNotificationImage"
  ) => {
    try {
      const { valid, message } = await validateImageDimensions(
        type == "expandedNotificationImage" ? "pushExpanded" : "pushHeader",
        value.file
      );
      if (valid && appId) {
        //upload image
        setImage((prevState: any) => ({
          ...prevState,
          [type]: {
            loading: true,
          },
        }));
        const formData = new FormData();
        formData.append("image", value.file);
        formData.append("image_id", value.file.name);
        formData.append("appid", appId);
        const res = await centralApi(
          "POST",
          API_ENDPOINTS.SAVE_CAMPAIGN_IMAGES,
          formData,
          null,
          false,
          authToken
        );
        dispatch(getToken())
        setTimeout(() => {
          setBlobContainerCDN(res.baseURL);
          setFormData((prevState: any) => ({
            ...prevState,
            [type]: "/" + res.url,
          }));
            if ((res.type === "gif" || res.type=="mp4") && type === "expandedNotificationImage") {
             if (res.type=="mp4"){
             setFormData((prevState:any)=>({
              ...prevState,
             mediaType:"video"
               }))
              }
            setFormData((prevState: any) => ({
              ...prevState,
              and: {
                ...prevState?.and,
                i: res.imgs,
                b: `${res.baseURL}`,
                m: `${res.baseURL + res.url}`,
                f: res.fe,
              },
            }));
          } 
         else if ((res.type !== "gif" || res.type !=="mp4") && type !== "expandedNotificationImage") {

            setFormData((prevState: any) => ({
              ...prevState,
              and: andIntial,
            }));
          }
          setImage((prevState: any) => ({
            ...prevState,
            [type]: {
              loading: false,
            },
          }));
        }, 600);
      } else {
        showError(message);
      }
    } catch (error) {
      setImage((prevState: any) => ({
        ...prevState,
        [type]: {
          loading: false,
        },
      }));
    }
  };
  useEffect(() => {
    dispatch(
      fetchTemplatesList({
        app_id: appId,
        api_key: loginUser.api_key,
        template_type: CampaignChannelTypeEnum.PUSH,
      })
    );
    dispatch(
      fetchTemplateMetaData({
        app_id: appId,
        api_key: loginUser.api_key,
        args: {},
      })
    );
    dispatch(getToken())
    if (campaignData.data) {
      dispatch(setSelectedTemplate(campaignData.data));
    }
  }, []);
  useEffect(() => {
    if (formData.actions?.length > 0) {
      setFormData((prevState: any) => ({
        ...prevState,
        cdata: {},
      }));
    }
  }, [formData.actions]);
  const handleSaveOrUpdate = async () => {
    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed if same template selected and name not changed then dont call api
      if (template.template_name !== oldTemplateName) {
        try {
          //name validation API
          isValidTemplateName = await validateName(
            template.template_name,
            CampaignChannelTypeEnum.PUSH,
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
      showError(t("somethingWrongLabel"));
        }
      }

      // Validate the form fields for checking template name exists or not
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")], //show error
          },
        ]);
      } else {
        //check other fields validation
        await form.validateFields();
        
        const payload = {
          api_key: loginUser.api_key,
          app_id: appId,
          authToken,
          template_id: selectedTemplate ? selectedTemplate._id : undefined, //template id if template is selected otherwise undefined
          args: JSON.stringify({
            ...template,
            template: variants.length > 0 ? variants : formData,

                createdOn: selectedTemplate
                  ? undefined
                  : formatDate(getCurrentDate(), "MM/DD/YYYY"), // if new template then add created on
                modifiedOn: selectedTemplate //if existing template set modified on
                  ? formatDate(getCurrentDate(), "MM/DD/YYYY")
                  : undefined,
       
          }),
        };
       
        // Determine the endpoint and method based on the presence of a selected template
        const endpoint = selectedTemplate
          ? API_ENDPOINTS.UPDATE_CAMPAIGN_TEMPLATE
          : API_ENDPOINTS.CREATE_CAMPAIGN_TEMPLATE;
        //call the Api with the selected endpoint
        const response = await centralApi("POST", endpoint, payload);
      // response from API setting its value in redux 
        dispatch(
          setCampaignData({
            ...campaignData,
            data: { ...response, blobContainerCDN },

            tid: response._id,
            t: response.template_type,
          })
        );
        dispatch(getToken())

        //moving to next step
        dispatch(setCurrentStep(currentStep + 1));
     
    
      }
    } catch (errors) {

    dispatch(getToken())
      showError(t("somethingWrongLabel"));
    }
  };
  useEffect(() => {
    if (selectedVariant) {
      const modifiedVariants: any = [];
      variants.forEach((variant: any) => {
        if (variant.variance_id == selectedVariant.variance_id) {
          let targetVariant = variant;
          targetVariant = { ...targetVariant, ...formData };
          modifiedVariants.push(targetVariant);
        } else {
          modifiedVariants.push(variant);
        }
      });
      setVariants(modifiedVariants);
    }
  }, [formData, selectedVariant]);
  const handleCreateVariant = async (
    type: "variant" | "language" = "variant",
    language: any = {}
  ) => {
    const payload = {
      app_id: appId,
      api_key: loginUser.api_key,
    };
    const id =
      type == "variant"
        ? await centralApi("GET", "o/metrices/getObjectId", null, payload)
        : language?._id;
    const variance_top_name =
      type == "variant"
        ? `Variant ${
            variants.filter((variant: any) =>
              variant?.variance_top_name.includes("Variant")
            ).length + 1
          }`
        : language?.name;
    const payloadData: any = {
      variance_top_name,
      variance_id: id,
      variant_type: "Multivariate",
      lang_id:language?.lang_id,
      ...formData,
      variance_name: template.template_name,
    };
    setVariants((prevState: any) => [...prevState, payloadData]);
  };
  const handleDeleteVariant = (id: string) => {
    const afterVariantDelete = variants.filter(
      (variant: any) => variant.variance_id !== id
    );
    setVariants(afterVariantDelete);
  };
  const handleVariantClick = (id: string) => {
    variants.forEach((variant: any) => {
      if (variant.variance_id == id) {
        setSelectedVariant(variant);
      }
    });
  };
/**
 * Transforms an array of actions either from PushActionButtonType to Actions (for sending)
 * or from Actions to PushActionButtonType (for receiving), based on the isSend flag
 */
  function transformActions(
    actions: pushActionButtonType[] | Actions[],
    isSend: boolean
  ): any[] {
    // return empty array if incoming data is not array
    if (!Array.isArray(actions)) {
      return []
    }
    return actions.map(action => {
      if (isSend) {
        // Since we're sending, the input type must be pushActionButtonType
        const originalAction = action as pushActionButtonType;
        return {
          abt: originalAction.actionName,
          act: originalAction.actionType,
          acu: originalAction.actionExternalUrl
        };
      } else {
        // Since we're receiving, the input type must be Actions
        const transformedAction = action as Actions;
        return {
          actionName: transformedAction.abt,
          actionType: transformedAction.act,
          actionExternalUrl: transformedAction.acu
        };
      }
    });
  }



  const isRtl =
  selectedVariant?.variance_top_name === RTLLanguageEnum.ARABIC ||
  selectedVariant?.variance_top_name === RTLLanguageEnum.URDU;

  useEffect(() => {
      form.setFieldsValue({
        templateName: template.template_name,
        notificationHeader: formData?.notificationHeader,
        notificationDescription: formData?.notificationDescription,
      });
  }, [template,formData]);

  return (
    <div className="mobile-push-parent-container container-fluid  ">
      <Personalization
        personalizationState={personalization}
        setFormData={setFormData}
        setPersonalizationState={setPersonalization}
      />
      <CustomDrawer
        open={showDrawer}
        placement="right"
        onClose={() => {
          setShowDrawer(false);
        }}
        className="p-0"
        closable={false}
        width={540}
        footer={
          <Space className="d-flex justify-content-end">
            <PrimaryButton
              onClick={() => {
                setShowDrawer(false);
              }}
            >
              {t("cancelLabel")}
            </PrimaryButton>
            <PrimaryButton
              type="primary"
              onClick={() => {
                saveCdata();
                setShowDrawer(false);
              }}
            >
              {t("saveLabel")}
            </PrimaryButton>
          </Space>
        }
        height={"90%"}
      >
        
        <CustomData
          customDataState={customDataState}
          setCustomDataState={setCustomDataState}
        />
      </CustomDrawer>
      <Form
        colon={false}
        labelAlign="left"
        labelCol={{ flex: "180px" }}
        layout="horizontal"
        form={form}
      >
        <TemplateLayout
          leftItems={
            <CampaignDevice
              setDevice={(value: "IOS" | "Android") => {
                setDeviceType(value);
              }}
            >
              <div className="h-100">
                <PushContainer
                  type={deviceType}
                  headerText={formData?.notificationHeader}
                  descriptionText={formData?.notificationDescription}
                  uploadImage={handleImageUplaod}
                  buttons={transformActions(formData?.actions,false)}
                  variableInfo={formData?.variableInfo}
                  headerImage={
                    formData.notificationImage
                      ? `${blobContainerCDN + formData.notificationImage}`
                      : ""
                  }
                  isExpandedImage={formData.pushType == "expanded"}
                  expandedImage={
                    formData.expandedNotificationImage
                      ? `${
                          blobContainerCDN + formData.expandedNotificationImage
                        }`
                      : ""
                  }
                  expandedDescription={
                    formData?.expandedNotificationDescription
                  }
                  imageLoading={image}
                  mediaType={formData.mediaType}
                />
              </div>
            </CampaignDevice>
          }
          rightItems={
            <div>
              <Row className="pt-2">
                <Col span={24}>
                  <h5 className="mt-0">{t("pushCampaignLabel")}</h5>
                </Col>
                <Col className="d-flex gap-1 justify-content-between" span={24}>
                  <FormItem
                    className="my-0 w-100"
                    name={"template_name"}
                    label=""
                  >
                    <Selector
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                  <div className="d-flex gap-1  align-items-center">
                    {!featuresSetting?.ab_testing_variant && (
                      <Selector
                        style={{ width: 110 }}
                        onChange={handleVariantClick}
                        disabled={
                          !template.template_name || variants.length >= 6
                        }
                        value={
                          selectedVariant?.variance_top_name?.includes(
                            "Variant"
                          ) && selectedVariant?.variance_id
                        }
                        placeholder={t("variantLabel")}
                        dropdownRender={(menu) => (
                          <>
                            <PrimaryButton
                              type="link"
                              className="w-100"
                              onClick={() => handleCreateVariant()}
                            >
                              <PlusOutlined />
                              <span className="ms-2 me-3">Create</span>
                            </PrimaryButton>
                            {menu}
                          </>
                        )}
                        options={variants
                          ?.filter((variant: any) =>
                            variant?.variance_top_name?.includes("Variant")
                          )
                          .map((variant: any) => ({
                            label: variant.variance_top_name,
                            value: variant?.variance_id,
                          }))}
                      />
                    )}
                    {!featuresSetting?.language_variant && (
                       <Selector
                       style={{ width: 110 }}
                       disabled={
                         !template.template_name || variants.length >= 6
                       }
                       onChange={(jsonData) => {
                         const language  = JSON.parse(jsonData)
                         if (selectedVariant && selectedVariant.variance_top_name.startsWith("Variant")) {
                           setVariants((prevVariants: any[]) => {
                             return prevVariants.map((variant) =>
                               variant.variance_top_name === selectedVariant.variance_top_name
                                 ? { ...variant, variance_language: language.name }
                                 : variant
                             );
                           });
                         } else {
                           if(!variants.some((variant : any)=>{
                             return variant.variance_id == language._id
                           })){
                             handleCreateVariant("language", language);
                           }   
                         }
                       }}
                       options={languageVariants?.varaints?.map((langVariant: any) => ({
                         label: langVariant.name,
                         value: JSON.stringify(langVariant),
                       }))}
                       placeholder={t("languageLabel")}
                     />
                    )}
                  </div>
                </Col>
                <Col
                  span={24}
                  className="d-flex aligm-items-center justify-content-between pt-2"
                >
                  <VariantList
                    variants={variants}
                    selectedVariant={selectedVariant}
                    handleDeleteVariant={handleDeleteVariant}
                    handleVariantClick={handleVariantClick}
                  />
                </Col>
              </Row>

              <Row className="pt-2 pb-2 position-relative d-flex justify-content-center">
                <Col className="d-flex gap-1 " span={24}>
                  <FormItem
                    initialValue={template.template_name}
                    className="w-100 m-0 mt-2  "
                    label={t("templateNameLabel")}
                    name={"templateName"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}
                    ></TextInput>
                  </FormItem>
                </Col>
                { !featuresSetting?.embedCustomField && (

                <div className="d-flex pt-2 w-100 justify-content-end">
                  <MoreOutlined
                    onClick={() => {
                      setPersonalization({
                        open: true,
                        field: "notificationHeader",
                      });
                    }}
                  />
                </div>)}
                <Col className="d-flex gap-1 mt-2 my-0 " span={24}>
                  <FormItem
                    className="m-0 w-100"
                    label={t("headerLabel")}
                    initialValue={formData.notificationHeader}
                    name="notificationHeader"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      maxLength={charLimit?.hl}
                      showCount
                      value={formData.notificationHeader}
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          notificationHeader: e.target.value,
                        }));
                      }}
                      placeholder={t("pushNotificationHeaderLabel")}
                      dir={isRtl ? "rtl" : "ltr"}

                    />
                  </FormItem>
                </Col>
                { !featuresSetting?.embedCustomField && (

                <div className="d-flex pt-2 w-100 justify-content-end">
                  <MoreOutlined
                    onClick={() => {
                      setPersonalization({
                        open: true,
                        field: "notificationDescription",
                      });
                    }}
                  />
                </div>)}
                <Col className="d-flex  mt-2 " span={24}>
                  <FormItem
                    initialValue={formData.notificationDescription}
                    className=" w-100  mx-0"
                    label={t("descriptionLabel")}
                    name="notificationDescription"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <CustomTextArea
                      value={formData.notificationDescription}
                      maxLength={charLimit?.dl}
                      showCount
                      style={{
                        height: 70,
                        color: "black",
                        resize: "none",
                      }}
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          notificationDescription: e.target.value,
                        }));
                      }}
                      placeholder={`${t("pushNotificationDescriptionLabel")}`}
                             dir={isRtl ? "rtl" : "ltr"}

                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem className="m-0 my-2" label={t("expandedTextLabel")}>
                    <SegmentedCustom
                      value={formData.pushType !== "expanded"}
                      onChange={(value: boolean) => {
                        setFormData((prevState: any) => {
                          return {
                            ...prevState,
                            pushType: value ? "" : "expanded",
                          };
                        });
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    className="m-0 my-2"
                    label={t("expandedImageLabel")}
                  >
                    <SegmentedCustom
                      value={formData.pushType == "expanded"}
                      onChange={(value: boolean) => {
                        setFormData((prevState: any) => {
                          return {
                            ...prevState,
                            pushType: value ? "expanded" : "",
                            expandedNotificationImage: "",
                            expandedNotificationDescription: "",
                            and: andIntial,
                          };
                        });
                      }}
                    />
                  </FormItem>
                </Col>
                {formData.pushType !== "expanded" && (
                  <Col className="d-flex " span={24}>
                    <FormItem
                      className=" w-100  mx-0"
                      label={t("expandedTextDescriptionLabel")}
                    >
                      <CustomTextArea
                        maxLength={charLimit?.edl}
                        showCount
                        style={{
                          height: 70,
                          color: "black",
                          resize: "none",
                        }}
                        value={formData.expandedNotificationDescription}
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            expandedNotificationDescription: e.target.value,
                          }));
                        }}
                        placeholder={`${t("expandedTextDescriptionLabel")}`}
                        dir={isRtl ? "rtl" : "ltr"}
                        />
                    </FormItem>
                  </Col>
                )}
                   <Col className="d-flex " span={24}>
                    <FormItem
                      className=" w-100  mx-0"
                      label={t("categoryLabel")}
                    >
                      <TextInput
                        
                        value={formData.category}
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            category: e.target.value,
                          }));
                        }}
                        placeholder={`${t("categoryLabel")}`}
                        />
                    </FormItem>
                  </Col>
                <Col className="pb-2" span={24}>
                  <FormItem
                    className="m-0 "
                    style={{ minWidth: 300 }}
                    label={t("buttonsLabel")}
                  >
                    <SegmentedCustom
                      value={formData.actions?.length > 0}
                      onChange={(value: boolean) => {
                        setFormData((prevState: any) => {
                          return {
                            ...prevState,
                            actions: value
                              ? [
                                  {
                                    actionName: "",
                                    actionExternalUrl: "",
                                    actionType: "",
                                  },
                                ]
                              : [],
                          };
                        });
                      }}
                    />
                    {formData.actions?.length > 0 && (
                      <div className={"my-2"}>
                        <ActionButtons
                          maxButtons={3}
                          type={CampaignChannelTypeEnum.PUSH}
                          buttonsState={transformActions(formData.actions,false)}
                          setButtonsState={(value:pushActionButtonType[]) =>
                            setFormData((prevState: any) => ({
                              ...prevState,
                              actions: transformActions(value,true),
                            }))
                          }
                        />
                      </div>
                    )}
                  </FormItem>
                </Col>
                <CustomCollapse
                  props={{ className: "w-100" }}
                  header={t("advanceSettingLabel")}
                  body={
                    <>
                      <Col span={24}>
                        {formData?.and && formData?.and?.m?.length > 0 ? (
                          <>
                            <FormItem
                              className="m-0 mt-2"
                              label={t("imageSlideDifferenceLabel")}
                            >
                              <TextInput
                                onChange={(e: any) => {
                                  setFormData((prevState: any) => ({
                                    ...prevState,
                                    and: {
                                      ...prevState.and,
                                      d: e.target.value,
                                    },
                                  }));
                                }}
                                value={formData?.and?.d}
                                className="w-25"
                              />
                            </FormItem>
                            <FormItem
                              className="m-0 mt-2"
                              label={t("timeLoopLabel")}
                            >
                              <TextInput
                                onChange={(e: any) => {
                                  setFormData((prevState: any) => ({
                                    ...prevState,
                                    and: {
                                      ...prevState.and,
                                      l: e.target.value,
                                    },
                                  }));
                                }}
                                value={formData?.and?.l}
                                className="w-25"
                              />
                            </FormItem>{" "}
                            <FormItem
                              className="m-0 mt-2"
                              label={t("timeGapLabel")}
                            >
                              <TextInput
                                onChange={(e: any) => {
                                  setFormData((prevState: any) => ({
                                    ...prevState,
                                    and: {
                                      ...prevState.and,
                                      g: e.target.value,
                                    },
                                  }));
                                }}
                                value={formData?.and?.g}
                                className="w-25"
                              />
                            </FormItem>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>

                     
                       { !featuresSetting?.deepLinkUrl && (
                        <>
                                               { !featuresSetting?.embedCustomField && (

                          <div className="d-flex  w-100 justify-content-end">
                            <MoreOutlined
                              onClick={() => {
                                setPersonalization({
                                  open: true,
                                  field: "externalUrl",
                                });
                              }}
                            />
                          </div>)}
                          <Col className="d-flex" span={24}>
                            <FormItem
                              className="w-100 m-0 mt-2"
                              label={t("urlLabel")}
                              initialValue={formData.externalUrl}
                              name={"externalUrl"}
                            >
                              <TextInput
                                onChange={(e: any) =>
                                  setFormData((prevState: any) => ({
                                    ...prevState,
                                    externalUrl: e.target.value,
                                  }))
                                }
                                placeholder={t("externalURL/deepLinkURLLabel")}
                              />
                            </FormItem>
                          </Col></>)}
                          <Col className="" span={24}>
                            <div className="d-flex flex-wrap justify-content-between w-100">
                              <FormItem
                                className="m-0 w-100 mt-2"
                                label={t("URLTypeLabel")}
                              >
                                <SegmentedCustom
                                  value={formData.notificationType}
                                  onChange={(value: "lp" | "dl") => {
                                    setFormData((prevState: any) => ({
                                      ...prevState,
                                      notificationType: value,
                                    }));
                                  }}
                                  options={[
                                    {
                                      label: t("landingPageLabel"),
                                      value: "lp",
                                    },
                                    { label: t("deepLinkLabel"), value: "dl" },
                                  ]}
                                />
                              </FormItem>
                              {formData.notificationType == "lp" && !featuresSetting?.landingPageView &&(
                                <FormItem
                                  className=" my-0 mt-2"
                                  label={t("openInLabel")}
                                >
                                  <SegmentedCustom
                                    options={[
                                      {
                                        label: t("browserLabel"),
                                        value: "browser",
                                      },
                                      {
                                        label: t("webViewLabel"),
                                        value: "webview",
                                      },
                                    ]}
                                  />
                                </FormItem>
                              )}
                            </div>
                            { !featuresSetting?.actionbuttonSettings && (

                            <Col className="mt-2" span={24}>
                              <FormItem
                                className="my-0  w-100"
                                label={t("customDataLabel")}
                              >
                                <div className="d-flex gap-2">
                                  <SegmentedCustom
                                    value={
                                      Object.keys(customDataState)?.length > 0
                                    }
                                    onChange={(value: boolean) => {
                                      setCustomDataState((prevState: any) =>
                                        value
                                          ? {
                                              [uuid()]: {
                                                key: "",
                                                type:"string",
                                                value: "",
                                              },
                                            }
                                          : {}
                                      );
                                      !value &&
                                        setFormData((prevState: any) => {
                                          return { ...prevState, cdata: {} };
                                        });
                                    }}
                                  />
                                  {Object.keys(customDataState).length > 0 && (
                                    <PrimaryButton
                                      onClick={() => {
                                        setShowDrawer(true);
                                      }}
                                      style={{
                                        color: "white",
                                        background: "var( --color-red-main)",
                                      }}
                                    >
                                      {t("addLabel")}
                                    </PrimaryButton>
                                  )}
                                </div>
                              </FormItem>
                            </Col>)}
                          </Col>
                        
                                             { !featuresSetting?.specialattributes && (

                      <Col className="mt-2" span={24}>
                        <FormItem label="Special Attributes">
                          <div className="d-flex justify-content-between">
                            <div>
                              <p className="m-0 p-0 ant-form-item-label">
                                {t("soundLabel")}
                              </p>
                              <SegmentedCustom
                                onChange={(value: boolean) => {
                                  setFormData((prevState: any) => ({
                                    ...prevState,
                                    sound: value,
                                  }));
                                }}
                                value={formData.sound}
                              />
                            </div>
                            <div>
                              <p className="m-0 p-0 ant-form-item-label">
                                {t("vibrateLabel")}
                              </p>
                              <SegmentedCustom
                                onChange={(value: boolean) => {
                                  setFormData((prevState: any) => ({
                                    ...prevState,
                                    vibrate: value,
                                  }));
                                }}
                                value={formData.vibrate}
                              />
                            </div>
                            <div>
                              <p className="m-0 p-0 ant-form-item-label">
                                {t("incrementBadgeLabel")}
                              </p>
                              <SegmentedCustom
                                onChange={(value: boolean) => {
                                  setFormData((prevState: any) => ({
                                    ...prevState,
                                    badge: value,
                                  }));
                                }}
                                value={formData.badge}
                              />
                            </div>
                          </div>
                        </FormItem>
                      </Col>)}
                    </>
                  }
                ></CustomCollapse>
              </Row>
            </div>
          }
          footerItem={
            <Row className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                disabled={!template.template_name}
                type="primary"
                htmlType="submit"
                onClick={handleSaveOrUpdate}
              >
                {selectedTemplate ? t("updateLabel") : t("saveLabel")}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
                onClick={() => {
                  dispatch(setCampaignData({}));
                  dispatch(setCurrentStep(0));
                }}
              >
                {t("closeLabel")}
              </PrimaryButton>
            </Row>
          }
        />
      </Form>
    </div>
  );
};
export default MobilePushNotification;
