import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { STATUS } from "../../constant"
import centralApi from "../../../services/centralApi"
import { decryptData } from "helperFunctions/common";

export const getFeaturesSettings = createAsyncThunk(
  "app/featuresSetting",
  async ({api_key, app_id}:any) => {
    const params = {
      args:JSON.stringify({
        app_id
      }),
      api_key,
      app_id,
    };
    return centralApi(
      "GET",
      "/o/apps/getFeaturesSettings",
      null,
      params
    ).then((data) => {
      const updatedData = data.map((doc: any) => {
          return decryptData(params.api_key,doc)
      });
      return updatedData
    })
  }
)
const initialState: any = {
  data: [],
  status: STATUS.IDLE,
}
const featuresSettingSlice = createSlice({
  name: "featuresSetting",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFeaturesSettings.pending, (state) => {
      state.status = STATUS.LOADING
    })
    builder.addCase(getFeaturesSettings.fulfilled, (state, action) => {
      state.status = STATUS.IDLE
      state.data = action.payload
    })
    builder.addCase(getFeaturesSettings.rejected, (state) => {
      state.status = STATUS.ERROR
    })
  },
    reducers: {
      setFeatureSettings(state, action) { 
        state.data[0].features = {...state.data[0].features , ...action.payload} 
      },
    }
})
export const {setFeatureSettings} = featuresSettingSlice.actions
export default featuresSettingSlice.reducer
