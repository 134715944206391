import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserCard from "../../../components/Cards/UserCard/UserCard";
import "./AppDashboard.css";
import Selector from "../../../components/Selector/Selector";
import { DonutPieChart } from "../../../components/Charts/DonutPieChart/DonutPieChart";
import { useDispatch, useSelector } from "react-redux";
import { DateRangeData } from "@customTypes/dateRange";

import {
  getActiveUserTimelySessions,
  getDauWauMau,
  getDauWauMauWithPlatform,
  getEventsStats,
  getTotalStats,
} from "../../../helper/metrices";
import { pieData } from "./pieData";
import { getTopTenEvents } from "../../../helperFunctions/events";
import {
  formateIntoKorM,
  getPercentage,
} from "../../../helperFunctions/number";
import {
  convertDateToAbbreviation,
  formatDate,
} from "../../../helperFunctions/date";
import { setActiveApp } from "../../../redux/features/app/activeAppSlice";
import { Helmet } from "react-helmet";
import { Cohort } from "./Cohort/Cohort";
import { concatDayWiseDauDuplicateDate } from "../../../helperFunctions/common";
import CustomTable from "../../../components/CustomTable/CustomTable";
import Modals from "./Modals";
import CustomHighChart from "../../../components/Charts/CustomHighChart/CustomHighChart";
import { colors } from "../../../components/Charts/CustomHighChart/data";
import SkeletonLoader from "components/SkeletonLoader/SkeletonLoader";
import { logoutUser } from "redux/features/user/loginUserSlice";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { t } from "i18next";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { roleCheckTypes } from "utils/constants/constants";

type percentageType = {
  newUser: {
    percentage: string;
    status: 0 | 1 | -1;
  };
  activeUser: {
    percentage: string;
    status: 0 | 1 | -1;
  };
  engagement: {
    percentage: string;
    status: 0 | 1 | -1;
  };
  churn: {
    percentage: string;
    status: 0 | 1 | -1;
  };
};
function Dashboard() {
  const [activeUserTimelySessions, setActiveUserTimelySessions] =
    useState<any>();
  const dispatch: any = useDispatch();
  const {name:appname} = useSelector((state: any) => state.featuresSetting?.data?.[0] || {});
  const [dauWauMau, setDauWauMau] = useState<any>();
  const [totalStats, setTotalStats] = useState<any>();
  const [lineData, setLineData] = useState<any>();
  const [topTenEvents, setTopTenEvents] = useState<any>();
  const [eventsStats, setEventsStats] = useState<any>();
  const [cardPercentages, setCardPercentages] = useState<percentageType>({
    newUser: {
      percentage: "",
      status: 0,
    },
    activeUser: {
      percentage: "",
      status: 0,
    },
    engagement: {
      percentage: "",
      status: 0,
    },
    churn: {
      percentage: "",
      status: 0,
    },
  });
  
  const [platform, setPlatform] =
    useState<"all" | "android" | "ios" | "web">("all");

  // const dateRange = useSelector((state: any) => state.dateRange);
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);

  const location = useLocation();
  const styleBtn = {
    width: "auto",
    marginRight: "10px",
    marginBottom: "10px",
  };
  const searchParams = new URLSearchParams(location.search);
  const app_id = searchParams.get("id");
  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };

  const fetchDauWauMau = async () => {
    const apiKey = loginUser.data.api_key;
    if (loginUser && apiKey && app_id) {
      const dauWauMauData =
        platform === "all"
          ? await getDauWauMau({ value: dateRange, apiKey, appId: app_id })
          : await getDauWauMauWithPlatform({
              value: dateRange,
              whichPlatform: platform,
              apiKey,
              appId: app_id,
            });
      const new_data =
        platform === "all"
          ? dauWauMauData
          : {
              ...dauWauMauData[platform],
              activeCmp: dauWauMauData?.activeCamp,
              totalCmp: dauWauMauData?.totalCmp,
            };
      setDauWauMau(new_data);
      setLineData(concatDayWiseDauDuplicateDate(new_data?.daywisedau));
    }
  };
  // const { app_id } = useParams();
  //call api methods
  const apiCalls = async (value: any) => {
    try {
      setLoading(true);
      const apiKey = loginUser.data.api_key;
      if (loginUser && apiKey && app_id) {
        const promises = [];
        promises.push(
          getActiveUserTimelySessions({
            value,
            apiKey,
            appId: app_id,
          }),
          getEventsStats({ value, apiKey, appId: app_id }),
          getTotalStats({ value, apiKey, appId: app_id })
        );

        const [timelySessionData, eventsStats, totalStats] = await Promise.all(
          promises
        );
        setTotalStats(totalStats);
        setEventsStats(() => eventsStats);
        const top_ten_events = getTopTenEvents(eventsStats, platform);
        setTopTenEvents(top_ten_events);
        setActiveUserTimelySessions(timelySessionData);
        
        dispatch(setActiveApp({ ...activeApp, appId: app_id }));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      dispatch(logoutUser());
    }
  };
  useEffect(() => {
    if (dateRange !== null) {
      apiCalls(dateRange);
    }
  }, [loginUser, dateRange, app_id]);
  useEffect(() => {
    if (dateRange !== null) {
      fetchDauWauMau();
    }
  }, [loginUser, dateRange, platform, app_id]);
  useEffect(() => {
    const top_ten_events = getTopTenEvents(eventsStats, platform);
    setTopTenEvents(top_ten_events);
  }, [eventsStats, platform]);
  const previousInstallUninstallTotal = (type: "I" | "U") => {
    if (platform == "all") {
      return (
        totalStats?.previous?.[`android${type == "I" ? "Total" : "U"}`] +
          totalStats?.previous?.[`ios${type == "I" ? "Total" : "U"}`] +
          totalStats?.previous?.[`web${type == "I" ? "Total" : "U"}`] || 0
      );
    } else {
      return (
        totalStats?.previous?.[`${platform + type == "I" ? "Total" : "U"}`] || 0
      );
    }
  };
  const currentInstallUninstallTotal = (type: "I" | "U") => {
    if (platform == "all") {
      return (
        totalStats?.current?.[`androidTotal${type == "I" ? "" : "U"}`] +
          totalStats?.current?.[`iosTotal${type == "I" ? "" : "U"}`] +
          totalStats?.current?.[`webTotal${type == "I" ? "" : "U"}`] || 0
      );
    } else {
      return (
        totalStats?.current?.[`${platform}Total${type == "I" ? "" : "U"}`] || 0
      );
    }
  };
  useEffect(() => {
    const newUser = getPercentage(
      previousInstallUninstallTotal("I"),
      currentInstallUninstallTotal("I")
    );
    const activeUser = getPercentage(
      activeUserTimelySessions?.previous?.previousTotal,
      activeUserTimelySessions?.activeUsers
    );
    const engagement = getPercentage(
      activeUserTimelySessions?.previous.previousTotal,
      activeUserTimelySessions?.currentTotal
    );
    const churn = getPercentage(
      previousInstallUninstallTotal("U"),
      currentInstallUninstallTotal("U")
    );

    setCardPercentages({
      newUser: {
        percentage: newUser.percentage,
        status: newUser.status,
      },
      activeUser: {
        percentage: activeUser.percentage,
        status: activeUser.status,
      },
      engagement: {
        percentage: engagement.percentage,
        status: engagement.status,
      },
      churn: {
        percentage: churn.percentage,
        status: churn.status,
      },
    });
  }, [dauWauMau, activeUserTimelySessions, platform, totalStats, eventsStats]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [downloadType, setDownloadtype] = useState("");
  const handleDownloadClick = (downloadType: any) => {
    setDownloadtype(downloadType);
    setModalOpen(true);
    // Additional logic or data fetching related to download can be added here
  };
  return (
    <div className="app-dashboard-container">
      <Helmet>
        <title>Appice | Dashboard</title>
      </Helmet>
      <div className="d-flex justify-content-between flex-wrap my-3">
        <h4>{appname || activeApp?.appName}</h4>
        <div className="d-flex">
          <div>
            <strong>Platform </strong>
            <Selector
              onChange={(value) => {
                setPlatform(value);
              }}
              size="middle"
              defaultValue={platform}
              style={{ ...styleBtn, width: "90px" }}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "android",
                  label: "Android",
                },
                {
                  value: "ios",
                  label: "IOS",
                },
                {
                  value: "web",
                  label: "Web",
                },
              ]}
            />
          </div>
          <div>
            <strong>Period </strong>

            <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 3, rangeType: "year" }}
              disableType="year"
              numDaysToShow={30} // Example value

            />
          </div>
        </div>
      </div>
      {
        <div className="app-dashboard-container-inner   ">
          <div className="app-dashboard-card-container p-3 ">
            {/* welcome to dashboard appid {params.app_id} */}
            <UserCard
              title="NEW USER"
              value={currentInstallUninstallTotal("I")}
              timeString={`${
                cardPercentages.newUser.percentage
              } w.r.t. ${formatDate(
                dateRange?.startDate_PNew,
                "D MMM, YY"
              )} - ${formatDate(dateRange?.endDate_PNew, "D MMM, YY")}`}
              upDownStatus={cardPercentages.newUser.status}
              lineColor="green"
              key1="Android"
              onViewDetailsClick={() => {
                navigate("/dashboard/acquisition");
              }}
              value1={totalStats?.current?.androidTotal}
              key2="IOS"
              value2={totalStats?.current?.iosTotal}
              key3="Web"
              value3={totalStats?.current?.webTotal}
              showIcons={true}
              // onDownloadClick={(e: any) => {}}
              loading={loading}
              onDownloadClick={() => handleDownloadClick("newUser")}
            />
            <UserCard
              title={t("retainedUsersLabel")}
              onViewDetailsClick={() => {
                navigate("/dashboard/appuser");
              }}
              value={
                platform == "all"
                  ? activeUserTimelySessions?.activeUsers
                  : platform == "web"
                  ? activeUserTimelySessions?.webUsers
                  : platform == "ios"
                  ? activeUserTimelySessions?.iosUsers
                  : activeUserTimelySessions?.androidUsers
              }
              timeString={`${
                cardPercentages.activeUser.percentage
              } w.r.t. ${formatDate(
                dateRange?.startDate_PNew,
                "D MMM, YY"
              )} - ${formatDate(dateRange?.endDate_PNew, "D MMM, YY")}`}
              upDownStatus={cardPercentages.activeUser.status}
              lineColor="blue"
              key1="DAU"
              value1={Math.floor(dauWauMau?.dau)}
              key2="WAU"
              value2={Math.floor(dauWauMau?.wau)}
              key3="MAU"
              value3={Math.floor(dauWauMau?.mau)}
              onDownloadClick={() => {}}
              showDownloadIcon={false}
              loading={loading}
            />

            <UserCard
              title="ENGAGEMENT"
              value={activeUserTimelySessions?.currentTotal}
              onViewDetailsClick={() => {
                navigate("/dashboard/usage");
              }}
              timeString={`${
                cardPercentages.engagement.percentage
              } w.r.t. ${formatDate(
                dateRange?.startDate_PNew,
                "D MMM, YY"
              )} - ${formatDate(dateRange?.endDate_PNew, "D MMM, YY")}`}
              upDownStatus={cardPercentages.engagement.status}
              lineColor="red"
              key1="Active Campaigns"
              value1={Math.floor(dauWauMau?.activeCmp)}
              key2="Total Campaigns"
              value2={Math.floor(dauWauMau?.totalCmp)}
              key3="DAU/MAU"
              value3={dauWauMau?.dau / dauWauMau?.mau}
              onDownloadClick={() => {}}
              loading={loading}
              showDownloadIcon={false}
            />
            <UserCard
              title={t("inActiveLabel")}
              value={currentInstallUninstallTotal("U")}
              onViewDetailsClick={() => {
                navigate("/dashboard/churn");
              }}
              timeString={`${
                cardPercentages.churn.percentage
              } w.r.t. ${formatDate(
                dateRange?.startDate_PNew,
                "D MMM, YY"
              )} - ${formatDate(dateRange?.endDate_PNew, "D MMM, YY")}`}
              upDownStatus={cardPercentages.churn.status}
              lineColor="blue"
              key1="Android"
              value1={totalStats?.current?.androidTotalU}
              key2="IOS"
              value2={totalStats?.current?.iosTotalU}
              key3="Web"
              value3={totalStats?.current?.webTotalU}
              showIcons={true}
              loading={loading}
              onDownloadClick={() => handleDownloadClick("churn")}
            />
          </div>
          <div className="app-dashboard-container-inner-body">
            <div className="d-flex justify-content-between flex-wrap p-3 app-dashboard-element-container">
              <div className="app-dashboard-active-user-trend ">
                <strong>Active User Trends</strong>
                <div className="mt-3">
                  <CustomHighChart
                    options={{
                      loading: loading,
                      title: "",
                      colors: colors["primary"],
                      chart: {
                        type: "column",
                        height: 340, // Set the desired height in pixels
                      },
                      legend: {
                        enabled: false, // Set to false to hide the legend
                      },
                      navigation: {
                        buttonOptions: {
                          enabled: false, // Set to false to hide the hamburger icon
                        },
                      },
                      xAxis: {
                        categories: ["DAU", "WAU", "MAU"],
                        title: {
                          text: "Users",
                        },
                      },
                      yAxis: {
                        title: {
                          text: "Active Users Counts",
                        },
                      },
                      plotOptions: {
                        column: {
                          grouping: true,
                          colorByPoint: true,
                          dataLabels: {
                            enabled: true,
                          },
                        },
                        // Set to false to remove the gap between bars
                      },
                      series: [
                        {
                          name: "Count",
                          data: [
                            Math.floor(dauWauMau?.dau || 0),
                            Math.floor(dauWauMau?.wau || 0),
                            Math.floor(dauWauMau?.mau || 0),
                          ],
                          type: "column",
                        },

                        // Add more series as needed
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="app-dashboard-dau-trend overflow-hidden">
                <strong className="my-5">DAU Trends</strong>
                <div className="mt-3">
                  <CustomHighChart
                    options={{
                      loading: loading,
                      chart: {
                        type: "spline",
                        height: 340, // Set the desired height in pixels
                      },
                      title: {
                        text: undefined,
                      },
                      xAxis: {
                        categories: lineData?.map((dataPoint: any) =>
                          convertDateToAbbreviation(dataPoint[0], platform)
                        ),
                        tickInterval: 5,
                        title: {
                          text: "Date-Month",
                        },
                      },
                      legend: {
                        enabled: false, // Set to false to hide the legend
                      },
                      navigation: {
                        buttonOptions: {
                          enabled: false, // Set to false to hide the hamburger icon
                        },
                      },
                      yAxis: {
                        title: {
                          text: "Daily Active Users Counts",
                        },
                      },
                      series: [
                        {
                          name: "DAU",
                          data: lineData?.map((dataPoint: any) => dataPoint[1]),
                          lineWidth: 1,
                          marker: {
                            enabled: false,
                          },
                        },
                      ],
                    }}
                  />
                </div>
             <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}}> 
                <div className="app-dashboard-link-buttons" >
                  <PrimaryButton
                    type="link"
                    onClick={() => navigate("/dashboard/appuser")}
                    style={{ fontSize: "var(--font-size-sm)" }}
                   >
                    <span className="mx-1">View Details</span>
                   </PrimaryButton>
                  </div>
                </AccessControl>
              </div>
            </div>
            <div className="d-flex justify-content-between  flex-wrap app-dashboard-events-container mx-3  app-dashboard-element-container">
              {loading ? (
                <SkeletonLoader type="default" paragraph={{ rows: 12 }} />
              ) : (
                <>
                  <div className=" app-dashboard-events-graph ">
                    <strong>Top 10 Events</strong>
                    {topTenEvents && topTenEvents.result && topTenEvents.total && (
                      <DonutPieChart
                        data={pieData(topTenEvents.result)}
                        colorSchema={{ scheme: "paired" }}
                        totalEvents={topTenEvents.total[1]}
                        outerSpace={60} //1 index contian total value
                      />
                    )}
                  </div>
                  <div className=" app-dashboard-events-details">
                    <CustomTable
                      dataSource={Object.keys(
                        topTenEvents?.result ? topTenEvents?.result : {}
                      )
                        .filter((key) => key !== "total")
                        .map((key) => ({
                          eventName: key,
                          numberOfEvents: topTenEvents?.result[key],
                          key: key, // Assuming the key can be used as a unique identifier
                        }))}
                      columns={[
                        {
                          title: "Event Name",
                          dataIndex: "eventName",
                          key: "eventName",
                          className: "table-active",
                        },
                        {
                          title: "Number Of Events",
                          dataIndex: "numberOfEvents",
                          key: "numberOfEvents",
                          className: "table-light",
                          render: (text: any) => formateIntoKorM(text), // Assuming formateIntoKorM is a function to format the number
                        },
                      ]}
                      pagination={false}
                    ></CustomTable>
                  </div>
                </>
              )}
             <AccessControl accessControl={{role:[roleCheckTypes.Contributor ]}}> 
                <div className="app-dashboard-link-buttons" >
                  <PrimaryButton
                    type="link"
                    onClick={() => navigate("/dashboard/events")}
                    style={{ fontSize: "var(--font-size-sm)" }}
                   >
                    <span className="mx-1">View Details</span>
                   </PrimaryButton>
                  </div> 
              </AccessControl>
            </div>
            <div className="install-cohort-container p-3 m-3">
              <Cohort />
            </div>
          </div>

          <Modals
            openModal={isModalOpen}
            setModalOpen={setModalOpen}
            formType={downloadType}
          />
        </div>
      }
    </div>
  );
}

export default Dashboard;
