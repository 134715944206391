import FormItem from "antd/es/form/FormItem";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import { Checkbox, Col, Form, Row, Space ,Flex} from "antd";
import Selector from "../../../../../components/Selector/Selector";
import "./LaunchCampaign.css"
import RangeSelector from "../../../../../components/RangeSelector/RangeSelector";
import SegmentedCustom from "../../../../../components/SegmentedCustom/SegmentedCustom";
import SingleDateSelector from "../../../../../components/DateTimeSelectors/SingleDateSelector/SingleDateSelector";
import { generateNumbersInRange } from "../../../../../helperFunctions/number";
import CustomDrawer from "../../../../../components/CustomDrawer/CustomDrawer";
import ConversionEvent from "../ConversionEvent/ConversionEvent";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventList } from "../../../../../redux/features/event/eventListSlice";
import {SchedulerPayload } from '../../../../../utils/models/index'
import { useForm } from "antd/es/form/Form";
import {
  convertToEpoch,
  disabledDate,
  getCurrentDate,
  parseDate,
  minutesToHHmmString,
  toHumanReadableTime,
  HHmmStringtoMinutes,
  dateTimeToEpoch,
  getWeekdaysInRange
} from "../../../../../helperFunctions/date";
import { STATUS } from "../../../../../redux/constant";
import Spinner from "../../../../../components/Spinner/Spinner";
import SwitchBtn from "../../../../../components/Buttons/SwitchBtn/SwitchBtn";
import useMessage from "../../../../../hooks/useMessage";
import { setCampaignData } from "../../../../../redux/features/campaign/campaignDataSlice";
import centralApi from "../../../../../services/centralApi";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { validateNoSpecialChars } from "../../../../../validator/antdValidator";
import TimePickerSelector from "components/DateTimeSelectors/TimePickerSelector/TimePickerSelector";
import { CampaignCategoryEnum, CampaignChannelTypeEnum, CampaignStatusEnum, CampaignTimeRangeEnum, DayAndTimeSelectorEnum, DeliveryTypeEnum, FrequencyModeTypesEnum, FrequencyTypeEnum, IntervalUnitTypesEnum, LaunchCampStagUnitTimeEnum, MinuteEnum, SchedulerPayloadEnum, TimeUnitEnum } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import { CloseOutlined } from "@ant-design/icons";
import { BOOLEAN_OPTIONS, STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { deserialize, serialize } from "utils/models/serializer";
import { getToken } from "redux/features/app/authTokenSlice";
import { CONSTANTS } from "utils/constants/constants";
import { FeatureSettingsFlagTypes } from "utils/constants/constants";

interface LaunchCampaignProps {
  mode?: 'review';
}
const LaunchCampaign : React.FC<LaunchCampaignProps> = ({mode}) => {
  const [showCEventDrawer, setShowCEventDrawer] = useState(BOOLEAN_OPTIONS.FALSE);
  const [cEvent, setCEvent] = useState(BOOLEAN_OPTIONS.FALSE);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const eventList = useSelector((state: any) => state.eventList);
  const authToken = useSelector((state: any) => state.authToken.token);
  const dispatch: any = useDispatch();
  const [form] = useForm();
  const [conversionForm]= useForm();
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const { showError, showSuccess } = useMessage();
  const navigate=useNavigate()
  const campaignDataLoading = useSelector(
    (state: any) => state.campaignData.status === STATUS.LOADING
  );
  //manage state for enable or disable checkbox for day part
  const [disabledDays, setDisabledDays] = useState<number[]>([]);
//flag to check if in edit mode or create mode
  const [isRangeEdited, setIsRangeEdited] = useState(false);

  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features;
  const varientValues=(templateData:any)=>{
   if(Array.isArray(templateData?.template)){
    const variants=templateData?.template?.filter(({variance_top_name}:{variance_top_name:string})=>variance_top_name?.includes("Variant"))
    return variants?.map(
       ({
         variance_id,
         variance_top_name,
        }: {
          variance_id: string;
          variance_top_name: string;
        }) => ({ id: variance_id, name: variance_top_name, p: Math.floor(100/variants?.length) })
        )
      }
    else {
      return []
    }
  }

  const [formData, setFormData] = useState(
    {
          nm: campaignData?.nm || "", //campaign name--
          dlvy: campaignData?.dlvy || "a", //delivery type--
          cy: campaignData?.cy || "e", //category--
          sd: campaignData?.sd || undefined,
          ed: campaignData?.ed || undefined,
          cd: campaignData?.cd || undefined,
          ud:campaignData?.ud || undefined,
          c_arr: campaignData?.c_arr || [], //coversion events--
          appinbox_et:campaignData?.appinbox_et ||  0, //app inbox expiry--
          stag: campaignData?.stag || {
            max_batch: undefined, //Maximum Batch Per Time Window
            time: undefined, //time window
            t_unit: undefined, //unit dd hh mm
          },
          t:campaignData?.t ||  campaignData.data?.template_type, //type
          d: campaignData?.d || BOOLEAN_OPTIONS.FALSE,
          st: campaignData?.st || CampaignStatusEnum.DRAFT,
          tid:campaignData?.tid ||  "", //template id
          aud: campaignData?.aud || "", // aud id
          liveEvent: campaignData?.liveEvent || BOOLEAN_OPTIONS.FALSE ,
          pa : campaignData?.pa || undefined,
          fre:campaignData?.fre ||  {
            show: "1", //Show this notification
            rangeStatus: "lt",
            rangeValue: "",
            pastDays: "",
            interval: 0, //times to users,after an interval of
            iUnit: "mm", // interval unit
            e: "no", //Exclude this campaign from all limits
            d: "", //Number of times a campaign is shown in a day
            l: "", //Number of times a campaign is shown across the lifetime of the app
            ss : "", //Number of times a campaign is shown in a session
          },
          limitedTo:campaignData?.limitedTo ||  "",
          limitVal:campaignData?.limitVal ||  "",
          diff:campaignData?.diff ||  "+19800",
          daySelector: campaignData?.daySelector || DayAndTimeSelectorEnum.ALLWEEK, 
          days: campaignData?.days,
          timeSelector: campaignData?.timeSelector || DayAndTimeSelectorEnum.ALLDAY, 
          time: campaignData?.time?.t || campaignData?.time || [CampaignTimeRangeEnum.STARTTIME ,CampaignTimeRangeEnum.ENDTIME], //new time range implementation it works for both old  and new campaigns without breaking
          childIds: campaignData?.childIds || [],
          m_sub: campaignData?.m_sub || "",
          m_cont: campaignData?.m_cont || "",
          s_cfg: campaignData?.s_cfg || "",
          e_cfg: campaignData?.e_cfg || "",
          delays: campaignData?.delays || 0,
          delayi: campaignData?.delayi || "", //delay value
          delayu: campaignData?.delayu || "", //delay unit
          AbTypeSelector: campaignData?.AbTypeSelector || "manual",
          controlGroup: campaignData?.controlGroup || 0,
          abTypeRange:campaignData?.abTypeRange || varientValues(campaignData?.data),
          variant:campaignData?.variant || varientValues(campaignData?.data).length>0? BOOLEAN_OPTIONS.TRUE : BOOLEAN_OPTIONS.FALSE
        }
  );
  const formStyle: React.CSSProperties = {
    maxWidth: "none",
    padding: 12,
  };
  const rowStyle = {
    borderBottom: "1px solid var(--color-text-disabled)",
  };
  const timeUnitOptions = [
    { label: t("secondsLabel"), value: TimeUnitEnum.SECONDS },
    { label: t("minutesLabel"), value: TimeUnitEnum.MINUTES },
    { label: t("hoursLabel"), value: TimeUnitEnum.HOURS },
    { label: t("dayLabel"), value: TimeUnitEnum.DAYS },

  ];
  const handleFormChange = (key: string, value: any) => {
    setFormData((prevState: typeof formData) => ({
      ...prevState,
      [key]: value,
    }));
  };
   
  useEffect(() => {
    if (formData.sd && formData.ed) {
      //extract the start and end date that user selects
      const startDate = new Date(formData.sd);
      const endDate = new Date(formData.ed);
      //get the number of days selected
      const selectedDays :any =  getWeekdaysInRange(startDate, endDate);
        // Keep only the days that are either in selectedDays or already in formData.days.
       //if not edit mode then selected days then use the exisiting values to show for edit
         const updatedDays = isRangeEdited 
         ? selectedDays // Update with new range
         : formData.days; // Keep previous selections if not edited
     //store them in a disableDays satte
      setDisabledDays(selectedDays);
      setFormData((prevState: typeof formData) => ({
        ...prevState,
        days: updatedDays,

      }));

          // Reset the range edit flag after updating days
    setIsRangeEdited(false);
   
    }
  }, [formData.sd, formData.ed]);
  
  
  const getVarientPCount = () => {
    return (
      formData.abTypeRange.reduce(
        (accumulator: any, varient: any) => accumulator + parseInt(varient.p),
        0
      ) + parseInt(formData.controlGroup)
    );
  };
  const handleSaveConversionEvent = (value: any) => {
    setFormData((prevState: any) => ({ ...prevState, c_arr: value }));
    setShowCEventDrawer(BOOLEAN_OPTIONS.FALSE);
  };
  useEffect(() => {
    if (loginUser) {
      dispatch(fetchEventList({ api_key: loginUser.api_key, app_id: appId }));
    }
   
    

  }, [loginUser]);

 /**
  *  Function to handle changes in scheduling data
  * @param field these would be the schedulerPayload keys
  * @param value these would be the schedulerPayload values
  */
    const handleSchedulerChange = (field: string, value: any) => {
      //if there would be any change in frequency type we will remove all values formdata.fre.s except for ft(frequency type)
      if (field === SchedulerPayloadEnum.FREQUENCYTYPE) {
        // Handle changes when Frequency Type changes
        if (value === FrequencyTypeEnum.NONE) {
          const { s, ...restFre } = formData.fre; // Destructure s from fre
          // If Frequency Type is 'None', clear all data in fre.s 
          setFormData({
            ...formData,
            fre: {
              ...restFre, 
            },
          });
        } else {
          // For other Frequency Types, set ft to the selected value and clear other fields
          setFormData({
            ...formData,
            fre: {
              ...formData.fre,
              s: {
                ft: value,
              },
            },
          });
        }
      } else if (field === SchedulerPayloadEnum.FREQUENCYMODE) {
        const { ft, ...restFre } = formData.fre.s; // Destructure ft from fre
        //  when Mode changes we would be setiing up ft and m only and clear all data in other keys
        setFormData({
          ...formData,
          fre: {
            ...formData.fre,
            s: {
              ft : ft,
              m: value, // Update mode field
            },
          },
        });
      } else {
        // For other fields, update directly in fre.s
        setFormData({
          ...formData,
          fre: {
            ...formData.fre,
            s: {
              ...formData.fre.s,
              [field]: value,
            },
          },
        });
      }
    };
  
  /**
   * addDateSelector Function to add a new date selector for frequency type onMultipleDates
   * This function would be  firstly called when we set frequency type onMultipleDates so that one SingleDatePicker renders
   * it would be also called when we click on a link add more dates
   */
  const addDateSelector = () => {
    const updatedMultipleDates = [...(formData?.fre?.s?.r || []), undefined]; // Add undefined for a new date
    setFormData({
      ...formData,
      fre: {
        ...formData?.fre,
        s: {
          ...formData?.fre?.s,
          r: updatedMultipleDates,
        },
      },
    });
  };

    /**
     * removeDateSelector function gets called when we click on a cross link on side of singledateselector component
     * This function removes the component from the ui
     * @param index value is the number of dateSelectors added
     */
    const removeDateSelector = (index : number) => {
      const updatedMultipleDates = formData.fre.s.r.filter(( date : number, i : number) => i !== index); // Remove date at index
      setFormData({
        ...formData,
        fre: {
          ...formData.fre,
          s: {
            ...formData.fre.s,
            r: updatedMultipleDates,
          },
        },
      });
    };

  useEffect(() => {
    dispatch(setCampaignData({...campaignData,...formData}))
  }, [formData]);
  const handleFormSubmit = async () => {
    
    //chk template is selected
    if (!campaignData?.tid) {
      showError("Please Select Template");
    //chk audience is selected
    } else if (!campaignData.aud) {
      showError("Please Select Audience");
    } else {
      //chk start date  is less then end date
      if (!(formData.ed >= formData.sd)) {
        showError("Start Date must be less than End Date ");
        return;
      }
      if(formData.daySelector!==DayAndTimeSelectorEnum.ALLWEEK && formData.days.length==0){
        showError("Please select at least one day")
      return
      }
      if(getVarientPCount()!==100 && formData.abTypeRange.length > 0 && formData.AbTypeSelector!=="auto"){
        showError("Total Variant range and Control group should be 100%")
        return
      }
      //Serializing and deserializing scheduler Payload
      let serializedData;
      if(formData.fre.s){
          //Serializing and deserializing scheduler Payload
         const deserializedData = deserialize(formData.fre.s, SchedulerPayload );
         serializedData =  serialize(deserializedData as SchedulerPayload );
      }
      // Calculate sd and ed in epoch (Unix timestamp) format
      const sdEpoch = dateTimeToEpoch(formData.sd, formData.time[0]);
      const edEpoch = dateTimeToEpoch(formData.ed, formData.time[1]);
      const payloadData = {
        ...formData,
        fre: {
          ...formData.fre,
          s: serializedData,
        },
        sd: sdEpoch,
        ed: edEpoch,
        tid: campaignData.tid,
        aud: campaignData.aud,
        ud:getCurrentDate(),
        t: campaignData.t,
        abTypeRange:formData.AbTypeSelector=="auto"?[]:formData.abTypeRange
      };
      //if  not edit
      if(!campaignData._id){
        payloadData["cd"]= getCurrentDate()
      }
      const body = {
        api_key: loginUser.api_key,
        app_id: appId,
        authToken 
      };
      const params:any = {
        api_key: loginUser.api_key,
        app_id: appId,
        args: JSON.stringify(payloadData),
      };
      try {
        //if campaignData._id do edit else create 
        const data =campaignData?._id?await centralApi(
          "GET",
          API_ENDPOINTS.UPDATE_CAMPAIGN_URL,
          null,
         {...params,cid:campaignData._id}
        ):await centralApi(
          "POST",
          API_ENDPOINTS.CREATE_CAMPAIGN_URL,
          body,
         params
        )
        if(data?.nm){
          dispatch(setCampaignData({}))
          showSuccess(campaignData._id?"Campaign Updated Successfully":"Campaign Created Successfully")
          navigate("/dashboard/campaign")
        }
        else{
          form.setFields([
            {
              name: "nm",
              errors: [t("campaignNameExistsErrorMessage")],
            },
          ]);
          showError(t("campaignNameExistsErrorMessage"))

          

        }
        
      } catch (error) {
        showError(t("somethingWrongLabel"))
        
      }
      
    
    }
    dispatch(getToken())

  };

  // week days
  const weekdays = [
    { label: t("sundayLabel"), value: 0 },
    { label: t("mondayLabel"), value: 1 },
    { label: t("tuesdayLabel"), value: 2 },
    { label: t("wednesdayLabel"), value: 3 },
    { label: t("thursdayLabel"), value: 4 },
    { label: t("fridayLabel"), value: 5 },
    { label: t("saturdayLabel"), value: 6 },
  ];
  return (
    <div className="launch-campaign-main-container p-1">
      <div className="">
        {campaignDataLoading ? (
          <Spinner />
        ) : (
          <Form
            style={formStyle}
            onFinish={handleFormSubmit}
            labelAlign="left"
            colon={BOOLEAN_OPTIONS.FALSE}
            form={form}

            
          >
            <CustomDrawer
              open={showCEventDrawer}
              placement="right"
              onClose={() => {
                setShowCEventDrawer(BOOLEAN_OPTIONS.FALSE);
              }}
              className="p-0"
              closable={BOOLEAN_OPTIONS.FALSE}
              footer={
                <Space className="d-flex justify-content-end">
                  <PrimaryButton
                    onClick={() => {
                      setShowCEventDrawer(BOOLEAN_OPTIONS.FALSE);
                    }}
                  >
                    Cancel
                  </PrimaryButton>
                  <PrimaryButton
                    type="primary"
                    onClick={() => {
                      conversionForm.submit();
                    }}
                  >
                    Save
                  </PrimaryButton>
                </Space>
              }
            >
              <ConversionEvent
                form={conversionForm}
                eventList={eventList?.data}
                setData={handleSaveConversionEvent}
                intialEventList={formData?.c_arr}
              />
            </CustomDrawer>
            <Row gutter={50} style={rowStyle}>
              <Col className="col-12 d-flex gap-5">
                <FormItem
                  className="w-50"
                  labelCol={{ flex: "120px" }}
                  label="Campaign Name"
                  name={"nm"}
                  rules={[
                    { required: BOOLEAN_OPTIONS.TRUE, message: "Campaign name is required" },
                    {validator:validateNoSpecialChars}
                  ]}
                  initialValue={formData?.nm}
                >
                  <TextInput
                    placeholder={"Campaign Name"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleFormChange("nm", e.target.value);
                    }}
                  />
                </FormItem>
                 {campaignData?.t!==CampaignChannelTypeEnum.SMS && campaignData?.t!==CampaignChannelTypeEnum.EMAIL && campaignData?.t!==CampaignChannelTypeEnum.WHATSAPP?

                <FormItem
                  className="w-50"
                  labelCol={{ flex: "120px" }}
                  label="Delivery Type"
                  name={"dlvy"}
                  initialValue={formData?.dlvy}
                >
                  <Selector
                    placeholder="Select Delivery Type"
                    onChange={(value) => {
                      handleFormChange("dlvy", value);
                    }}
                    options={[
                      { label: t("allLabel"), value: DeliveryTypeEnum.ALL },
                      { label: t("directPushLabel"), value: DeliveryTypeEnum.DIRECTPUSH },
                      { label: t("inboxOnlyLabel"), value: DeliveryTypeEnum.INBOXONLY },
                    ]}
                  />
                </FormItem>:null}
              </Col>
              <Col className="col-12 d-flex gap-5">
              {campaignData?.t!==CampaignChannelTypeEnum.SMS && campaignData?.t!==CampaignChannelTypeEnum.EMAIL && campaignData?.t!==CampaignChannelTypeEnum.WHATSAPP?

                <FormItem
                  className="w-50"
                  labelCol={{ flex: "120px" }}
                  label="Category"
                  name={"cy"}
                  initialValue={formData.cy}
                >
                  <Selector
                    className="w-100"
                    onChange={(value) => {
                      handleFormChange("cy", value);
                    }}
                    disabled={featuresSetting?.category}
                    options={[
                      { label: t("engagementLabel"), value: CampaignCategoryEnum.ENGAGEMENT },
                      { label: t("uninstallLabel"), value: CampaignCategoryEnum.UNINSTALL},
                      { label: t("geofenceLabel"), value: CampaignCategoryEnum.GEOFENCE },
                      { label: t("transactionalLabel"), value: CampaignCategoryEnum.TRANSACTIONAL},
                    ]}
                  />
                </FormItem>:null}
                <div className="w-50 d-flex ">
                  <FormItem
                    label={<span style={{ width: 110 }}>Date Range</span>}
                  ></FormItem>
                  <div className="d-flex w-100 ">
                    <FormItem
                      className="w-100"
                      rules={[
                        { required: BOOLEAN_OPTIONS.TRUE, message: "Start date  is required" },
                      ]}
                      name="sd"
                      initialValue={
                        formData.sd ? dayjs(formData.sd) : undefined
                      }
                    >
                      <SingleDateSelector
                        className="w-100"
                        disabledDate={(current) =>
                          disabledDate(current, "past")
                        }
                        onChange={(value) => {
                          handleFormChange("sd", parseDate(value));
                          setIsRangeEdited(true); // Set the flag to true when range is modified

                         
                        }}
                      />
                    </FormItem>
                    <div className="h-100 p-1">to</div>
                    <FormItem
                      className="w-100"
                      name={"ed"}
                      rules={[
                        { required: BOOLEAN_OPTIONS.TRUE, message: "End date  is required" },
                      ]}
                      initialValue={
                        formData.ed ? dayjs(formData.ed) : undefined
                      }
                    >
                      <SingleDateSelector
                        className="w-100"
                        disabledDate={(current) =>
                          disabledDate(current, "past")
                        }
                        onChange={(value) => {
                          handleFormChange("ed", parseDate(value));
                          setIsRangeEdited(true); // Set the flag to true when range is modified

                          
                        }}
                      />
                    </FormItem>
                  </div>
                </div>
              </Col>

              <Col className="col-12  d-flex gap-5 justify-content-between ">
              {campaignData?.t==CampaignChannelTypeEnum.PUSH?
            !featuresSetting?.appInboxAndDeliveryType && 

                <FormItem
                  className="w-50"
                  labelCol={{ flex: "120px" }}
                  label="App Inbox Expiry"
                  name={"appinbox_et"}
                >
                  <SingleDateSelector
                    defaultValue={
                      formData.appinbox_et
                      ? dayjs.unix(formData.appinbox_et)
                      : undefined
                    }
                    showTime={{ format: 'hh:mm A' }}
                    format="DD-MM-YYYY hh:mm A"
                    disabledDate={(current) => disabledDate(current, "past")}                 
                    onChange={(value) => {
                      handleFormChange("appinbox_et", convertToEpoch(value));
                    }}
                    className="w-100"
                  />
                </FormItem>:null}
                { featuresSetting?.[FeatureSettingsFlagTypes.DELAY_CAMP] &&

                <div className="w-50 d-flex m-0 justify-content-between">
                  <FormItem labelCol={{ flex: "120px" }} label={"Display"}>
                    <SegmentedCustom
                      value={formData.delays}
                      onChange={(value: string) =>{
                        handleFormChange("delays", value);
                        if(!value){
                          handleFormChange("delayi", "");
                          handleFormChange("delayu", "");

                        }
                      }
                      }
                      options={[
                        {
                          label: "Now",
                          value: 0,
                        },
                        {
                          label: "Delay",
                          value: 1,
                        },
                      ]}
                    />
                  </FormItem>
                  {formData?.delays?<Space.Compact className="d-flex col-5 ">
                    <FormItem
                      initialValue={formData.delayi}
                      name="delayi"
                      rules={[
                        {
                          required: formData.delays == 1,
                          message: "Input  value",
                        },
                      ]}
                    >
                      <TextInput
                        onChange={(e: any) =>
                          handleFormChange("delayi", e.target.value)
                        }
                      />
                    </FormItem>
                    <FormItem
                      className="w-100"
                      initialValue={formData.delayu}
                      name="delayu"
                      rules={[
                        {
                          required: formData.delays !== 0,
                          message: "Input unit",
                        },
                      ]}
                    >
                      <Selector
                        options={timeUnitOptions}
                        onChange={(value: string) =>
                          handleFormChange("delayu", value)
                        }
                      />
                    </FormItem>
                  </Space.Compact>:<></>}
                </div>
                }
              </Col>
              <Col className="d-flex col-12 gap-5  ">
              {campaignData?.t!==CampaignChannelTypeEnum.SMS && campaignData?.t!==CampaignChannelTypeEnum.EMAIL && campaignData?.t!==CampaignChannelTypeEnum.WHATSAPP?
                          !featuresSetting?.conversion && 
        <div className="w-50 d-flex">
    
                  <FormItem
                    className="w-50 "
                    labelCol={{ flex: "120px" }}
                    label={t("ConversionLabel")}
                  >
                    <SegmentedCustom
                      value={cEvent || formData?.c_arr?.length > 0}
                      onChange={(value: boolean) => {
                        setCEvent(value);
                        setFormData((prevState: any) => {
                          return { ...prevState, c_arr: [] };
                        });
                      }}
                    />
                  </FormItem>
                  {(cEvent || formData?.c_arr?.length > 0) && (
                    <FormItem>
                      <PrimaryButton onClick={() => setShowCEventDrawer(BOOLEAN_OPTIONS.TRUE)}>
                        Add
                      </PrimaryButton>
                    </FormItem>
                  )}
                </div>:null
                  }
                <div className="w-50 d-flex ">
                  
                <FormItem
                    labelCol={{ flex: "120px" }}
                    label={t("LiveEventLabel")}
                    name="live_event"
                    initialValue={formData?.liveEvent}
                  >
                    <SegmentedCustom
                      options={[
                        {
                          label: t("TrueLabel"),
                          value: BOOLEAN_OPTIONS.TRUE,
                        },
                        {
                          label: t("FalseLabel"),
                          value: BOOLEAN_OPTIONS.FALSE,
                        },
                      ]}
                      onChange={(value : boolean) =>
                        handleFormChange("liveEvent",value)
                      }
                    />
                  </FormItem>
                  </div>
               
              </Col>
            </Row>
            {!featuresSetting?.launchCampFreq && <>

            <Row gutter={50} style={rowStyle} className="mt-3 ">
              <Col span={24}>
                <h5 className="my-3">Frequency Capping</h5>
              </Col>

              <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis">
                <span
                  style={{ minWidth: 140 }}
                  className="color-disabled-strong my-1 "
                >
                  Show this notification
                </span>
                <FormItem
                  className="w-25"
                  name={"fre_show"}
                  style={{ maxWidth: 170 }}
                  initialValue={formData?.fre?.show}
                >
                  <Selector
                    className="mx-2"
                    onChange={(value) =>
                      handleFormChange("fre", { ...formData?.fre, show: value })
                    }
                    options={[{label:t("unLimitedLabel"),value:"10000"},...(generateNumbersInRange(1, 1000)?.map(
                      (num: number) => ({
                        label: num.toString(),
                        value: num.toString(),
                      })
                    ) || [])]}
                  />
                </FormItem>
                <span className="color-disabled-strong  my-1  ">
                  times to users,after an interval of
                </span>
                <div className="d-flex gap-1 justify-content-between">
                  <Space.Compact>
                    <FormItem
                      className="w-100"
                      name="fre_interval"
                      initialValue={formData?.fre?.interval}
                    >
                      <TextInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleFormChange("fre", {
                            ...formData?.fre,
                            interval: e.target.value,
                          })
                        }
                        type="number"
                        defaultValue={0}
                        min={0}
                      />
                    </FormItem>
                    <FormItem
                      style={{ minWidth: 100 }}
                      className="w-50"
                      name="fre_iUnit"
                      initialValue={formData?.fre?.iUnit}
                    >
                      <Selector
                        onChange={(value) =>
                          handleFormChange("fre", {
                            ...formData?.fre,
                            iUnit: value,
                          })
                        }
                        
                        options={[
                          { label: t("minutesLabel"), value: LaunchCampStagUnitTimeEnum.MINUTES },
                          { label: t("hoursLabel"), value: LaunchCampStagUnitTimeEnum.HOURS},
                          { label: t("dayLabel"), value: LaunchCampStagUnitTimeEnum.DAYS },
                        ]}
                      />
                    </FormItem>
                  </Space.Compact>
                </div>
              </Col>
              {!featuresSetting?.advancedFreq && 

                <div style={{ width: '64%' }}>

                {campaignData?.t!==CampaignChannelTypeEnum.SMS && campaignData?.t!==CampaignChannelTypeEnum.EMAIL && campaignData?.t!==CampaignChannelTypeEnum.WHATSAPP ?
                <Col className="d-flex gap-1 col-12 ">
               <FormItem
                    className="w-100"
                    labelCol={{ flex: "460px" }}
                    label="Interval between messages"
                  >
                    <TextInput  />
                  </FormItem>
                  <FormItem className="w-10">
                    <Selector
                      defaultValue={TimeUnitEnum.SECONDS}
                      options={timeUnitOptions}
                    />
                  </FormItem>
                </Col>:null}
                <Col className="col-12 ">
                  <FormItem
                    labelCol={{ flex: "460px" }}
                    label="Exclude this campaign from all limits"
                    name="fre_e"
                    initialValue={formData?.fre?.e}
                  >
                    <SegmentedCustom
                      options={[
                        {
                          label: "Yes",
                          value: "yes",
                        },
                        {
                          label: "NO",
                          value: "no",
                        },
                      ]}
                      onChange={(value: "yes" | "no") =>
                        handleFormChange("fre", { ...formData?.fre, e: value })
                      }
                    />
                  </FormItem>
                </Col>
                {campaignData?.t==CampaignChannelTypeEnum.PUSH?

                <Col className="col-12 ">
                  <FormItem
                    labelCol={{ flex: "460px" }}
                    label="Push Amplification"
                    name="pa"
                    initialValue={formData?.pa  ? STRING_BOOLEAN_OPTIONS.TRUE : formData?.pa == BOOLEAN_OPTIONS.FALSE ? STRING_BOOLEAN_OPTIONS.FALSE : undefined} 
                  >
                    <Selector
                      options={[
                        {
                          label: t("TrueLabel"),
                          value: STRING_BOOLEAN_OPTIONS.TRUE,
                        },
                        {
                          label: t("FalseLabel"),
                          value: STRING_BOOLEAN_OPTIONS.FALSE,
                        },
                      ]}
                      onChange={(value: string) =>
                        handleFormChange("pa", JSON.parse(value))
                      }
                    />
                  </FormItem>
                </Col>:null}
                {campaignData?.t!==CampaignChannelTypeEnum.SMS && campaignData?.t!==CampaignChannelTypeEnum.EMAIL && campaignData?.t!==CampaignChannelTypeEnum.WHATSAPP ?
<>
                <Col className="col-12">
                  <FormItem
                    className=""
                    labelCol={{ flex: "460px" }}
                    label="Number of times a campaign is shown in a session"
                    initialValue={formData?.fre?.ss}
                    name="fre_s"
                  >
                    <TextInput
                      className="w-100"
                      type="number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFormChange("fre", {
                          ...formData?.fre,
                          ss: e.target.value,
                        })
                      }
                    />
                  </FormItem>
                </Col>
                <Col className="col-12">
                  <FormItem
                    className=""
                    labelCol={{ flex: "460px" }}
                    label="Number of times a campaign is shown in a day"
                    name="fre_d"
                    initialValue={formData?.fre?.d}
                  >
                    <TextInput
                      className="w-100"
                      type="number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFormChange("fre", {
                          ...formData?.fre,
                          d: e.target.value,
                        })
                      }
                    />
                  </FormItem>
                </Col>
                </>:null}
                {campaignData?.t!==CampaignChannelTypeEnum.SMS && campaignData?.t!==CampaignChannelTypeEnum.EMAIL && campaignData?.t!==CampaignChannelTypeEnum.WHATSAPP?

                <Col className="col-12">
                  <FormItem
                    className=""
                    labelCol={{ flex: "460px" }}
                    label="Number of times a campaign is shown across the lifetime of the app"
                    name="fre_l"
                    initialValue={formData?.fre?.l}
                  >
                    <TextInput
                      className="w-100"
                      type="number"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFormChange("fre", {
                          ...formData?.fre,
                          l: e.target.value,
                        })
                      }
                    />
                  </FormItem>
                </Col>:null}
              </div>}
            </Row>
            {!featuresSetting?.optimisation && (
<>
            <Row gutter={50} style={rowStyle} className="mt-3">
              <Col span={24}>
                <h5 className="my-3">Staggered Send</h5>
              </Col>
              <Col className="d-flex gap-3 flex-wrap" span={24}>
                <FormItem
                  className=""
                  name="stag_maxBatch"
                  rules={[
                    {
                      required: formData?.stag?.t_unit || formData?.stag?.time ,
                      message: "Please enter max batch size",
                    },
                  ]}
                  initialValue={formData?.stag?.max_batch}
                  label="Maximum Batch Per Time Window %"
                >
                  <TextInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFormChange("stag", {
                        ...formData?.stag,
                        max_batch: e.target.value,
                      })
                    }
                  />
                </FormItem>
                <Space.Compact>
                  <FormItem
                    className="w-50"
                    name="stag_time"
                    initialValue={formData?.stag?.time}
                    rules={[
                      {
                        required: formData?.stag?.t_unit || formData?.stag?.max_batch,
                        message: "Please enter time",
                      },
                    ]}
                    label="Time Window "
                  >
                    <TextInput
                      type="number"
                      min={1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFormChange("stag", {
                          ...formData?.stag,
                          time: e.target.value,
                        })
                      }
                    />
                  </FormItem>
                  <FormItem
                    className="w-50"
                    name="stag_tUnit"
                    rules={[
                      {
                        required: formData?.stag?.time || formData?.stag?.max_batch,
                        message: "Please Select",
                      },
                    ]}
                    initialValue={formData?.stag?.t_unit}
                  >
                    <Selector
                      onChange={(value) =>
                        handleFormChange("stag", {
                          ...formData?.stag,
                          t_unit: value,
                        })
                      }
                      placeholder="Select unit"
                      options={[
                        { label: t("dayLabel"), value: LaunchCampStagUnitTimeEnum.DAYS },
                        { label: t("minutesLabel"), value: LaunchCampStagUnitTimeEnum.MINUTES },
                        { label: t("hoursLabel"), value: LaunchCampStagUnitTimeEnum.HOURS},
                      ]}
                    />
                  </FormItem>
                </Space.Compact>
              </Col>
            </Row>
            {campaignData?.t!==CampaignChannelTypeEnum.SMS && campaignData?.t!==CampaignChannelTypeEnum.EMAIL && campaignData?.t!==CampaignChannelTypeEnum.WHATSAPP ?

            <Row className="mt-3 d-flex flex-wrap" style={rowStyle} gutter={50}>
              <Col span={24}>
                <h5 className="my-2">Optimization</h5>
              </Col>
              <Col className="d-flex justify-content-between gap-4" span={12}>
                <FormItem className="" label="Send time optimization">
                  <Checkbox />
                </FormItem>
                {campaignData?.t==CampaignChannelTypeEnum.PUSH ?

                <FormItem className="w-50" label=" Push Amplification">
                  <Checkbox />
                </FormItem>:null}
              </Col>
              {campaignData?.t!==CampaignChannelTypeEnum.SMS && campaignData?.t!==CampaignChannelTypeEnum.EMAIL && campaignData?.t!==CampaignChannelTypeEnum.WHATSAPP ?

              <Col className="d-flex justify-content-between" span={8}>
                <FormItem label={<span></span>}>
                  <SegmentedCustom
                    options={[
                      { label: "Best device", value: "Best device " },
                      {
                        label: "Send to all devices",
                        value: "Send to all devices",
                      },
                    ]}
                  />
                </FormItem>
              </Col>:null}
            </Row>
            :null}</>)}
            </>}
            <Row className="mt-3" style={rowStyle} gutter={50}>
            <Col span={24}>
                <h5 className="mb-4 mt-2">Day Part</h5>
              </Col>
              <Col className="d-flex gap-4" span={24}>
                <FormItem
                  name="daySelector"
                  initialValue={formData.daySelector}
                >
                  <SegmentedCustom
                    onChange={(value: DayAndTimeSelectorEnum.ALLWEEK | DayAndTimeSelectorEnum.SPECIFIC) => {
                      handleFormChange("daySelector", value);
                      if (value == DayAndTimeSelectorEnum.ALLWEEK) {
                        handleFormChange("days", disabledDays);
                      }
                    }}
                    options={[
                      { label: t("allweekLabel"), value: DayAndTimeSelectorEnum.ALLWEEK },
                      { label: t("specificdaysLabel"), value: DayAndTimeSelectorEnum.SPECIFIC },
                    ]}
                  />
                </FormItem>
                <FormItem >
                <Checkbox.Group
              disabled={formData.daySelector === DayAndTimeSelectorEnum.ALLWEEK}
              value={formData?.days}
              onChange={(checkedValues) => handleFormChange("days", checkedValues)}
>
       {weekdays?.map((day) => (
          <Checkbox
           key={day?.value} //key
           value={day?.value} //value ofthe checkbox
           disabled={!disabledDays?.includes(day.value)}  //only disable those which are not in date range
         >
          {day.label} 
        </Checkbox>
         ))}
        </Checkbox.Group>
                </FormItem>
              </Col>
              <Col
                className="d-flex flex-wrap justify-content-between"
                span={24}
              >
                <FormItem
                  name="timeSelector"
                  initialValue={formData.timeSelector}
                >
                  <SegmentedCustom
                    options={[
                      { label: t("allDayLabel"), value: DayAndTimeSelectorEnum.ALLDAY },
                      { label: t("timeRangeLabel") ,value: DayAndTimeSelectorEnum.SPECIFIC },
                    ]}
                    onChange={(value : string) => {
                      handleFormChange("timeSelector", value);
                    }}
                  />
                </FormItem>

                <FormItem
                  className="w-50"
                  name="time"
                  initialValue={formData.time.map((timeStr: string) => HHmmStringtoMinutes(timeStr))}
                  label={`Selected Time: ${toHumanReadableTime(formData.time[0])} - ${toHumanReadableTime(formData.time[1])}`}
                >
                  <RangeSelector
                    range
                    min={0}
                    max={MinuteEnum.TWENTY_FOUR_HOUR} // 24 hours converted to minutes 
                    step={MinuteEnum.FIFTEEN_MINUTE_INTERVAL}
                    disabled={formData.timeSelector == DayAndTimeSelectorEnum.ALLDAY}
                    tooltip={{ formatter: (value:any) => minutesToHHmmString(value,BOOLEAN_OPTIONS.TRUE) }} // Format the value in human-readable format for tooltip
                    onChange={(value: any) => {
                      // If there are exactly two times selected and they are the same, adjust the second time
                     if (value.length === 2 && value[0] === value[1]) {
                      // If both times are the same, add 15 minutes to the second time
                          value[1] = value[0] + 15;
                        }
                     const formattedValue = value.map((minutes: number) => minutesToHHmmString(minutes, BOOLEAN_OPTIONS.FALSE)); // Convert minutes to HHmm format for form data
                     handleFormChange('time', formattedValue);
                   }}
                  />
                </FormItem>
              </Col>
              {Array.isArray(campaignData?.data?.template) &&
                formData.abTypeRange.length > 0 && (
                  <Col span={24}>
                    <FormItem
                      name="AbTypeSelector"
                      initialValue={formData.AbTypeSelector}
                    >
                      <SegmentedCustom
                        onChange={(value: "manual" | "auto") => {
                          handleFormChange("AbTypeSelector", value);
                        }}
                        options={[
                          { label: "Let the Machine do it", value: "auto" },
                          { label: "Set A/B Split", value: "manual" },
                        ]}
                      />
                    </FormItem>
                    {formData.AbTypeSelector == "manual" &&
                      formData.abTypeRange.length > 0 && (
                        <div className="d-flex gap-2 flex-wrap">
                          {formData.abTypeRange?.map(
                            ({
                              name,
                              p,
                              id,
                            }: {
                              name: string;
                              p: number;
                              id: string;
                            }) => {
                              return (
                                <FormItem
                                  initialValue={p}
                                  label={name}
                                  name={id}
                                >
                                  <TextInput
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      handleFormChange(
                                        "abTypeRange",
                                        formData.abTypeRange.map(
                                          (varient: {
                                            name: string;
                                            p: number;
                                            id: string;
                                          }) => {
                                            if (varient.id == id) {
                                              return {
                                                ...varient,
                                                p: e.target.value,
                                              };
                                            }
                                            return varient;
                                          }
                                        )
                                      )
                                    }
                                    type={"number"}
                                    style={{ width: 70 }}
                                    min={1}
                                    max={100}
                                    placeholder="%"
                                  />
                                </FormItem>
                              );
                            }
                          )}
                          <FormItem className="ms-4" label="Control Group">
                            <SwitchBtn
                            checked={formData.controlGroup}
                              onChange={(value) =>
                                handleFormChange("controlGroup", value ? 10 : 0)
                              }
                            />
                          </FormItem>
                          {formData.controlGroup ? (
                            <FormItem initialValue={formData?.controlGroup}>
                              <TextInput
                                type="number"
                                value={formData?.controlGroup}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleFormChange(
                                    "controlGroup",
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          ) : (
                            <></>
                          )}
                          {getVarientPCount() !== 100 && (
                            <p className="mt-1 mx-2">
                              Total :{" "}
                              <strong
                                style={{ color: "var(--color-red-main)" }}
                              >
                                {getVarientPCount()+"%"}
                              </strong>{" "}
                              (expected <strong>100%</strong>)
                            </p>
                          )}
                        </div>
                      )}
                  </Col>
                )}
            </Row>
            <Row gutter={50} style={rowStyle} className="mt-3 ">
              <Col span={24}>
              <h5 className="my-3">
                  Scheduler
              </h5>
               <p className="subtitle color-disabled-strong my-1">
                  When do you want to schedule this campaign?
                </p>   
              </Col>
              <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis my-3">
                <span
                  style={{ minWidth: 140 }}
                  className="my-1 "
                >
                  Frequency Type
                </span>
                <FormItem
                  className="w-25"
                  name={"freq_type"}
                  style={{ maxWidth: 170 }}
                  initialValue={formData?.fre?.s?.ft || FrequencyTypeEnum.NONE }
                >
                  <Selector
                    onChange={(value) => handleSchedulerChange(SchedulerPayloadEnum.FREQUENCYTYPE ,value) }
                    options={[
                       { label: t("noneSmallLabel"), value: FrequencyTypeEnum.NONE },
                       { label: t("recurringLabel"), value: FrequencyTypeEnum.RECURRING},
                       { label: t("onMultipleDatesLabel"), value: FrequencyTypeEnum.ONMULTIPLEDATES },
                     ]}
                   />
                </FormItem>
               </Col>
               {/* Conditionally render multipleDateSelector based when Frequency Type is on multiple dates */}
               {formData?.fre?.s?.ft == FrequencyTypeEnum.ONMULTIPLEDATES && (
               <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis">
               <span
                  style={{ minWidth: 140 }}
                  className="my-1 "
                >
                {t("pickDatesLabel")}
                </span>
                <FormItem
                  className="w-25"
                  name={"mm-r"}
                  style={{ maxWidth: 170 }}
                  rules={[
                    {
                      required: !formData.fre.s.r ||formData.fre.s.r == "" ,
                      message: t("pleaseSelectLabel") as string,
                    },
                  ]}
                

                >
                {(!formData?.fre?.s?.r || formData?.fre?.s?.r?.length === 0) && (
                        // Initialize with at least one date selector
                        <>{addDateSelector()}</>
                      )}
                 <div>
                 {formData.fre.s.r && formData.fre.s.r.length > 0 && (
                   formData?.fre?.s?.r.map((date : number, index : number) => (
                    <div key={index} className={`d-flex align-items-center ${index === 0 ? '' : 'mt-3'}`} style={{ width: index !== 0 ? '350px' : '300px' }}>
                      <div className="d-flex align-items-center">
                        <SingleDateSelector
                          className="w-100"
                          showTime={{ format: 'hh:mm A' }}
                          format="DD-MM-YYYY hh:mm A"
                          disabledDate={(current) =>
                            disabledDate(current, "past")
                          }
                          defaultValue={formData.fre.s.r  && formData?.fre?.s?.r[index] ? dayjs.unix(formData?.fre?.s?.r[index]) : undefined}
                          onChange={(value) => {
                          const updatedMultipleDates = [...formData.fre.s.r];
                          updatedMultipleDates[index] = convertToEpoch(value);
                          handleSchedulerChange(SchedulerPayloadEnum.CAMPAIGNRUNAT,updatedMultipleDates)
                          }}
                        />
                         {index !== 0 && (
                           <button
                             type="button"
                             className="btn"
                             onClick={() => removeDateSelector(index)}
                             >
                                <CloseOutlined />
                            </button>
                          )}
                      </div>
                 </div>
                  )))}
              </div>
                <div>
                <button type="button" onClick={addDateSelector} className="btn btn-link btn-sm ps-0">+ Add another date</button>
                  </div>
                </FormItem>                
               </Col>
               )}
                {/* Conditionally render Mode and Time based when Frequency Type is recurring */}
              {formData?.fre?.s?.ft == FrequencyTypeEnum.RECURRING &&(
                <>
                <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis">
                <span
                  style={{ minWidth: 140 }}
                  className="my-1 "
                >
                  {t("modeLabel")}
                </span>
                <FormItem
                  className="w-25"
                  name={"freq-mode"}
                  style={{ maxWidth: 170 }}
                  initialValue={formData?.fre?.s?.m}
                  rules={[
                    {
                      required: !formData.fre.s.m ||formData.fre.s.m=="" ,
                      message: t("pleaseSelectLabel") as string,
                    },
                  ]}
                >
                 <Selector
                    onChange={(value) => handleSchedulerChange(SchedulerPayloadEnum.FREQUENCYMODE, value)}
                    options={[
                              { label: t("dailyLabel"), value: FrequencyModeTypesEnum.DAILY },
                              {label:t("weeklyLabel") , value:FrequencyModeTypesEnum.WEEKLY},
                              {label :t("monthlyLabel") ,value :FrequencyModeTypesEnum.MONTHLY},
                              {label:t("continuousLabel") , value :FrequencyModeTypesEnum.CONTINOUS}
                            ]}
                  />
                </FormItem>
                </Col>
                {formData.fre.s.m == FrequencyModeTypesEnum.WEEKLY && (
                <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis">
                <span
                  style={{ minWidth: 140 }}
                  className="my-1 "
                >
                  {t("specificdaysLabel")}
                </span>
                <FormItem initialValue={formData.fre.s.d} name="Recurringdays"
                rules={[
                  {
                    required: !formData.fre.d ||formData.fre.s.d=="" ,
                    message: t("pleaseSelectLabel") as string,
                  },
                ]}
                >
                  <Checkbox.Group
                    value={formData.fre.s.d}
                    onChange={(value : any) => {
                      handleSchedulerChange( SchedulerPayloadEnum.WEEKLYDAYS , value );
                    }}
                    
                 
                  >
            {weekdays?.map((day) => (
                    <Checkbox
                     key={day?.value} //key
                     value={day?.value} //value ofthe checkbox
                     >
                      {day.label} 
                     </Checkbox>
         ))}
                  </Checkbox.Group>
                </FormItem>
                </Col>
              )}
              {formData.fre.s.m== FrequencyModeTypesEnum.MONTHLY &&(
              <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis">
                <span
                  style={{ minWidth: 140 }}
                  className="my-1 "
                >
                  {t('selectDatesLabel')}
                </span>
                <FormItem
                  className="w-25"
                  name={"mm-r"}
                  style={{ maxWidth: 170 }}
                  rules={[
                    {
                      required: !formData?.fre?.s?.dt ||formData?.fre?.s?.dt=="" ,
                      message: t("pleaseSelectLabel") as string,
                    },
                  ]}
                > 
                 <div className="launch-campaign-scheduler-recurring-monthly-multiple-date-selector">
                    <Flex vertical gap="small">
                        <SingleDateSelector
                          className="w-100"
                          popupClassName="custom-datepicker-dropdown" 
                          format="D"
                          multiple
                          maxTagCount="responsive"
                          defaultValue={formData?.fre?.s?.dt?.map((date : number) => (
                            dayjs(`2024-07-${date}`)
                          ))}
                          onChange={(value) => {
                          const datesArray = Array.isArray(value) ? value : [value];
                          const intArray = datesArray.map(dateStr => dateStr.$D);
                          handleSchedulerChange(SchedulerPayloadEnum.MONTHLYDAYS,intArray)
                          }}
                        />
                      </Flex>
                    </div>
                </FormItem>
               </Col>
              )}
              {/* Conditionally render pick date and interval when mode is continuous */}
             {formData.fre.s.m === FrequencyModeTypesEnum.CONTINOUS && (
               <>
                <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis">
                  <span style={{ minWidth: 140 }} className="my-1">
                  {t("pickDateLabel")}                 
                   </span>
                  <FormItem 
                      name={"continuous-date"} 
                      style={{ maxWidth: 180 }}
                      rules={[
                        {
                          required: !formData?.fre?.s?.r||formData?.fre.s.r=="" ,
                          message: t("selectDatesLabel") as string,
                        },
                      ]}
                      initialValue={
                        formData.fre.s.r  && formData?.fre?.s?.r[0] ? dayjs(formData.fre.s.r[0]) : undefined
                      }
                      >
                          <div className="d-flex align-items-center" >
                            <SingleDateSelector
                              className="w-120"
                              showTime={{ format: 'hh:mm A' }}
                              format="DD-MM-YYYY hh:mm A"
                              disabledDate={(current) => disabledDate(current, "past")}
                              defaultValue={formData.fre.s.r  && formData?.fre?.s?.r[0] ? dayjs.unix(formData.fre.s.r[0]) : undefined}
                              onChange={(value) => {
                                const updatedMultipleDates = [];
                                updatedMultipleDates[0] = convertToEpoch(value);
                                handleSchedulerChange(SchedulerPayloadEnum.CAMPAIGNRUNAT, updatedMultipleDates );
                              }}
                            />
                        </div>
                  </FormItem>
                </Col>
                <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis">
                <span style={{ minWidth: 140 }} className="my-1">
                  {t("intervalLabel")}
                </span>
                <div className="d-flex gap-1 justify-content-between">
                  <Space.Compact>
                    <FormItem
                      style={{ maxWidth: 80 }}
                      name="scheduler-continous-interval"
                      initialValue={formData?.fre?.s?.i}
                      rules={[
                        {
                          required: !formData?.fre?.s?.i||formData?.fre.s.i=="" ,
                          message: t("plzInputLabel") as string,
                        },
                      ]}
                    >
                      <TextInput
                         type="number"
                         defaultValue={0}
                         min={0}
                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           const inputValue = parseInt(e.target.value, 10);
                            handleSchedulerChange(SchedulerPayloadEnum.INTERVAL, inputValue)
                         }
                       
                        }
                      />
                    </FormItem>
                    <FormItem
                      style={{ minWidth: 100 }}
                      className="w-50"
                      name="scheduler-continous-interval-unit"
                      initialValue={formData?.fre?.s?.iu}
                      rules={[
                        {
                          required: !formData?.fre?.s?.iu||formData?.fre.s.iu=="" ,
                          message: t("pleaseSelectLabel") as string,
                        },
                      ]}
                    >
                      <Selector
                          onChange={(value) => {
                            handleSchedulerChange(SchedulerPayloadEnum.INTERVALUNIT,  value);
                          }}
                        options={[
                          { label: t("dayLabel"), value: IntervalUnitTypesEnum.DAYS },
                          { label: t("hoursLabel"), value: IntervalUnitTypesEnum.HOURS },
                          { label: t("minutesLabel"), value: IntervalUnitTypesEnum.MINUTES},
                          {label :t("secondsLabel") , value : IntervalUnitTypesEnum.SECONDS}
                        ]}
                      />
                    </FormItem>
                  </Space.Compact>
                </div>
                </Col>
              </>
              )}
              {/* Render type when mode is not equal to continuous */}
              {formData.fre.s.m !== FrequencyModeTypesEnum.CONTINOUS &&(
                <Col className="d-flex gap-3 flex-wrap col-12 text-overflow-ellipsis">
                <span
                  style={{ minWidth: 140 }}
                  className="my-1 "
                >
                  {t("timeLabel")}
                </span>
                <FormItem
                  className="w-25"
                  name={"freq-time"}
                  style={{ maxWidth: 170 }}
                  rules={[
                    {
                      
                      required: !formData?.fre?.s?.t || formData?.fre?.s?.t=="" ,
                      message: t("plzInputLabel") as string,
                    },
                  ]}
                  initialValue={formData?.fre?.s?.t ? dayjs(formData.fre.s.t, 'HH:mm') : null}
                >
               <TimePickerSelector   

                    use12Hours = {BOOLEAN_OPTIONS.TRUE}
                    onChange={(time, timeString) =>
                      handleSchedulerChange(SchedulerPayloadEnum.TIME, timeString)
                    }
                    value={formData?.fre?.s?.t ? dayjs(formData.fre.s.t, 'HH:mm') : null}
                    />
                </FormItem>
              </Col>
            )}
              </>
                )}
            </Row>
            {mode !=CONSTANTS.REVIEW &&(       
            <Row className="mt-3 d-flex  justify-content-end">
              <Col>
                <PrimaryButton type="primary" htmlType="submit">{campaignData._id?t("updateLabel"):t("saveLabel")} {t("campaignSmallLabel")}</PrimaryButton>
              </Col>
            </Row>
            )}
          </Form>
        )}
      </div>
    </div>
  );
}

export default LaunchCampaign;
