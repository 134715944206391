"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Funnel = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const base_1 = require("../../common/base");
// Define FunnelList class with serialization metadata
let FunnelList = class FunnelList extends base_1.BaseModel {
    constructor(name, type) {
        super(); // Call the superclass constructor (BaseModel)
        this.name = name; // Initialize name property
        this.type = type; // Initialize type property
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FunnelList.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FunnelList.prototype, "type", void 0);
FunnelList = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String])
], FunnelList);
// Define Attribute class with serialization metadata
let AttributeStep = class AttributeStep extends base_1.BaseModel {
    constructor(attributeName, type, value, operator) {
        super(); // Call the superclass constructor (BaseModel)
        this.attributeName = attributeName; // Initialize attributeName property
        this.type = type; // Initialize type property
        this.value = value; // Initialize value property
        this.operator = operator; // Initialize operator property
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AttributeStep.prototype, "attributeName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AttributeStep.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AttributeStep.prototype, "value", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], AttributeStep.prototype, "operator", void 0);
AttributeStep = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, String, String])
], AttributeStep);
// Define EventStep class with serialization metadata
let EventStep = class EventStep extends base_1.BaseModel {
    constructor(eventName, list, attributes) {
        super(); // Call the superclass constructor (BaseModel)
        this.eventName = eventName; // Initialize eventName property
        this.list = list; // Initialize list property
        this.attributes = attributes; // Initialize attributes property
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], EventStep.prototype, "eventName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: FunnelList }),
    __metadata("design:type", Array)
], EventStep.prototype, "list", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: AttributeStep }),
    __metadata("design:type", Array)
], EventStep.prototype, "attributes", void 0);
EventStep = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, Array, Array])
], EventStep);
// Define Funnel class with serialization metadata
let Funnel = class Funnel extends base_1.BaseModel {
    constructor(data) {
        super(); // Call the superclass constructor (BaseModel)
        this.name = data.name; // Initialize name property
        this.description = data.description; // Initialize description property
        this.duration = data.duration; // Initialize duration property
        this.customDate = data.customDate; // Initialize customDate property
        this.unit = data.unit; // Initialize unit property
        this.customunit = data.customunit; // Initialize customunit property
        this.events = data.events; // Initialize events property
        this.platform = data.platform; // Initialize platform property
        this.journey = data.journey; // Initialize journey property
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Funnel.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Funnel.prototype, "description", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Funnel.prototype, "duration", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Object)
], Funnel.prototype, "customDate", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Funnel.prototype, "unit", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Funnel.prototype, "customunit", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: EventStep }),
    __metadata("design:type", Array)
], Funnel.prototype, "events", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], Funnel.prototype, "platform", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Funnel.prototype, "journey", void 0);
Funnel = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [Object])
], Funnel);
exports.Funnel = Funnel;
