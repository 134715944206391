import { Tag } from "antd";
import  { FC } from "react";

interface VariantListProps {
  variants: any[];
  selectedVariant: any;
  handleVariantClick?: (id: string) => void;
  handleDeleteVariant?: (id: string) => void;
}

const VariantList: FC<VariantListProps> = ({
  variants,
  selectedVariant,
  handleVariantClick,
  handleDeleteVariant,
}) => {
  return (
    <div className=" d-flex gap-1  overflow-auto">
      {variants?.map((variant: any) => (
        <div className="d-flex  flex-column gap-1 enable-interaction">
        <Tag
          key={variant?.variance_id}
          color={
            variant?.variance_id == selectedVariant?.variance_id ? "blue" : ""
          }
          className="cursor-pointer"
          closable={handleDeleteVariant ? true : false}
          onClick={() => {
            if (handleVariantClick) {
              handleVariantClick(variant?.variance_id);
            }
          }}
          onClose={(e) => {
            e.preventDefault();
            if (handleDeleteVariant) {
              handleDeleteVariant(variant?.variance_id);
            }
          }}
        >
          {variant?.variance_top_name}
        </Tag>
        {variant?.variance_language &&(
        <Tag>
          {variant?.variance_language}
        </Tag>
        )}
        </div>
      ))}
    </div>
  );
};

export default VariantList;
