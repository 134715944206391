import { useEffect, useState } from "react";
import "./UserManagement.css";
import deleteIcon from "../../images/trashpink.svg";
import keyIcon from "../../images/key.svg";
import unlockIcon from "../../images/unlock.svg";
import lockIcon from "../../images/lock.svg";
import minusIcon from "../../images/minus.svg";
import "../../global.css";
import { SizeType } from "antd/es/config-provider/SizeContext";
import Selector from "../../components/Selector/Selector";
import Search from "../../components/Inputs/Search/Search";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import ToggleBtn from "../../components/Buttons/ToggleBtn/ToggleBtn";
import CopyModal from "components/Models/CopyModel/CopyModel";
import AddUserModel from "components/Models/AddUserModel/AddUserModel";
import ConfirmPopUp from "components/PopUp/ConfirmPopUp/ConfirmPopUp";
import CustomTable from "components/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import CustomToolTip from "components/CustomToolTip/CustomToolTip";
import { BOOLEAN_OPTIONS, PAGE_OPTIONS } from "utils/constants/selectorOptions";
import useMessage from "hooks/useMessage";
import { t } from "i18next";
import centralApi from "services/centralApi";
import { formatDate } from "helperFunctions/date";
import { EDStatusEnum, UserRoleEnum } from "utils/enums";
import { copyText, generateStrongRandomString } from "helperFunctions/string";
import { CopyTwoTone } from "@ant-design/icons";
import { API_ENDPOINTS } from "utils/constants";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { roleCheckTypes } from "utils/constants/constants";
import downloadIcon from "../../images/downloadNew.svg";

type propTypes = {
  pageSize?: number;
  tableSize: SizeType;
};

function UserManagement({pageSize,tableSize }: propTypes) {
  const [entries, setEntries] = useState(pageSize || PAGE_OPTIONS.DEFAULT_VALUE);
  const [copyModelVisibility, setCopyModelVisibility] = useState(BOOLEAN_OPTIONS.FALSE);
  const [addUserModel, setAddUserModel] = useState(BOOLEAN_OPTIONS.FALSE);
  const [copyModelInputValue, setCopyModelInputValue] = useState("");
  const [usersData, setUsersData] = useState<any>([]);
  const [usersDataCopy, setUsersDataCopy] = useState<any>([]);
  const [search,setSearch]=useState("")
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const {appId} = useSelector((state: any) => state.activeApp);
  const apiKey =loginUser?.api_key;
  const userRole = loginUser?.activeAppUserRole
  const [filterColumns , setFilteredColumns] = useState([])
  const {showError,showSuccess}=useMessage();
  //Get the site setting from redux store
  const ssoLogin  = useSelector(
    (state: any) => state.featuresSetting?.data[0]?.siteSettings?.is_sso || ""
  );


//to check if the user is global admin
const isGlobalAdmin = userRole === UserRoleEnum.GLOBAL_ADMIN 

/**
 * getUpdatedData function get's real time updated user data
 */
async function getUpdatedData() {
  try {
      if (apiKey && appId) {
          const params = {
              appid: appId,
              args: JSON.stringify({ appid: appId }),
              api_key: apiKey,
          };
          const url = API_ENDPOINTS.GET_ALL_USERS_URL;
          const response = await centralApi("GET", url, null, params);
          const userData = response?.members?.map((user: any) => {
              let role = "";
              if (user.user_role) {
                  Object.keys(user.user_role).forEach((key) => {
                      user.user_role[key].forEach((value: any) => {
                          if (value === appId) {
                              role = key;
                          }
                      });
                  });
              }

              return {
                  // restructuring the data for table columns
                  Key: user._id,
                  Email: user.email,
                  Username: user.username,
                  Created_On: formatDate(user.createdOn,'DD-MM-YYYY'),
                  Modified_On: formatDate(user.modifiedOn,'DD-MM-YYYY'),
                  Role: role ? role.charAt(0).toUpperCase() + role.slice(1) : t("noRoleYetLabel"),
                  Change_Role: { _id: user._id, currentRole: role },
                  Action: {_id: user._id, currentRole: role },
                  Status: {
                      _id: user._id,             
                      isApproved: user.approvedAppids?.includes(appId) ?? false,
                      email: user.email,
                  },
                  Reset_Password: { _id: user._id, email: user.email },
                  Unblock: {
                      _id: user._id,
                      email: user.email,
                      isBlock: user.isblocked ?? false,
                  },
                  Delete_User : user._id,
                  Last_Login:user.previous_login_time
              };
          }) || []; // Ensure data is an array

          setUsersData(() => userData);
          handleSearch(search, userData);
      }
  } catch (error) {
      showError(`${t("somethingWrongLabel")}`);
  }
}


/**
 * confirmUserRevoke function is called when we have to revoke the role of the user
 * @param id gets user id whose role is to be revoked
 */
  const confirmUserRevoke = async (id: string) => {
    try {
      const url: string = API_ENDPOINTS.DELETE_USER_ROLE_URL;
      const payload = {
        app_id: appId,      
        args: JSON.stringify({
          userId: id,
          modified_by: loginUser._id,
          appid: appId,
        }),
        api_key: apiKey,
      }
      const response = await centralApi("GET", url, null, payload);
      if(response){
        getUpdatedData();
        showSuccess(t("successfullyRoleDeleted"))
      }
    } catch (error) {
      showError(t("somethingWrongLabel"))
    }
  };

  useEffect(() => {
    getUpdatedData();

  }, [loginUser]);

  
  /**
   * showAddUserModel open the model for add user when clicking that button
   */
  const showAddUserModel = () => {
    setAddUserModel(true);
  };
  
  /**
   * 
   * @param text takes email string and copies it from copyTxt helper function
   */
  const handleTextCopy = async (text: string) => {
    const res = await copyText(text)
    showSuccess(res.message)
  }
  
  /**
   * 
   * @param value is a boolean value where we assign is user approved to true or false
   * @param id   is an user id of that user which is to be approved or disapproved
   * @param email is an email id of that user which is to be approved or disapproved
   */
  const changeUserStatus = async (value: string, id: any, email: string) => {
  try {
  const url: string = API_ENDPOINTS.USER_APPROVE_URL;
  const booleanState = value == "true" ? true : false;

   const  params = {
      app_id: appId,
      args: JSON.stringify({
        user_id: id,
        modified_by: loginUser._id,
        isapproved: booleanState,
        email:email,  
      }),
      api_key: apiKey,
    }
    const response = await centralApi("GET", url, null, params);
      if(response){
        getUpdatedData();
        showSuccess(t("userUpdatedSuccesfullyLabel"))
      }    
  } catch (error) {
    showError(t("somethingWrongLabel"))
  }
  
  };


  /**
   * 
   * @param id is an appid of that app for which we want to assign role
   * @param value it's a user role (admin,manager,marketer)
   */
  const changeUserRole = async (id: any, value: string) => {
    try {
      const url: string = API_ENDPOINTS.UPDATE_USER_ROLE_URL;
      const params= {
        app_id: appId,
        args: JSON.stringify({
          appid: appId,
          userId: id,
          role: value,
          email: loginUser.email,
        }),
        api_key: apiKey,
        modified_by: loginUser._id,
      }
      const response = await centralApi("GET", url, null, params);
      if(response){
        getUpdatedData();
        showSuccess(t("userUpdatedSuccesfullyLabel"))
      }    
  } catch (error) {
    showError(t("somethingWrongLabel"))
  }
   
  };
  
  /**
   * 
   * @param id takes user id of that user whose password need's t be reseted
   * @param email email of that user whose password need's t be reseted
   */
  const resetPassword = async (id: any, email: string) => {
    try {
      const generatedPassword = generateStrongRandomString(6);
      const url: string = API_ENDPOINTS.RESET_PASSWORD_URL;
      const params = {
        app_id: appId,
        args: JSON.stringify({
          field: generatedPassword,
          userId: id,
          appid: appId,
          modified_by: loginUser._id,
          email: email,
        }),
        api_key: apiKey,
      }
      const data = await centralApi("GET", url, null, params);
      if (data) {
        setCopyModelInputValue(generatedPassword);
        setCopyModelVisibility(true);
      }
    } catch (error) {
      showError(t("somethingWrongLabel"))
    }
  };
 
  /**
   * 
   * @param id takes id of the user which needs to be unblocked 
   * @param email takes email of the user which needs to be unblocked 
   */
  const unblockUser = async (id: any, email: string  ) => {
    try {
      const url: string = API_ENDPOINTS.UNBLOCK_USER_URL;
      const params = {
        args: JSON.stringify({
          userId: id,
          modified_by: loginUser._id,
          email: email,
        }),
        app_id: appId,
        api_key : apiKey
      };
      const response = await centralApi("GET",url,null,params);
      if (response){
        getUpdatedData();
        showSuccess(`${email} ${t("isUnblockedSuccessfullyMsg")}`);   
      }
      else{
        showError(`${t("somethingWrongLabel")}`)
      }
      
    } catch (error) {
      showError(`${t("somethingWrongLabel")}`)
    } 
  };

  const deleteUser = async (id : string) => {
    try {
      const url : string = API_ENDPOINTS.DELETE_USER_URL;
      const params = {
        args: JSON.stringify({
          userId: id,
          modified_by: loginUser._id,
          appid : appId
        }),
        app_id: appId,
        api_key : apiKey
      }
      const response = await centralApi("GET",url,null,params);
      if (response){
        getUpdatedData();
        showSuccess(t("userDeletedSuccessfullyMsg"));   
      }
    } catch (error) {
      showError(t("somethingWrongLabel"));
    }
  }

  //Name of columns and default enteries
  const columns: any = [
    {
      title: t("emailLabel"),
      dataIndex: "Email",
      className: "fix",
      width: 80,
      render: (email: string) => {
        return ( 
          <span className="d-flex align-items-center justify-content-between">
             {email}
             <CopyTwoTone onClick={() => handleTextCopy(email)} />
          </span>
        );
      }
       
    },
    {
      title: t("createdOnLabel"),
      dataIndex: "Created_On",
      width: 40,
      className: "item-align",
    },
    {
      title: t("modifiedOnLabel"),
      dataIndex: "Modified_On",
      width: 40,
      className: "item-align",
    },
    {
      title: t("lastLoginLabel"),
      dataIndex: "Last_Login",
      width: 140,
      className: "item-align",
    },
    {
      title: t("roleLabel"),
      dataIndex: "Role",
      width: 80,
      className: "item-align",
    },

     {
      title: t("changeRoleLabel"),
      dataIndex: "Change_Role",
      width: 100,
      className: "item-align",
      render: ({ _id, currentRole}: { _id: any , currentRole:string}) => {
        return (
            <Selector
              value={currentRole}
              onChange={(value) => {
                changeUserRole(_id, value);
              }} 
              disabled={!currentRole}
              style={{ width: 150 }}
              options={[
                {
                  value: UserRoleEnum.APP_ADMIN,
                  label: t("adminLabel"),
                },
                {
                  value: UserRoleEnum.APP_MANAGER,
                  label: t("managerLabel"),
                },
                {
                  value: UserRoleEnum.APP_MARKETER,
                  label: t("marketerLabel"),
                },
                {
                  value: UserRoleEnum.APP_REVIEWER,
                  label: t("reviewerLabel"),
                },
                {
                  value: UserRoleEnum.APP_INFOSEC,
                  label: t("infosecLabel"),
                },
                {
                  value: UserRoleEnum.APP_CONTACT_CENTER,
                  label: t("contactCenterLabel"),
                },
              ]}
            />
          )
      },
    },
    {
      title: t("removeAccessLabel"),
      dataIndex: "Action",
      width: 40,
      className: "item-align",

      render: ({ _id, currentRole}: { _id: any , currentRole:string}) => {
        return (
            <ConfirmPopUp
              title={t("deleteRoleMsg")}
              onConfirm={confirmUserRevoke}
              id={_id}
              icon={minusIcon}
            >
              <CustomToolTip title={t("removeAccessLabel")}>
              <PrimaryButton disabled={!currentRole}>
                <img  src={minusIcon}  alt={"minus_icon"} className="minusIcon"></img>
              </PrimaryButton>
              </CustomToolTip>
            </ConfirmPopUp>
          )
      },
    },
    {
      title: t("statusLabel"),
      dataIndex: "Status",
      width: 100,
      className: "item-align",
      render: (value: { isApproved: boolean; _id: any; email: string }) => {
        return (
            <ToggleBtn
              isApproved={value.isApproved}
              onChange={(e: any) => {
                changeUserStatus(e.target.value, value._id, value.email);
              }}
            />
          )
      },
    },
    ...(ssoLogin === EDStatusEnum.DISABLE ?
    [
    {
      title: t("resetPasswordShortLabel"),
      dataIndex: "Reset_Password",
      width: 40,

      className: "item-align",
      render: (value: any) => {
        return (
          
            <CustomToolTip title={t("resetLabel")}>
            <PrimaryButton
              onClick={() => resetPassword(value._id, value.email)}
            >
              <img
                style={{ transform: "rotate(20deg)" }}
                src={keyIcon}
                alt=""
              ></img>
            </PrimaryButton>
            </CustomToolTip>
          
        );
      },
    },
    {
      title: t("unBlockLabel"),
      dataIndex: "Unblock",
      width: 40,

      className: "item-align",
      render: (value: any) => {
        return (
            <>
            {value.isBlock ? (
                <CustomToolTip title={t("unBlockLabel")}>
                  <PrimaryButton onClick={() => unblockUser(value._id, value.email)}>
                    <img src={lockIcon} alt="" />
                  </PrimaryButton>
                </CustomToolTip>
              ) : (
                <PrimaryButton disabled>
                  <img src={unlockIcon} alt="" />
                </PrimaryButton>
              )}
            </>
          )
      },
    }] : []),
    {
      title: t("deleteUserLabel"),
      dataIndex: "Delete_User",
      width: 40,
      role :roleCheckTypes.GlobalAdmin,

      className: "item-align",
      render: (_id: string) => {
        return (
            <ConfirmPopUp
              title={t("deleteUserMsg")}
              onConfirm={deleteUser}
              id={_id}
              icon={deleteIcon}
            >
              <CustomToolTip title={t("deleteLabel")}>
              <PrimaryButton>
                <img  src={deleteIcon} alt={"delete_icon"} ></img>
              </PrimaryButton>
              </CustomToolTip>
            </ConfirmPopUp>
          )
      }
    }
  ];

  /**
   * 
   * @param value takes value that is to be searched 
   * @param data  takes all users data  
   */
  const handleSearch = (value:string,data:any=null) => {
    setSearch(()=>value)
    const originalData=data?data:usersData
    const regex = new RegExp(value, "i");
    setUsersDataCopy(
      originalData?.filter((user: any) => {
        return user.Email ? regex.test(user.Email) : false;
      })
    );
  };
  const handleDownloadExport=async()=>{
    
    
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      api_key: apiKey,
      app_id: appId,

    
    };
    
    try {
      await centralApi("GET", API_ENDPOINTS.DOWNLOAD_USER_MANAGEMENT_DATA, null, payload);
    showSuccess(t("downloadInitiatedLabel"))
      //  await centralApi("GET", url, null, payload);
    } catch (e: any) {
      
    }
    
    
    
      }
  return (
    <div className="container-fluid py-3 px-sm-3 px-1 user-table-container">
      <Helmet>
        <title>Appice | All Users</title>
      </Helmet>
      <AccessControl items={columns} getAccessedItems={(items : any)=> setFilteredColumns(items)}> <> </>   </AccessControl>
      <div className=" w-100   overflow-hidden">
        <div className="py-4 ">
          <CopyModal
            visibility={copyModelVisibility}
            setVisibility={setCopyModelVisibility}
            inputValue={copyModelInputValue}
          />

          <AddUserModel
            visibility={addUserModel}
            setVisibility={setAddUserModel}
            getUpdatedData={getUpdatedData}
          />
          <div className="d-flex flex-row flex-nowrap w-100">
            <div className="w-100  d-flex">
              <Search
                placeholder={`${t("searchLabel")}`}
                onChange={(e)=>handleSearch(e.target.value)}
                size="middle"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  marginRight: "10px",
                }}
              />
              <Selector
                onChange={(value) => {
                  setEntries(value);
                }}
                style={{ width: "auto", marginRight: "10px" }}
                size="middle"
                defaultValue={entries}
                options={PAGE_OPTIONS.DATA}
              />
            </div>
            <div>
                <PrimaryButton
                  size="middle"
                  type="primary"
                  style={{ border: "none" }}
                  onClick={showAddUserModel}
                >
                {t("addUserLabel")}
                </PrimaryButton>
            </div>
            {userRole === UserRoleEnum.APP_INFOSEC ?
            <div className="d-flex ms-2 gap-1 ">
            <CustomToolTip title={t("downloadCsvLabel")}>

            <PrimaryButton 
 onClick={handleDownloadExport}    >
            <img src={downloadIcon}         alt="download icon"></img>
          </PrimaryButton>
          </CustomToolTip>
          </div>:null}
          </div>
        </div>

        <CustomTable
          columns={filterColumns}
          dataSource={usersDataCopy.map((user: any) => ({
            ...user,
            key: user.Key,
          }))}
          size={tableSize}
          pagination={{ pageSize: entries, simple: false }}
          scroll={{ x: 1500 }}
        />
      </div>
    </div>
  );
}
export default UserManagement;
UserManagement.defaultProps = {
  tableSize: "small"
};
