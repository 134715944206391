import { Col, Form, Row, Space } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton'
import TextInput from 'components/Inputs/TextInput/TextInput';
import ShowMessage from 'components/ShowMessage/ShowMessage';
import "./UserList.css"
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import centralApi from 'services/centralApi';
import { API_ENDPOINTS } from 'utils/constants';
import Spinner from 'components/Spinner/Spinner';
import CustomTextArea from 'components/Inputs/CustomTextArea/CustomTextArea';
import { t } from 'i18next';
import CustomTable from 'components/CustomTable/CustomTable';
import Search from 'components/Inputs/Search/Search';
import Selector from 'components/Selector/Selector';
import { BOOLEAN_OPTIONS, PAGE_OPTIONS } from 'utils/constants/selectorOptions';
import CustomDrawer from 'components/CustomDrawer/CustomDrawer';

const UserList = () => {
    const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
    const loginUser = useSelector((state: any) => state.loginUser);
    const activeApp = useSelector((state: any) => state.activeApp);
    const authToken = useSelector((state: any) => state.authToken.token);
    const [didDetails , setDidDetails] = useState<any>(); //stores response from search did functionality
    const [pushResponse , setPushResponse] = useState();  //stores response from send push functionality
    const [mongoFields , setMongoFields] = useState<any>() //stores mongofields from search did functionality
    const [pgSqlFields , setPgSqlFields] = useState<any>() //stores pgsql from search did functionality
    const [customData, setCustomData] = useState({}); // stores cdata from appinbox  for custom drawer
    const [lastEventsDataCopy , setLastEventsDataCopy] = useState<any[]>() //stores copy of last 50 events from search did functionality for searching and pagination
    const [appInboxDataCopy ,setAppInboxDataCopy] = useState<any>() //stores copy of appinbox data from search did functionality for searching and pagination
    const [showDrawer, setShowDrawer] = useState(BOOLEAN_OPTIONS.FALSE); // used for custom drawer 
    const [form] = Form.useForm();
   
    //used for appinbox data for pagination
    const [pageData, setPageData] = useState({
      pageSize: PAGE_OPTIONS.DEFAULT_VALUE,
      simple: PAGE_OPTIONS.PAGINATION.simple
    })
     //used for last 50 events data for pagination
    const [lePageData ,setLEPageData] = useState({
        pageSize: PAGE_OPTIONS.DEFAULT_VALUE,
        simple: PAGE_OPTIONS.PAGINATION.simple
      })

    //used for popup message
    const [showMessage, setShowMessage] = useState<any>({
      type: "success",
      message: "",
      show: BOOLEAN_OPTIONS.FALSE,
    });
    const appId = activeApp.appId;
    const api_key = loginUser.data.api_key;
     
  

    //columns for C Data table
    const customDataColumns = [
        { title: 'Key', dataIndex: 'key', key: 'key' },
        { title: 'Value', dataIndex: 'value', key: 'value' },
    ];
     
    //columns for appInbox table
    const appInboxColumns = [
      { title: 'cdata', dataIndex: 'cdata', key: 'cdata' ,  width: 10,
      render: (_: any, record: any) => {

        return (
            <PrimaryButton
            className="action-btn mx-1"
            onClick={() => {
               const data = record.cdata;
               setCustomData(Object.entries(data).map(([key, value]) => ({ key, value }))); // Set the custom data state
               setShowDrawer(BOOLEAN_OPTIONS.TRUE);
            }}
          >
            {t("viewCdataLabel")}
          </PrimaryButton>
        );
      } },
      { title: 'ct', dataIndex: 'ct', key: 'ct' },
      { title: 'et', dataIndex: 'et', key: 'et' },
      { title: 'Language', dataIndex: 'ln', key: 'ln' },
      { title: 'ni', dataIndex: 'ni', key: 'ni' },
      { title: 'nid', dataIndex: 'notificationId', key: 'notificationId' },
      { title: 'nm', dataIndex: 'nm', key: 'nm' },
      { title: 'nt', dataIndex: 'nt', key: 'nt' },
      { title: 'st', dataIndex: 'st', key: 'st' },
    ];

    //columns for last50events table
    const lastEventsColumns = [
      {title: "Event Time" , dataIndex : 'eventtime' , id :1},
      {title: "Key" ,dataIndex : "key" , id : 2 }
    ]
    
    /**
     * searchDid function calls an api for getting did details 
     * @param values takes a did for search functionality
     * stores the did response in states for rendering of different data fields
     */
    async function searchDid (values : any){
       const {search_did} = values;
       const searchValue = {
        app_id: appId,
        did: search_did
    }
        const params = {
          api_key: api_key,
          args: JSON.stringify(searchValue)
      };

      try {
          const url = API_ENDPOINTS.SEARCH_DID_URL;
          setLoading(BOOLEAN_OPTIONS.TRUE);
          const response = await centralApi("GET", url, null, params);
          setDidDetails(response);
          setAppInboxDataCopy(response.appInbox);
          setLastEventsDataCopy(response.last50Events);
          setMongoFields(response.mongoFields);
          setPgSqlFields(response.pgsqlFields);
          setLoading(BOOLEAN_OPTIONS.FALSE);
          
      } catch (error) {
          setLoading(BOOLEAN_OPTIONS.FALSE);
          setShowMessage({
              type: "error",
              message: "Failed to Search DID",
              show: BOOLEAN_OPTIONS.TRUE,
          });
      }

      hideMessage();
    }

    /**
     * updateFcmToken updates push token for that particular DID
     * @param values takes did and push token from input areas 
     */
    async function updateFcmToken(values : any){
      const {fcmDid , fcmToken} = values;
      const params : any ={
        app_id : appId,
        api_key,
        authToken,
        token : fcmToken,
        did : fcmDid
      }

      const data = new FormData();
      for (const key in params) {
        data.set(key, params[key]);
      }

      try {
        const url: string = API_ENDPOINTS.UPDATE_FCM_TOKEN_URL;
        setLoading(BOOLEAN_OPTIONS.TRUE);
        await centralApi("POST", url, data , params);
        setLoading(BOOLEAN_OPTIONS.FALSE);
        setShowMessage({
          type: "success",
          message: "Successfully Updated",
          show: BOOLEAN_OPTIONS.TRUE,
      });
        
    } catch (error) {
       setLoading(BOOLEAN_OPTIONS.FALSE);
        setShowMessage({
            type: "error",
            message: "Failed to Update fcm Token",
            show: BOOLEAN_OPTIONS.TRUE,
        });
    }
    hideMessage();
    }

    /**
     * sendPush checks the push response 
     * @param values takes appid and did from input fields
     */
    async function sendPush(values: any) {
      const { push_appId, push_did } = values;
  
      const payload = {
          authToken,
          app_id: push_appId,
          did: push_did
      };
  
      try {
          const url: string = API_ENDPOINTS.SEND_PUSH_URL;
          setLoading(BOOLEAN_OPTIONS.TRUE);
          const response = await centralApi("POST", url, payload);
          setPushResponse(response);
          const responseString = JSON.stringify(response, null, 2);
          form.setFieldsValue({ pushResponse: responseString });
          setLoading(BOOLEAN_OPTIONS.FALSE);
          
      } catch (error) {
         setLoading(BOOLEAN_OPTIONS.FALSE);
          setShowMessage({
              type: "error",
              message: "Failed to send push notification.",
              show: BOOLEAN_OPTIONS.TRUE,
          });
      }
      hideMessage();
   }

   /**
    * downloadLogs function is used for downloading  csv file for logs 
    */
    async function downloadLogs() {
        const payload = {
          args: JSON.stringify({
            app_id: appId,
          }),
          api_key: api_key,
        };
    
        const url: string = API_ENDPOINTS.DOWNLOAD_LOGS_URL;
        try {
          const responseData = await centralApi("GET", url, null, payload);
          const blob = new Blob([responseData], { type: "application/json" });
    
          // Create object URL for the Blob
          const data = URL.createObjectURL(blob);
    
          // Create download link
          const link = document.createElement("a");
          link.href = data;
          link.download = "combinedlog.txt"; // Filename for the downloaded file
    
          // Optionally, trigger the download programmatically
          link.dispatchEvent(new MouseEvent("click"));
    
          // Revoke the object URL to free up resources
          URL.revokeObjectURL(url);
    
          setShowMessage({
            type: "success",
            message: "dowmloaded logs ",
            show: BOOLEAN_OPTIONS.TRUE,
          });
        } catch (error) {
          setShowMessage({
            type: "error",
            message: "Something went wrong",
            show: BOOLEAN_OPTIONS.TRUE,
          });
        }
        hideMessage();
      }

      /**
       * hideMessage funcyion is used to hide the pop message once it gets rendered
       */
      const hideMessage = () => {
        setTimeout(() => {
          setShowMessage({ type: "success", message: "", show: BOOLEAN_OPTIONS.FALSE });
        }, 2000);
      };

      /**
       * 
       * @param e is the search value which needs to be searched
       * @param source is used for dynamic search for appinbox(appInbox) and last50events(eventList)
       * it stires the filtered data and is used for rendering that searched value
       */
      const handleSearch = (e: any, source:string) => {
        const searchValue = e.target.value.toLowerCase();
        let filteredData;
    
        if (source === 'appInbox') {
            filteredData = didDetails.appInbox?.data?.filter((item: any) =>
                Object.keys(item).some(key =>
                    item[key]?.toString().toLowerCase().includes(searchValue)
                )
            );
            // Update appInboxDataCopy with the filtered data while preserving the count
            setAppInboxDataCopy({
                count: filteredData?.length ?? 0, // Optionally update the count based on the filtered data length
                data: filteredData
            });
        } else if (source === 'eventList') {
            filteredData = didDetails?.last50Events?.filter((item: any) =>
                Object.keys(item).some(key =>
                    item[key]?.toString().toLowerCase().includes(searchValue)
                )
            );
            // Update lastEventsDataCopy with the filtered data
            setLastEventsDataCopy(filteredData);
        }
    };

    /**
     * 
     * @param value is from the mongofield and pgsql field in which we stringfy the data for rendering that object on FE 
     * @returns string value that is used for mongofields and pgsql fields
     */
    const renderValue = (value : any) => {
        if (typeof value === 'object' && value !== null) {
          // Handle cases where the value is an object
          return JSON.stringify(value);  // Convert object to string
        } else if (Array.isArray(value)) {
          // Handle arrays
          return value.join(', ');  // Join array elements with commas
        } else {
          // Handle strings, numbers, and other primitives
          return String(value);
        }
      };
        
      return (
        <div className="user-list-container">
          {showMessage.show && (
            <ShowMessage type={showMessage.type} content={showMessage.message} />
          )}
           {loading ? (
            <Spinner />
          ) : (
          <div className="user-list-form mt-4 mb-4">
           
            <div className="mt-4 mb-4 " >
          
              <Row gutter={16}>
               <Form
                form={form}
                layout="horizontal"
                onFinish={sendPush}
                 >

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name={"push_appId"}
                    initialValue={""}
                  >
                    <TextInput placeholder={'App Id'} />
                  </Form.Item>
                  <Form.Item
                    name={"push_did"}
                    initialValue={""}
                  >
                    <TextInput   placeholder={'DID'} />
                  </Form.Item>
                  <PrimaryButton type="primary" htmlType="submit">
                    {t("sendPushLabel")}
                  </PrimaryButton>
                  { pushResponse &&(
                  <div className='mt-2'>
                    <Form.Item
                    name="pushResponse" 
                  >
                    <CustomTextArea rows={8} value={form.getFieldValue("pushResponse")} />
                  </Form.Item>
                  </div>
                  )}
                </Col>
                </Form>

                {/* Second Column */}
                <Form
                layout="horizontal"
                onFinish={searchDid}
                 >
                <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                    name={"search_did"}
                    initialValue={""}
                  >
                    <TextInput className={"mb-1 ms-5"} placeholder={"Enter DID"} />
                </Form.Item>
                    <PrimaryButton className={"ms-5"} type="primary" htmlType="submit" >
                     {t("searchLabel")}
                    </PrimaryButton>
                </Col>
                </Form>
               </Row>    
            </div>
             <div className="pt-4" style={{borderTop :"1px solid lightgrey"}}> 
             <Form
              layout="horizontal"
              onFinish={updateFcmToken}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                    name={"fcmDid"}
                    initialValue={""}
                  >
                    <TextInput placeholder={'Enter DID'} />
                  </Form.Item>
                  <Form.Item
                    name={"fcmToken"}
                    initialValue={""}
                  >
                    <TextInput maxLength={300} placeholder={'Enter Push Token'} />
                  </Form.Item>
                  <PrimaryButton type="primary" htmlType="submit" >
                    {t("updateFcmTokenLabel")}
                  </PrimaryButton>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                <div className='mt-3'>
                      <PrimaryButton type="primary" onClick={() => downloadLogs()}>
                        {t("downloadLogsLabel")}
                      </PrimaryButton>
                    </div>
                </Col>
              </Row>
              </Form>
              </div>
              {/* Rendering this part when we search DID's */}
              {didDetails &&(
              <>
               <div className='pt-4 mt-3 user-list-pushtoken' style={{borderTop :"1px solid lightgrey"}}>
                <h6>{t("pushTokenLabel")}</h6>
                <CustomTextArea rows={3} value={didDetails.pushToken} />
               </div>

               <div className='pt-4 mt-3 user-list-appinbox' style={{borderTop :"1px solid lightgrey"}}> 
                <h6>{t("appInboxLabel")}</h6>
                <div className='mt-2 mb-2'>
                  <Search
                      placeholder={`${t("searchLabel")}`}

                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      marginRight: "10px",
                      marginBottom: "10px",
                      height: "100%",
                    }}
                    onChange={(e) => handleSearch(e, 'appInbox')}
                  />
                  <Selector
                  onChange={(value) => {
                    setPageData({ ...pageData, pageSize: value })
                    }}
                    options={PAGE_OPTIONS.DATA} 
                    defaultValue={pageData.pageSize}
                  />
                </div>
                <CustomTable 
                   columns={appInboxColumns} 
                   dataSource={appInboxDataCopy?.data} 
                   pagination={pageData}
                   scroll={{ x: 1500 }}
                   size="small"
                />
               </div>
               <div className='pt-4 mt-3  user-list-lastevents' style={{borderTop :"1px solid lightgrey"}}> 
               <h6>{t("last50eventsLabel")}</h6>
                <div className='mt-2 mb-2'>
                  <Search
                      placeholder={`${t("searchLabel")}`}

                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      marginRight: "10px",
                      marginBottom: "10px",
                      height: "100%",
                    }}
                    onChange={(e) => handleSearch(e, 'eventList')}
                  />
                  <Selector
                  onChange={(value) => {
                    setLEPageData({ ...lePageData, pageSize: value })
                    }}
                    options={PAGE_OPTIONS.DATA} 
                    defaultValue={lePageData.pageSize}
                  />
                
               </div>
               <div className='last-events-table'> 
               <CustomTable 
                   rowKey="id"
                   columns={lastEventsColumns} 
                   dataSource={lastEventsDataCopy} 
                   pagination={lePageData}
                />  
               </div>               
              </div>
              <div className='pt-4 mt-3 overflow-auto user-list-mongolist ' style={{borderTop :"1px solid lightgrey"}}>
              <h6>{t("mongoFieldsLabel")}</h6>
                <div className='mt-2 mb-2'>
                 <ul>
                    {mongoFields && typeof mongoFields === 'object' ? (
                        Object.keys(mongoFields)
                        .filter(key => mongoFields[key] !== null) // Exclude null values
                        .map((key, index) => (
                            <li key={index}>
                            <strong>{key}:</strong> <small>{renderValue(mongoFields[key])}</small>
                            </li>
                        ))
                    ) : (
                        <li>{t("noFieldsAvailableLabel")}</li>
                    )}
                </ul>
                </div>
              </div>
              <div className='pt-4 mt-3 overflow-auto user-list-pgsqlfields ' style={{borderTop :"1px solid lightgrey"}}>
              <h6>{t("pgsqlFieldsLabel")}</h6>
                <div className='mt-2 mb-2'>
                <ul>
                    {pgSqlFields && typeof pgSqlFields === 'object' ? (
                        Object.keys(pgSqlFields)
                        .filter(key => pgSqlFields[key] !== null) // Exclude null values
                        .map((key, index) => (
                            <li key={index}>
                            <strong>{key}:</strong> <small>{renderValue(pgSqlFields[key])}</small>
                            </li>
                        ))
                    ) : (
                        <li>{t("noFieldsAvailableLabel")}</li>
                    )}
                </ul>
                </div>
              </div>
              <CustomDrawer
                open={showDrawer}
                placement="right"
                onClose={() => {
                setShowDrawer(BOOLEAN_OPTIONS.FALSE);
                }}
                className="p-0"
                closable={BOOLEAN_OPTIONS.FALSE}
                width={540}
                footer={
                <Space className="d-flex justify-content-end">
                    <PrimaryButton
                    onClick={() => {
                        setShowDrawer(BOOLEAN_OPTIONS.FALSE);
                    }}
                    >
                    {t("cancelLabel")}
                    </PrimaryButton>
                </Space>
                }
                height={"90%"}
             >
               {/* Display the CustomTable with dynamic data */}
                <CustomTable
                columns={customDataColumns}
                dataSource={customData}
                pagination={BOOLEAN_OPTIONS.FALSE}
                />
            </CustomDrawer>

              </>
             )}
          </div>
           )}
        </div>
  )
}

export default UserList