import React, { FC } from 'react'
import NudgeScreen from '../NudgeScreen/NudgeScreen';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import { PlusOutlined } from '@ant-design/icons';
import img2 from "images/nudgeimg1.jpeg"
import img3 from "images/nudgeimg3.jpeg"


import "./AddNudgeScreen.css"
interface AddNudgeScreenProps {
  screen:any[];
  max:number;
  setScreen: React.Dispatch<React.SetStateAction<any[]>>
  onClick:(index:number)=>void
}

const AddNudgeScreen: FC<AddNudgeScreenProps> = ({ screen,setScreen ,max,onClick}) => {
  const imgArray=[img2,img3]
    const handleScreenInputChange = (index:number, newValue:string) => {
        setScreen((prevScreen) => {
          const updatedScreen = [...prevScreen]; // create a copy of the current state
          updatedScreen[index] = { ...updatedScreen[index], name: newValue }; // update the specific index
          return updatedScreen; // return the updated state
        });
      };
      
    return (
        <div className="nudge-add-screen-main-container h-100">
          <div className="d-flex justify-content-between">
            <span>Add mobile app screen on which nudge campaign will run.</span>
            <PrimaryButton title={`You can add maximum ${max} screens`} disabled={screen.length>=max} onClick={()=>setScreen((prevState)=>([...prevState,{file:imgArray[screen.length-1]}]))} type="primary">
              <PlusOutlined className="me-1" /> Screen
            </PrimaryButton>
          </div>
          <div className="nudge-add-screen-body-container py-1 d-flex justify-content-around">
            {screen.map((_,index)=>{
              return <NudgeScreen index={index} screen={screen} onScreenClick={()=>onClick(index)} onScreenInputChange={(e)=>handleScreenInputChange(index,e.target.value)}/>
            })}
          </div>
          <div className="d-flex justify-content-end">
            <PrimaryButton
            onClick={()=>onClick(0)}
              style={{
                color: "white",
                background: "var(--color-red-main)",
              }}
            >
              <span className="px-1">Continue</span>
            </PrimaryButton>
          </div>
        </div>
      );
}

export default AddNudgeScreen;