import React, { useEffect, useState } from "react";
import { DonutPieChart } from "../../components/Charts/DonutPieChart/DonutPieChart";
import CustomTable from "../../components/CustomTable/CustomTable";
import Selector from "../../components/Selector/Selector";
import { colorSchema } from "../../components/Charts/DonutPieChart/colorSchema";
import centralApi from "../../services/centralApi";
import { useSelector } from "react-redux";
import {
  acqChurnAppVersionModifiedData,
  acquisitionOSModifiedData,
  acqSrcChurnModifiedData,
  dayWiseModifiedData,
  locationModifiedData,
} from "./getData";
import { t } from "i18next";
import { generateCSV } from "../../helperFunctions/common";
import {
  dayHourWiseData,
  transformVersionData,
  transformLocationData,
  transformOsData,
} from "../../helperFunctions/acquisitionChurn";
import CustomHighChart from "../../components/Charts/CustomHighChart/CustomHighChart";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import { DateRangeData } from "@customTypes/dateRange";
import useMessage from "hooks/useMessage";
import TableChartLayout from "layout/TableChartLayout/TableChartLayout";
import { FeatureSettingsFlagTypes } from "utils/constants/constants";
import AccessControl from "components/HOC/AccessControl/AccessControl";

type propTypes = {
  churn: boolean;
};
function AcquisitionChurn({ churn }: propTypes) {
  const type = churn ? "U" : "I";
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  // const dateRange = useSelector((state: any) => state.dateRange);
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);

  const [acqSrcChurn, setAcqSrcChurn] = useState<any>({
    modified: {},
    unModified: {},
  });
  const [appVersions, setAppVersions] = useState({
    modified: { versions: [], total: 0 },
    unModified: [],
  });
  const [OSData, setOSData] = useState({
    modified: { versions: [], total: 0 },
    unModified: {},
  });

  const [stateDayWiseData, setStateDayWiseData] = useState<any>({
    modified: [],
    unModified: { newData: [], repeatData: [] },
  });
  const [stateTimeWiseData, setStateTimeWiseData] = useState<any>({
    modified: [],
    unModified: { newData: [], repeatData: [] },
  });
  const [locationData, setLocationData] = useState<any>({
    modified: [],
    unModified: { cityWiseData: [], countryWiseData: [] },
  });
  const [acqSrcChurnFilter, setAcqSrcChurnFilter] = useState("all");
  const [versionFilter, setVersionFilter] = useState("all");
  const [OSFilter, setOSFilter] = useState("all");
  const [timeWiseFilter, setTimeWiseFilter] = useState("all");
  const [dayWiseFilter, setDayWiseFilter] = useState("all");
  const [acqSCData, setAcqSCData] = useState([]);
  const [locationFilter, setLocationFilter] =
    useState<"country" | "city">("city");

  const [acqSCFilter, setAcqSCFilter] = useState("S");
  const [acqPlatformFilter, setAcqPlatformFilter] = useState("all");
  const { showError } = useMessage();

  const locationColumns = [
    {
      title: t("locationLabel"),
      dataIndex: "location",
    },
    {
      title: t("totalLabel"),
      dataIndex: "cur",
    },
    {
      title: "",
      dataIndex: "prev",
      render: () => "NA",
    },
  ];
  const sourcesColumns = [
    {
      title: churn ? t("androidLabel") : t("newAcqLabel"),
      dataIndex: churn ? "android" : "newAcquisition",
    },
    {
      title: churn ? t("iosLabel") : t("repeatAcqLabel"),
      dataIndex: churn ? "web" : "repeatAcquisition",
    },
    {
      title: churn ? t("webLabel") : t("totalLabel"),
      dataIndex: churn ? "web" : "total",
    },
  ];
  const AcquistionColumns = [
    {
      title: t("sourceLabel"),
      dataIndex: "cmp",
    },
    {
      title: t("acqLabel"),
      dataIndex: "in",
    },
    {
      title: t("avgCPILabel"),
      dataIndex: "avgCPI",
    },
    {
      title: t("spendLabel"),
      dataIndex: "sp",
      render: (value: number) => `₹${value}`,
    },
  ];
  const OSColumns = [
    {
      title: t("platformLabel"),
      dataIndex: "platform",
    },
    {
      title: t("totalLabel"),
      dataIndex: "total",
    },
  ];

  const DayWiseColumns =[
    {
      title: t("dayWiseLabel"),
      dataIndex: "name",
    },
    {
      title: t("newLabel"),
      dataIndex: "new",
    },
    {
      title: t("repeatLabel"),
      dataIndex: "repeat",
    },
  ]

  const TimeWiseColumns =[
    {
      title: t("timeWiseLabel"),
      dataIndex: "name",
    },
    {
      title: t("newLabel"),
      dataIndex: "new",
    },
    {
      title: t("repeatLabel"),
      dataIndex: "repeat",
    },
  ]
  const platformOptions = [
    {
      value: "all",
      label: t("allLabel"),
    },
    {
      value: "android",
      label: t("androidLabel"),
    },
    {
      value: "ios",
      label: t("iosLabel"),
    },
    {
      value: "web",
      label: t("webLabel"),
    },
  ];
  const getInstallUnistallStatsByParam = async () => {
    try {
      const params = {
        args: JSON.stringify({ ...dateRange, type }),
        api_key: loginUser.api_key,
        app_id: appId,
      };
      let {
        appversion,
        osversionWiseData,
        dayWiseData,
        timeWiseData,
        cityWiseData,
        cityWiseData_currentData,
        countryWiseData_currentData,
        appversionWiseData_currentData,
        dayWiseData_newData,
        dayWiseData_repeatData,
        timeWiseData_newData,
        timeWiseData_repeatData,
        countryWiseData,
      } = await centralApi(
        "GET",
        "o/metrices/getInstallUnistallStatsByParam",
        null,
        params
      );
      const modified_data = acqChurnAppVersionModifiedData(
        churn ? appversionWiseData_currentData : appversion,
        versionFilter,
        churn,
        5
      );

      setAppVersions({
        modified: modified_data,
        unModified: churn ? appversionWiseData_currentData : appversion,
      });
      const { OScurrentData, OSpreviousData } = !churn
        ? osversionWiseData
        : { OScurrentData: undefined, OSpreviousData: undefined };
      const os_modified_data = acquisitionOSModifiedData(
        OScurrentData,
        OSFilter,
        5
      );
      setOSData({ modified: os_modified_data, unModified: OScurrentData });
      const locations_modified_data = locationModifiedData(
        churn ? cityWiseData_currentData : cityWiseData,
        churn ? countryWiseData_currentData : countryWiseData,
        locationFilter,
        churn
      );

      setLocationData({
        modified: locations_modified_data,
        unModified: {
          cityWiseData: churn ? cityWiseData_currentData : cityWiseData,
          countryWiseData: churn
            ? countryWiseData_currentData
            : countryWiseData,
        },
      });
      // dayWiseData will be modified if the page is churn
      dayWiseData = churn
        ? { newData: dayWiseData_newData, repeatData: dayWiseData_repeatData }
        : dayWiseData;
      const day_wise_data = dayWiseModifiedData(
        dayWiseData,
        dayWiseFilter,
        "d"
      );
      setStateDayWiseData({ modified: day_wise_data, unModified: dayWiseData });
      // timeWiseData will be modified if the page is churn
      timeWiseData = churn
        ? { newData: timeWiseData_newData, repeatData: timeWiseData_repeatData }
        : timeWiseData;
      const time_wise_data = dayWiseModifiedData(
        timeWiseData,
        timeWiseFilter,
        "h"
      );
      setStateTimeWiseData({
        modified: time_wise_data,
        unModified: timeWiseData,
      });
    } catch (error) {
      showError((error as Error).message);

    }
  };
  const getInstallUnistallStatsBySource = async () => {
    try {
      const params = {
        args: JSON.stringify({ ...dateRange, type }),
        api_key: loginUser.api_key,
        app_id: appId,
      };
      const { installs } = await centralApi(
        "GET",
        "o/metrices/getInstallUnistallStatsBySource",
        null,
        params
      );
      const acquisition_src_modified_data = acqSrcChurnModifiedData(
        installs,
        acqSrcChurnFilter,
        churn
      );
      setAcqSrcChurn({
        modified: acquisition_src_modified_data,
        unModified: installs,
      });
    } catch (error) {
      showError((error as Error).message);

    }
  };
  const getInstallData = async () => {
    try {
      const params = {
        args: JSON.stringify({
          ...dateRange,
          type: acqSCFilter,
          platform: acqPlatformFilter,
        }),
        api_key: loginUser.api_key,
        app_id: appId,
      };
      const data = await centralApi(
        "GET",
        "o/metrices/getInstallData",
        null,
        params
      );
      setAcqSCData(
        data.map((item: any) => {
          return { ...item, avgCPI: item.sp / item.in };
        })
      );
    } catch (error) {
      showError((error as Error).message);
    }
  };
  useEffect(() => {
    if (loginUser?.api_key && dateRange) {
      getInstallUnistallStatsBySource();
      getInstallUnistallStatsByParam();
    }
  }, [loginUser, dateRange, churn]);
  useEffect(() => {
    if (loginUser?.api_key && !churn && dateRange) {
      getInstallData();
    }
  }, [acqSCFilter, acqPlatformFilter, dateRange, loginUser, churn]);

  useEffect(() => {
    try {
      const acqsrc_churn_data = acqSrcChurnModifiedData(
        acqSrcChurn.unModified,
        acqSrcChurnFilter,
        churn
      );
      setAcqSrcChurn({ ...acqSrcChurn, modified: acqsrc_churn_data });
      const versions_data = acqChurnAppVersionModifiedData(
        appVersions.unModified,
        versionFilter,
        churn,
        5
      );
      setAppVersions({ ...appVersions, modified: versions_data });
      const os_data = acquisitionOSModifiedData(OSData.unModified, OSFilter, 5);
      setOSData({ ...OSData, modified: os_data });
      const day_wise = dayWiseModifiedData(
        stateDayWiseData.unModified,
        dayWiseFilter,
        "d"
      );
      const location_data = locationModifiedData(
        locationData.unModified.cityWiseData,
        locationData.unModified.countryWiseData,
        locationFilter,
        churn
      );
      setLocationData({ ...locationData, modified: location_data });
      const time_wise = dayWiseModifiedData(
        stateTimeWiseData.unModified,
        timeWiseFilter,
        "h"
      );
      setStateDayWiseData({ ...stateDayWiseData, modified: day_wise });
      setStateTimeWiseData({ ...stateTimeWiseData, modified: time_wise });
    } catch (error) {
      showError((error as Error).message);
    }
  }, [
    loginUser,
    acqSrcChurnFilter,
    versionFilter,
    OSFilter,
    dayWiseFilter,
    timeWiseFilter,
    locationFilter,
  ]);
  const downloadCsvAquisition = () => {
    const tableData = acqSrcChurn.modified?.tableData || {};
    const data = [tableData];
    const filename = "acquisition_data.csv";
    generateCSV(data, filename);
  };

  const downloadCsvLocation = () => {
    const dataSource = locationData.modified;
    const selectedFilter = locationFilter;
    const transformedData = transformLocationData(dataSource, selectedFilter);
    const filename = `locationWiseData_${selectedFilter}.csv`;
    generateCSV(transformedData, filename);
  };
  const downloadCsvOs = () => {
    const dataSource = OSData.modified?.versions || [];
    const transformedData = transformOsData(dataSource);
    const filename = "os.csv";
    generateCSV(transformedData, filename);
  };
  const downloadCsvAppVesrion = () => {
    const dataSource = appVersions.modified?.versions || [];
    const transformedData = transformVersionData(dataSource);
    const filename = "versionWiseData.csv";
    generateCSV(transformedData, filename);
  };
  const downloadCsvDayWise = () => {
    const dataSource = stateDayWiseData.modified || [];
    const transformedData = dayHourWiseData(dataSource, dayWiseFilter, "Day");
    const filename = "daywiseData.csv";
    generateCSV(transformedData, filename);
  };
  const downloadCsvTimeWise = () => {
    const dataSource = stateTimeWiseData.modified || [];
    const transformedData = dayHourWiseData(dataSource, dayWiseFilter, "Time");
    const filename = "timeWiseData.csv";
    generateCSV(transformedData, filename);
  };

  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };
  return (
    <div className="acquistion-page-container">
      <div className="d-flex justify-content-between flex-wrap my-3">
        <h4>{churn ? t("churnLabel") : t("acqLabel")}</h4>
        <div>
          <strong>{t("periodLabel")} </strong> 
          <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              numDaysToShow={30} // Example value

            />
        </div>
      </div>
      <div>
        <div className="d-flex flex-wrap  ">
          <TableChartLayout
            title={churn ? t("churnLabel") : t("acqSourcesLabel")}
            className="col-lg-4 col-md-4 col-12"
            headerRight={
              <Selector
                size="middle"
                style={{ width: 90 }}
                defaultValue={"all"}
                onChange={(value: string) => setAcqSrcChurnFilter(value)}
                options={platformOptions}
              />
            }
            onDownloadClick={downloadCsvAquisition}

          >
            <DonutPieChart
              showLegends
              data={
                acqSrcChurn.modified?.data ? acqSrcChurn.modified?.data : []
              }
              colorSchema={colorSchema}
              totalEvents={acqSrcChurn.modified?.total}
              innerRadius={0.001}
              enableArcLinkLabels={false}
            />
            <CustomTable
              columns={sourcesColumns}
              dataSource={[acqSrcChurn.modified.tableData]}
              pagination={false}
            />
          </TableChartLayout>

          <TableChartLayout
            className="col-lg-4 col-md-4 col-12"
            title={t("appVersionsLabel")}
            headerRight={
              <Selector
                size="middle"
                style={{ width: 90 }}
                defaultValue={versionFilter}
                onChange={(value: string) => setVersionFilter(value)}
                options={platformOptions}
              />
            }
            onDownloadClick={downloadCsvAppVesrion}
          >
            <DonutPieChart
              showLegends
              data={appVersions.modified.versions}
              colorSchema={colorSchema}
              totalEvents={appVersions.modified.total}
              innerRadius={0.001}
              enableArcLinkLabels={false}
            />
          </TableChartLayout>
          {churn &&      
         
          <TableChartLayout
            className=" col-lg-4  col-12"
            title={t("locationsLabel")}
            headerRight={
              <>
                <Selector
                  size="middle"
                  style={{ width: 90 }}
                  defaultValue={locationFilter}
                  onChange={(value) => {
                    setLocationFilter(value);
                  }}
                  options={[
                    {
                      value: "city",
                      label: "City",
                    },
                    {
                      value: "country",
                      label: "Country",
                    },
                  ]}
                />
              </>
            }
            onDownloadClick={downloadCsvLocation}
          >
            <CustomTable
              columns={locationColumns}
              dataSource={locationData.modified}
              pagination={false}
            />
          </TableChartLayout>}
        
        {!churn && (
            <TableChartLayout
              className=" col-md-4 col-12"
              title={t("osLabel")}
              headerRight={
                <Selector
                  size="middle"
                  onChange={(value: string) => setOSFilter(value)}
                  style={{ width: 90 }}
                  defaultValue={OSFilter}
                  options={platformOptions}
                />
              }
              onDownloadClick={downloadCsvOs}
            >
              <DonutPieChart
                showLegends
                data={OSData.modified.versions}
                colorSchema={colorSchema}
                totalEvents={OSData.modified.total}
                innerRadius={0.001}
                enableArcLinkLabels={false}
              />
              <CustomTable
                columns={OSColumns}
                dataSource={OSData.modified.versions.map((version: any) => {
                  return {
                    platform: version.label,
                    total: version.value,
                  };
                })}
                pagination={false}
              />
            </TableChartLayout>
          )}
          {!churn && (
             <AccessControl
             accessControl={{
               features: [FeatureSettingsFlagTypes.ACQUISITION_BY_TYPE],
             }}
           >
            <TableChartLayout
              className=" col-md-4 col-12"
              title={t("acqLabel")}
              showDownloadIcon={false}

              headerRight={
                <>
                  <Selector
                    size="middle"
                    style={{ width: 90 }}
                    defaultValue={acqSCFilter}
                    onChange={(value) => {
                      setAcqSCFilter(value);
                    }}
                    options={[
                      {
                        value: "S",
                        label: "Source",
                      },
                      {
                        value: "C",
                        label: "Campaign",
                      },
                    ]}
                  />
                  <Selector
                    size="middle"
                    style={{ width: 90 }}
                    defaultValue={acqPlatformFilter}
                    onChange={(value) => {
                      setAcqPlatformFilter(value);
                    }}
                    options={platformOptions}
                  />
                </>
              }
            >
              <CustomTable
                columns={AcquistionColumns}
                dataSource={acqSCData}
                pagination={false}
              />
            </TableChartLayout>
            </AccessControl>
          )}
          
        </div>
        <div>
          <TableChartLayout
            title={`${t("dayWiseLabel")} ${
              churn ? t("churnLabel") : t("acqLabel")
            }`}
            width={"100%"}
            height={"600px"}
            headerRight={
              <>
                
                <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              numDaysToShow={30} // Example value

            />
                <Selector
                  size="middle"
                  style={{ width: 90 }}
                  defaultValue={"all"}
                  onChange={(value: string) => setDayWiseFilter(value)}
                  options={platformOptions}
                />
              </>
            }
            onDownloadClick={downloadCsvDayWise}
          >
          

            <CustomHighChart
              options={{
                chart: {
                 type:"column",
                  height: 580,
                  padding:0
                },
                title: {
                  text: undefined,
                },
                yAxis: {
                  title: {
                    text: undefined,
                  },
                  tickWidth: 0,
                },
                xAxis: {
                  categories: stateDayWiseData?.modified
                  ? stateDayWiseData?.modified?.map(
                    (item: any) => item.name
                  ) 
                  : [''],
                  tickWidth: 0,
                },
                legend: {
                  enabled: false,
                },
                navigation: {
                  buttonOptions: {
                    enabled: false,
                  },
                },
                colors: ["#1976d2", "#4a9df0"],
                plotOptions: {
                  column: {
                    stacking: "normal", // Set stacking to 'normal' or 'percent' for a stacked column chart
                    groupPadding: 0, // Set groupPadding to 0 to remove the gap between groups of bars
                    pointPadding: 0, // Set pointPadding to 0 to remove the gap between individual bars
                    // Set to false to remove the gap between bars
                  },

                },
                series: [
                  {
                    name: "new",
                    data:
                      stateDayWiseData?.modified?.map(
                        (item: any) => item.new
                      ) || 0,
                      
                  },
                  {
                    name: "repeat",
                    data:
                      stateDayWiseData.modified?.map(
                        (item: any) => item.repeat
                      ) || 0,
                  },
                  // Add more series as needed
                ],
              }}
            />
            <CustomTable
              rowKey="name"
              scroll={{ y: 500 }}
              size="small"
              columns={DayWiseColumns}
              dataSource={stateDayWiseData.modified}
              pagination={false}
            />
          </TableChartLayout>
        </div>
        <div>
          <TableChartLayout
            title={`${t("timeWiseLabel")} ${
              churn ? t("churnLabel") : t("acqLabel")
            }`}
            width={"100%"}
            height={"600px"}
            headerRight={
              <>
                 <DateRangeSelector
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="year"
              numDaysToShow={30} // Example value

            />
                <Selector
                  size="middle"
                  style={{ width: 90 }}
                  defaultValue={"all"}
                  onChange={(value: string) => setTimeWiseFilter(value)}
                  options={platformOptions}
                />
              </>
            }
            onDownloadClick={downloadCsvTimeWise}
          >
           
              <CustomHighChart
                options={{
                  chart: {
                    type: "column",
                    height: 580,
                  },
                  title: {
                    text: undefined,
                  },
                  xAxis: {
                    categories: stateTimeWiseData?.modified
                    ? stateTimeWiseData?.modified?.map(
                      (item: any) => item.name
                    ) 
                    : [''],
                    tickWidth: 0,
                  },
                  yAxis: {
                    title: {
                      text: undefined,
                    },
                  },
                  legend: {
                    enabled: false,
                  },
                  navigation: {
                    buttonOptions: {
                      enabled: false,
                    },
                  },
                  colors: ["#1976d2", "#4a9df0"],
                  plotOptions: {
                    column: {
                      stacking: "normal",
                      groupPadding: 0, // Set groupPadding to 0 to remove the gap between groups of bars
                      pointPadding: 0, // Set pointPadding to 0 to remove the gap between individual bars
                    },
                    colorByPoint: true
                  },
                  series: [
                    {
                      name: "new",
                      data:
                        stateTimeWiseData?.modified?.map(
                          (item: any) => item.new
                        ) || 0,
                    },
                    {
                      name: "repeat",
                      data:
                        stateTimeWiseData?.modified?.map(
                          (item: any) => item.repeat
                        ) || 0,
                    },
                    // Add more series as needed
                  ],
                }}
              />

            <CustomTable
              rowKey="name"
              scroll={{ y: 500 }}
              size="small"
              columns={TimeWiseColumns}
              dataSource={stateTimeWiseData.modified}
              pagination={false}
            />
           
          </TableChartLayout>
        </div>
      </div>
    </div>
  );
}

export default AcquisitionChurn;

