import { Form } from "antd";
import TextInput from "components/Inputs/TextInput/TextInput";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "redux/features/app/authTokenSlice";
import useMessage from "hooks/useMessage";
import { t } from "i18next";
import { EmailEditorConfig } from "utils/models";
import { API_ENDPOINTS } from "utils/constants";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { getFeaturesSettings } from "redux/features/app/featuresSettingSlice";
const EmailEditorSetting = () => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const beefreeConfig =  useSelector((state: any) => state.featuresSetting?.data[0]?.beefreeConfig || {});
  const dispatch: any = useDispatch();
  const {showError,showSuccess}=useMessage()
  const handleSubmit = async (values:EmailEditorConfig) => {
    try {
      const beefreeConfig=new EmailEditorConfig(values)
      const authToken = await dispatch(getToken()).unwrap();
      const payload = {
        args:{
          beefreeConfig: beefreeConfig.toString()},
        app_id: appId,
        api_key: loginUser.api_key,
        authToken,
      };
      await centralApi("POST", API_ENDPOINTS.SAVE_APP_SETTING_URL, payload);
      dispatch(getFeaturesSettings({app_id:appId,api_key:loginUser.api_key}))
      showSuccess(t("sucessfullySavedLabel"))
    } catch (error) {
      showError(t("somethingWrongLabel"))

    }
  };

  return (
    <div>
      <Form
        onFinish={handleSubmit}
        initialValues={beefreeConfig}
        layout="horizontal"
        labelCol={{ flex: "120px" }}
        labelAlign="left"
      >
        <Form.Item
          label={t("clientIdLabel")}
          name="clientId"
          rules={[{ required: true, message: `${t("plzEnterClientIdMsg")}` }]}
        >
          <TextInput />
        </Form.Item>

        <Form.Item
          label={t("clientSecretLabel")}
          name="clientSecret"
          rules={[{ required: true, message:  `${t("plzEnterClientSecretMsg")}` }]}
        >
          <TextInput />
        </Form.Item>

        <Form.Item
          label={t("grantTypeLabel")}
          name="grantType"
          rules={[{ required: true, message:  `${t("plzEnterGrantTypeMsg")}` }]}
        >
          <TextInput />
        </Form.Item>

        <Form.Item
          label={t("endpointLabel")}
          name="endpoint"
          rules={[{ required: true, message:  `${t("plzEnterEndpointURLMsg")}`}]}
        >
          <TextInput />
        </Form.Item>

        <Form.Item
          label={t("connectSrcLabel")}
          name="connectSrc"
          rules={[{ required: true, message:  `${t("plzEnterConnectSrcMsg")}` }]}
        >
          <TextInput />
        </Form.Item>
        <Form.Item
          label={t("frameSrcLabel")}
          name="frameSrc"
          rules={[{ required: true, message:  `${t("plzEnterFrameSrcMsg")}` }]}
        >
          <TextInput />
        </Form.Item>

        <Form.Item>
          <PrimaryButton type="primary" htmlType="submit">
            {t("saveLabel")}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EmailEditorSetting;
