import CustomAvatar from "components/CustomAvatar/CustomAvatar";
import "./Customer360.css";
import ThreeSixtyCard from "./components/Customer360Card/Customer360Card";
import Selector from "components/Selector/Selector";
import tickIcon from "../../images/tick_icon.png";
import { Heatmap } from "components/Charts/Heatmap/Heatmap";
import CircularProgress from "components/Progress/CircularProgress/CircularProgress";
import CustomTimeline from "components/CustomTimeline/CustomTimeline";
import Campaigns from "./components/Campaigns/Campaigns";
import LineProgress from "components/Progress/LineProgress/LineProgress";
import { data } from "./data";
import { t } from "i18next";
import { CONSTANTS } from "utils/constants/constants";
import CustomHighChart from "components/Charts/CustomHighChart/CustomHighChart";
import { HighChartTypeEnum } from "utils/enums";
import { Helmet } from "react-helmet";
import { FC } from "react";

const Customer: FC = () => {
  
  
  return (
    <div className="c-three-sixty-main-container">
      <Helmet>
        <title>Appice | C360</title>
      </Helmet>
      {/* -----header container starts from here----- */}
      <div className="d-flex justify-content-between my-3">
        <div className="d-flex gap-lg-3 gap-2  c-three-sixty-header-container ">
          <div>
            <CustomAvatar
              className="c-three-sixty-header-avatar"
              shape="square"
              size={100}
            >
              {data.icon}
            </CustomAvatar>
          </div>
          <div className="d-flex flex-column justify-content-between py-2">
            <strong>{data.name}</strong>
            <div className="d-flex flex-column gap-2 ">
              <div className="d-flex justify-content-between">
                <span>{t("cifIdLabel")}</span>
                <span>{data.cifId}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>{t("c360IdLabel")}</span>
                <span>{data.c360Id}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex w-50 justify-content-between">
          <ThreeSixtyCard
            cardType={CONSTANTS.HEADER}
            initialKey={t("firstSeenLabel")}
            initialValue={data.firstSeen}
            secondaryKey={t("lastSeenLabel")}
            secondaryValue={data.lastSeen}
          />
          <ThreeSixtyCard
            cardType={CONSTANTS.HEADER}
            initialKey={t("statusLabel")}
            initialValue={data.status}
            secondaryKey={t("loyaltyPointsLabel")}
            secondaryValue={data.loyalty_points}
          />
        </div>
      </div>
      {/* -----header container ends here----- */}
      {/* -----Customer Portfolio container starts from here----- */}

      <div className="w-100 c-three-sixty-full-row-container c-three-sixty-customer-portfolio-container my-4 p-4">
        <div>
          <h6 className="m-0">{t("customerPortfolioLabel")}</h6>
        </div>
        <div className="d-flex w-100 justify-content-around">
          {data.portfolio.map((item, index) => {
            return (
              <ThreeSixtyCard
                key={index}
                cardType={CONSTANTS.PORTFOLIO}
                colors={item.color}
                total={item.value}
                data={[{ name: item.title, y: item.value }]}
                title={item.title}
                subTitle={item.subTitle}
              />
            );
          })}
        </div>
      </div>
      {/* -----Customer Portfolio container ends here----- */}
      {/* -----Engagement container starts from here----- */}

      <div className=" mx-0  row c-three-sixty-full-row-container c-three-sixty-customer-engagement-container my-4 p-4">
        <div className="col-12 m-0 p-0 d-flex justify-content-between pb-3">
          <div>
            <h6 className="m-0">{t("engagementLabel")}</h6>
          </div>
          <div>
            <Selector placeholder="Last 3 mth" />
          </div>
        </div>
        <div className="col-6 m-0 p-0 d-flex flex-column gap-3">
          {data.engagement.visits.map((item, index) => {
            return (
              <span key={index} className="d-flex gap-3">
                <span>{item.title} :</span>
                <span>{item.value}</span>
              </span>
            );
          })}
        </div>
        <div className="col-6">
          <CustomHighChart
            options={{
              chart: {
                type: HighChartTypeEnum.SPLINE,
                height: 250,
                // Set the desired height in pixels
              },
              title: {
                text: undefined,
              },
              xAxis: {
                categories: CONSTANTS.MONTHS_FIRST_LETTER_LIST,
                tickInterval: 1,
                title: {
                  text: "",
                },
              },
              legend: {
                enabled: false, // Set to false to hide the legend
              },
              navigation: {
                buttonOptions: {
                  enabled: false, // Set to false to hide the hamburger icon
                },
              },
              yAxis: {
                title: {
                  text: "",
                },
                tickInterval: -1,

                lineWidth: 1,
                gridLineWidth: 0,
              },
              series: [
                {
                  name: "",
                  data: data.engagement.data,
                  lineWidth: 1,
                  marker: {
                    enabled: false,
                  },
                },
              ],
            }}
          />
        </div>
      </div>
      {/* -----Engagement container ends here----- */}
      {/* -----offers container starts here----- */}

      <div className="c-three-sixty-full-row-container my-4 p-4">
        <div>
          <h6>{t("offersLabel")}</h6>
        </div>
        <div className=" d-flex justify-content-between ps-md-5 ">
          {data.offers.map((offer, index) => {
            return (
              <ThreeSixtyCard
                key={index}
                cardType={CONSTANTS.OFFER}
                title={offer.title}
                icon={offer.icon}
                amount={offer.amount}
                updatedOn={offer.updated}
              />
            );
          })}
        </div>
      </div>
      {/* -----offers container ends here----- */}

      <div className="d-flex  c-three-sixty-multi-container gap-lg-3 gap-2">
        <div className="d-flex w-50 flex-column gap-lg-3 gap-2 ">
          {/* -----heatmap container starts here----- */}

          <div className="h-100 p-4 c-three-sixty-heatmap">
            <div>
              <h6>{t("heatmapActivityLabel")}</h6>
            </div>
            <div className="c-three-sixty-heatmap-wrapper ">
              <Heatmap top={30} right={20} bottom={30} left={80} />
            </div>
          </div>
          {/* -----heatmap container ends here----- */}

          {/* -----preference container starts here----- */}

          <div className=" c-three-sixty-preference-container p-4 w-100  d-flex flex-column  h-100 ">
            <h6>{t("channelPreferencesLabel")}</h6>
            <div className="d-flex justify-content-around h-100  align-items-center flex-wrap">
              {data.preferences.map((pref, index) => {
                return (
                  <CircularProgress
                    key={index}
                    size={100}
                    image={pref.icon}
                    name={pref.platform}
                    strokeWidth={10}
                    pathColor={pref.color}
                    persentage={pref.value}
                  ></CircularProgress>
                );
              })}
            </div>
          </div>
          {/* -----preference container ends here----- */}
        </div>
        {/* -----recent activity container starts here----- */}

        <div className="c-three-sixty-recent-activity   w-50 p-4 ">
          <div className="mb-2" >
            <h6 className="m-0">{t("recentActivityLabel")}</h6>
          </div>
          <div className=" d-flex c-three-sixty-timeline-wrapper  pt-2 " >
            <CustomTimeline mode="left" layout="event" items={data.recentActivity} />
          </div>
        </div>
        {/* -----recent activity container ends here----- */}
      </div>
      <div className="d-flex gap-lg-3 gap-2 mt-4 c-three-sixty-seg-camp-wrapper ">
        <div className="c-three-sixty-campaigns-wrapper w-50 p-4">
          <div  >
            <h6>{t("campaignsLabel")}</h6>
          </div>
          <Campaigns campaignData={data.campaigns} />
        </div>
        <div className="c-three-sixty-matched-segment-container p-4 w-50 d-flex  flex-column   h-100">
          <div className="d-flex justify-content-between">
            <h6>{t("matchedSegmentLabel")}</h6>
          </div>
          <div className="d-flex h-100 justify-content-around  flex-column">
            {data.matchedSegments.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex justify-content-between flex-wrap  align-items-center"
                >
                  <div className="col-lg-6 col-12 ">
                    <img src={tickIcon} />
                    <strong className="mx-2">{item.title}</strong>
                  </div>
                  <div className="col-lg-6 col-12">
                    <LineProgress percent={item.value} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
