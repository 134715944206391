import { Col, Form, Row } from "antd";
import "./SmartLinkCreate.css"
import Spinner from "components/Spinner/Spinner";
import { Helmet } from "react-helmet"
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import FormItem from "antd/es/form/FormItem";
import TextInput from "components/Inputs/TextInput/TextInput";
import { validateNoSpecialChars } from "validator/antdValidator";
import { useEffect, useMemo, useState } from "react";
import Selector from "components/Selector/Selector";
import { API_ENDPOINTS } from "utils/constants";
import { useSelector } from "react-redux";
import centralApi from "services/centralApi";
import { AppsContent } from "../components/AppsContent/AppsContent";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import useMessage from "hooks/useMessage";


export const SmartLinkCreate = () => {
    const location = useLocation();
    const [form] = useForm();
    const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
    const [appGroupData , setAppGroupData] = useState<any>([]);
    const [selectedGroupData, setSelectedGroupData] = useState<any>(null);
    const navigate = useNavigate();
    const loginUser = useSelector((state: any) => state.loginUser);
    const authToken = useSelector((state: any) => state.authToken.token);
    const activeApp = useSelector((state: any) => state.activeApp);
    const [routesByApp, setRoutesByApp] = useState<any[]>([]);
    const { showError, showSuccess } = useMessage();
    const kochava= useSelector((state: any) => state.featuresSetting.data[0]?.attribution?.kochava);
     
    const { id } = location.state || {}; // Access `id` from the state, with fallback to `undefined`
    const appId = activeApp.appId;
    const api_key = loginUser.data.api_key;

    /**
     * getAppGroupData for fetching app group data from kochava
     */
    const getAppGroupData = async()=>{
      const url: string = API_ENDPOINTS.GET_APP_GROUPS;
      const payload = {
          args: JSON.stringify({
            app_id: appId,
            is_active:BOOLEAN_OPTIONS.TRUE,
            kochava_api_key:kochava?.apiKey
          }),
          api_key: api_key,
          app_id:appId
        };
    
        try {
          setLoading(BOOLEAN_OPTIONS.TRUE);
          const response = await centralApi("GET", url, null, payload);
          setAppGroupData(response?.data)
          setLoading(BOOLEAN_OPTIONS.FALSE);
        } catch (e: any) {
          setLoading(BOOLEAN_OPTIONS.FALSE);
        }
  
    }

    //use state for handling form Data
    const [formData, setFormData] = useState(
      {
        name:"",
        app_group_id: null ,
        app_group_campaign_name:"",
        subdomain:"meem",
        network_id:3881,
        channel_type: "owned",
        path:"",
        meta:{
          path:"no_deep_link",
          path_name:"No Deep Link"
        },
        default:{
          app_id:"",
          destination_url:""
        }

      }
    )

//calling getAppGroupData function for fetching group data
    useEffect(()=>{
      if(kochava?.apiKey && kochava?.apiKey.length > 0){
      getAppGroupData() 
      }
    },[])

 

  //callback function for getting routes data
    const handleRoutesChange = (updatedRoutes: any[]) => {
      setRoutesByApp(updatedRoutes)
    };
   
    

    // handleFormChange handling form data change
    const handleFormChange = (key: string, value: any) => {
      setFormData((prevState: typeof formData) => ({
        ...prevState,
        [key]: value,
      }));
    };

  // generating options for app group selector
   const appGroupOptions  = useMemo(()=>{
    if (appGroupData.length > 0) {
      // Filter and transform the data
      const transformedOptions = appGroupData
        .filter((group: any) => group.eligible_for_smartlinks) // Filter only eligible groups
        .map((group: any) => ({
          label: group.name,
          value: group.id,
        }));
  
      return transformedOptions;
    }
    return [];
  },[appGroupData])

  //generating options for platform selector
  const otherPlatformOptions = useMemo(() => {
    if (!selectedGroupData || !selectedGroupData.apps) return [];
  
    return selectedGroupData.apps.map((app:any) => ({
      value: app.app_id, // Use `app_id` as the value
      label: app.name,   // Use `name` as the label
    }));
  }, [selectedGroupData]);

  //handleFormSubmit function for subbmitting the form data to kochava api and return the response data
    const handleFormSubmit = async()=>{
      
      const payloadData = {  
        ...formData,
        routes_by_app:routesByApp  ,
        kochava_api_key:kochava?.apiKey
      }
      const body = {
        api_key: loginUser.api_key,
        app_id: appId,
        authToken
      };
      const params:any = {
        api_key: api_key,
        app_id: appId,
        args: JSON.stringify(payloadData),
      };
      try {
      setLoading(BOOLEAN_OPTIONS.TRUE);
      const response = await centralApi(
        "POST",
        API_ENDPOINTS.SAVE_SMART_LINK,
        body,
        params
      )
      if(response){
          showSuccess("Successfully saved")
          setLoading(BOOLEAN_OPTIONS.FALSE);
          navigate("/dashboard/links")
        }
      } catch (error) {
        showError("Somthing went wrong")
        setLoading(BOOLEAN_OPTIONS.FALSE);
      }
      
    }
  return (
    <div className="container-fluid"> 
     <div className="smartlinks-create-container">
      <Helmet>
        <title>Appice | Add SmartLink</title>
      </Helmet>
       <div className="w-100 h-100 d-flex justify-content-between flex-wrap my-3">
        <h4>{id ? "Update SmartLink" :"Add SmartLink"}</h4>
       </div>
      </div>
      <div className="smartlinks-create-inner-container p-1">
        {loading ? (
         <div className="smartlinks-create-spinner">
            <Spinner />
         </div>      
        ):(
        <div className="smartlinks-create-form p-3">
          <Form
            onFinish={handleFormSubmit}
            colon={BOOLEAN_OPTIONS.FALSE}
            form={form}
            layout="vertical"

          >
             <Row gutter={50} >
              <Col  span={12}>
                <FormItem
                  className="w-100"
                  label="SmartLink Name"
                  name={"name"}
                  rules={[
                    { required: BOOLEAN_OPTIONS.TRUE, message: "SmartLink  name is required" },
                    {validator:validateNoSpecialChars}
                  ]}
                  initialValue={formData?.name}
                >
                  <TextInput
                    placeholder={"SmartLink Name"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleFormChange("name", e.target.value);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  className="w-100"
                  label="App Group"
                  name={"app_group_id"}
                  rules={[
                    { required: BOOLEAN_OPTIONS.TRUE, message: "AppGroup is required" },
                    {validator:validateNoSpecialChars}
                  ]}
                  initialValue={formData?.app_group_id}
                >
                   <Selector
                     onChange={(value) => {
                      handleFormChange("app_group_id", value);
                         // Find the selected group data based on the ID
                    const groupData = appGroupData.find((group:any) => group.id === value);

                    // Update the selectedGroupData state
                    if (groupData) {
                      setSelectedGroupData(groupData);
                    } else {
                      setSelectedGroupData([]); // Clear if no match is found
                  }
                    }}
                     showSearch
                     options={appGroupOptions}
                   />
                </FormItem>
                
              </Col>
              <Col span={6}>
                <FormItem
                  className="w-100"
                  label="Domain"
                  name={"subdomain"}
                  rules={[
                    { required: BOOLEAN_OPTIONS.TRUE, message: "Domain is required" },
                    {validator:validateNoSpecialChars}
                  ]}
                  initialValue={formData?.subdomain}
                >
                   <Selector
                     onChange={(value) => {
                      handleFormChange("subdomain", value);
                    }}
                     showSearch
                     options={[
                      {
                        label:"https://meem.smart.link",
                         value:"meem"
                      }   
                     ]}
                     disabled={BOOLEAN_OPTIONS.TRUE}
                   />
                </FormItem>
                
              </Col>
              </Row>
              <Row gutter={50} >
              <Col  span={6}>
                <FormItem
                  className="w-100"
                  label="Campaign Name"
                  name={"app_group_campaign_name"}
                  rules={[
                    { required: BOOLEAN_OPTIONS.TRUE, message: "Campaign name is required" },
                    {validator:validateNoSpecialChars}
                  ]}
                  initialValue={formData?.app_group_campaign_name}
                >
                  <TextInput
                    placeholder={"Campaign Name"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleFormChange("app_group_campaign_name", e.target.value);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  className="w-100"
                  label="Universal Link"
                  name={"meta_path"}
                  rules={[
                    { required: BOOLEAN_OPTIONS.TRUE, 
                      message: "Universal Link is required" },
                  ]}
                  initialValue={formData?.meta.path}
                >
                   <Selector
                    onChange={(value, option) => {
                    const selectedOption:any = option; // `option` contains the selected option (label and value)
                    handleFormChange("meta", {
                        ...formData.meta,
                        path: value, // Set the value of path
                        path_name: selectedOption?.label, // Set the label as path_name
                    });
                    }}
                     showSearch
                     options={[
                      {
                        label:"No Deep Link",
                        value:"no_deep_link"
                      },
                      {
                        label:"Custom",
                        value:"custom"
                      }
                     ]}
                   />
                </FormItem>
                
              </Col>
              <Col  span={12}>
                <FormItem
                  className="w-100 mt-1"
                  name={"path"}
                  rules={[
                    { required: formData?.meta?.path === "custom" , 
                      message: "Path is required is required"
                    },
                  ]}
                  initialValue={formData?.path}
                >
                  <TextInput
                    placeholder={"myapp://"}
                    className={"mt-4 d-flex"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleFormChange("path", e.target.value);
                    }}
                    disabled={formData?.meta?.path === "no_deep_link" ? BOOLEAN_OPTIONS.TRUE : BOOLEAN_OPTIONS.FALSE }
                  />
                </FormItem>
              </Col>  
              </Row>
              {selectedGroupData?.app_count > 0 &&(
              <div>
                <div>
                <AppsContent apps={selectedGroupData?.apps} onRoutesChange={handleRoutesChange} />
                </div>             
                <div className="apps-content-container my-3">
                  <h6 className="d-flex gap-1">All Other Platforms<small className="text-muted">(Catch-all)</small></h6>
                  <Row gutter={50} >
                    <Col  span={12}>
                      <FormItem
                        className="w-100"
                        label="Destination"
                        name={"default-destination_url"}
                        rules={[
                          { required: BOOLEAN_OPTIONS.TRUE, message: "Destination is required" },
                        ]}
                        initialValue={formData?.default?.destination_url}
                      >
                        <TextInput
                          placeholder={"Destination"}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleFormChange("default", {
                                ...formData.default,
                                destination_url : e.target.value, // Set the value of destination_url in default
  
                          })}}
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        className="w-100"
                        label="Target App (for tracking)"
                        name={"default-appid"}
                        rules={[
                          { required: BOOLEAN_OPTIONS.TRUE, message: "Target App is required" },
                          {validator:validateNoSpecialChars}
                        ]}
                        initialValue={formData?.default?.app_id}
                      >
                        <Selector
                          onChange={(value) => {
                            handleFormChange("default", {
                              ...formData.default,
                              app_id: value, // Set the value of 

                          });
                          
                          }}
                          placeholder={"Please Select"}
                          showSearch
                          options={otherPlatformOptions}
                        />
                      </FormItem>
                      
                    </Col>
                  </Row>
                </div>
                <Row className="gap-2  d-flex justify-content-end">
                  <PrimaryButton
                    style={{
                      color: "white",
                      background: "var( --color-red-main)",
                    }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    Cancel
                  </PrimaryButton>
                  <PrimaryButton
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </PrimaryButton>
                </Row>
              </div>
              )}
            
          </Form>
        </div>
        )}
     </div>
    </div>
  )
}
