import {  useState, Fragment } from "react";
import InnerNav from "components/InnerNav/InnerNav";
import BudgetSetting from "../BudgetSetting/BudgetSetting";
import EmailSetting from "../EmailSetting/EmailSetting";
import React from 'react';
import PageLayout from "layout/PageLayout/PageLayout";
import EmailEditorSetting from "../EmailEditorSetting/EmailEditorSetting";
import { t } from "i18next";
interface EmailNavProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;

}

const EmailNav: React.FC<EmailNavProps> = ({ currentAppKey,updateCurrentAppKey }) => {
  const [currentKey,setCurrentKey]=useState(1)
  return (
    <Fragment>
      <PageLayout className="app-setting-container ">
        <div className="app-setting-nav">
          <InnerNav
            theme="light"
            onTabClick={(e)=>setCurrentKey(e.key)}
            selected={currentKey.toString()}
            menu={[
              {
                label: `${t("emailSettingLabel")}`,
                key: 1,
              },
              {
                label: `${t("budgetSettingLabel")}`,
                key: 2,
              },
              {
                label: `${t("editorSettingLabel")}`,
                key: 3,
              }
            ]}
          />
        </div>
        <div className="mt-3 col-md-7 col-12">
       {currentKey==1?<EmailSetting currentAppKey={currentAppKey} updateCurrentAppKey={updateCurrentAppKey}/>:currentKey==2?<BudgetSetting currentAppKey={currentAppKey} updateCurrentAppKey={updateCurrentAppKey} />:<EmailEditorSetting/>}
        </div>
      </PageLayout>
    </Fragment>
  );
};
export default EmailNav;
