import FormItem from "antd/es/form/FormItem";
import React, { useEffect, useState } from "react";
import Selector from "../../../../../components/Selector/Selector";
import { audienceItems } from "../../../../../helperFunctions/audience";
import TextInput from "../../../../../components/Inputs/TextInput/TextInput";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import SegmentedCustom from "../../../../../components/SegmentedCustom/SegmentedCustom";
import { Form } from "antd";

interface ConversionEventProps {
  eventList: any[];
  form:any,
  intialEventList?: any[];
 setData?:any
}

const ConversionEvent: React.FC<ConversionEventProps> = ({
  eventList,
  intialEventList,
  form,
  setData
}) => {
  const intialState = {
    event: undefined,
    period: undefined,
    attr: undefined,
    operator: undefined,
    value: undefined,
    type: undefined, // Change from attrType to type
  };
  const [eventData, setEventData] = useState<any>(
    intialEventList && intialEventList?.length>0?intialEventList:[intialState]
  );
  const handlePopEvent = () => {
    setEventData((prevState: any) => {
      let copy_events = [...prevState];
      copy_events.pop();
      copy_events=[{
        ...copy_events[0],
        E_operator: undefined
      }]
      return copy_events;
    });
  };
  const handleFieldChange = (field: string, value: string | undefined, index: number) => {
    let e_data: any = [...eventData];
    if(field == 'attr'){
      const foundEvent = eventList.find(event => event.event === e_data[index].event);
      const selectedAttrType = foundEvent.list.find(
        ({ name }: { name: string }) => name === value
      )?.type;
      e_data[index] = {
        ...e_data[index],
        ['type']: selectedAttrType
      };
    }
  e_data[index] = {
    ...e_data[index],
    [field]: value
  };
    setEventData(() => (e_data));
  };
  useEffect(() => {
    if (eventData && !eventData[0]?.E_operator && eventData?.length>1) {
      handleFieldChange("E_operator", "AND", 0);
    }
  }, [eventData?.length]);
  return (
    <>
      <div>
      <Form 
      form={form}
      colon={false}
      labelCol={{flex:"70px"}}
      labelAlign="left"
      onFinish={()=>setData(eventData)}
      >
        {eventData?.map((event: any, index: number) => {
          return (
            <>
              <SingleEvent
              key={index}
                eventList={eventList}
                index={index}
                eventData={eventData}
                setEventData={setEventData}
                handleFieldChange={handleFieldChange}
                form={form}
              />
              <div className="d-flex justify-content-between">
                {index == 0 &&
                  (eventData.length == 1 ? (
                    <PrimaryButton
                      onClick={() =>
                        setEventData((prevState: any) => [
                          ...prevState,
                          intialState,
                        ])
                      }
                    >
                      <PlusCircleTwoTone />
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton onClick={handlePopEvent}>
                      <MinusCircleTwoTone />
                    </PrimaryButton>
                  ))}
                {index == 0 && eventData.length == 2 && (
                  <SegmentedCustom
                  value={eventData[0]?.E_operator}
                    onChange={(value: "AND" | "OR") =>
                      handleFieldChange("E_operator", value, 0)
                    }
                    options={[
                      { label: "AND", value: "AND" },
                      { label: "OR", value: "OR" },
                    ]}
                  />
                )}
              </div>
            </>
          );
        })}
        </Form>
      </div>
    </>
  );
};
const SingleEvent = ({
  eventList,
  index,
  eventData,
  handleFieldChange,
  form
}: any) => {
  const [selectedEvent, setSelectedEvent] = useState<any>(eventData.length>0?eventList.find((item: any) => item.event === eventData[index].event):null);
  useEffect(()=>{
return ()=>{setSelectedEvent(null);
  form.resetFields([`event${index}`,`attribute${index}`,`operator${index}`,`value${index}`,`period${index}`]);
}
  },[])
  return (
    <div className="w-100">
      <div className="p-2 my-2" style={{border:"1px solid var(--color-main-primary-light)",borderRadius:10}}>
        <FormItem label={"Event"} className="my-2" name={`event${index}`} rules={[{required:true}]} initialValue={eventData[index]?.event}>
          <Selector
            onChange={(value) => {
              setSelectedEvent(() => {
                return eventList.find((item: any) => item.event === value);
              });
              handleFieldChange("event", value, index);
            }}
            options={eventList.map(
              ({
                event,
                displayname,
              }: {
                event: string;
                displayname: string;
              }) => ({
                label: displayname,
                value: event,
              })
              )}
              />
        </FormItem>

        {selectedEvent?.list?.length > 0 && (
          <>
            <FormItem className="my-2"
            label="Attribute"
            name={`attribute${index}`}
            rules={[{required:true}]}
            initialValue={eventData[index]?.attr}
            >
              <Selector
              
                onChange={(value) => {
                  handleFieldChange("attr", value, index);
                }}
                options={selectedEvent.list.map(
                  ({ name, type }: { name: string; type: string }) => ({
                    label: name,
                    value: name,
                  })
                  )}
                  />
            </FormItem>
            {eventData[index].attr && (
              <>
                <FormItem className="my-2"
                label="Operator"
                  initialValue={eventData[index]?.operator}
                name={`operator${index}`}
            rules={[{required:true}]}
                
                >
                  
                  <Selector
                    onChange={(value) => {
                      handleFieldChange("operator", value, index);
                    }}
                    options={
                      audienceItems[
                        selectedEvent.list.find(
                          ({ name }: { name: string }) =>
                          name == eventData[index].attr
                          )?.type
                        ]
                      }
                      />
                </FormItem>
                <FormItem className="my-2 "
                label="Value"
                  initialValue={eventData[index]?.value}
                name={`value${index}`}
                rules={[{required:true}]}
                >
                  <TextInput
                    placeholder="value"
                    onChange={(e: any) => {
                      handleFieldChange("value", e.target.value, index);
                    }}
                    />
                </FormItem>
              </>
            )}
          </>
        )}
        <FormItem className="my-2"
        name={`period${index}`}
        label="Period"
        initialValue={eventData[index]?.period}

         rules={[{required:true}]}>
          <TextInput
          type="number"
          min={0}
            onChange={(e: any) => {
              handleFieldChange("period", e.target.value, index);
            }}
            placeholder="Enter time period in hrs"
            />
        </FormItem>
      </div>
    </div>
  );
};

export default ConversionEvent;
