// src/redux/slices/authTokenSlice.ts

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import centralApi from "services/centralApi";

// Async thunk for getting the token
export const getToken = createAsyncThunk(
  'authToken/getToken',
  async () => {

    const url = `/o/metrices/token`;

    const data = await centralApi("GET", url, null, null);
    return data.token; // Return the token
  }
);

const initialState = {
  token: '',
  loading: false,
  error: null, // Add error state
};

const authTokenSlice = createSlice({
  name: "authToken",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getToken.pending, (state) => {
        state.loading = true; // Set loading to true while fetching
      })
      .addCase(getToken.fulfilled, (state, action) => {
        state.token = action.payload; // Set the token
        state.loading = false;         // Reset loading
      })
      .addCase(getToken.rejected, (state, action) => {
        state.loading = false;         // Reset loading
      });
  },
});

export default authTokenSlice.reducer;
