import React, { useEffect, useState } from "react";
import { Space } from "antd"
import "./Verification.css";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "components/Spinner/Spinner";
import OtpAuthInput from "components/Inputs/OtpAuthInput/OtpAuthInput";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { fetchLoginUser } from "../../../redux/features/user/loginUserSlice";
import useMessage from "hooks/useMessage";
import centralApi from "services/centralApi";
const Verification: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const dispatch: any = useDispatch();
  const [csrfToken, setCsrfToken] = useState("");
  const [otp, setOtp] = useState("");
  const { showError, showSuccess } = useMessage();
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleSubmit = async ({ _csrf, otp }:{_csrf:string,otp:string}) => {
    const payload={
      _csrf,otp
      //TODO
    }
    try {
      const res = await centralApi("POST","/i/authentication",payload);
      if (res.auth) {
        dispatch(fetchLoginUser());
        navigate(res.redirectPath, { replace: true });
      }
      if (res.alert.length > 0) {
        showError(res.alert[0].msg)
      }
      if (res.message) {
        showError(res.message)
      }
    } catch (error: any) {
      showError(error.message)
    }
  };
  const fetchAuth = async () => {
    try {
      setLoading(true);
      const res = await centralApi("GET","/o/authentication");
      setCsrfToken(res.csrfToken);
      setLoading(false);
      if (res.otpsent && state.otpsent) {
        showSuccess(t("otpSentMsg"))
      } else if (res.otpsent && state.alreadysent) {
        showSuccess(t("otpAlreadySentMsg"))
      }

      if (res.auth || res.redirect) {
        navigate(res.redirectPath, { replace: true });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAuth();
  }, []);
  useEffect(() => {
    if (otp.length == 6) {
      const data = {
        otp,
        _csrf: csrfToken,
      };
      handleSubmit(data);
    }
  }, [otp]);

  return (
    <div className="container-fluid p-0 verification-container d-flex justify-content-center align-items-center  h-100">
      <Helmet>
        <title>Appice | Verification</title>
      </Helmet>
      {loading ? (
        <Spinner />
      ) : (
        <div className=" p-5 verification-form-wrapper">
          <h4 className="pb-2 text-center h4-head">AppICE</h4>
          <p className="my-2 text-center description">
            {t("userLifeCycleLabel")}
          </p>

          <div className="d-flex  align-items-center justify-content-center mt-3 w-100">
            <OtpAuthInput
              value={otp}
              onChange={(value: string) => {
                setOtp(value);
              }}
            />
          </div>

          <Space
            className="w-100 mt-3  pt-3"
            style={{
              borderTop: "1px solid",
              borderColor: "var(--color-other-line)",
            }}
          >
             <PrimaryButton type="primary" onClick={() => navigate("/login")}>
              {t("resendOtpLabel")}
            </PrimaryButton>
          </Space>
        </div>
      )}
    </div>
  );
};

export default Verification;
