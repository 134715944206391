import { Divider, Form } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import TextInput from 'components/Inputs/TextInput/TextInput';
import useMessage from 'hooks/useMessage';
import { t } from 'i18next';
import PageLayout from 'layout/PageLayout/PageLayout';
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from 'redux/features/app/authTokenSlice';
import { getFeaturesSettings } from 'redux/features/app/featuresSettingSlice';
import centralApi from 'services/centralApi';
import { API_ENDPOINTS } from 'utils/constants';
const GoogleMapKey: FC= () => {
    const googleMapApikey =  useSelector((state: any) => state.featuresSetting?.data[0]?.googleMapApikey);
    const loginUser = useSelector((state: any) => state.loginUser.data);
    const { appId } = useSelector((state: any) => state.activeApp);
    const dispatch: any = useDispatch();
    const {showError,showSuccess}=useMessage()
    const handleSubmit = async (data:{googleMapApikey:string}) => {
        try {
    
          const authToken = await dispatch(getToken()).unwrap();
          const payload = {
            args:data,
            app_id: appId,
            api_key: loginUser.api_key,
            authToken,
          };
          await centralApi("POST", API_ENDPOINTS.SAVE_APP_SETTING_URL, payload);
          dispatch(getFeaturesSettings({app_id:appId,api_key:loginUser.api_key}))
          showSuccess(t("sucessfullySavedLabel"))
        } catch (error) {
          showError(t("somethingWrongLabel"))
    
        }
      };
  return (
    <PageLayout >
        <div className="mb-4">
        <h6 className="file-encryption-setting-container-heading">
          {t("geoSettingsLabel")}
        </h6>
        <Divider className="file-encryption-setting-custom-divider" />
      </div>
      <Form
        onFinish={handleSubmit}
        initialValues={{googleMapApikey}}
        layout="horizontal"
        labelCol={{ flex: "120px" }}
        labelAlign="left"
      >
        <Form.Item
          label={t("googleMapKeyLabel")}
          name="googleMapApikey"
          className='w-50'
          rules={[{ required: true, message: `${t("plzEnterGoogleMapApiKeyMsg")}` }]}
          
        >
          <TextInput />
        </Form.Item>
          <PrimaryButton type="primary" htmlType="submit">
            {t("saveLabel")}
          </PrimaryButton>
        </Form>
        </PageLayout>
  )
}

export default GoogleMapKey;