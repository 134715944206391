import { FC, useState, useEffect, useRef } from "react";
import { Col, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import Selector from "components/Selector/Selector";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import CampaignDevice from "components/CampaignDevice/CampaignDevice";
import TextInput from "components/Inputs/TextInput/TextInput";
import "./WhatsApp.css";
import CustomTextArea from "components/Inputs/CustomTextArea/CustomTextArea";
import EmojiPicker from "components/EmojiPicker/EmojiPicker";
import { SmileOutlined, MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "services/centralApi";
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import { setCurrentStep } from "redux/features/campaign/campaignCreateCurrentStepSlice";
import { useForm } from "antd/es/form/Form";
import fileIcon from "images/File.svg";
import videoIcon from "images/video.svg";
import textIcon from "images/Text.svg";
import imageIcon from "images/image.svg";
import { t } from "i18next";
import WhatsAppContainer from "../../NotificationContainer/WhatsAppContainer/WhatsAppContainer";
import Personalization from "../../Personalization/Personalization";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import { setCampaignData } from "redux/features/campaign/campaignDataSlice";
import { ActionButtons } from "components/Buttons/ActionButtons/ActionsButtons";
import { formatDate, getCurrentDate } from "helperFunctions/date";
import useMessage from "hooks/useMessage";
import { validateImageDimensions } from "helperFunctions/file";
import { validateName } from "services/validateName";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import {IWhatsAppPayload} from "utils/models/index"
import { deserialize, serialize } from "utils/models/serializer";
import { CampaignChannelTypeEnum, RTLLanguageEnum } from "utils/enums";
import { API_ENDPOINTS } from "utils/constants";
import { fetchTemplateMetaData } from "redux/features/templates_/variantsSlice";
import { getToken } from "redux/features/app/authTokenSlice";

interface WhatsAppProps {
  type?:string;
}
const WhatsApp: FC<WhatsAppProps> = ({type}) => {
  const dispatch: any = useDispatch();
  const [form] = useForm();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const loginUser = useSelector((state: any) => state.loginUser.data); //get user details
  const { appId } = useSelector((state: any) => state.activeApp);
  //initiall set to false
  const [personalization, setPersonalization] = useState({
    open: false,
    field: "",
  });
  const [template, setTemplate] = useState({
    template_name: "",   //template name by deault empty
    template_type:type === CampaignChannelTypeEnum.WHATSAPP ? CampaignChannelTypeEnum.WHATSAPP :CampaignChannelTypeEnum.RCS, // type of template
    isTemplateDeleted: "false",  //by default false
  });
  const { showError } = useMessage();  //error mesaage
  //keeping track of on which step
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  //initial state for formValues 
  const [formData, setFormData] = useState<any>({
    language: "",
    headerType: "text", //by default text 
    category: "",   //category
    headerTitle: "",  //title
    description: "", //body text
    templateId: "",    //template id
    imageUrl: { name: "", url: "" }, // image type upload
    videoUrl: "",  //for video url
    fileUrl: { name: "", url: "" }, //file type upload
    actions: [
      {
        actionName: "",      //button name
        actionExternalUrl: "", //url
        actionType: "", //visit website
        urlType: "",  //type of url dynamic or url
        countryCode: "", //country code if phone selected
      },
    ],
    variableInfo: undefined,
    preview_url: false, //by default false
    shorten_url: true, //by default  true
    url: "", //url
    webHookDNId:"", //DNID
    type:"", //if image them MEDIA_TEMPLATE

  });
  const [image, setImage] = useState({
    imageUrl: { loading: false },
    fileUrl: { loading: false },
  });
  // const [selectedLanguage, setSelectedLanguage] = useState<any>({});
  const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change

  const templates = useSelector((state: any) => state.templatesList.data); //fetching templlate list
  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [blobContainerCDN, setBlobContainerCDN] = useState(
    campaignData?.data?.blobContainerCDN || ""
  );
  const languageVariants = useSelector((state: any) => state.templateMetaData.data);
  const features = useSelector((state: any) => state.featuresSetting.data);
  const charLimit = features[0]?.pushNotificationCharLimit;

  const emojiPickerRef = useRef(null);
  //emoji handler
  const handleToggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  //on emoji click
  const handleEmojiClick = (emojiObject: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      description: `${prevState?.description || ""}${emojiObject?.emoji}`,
    }));
    setShowEmojiPicker(false);
  };

  // Determine the text direction based on the language
  // const direction = selectedLanguage === "ar" ? "rtl" : "ltr";
  // const langAttributes = langAttribute === "ar" ? "ar" : "en"
//calling the API to get all whatsapp templates
  useEffect(() => {
    if (loginUser.api_key) {
      //fetching all templates list
      dispatch(
        fetchTemplatesList({
          app_id: appId,
          api_key: loginUser.api_key,
          template_type: type ===CampaignChannelTypeEnum.WHATSAPP ?CampaignChannelTypeEnum.WHATSAPP:CampaignChannelTypeEnum.RCS ,
        })
      );
      dispatch(
        fetchTemplateMetaData({
          app_id: appId,
          api_key: loginUser.api_key,
          args: {},
        })
      );
    }
    if (campaignData.data) {
      //fetching selected template and saving in redux also
      dispatch(setSelectedTemplate(campaignData.data));
    }
    dispatch(getToken())
  }, [loginUser.api_key]);
  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        //save campaign data in redux for edit
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: selectedTemplate._id,
          t: selectedTemplate.template_type,
        })
      );
    }
  }, [selectedTemplate]);
  useEffect(() => {
    //desyrtucturing values from form data or redux or if template selected
    const { template, template_name, template_type, blobContainerCDN } =
      selectedTemplate || campaignData.data || formData;
      //if blobcontainer then set
    if (blobContainerCDN) {
      setBlobContainerCDN(blobContainerCDN);
    }
    //setting template name and type
    if (template_name) {
      setTemplate((prevState) => ({
        ...prevState,
        template_name,
        template_type,
      }));
    }
    //destructuring required values from template
    if (template) {
      const {
        type,
        language,
        headerType,
        category,
        headerTitle,
        description,
        templateId,
        imageUrl,
        videoUrl,
        actions,
        fileUrl,
        variableInfo,
        url,
        shorten_url,
        preview_url,
        webHookDNId
      } = template;
      //setting values in form data state
      setFormData((prevState: any) => ({
        ...prevState,
        type,
        language,
        headerType,
        category,
        headerTitle,
        description,
        templateId,
        imageUrl,
        videoUrl,
        actions,
        fileUrl,
        variableInfo,
        url,
        shorten_url,
        preview_url,
        webHookDNId
      }));
    }
    setOldTemplateName(template_name); // save template name for validation purpose 
  }, [selectedTemplate, campaignData]);
  const handleSaveOrUpdate = async () => {
    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed if same template selected and name not changed then dont call api
      if (template.template_name !== oldTemplateName) {
        try {
          //name validation API
          isValidTemplateName = await validateName(
            template.template_name,
            (type ===CampaignChannelTypeEnum.WHATSAPP ?CampaignChannelTypeEnum.WHATSAPP:CampaignChannelTypeEnum.RCS),
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
          showError((error as Error).message);
        }
      }

      // Validate the form fields for checking template name exists or not
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")], //show error
          },
        ]);
      } else {
        //check other fields validation
        await form.validateFields();
       
        const data = {  
          ...template,
          template: formData,
            createdOn: selectedTemplate
                  ? undefined
                  : formatDate(getCurrentDate(), "MM/DD/YYYY"),// if new template then add created on
                modifiedOn: selectedTemplate  //if existing template set modified on
                  ? formatDate(getCurrentDate(), "MM/DD/YYYY")
                  : undefined,
              
        }
        const deserializedData=deserialize(data,IWhatsAppPayload) 
         const serialiedData=serialize(deserializedData as IWhatsAppPayload ) //serializing payload
        const payload = {
          api_key: loginUser.api_key,
          app_id: appId,
          authToken,
          template_id: selectedTemplate ? selectedTemplate._id : undefined, //template id if template is selected otherwise undefined
          args:serialiedData
        };
       
        // Determine the endpoint and method based on the presence of a selected template
        const endpoint = selectedTemplate
          ? API_ENDPOINTS.UPDATE_CAMPAIGN_TEMPLATE
          : API_ENDPOINTS.CREATE_CAMPAIGN_TEMPLATE;
        //call the Api with the selected endpoint
        const response = await centralApi("POST", endpoint, payload);
      // response from API setting its value in redux 
      dispatch(getToken())
        dispatch(
          setCampaignData({
            ...campaignData,
           data: { ...response, blobContainerCDN },

            tid: response._id,
            t: response.template_type,
          })
        );
        //moving to next step
        dispatch(setCurrentStep(currentStep + 1));
     
    
      }
    } catch (errors) {
      showError(t("somethingWrongLabel"));
    
           dispatch(getToken())
      showError(t("somethingWrongLabel"));
    
       }
  };
  const handleImageUpload = async (
    value: any,
    // type: "imageUrl | fileUrl "
    type: "imageUrl" | "fileUrl" | "videoUrl"
  ) => {
    try {
     //image validation
      const { valid, message } =
        type === "imageUrl"
          ? await validateImageDimensions("imageWhatsApp", value.file) //validate image size
          : { valid: true, message: "" }; // Skip validation for non-image types

      if (valid && appId) {
        //upload image
        setImage((prevState: any) => ({
          ...prevState,

          [type]: {
            loading: true,
          },
        }));

        const formData = new FormData();
        formData.append("image", value.file);
        formData.append("image_id", value.file.name);
        formData.append("appid", appId);
        const res = await centralApi(
          "POST",
          API_ENDPOINTS.SAVE_CAMPAIGN_IMAGES, //url for save image
          formData,
          null,
          false,
          authToken
        );
        
        dispatch(getToken())
     
        setTimeout(() => {
          setBlobContainerCDN(res.baseURL);
          const imgUrl = `${res.baseURL + res.url}`;
          setFormData((prevState: any) => ({
            ...prevState,
            [type]:
              type == "fileUrl"
                ? { url: imgUrl, name: value.file.name }
                : imgUrl,
          }));

          setImage((prevState: any) => ({
            ...prevState,
            [type]: {
              loading: false,
            },
          }));
        }, 600);
      } else {
        showError(message);
      }
    } catch (error) {
      //if error set loading to false
      setImage((prevState: any) => ({
        ...prevState,
        [type]: {
          loading: false,
        },
      }));
    }
  };

  const HeaderButtons = ({ name, imgSrc, type, disabled, ghost }: any) => {
    return (
      <PrimaryButton
   
        className={`${ghost ? "whatsapp-device-headers-button" : ""}`}
        disabled={selectedTemplate !== null ? disabled : false}
        
        onClick={() => {
          setFormData((prevState: any) => {
            const newHeaderType = type; // This is the current `type`
            const newType =
              newHeaderType === "media" ? "MEDIA_TEMPLATE" : prevState.type;  //set MEDIA template if type is image

            return {
              ...prevState,
              headerType: newHeaderType,
              type: newType,
            };
          });
        }}
      >
        <div
          className={`d-flex flex-wrap flex-column align-items-center whatsapp-header-buttons `}
        >
          <img
            className="engagement-tool-comp-icon"
            height={24}
            width={24}
            src={imgSrc}
          ></img>
          <span className="mt-1" style={{ fontSize: 12 }}>
            {name}
          </span>
        </div>
      </PrimaryButton>
    );
  };


  useEffect(() => {
    // Set initial values when template changes
    if (selectedTemplate) {
      form.setFieldsValue({
        template_name: template.template_name,
      });
    }
  }, [template, form]);
  const isRtl=  formData?.language==RTLLanguageEnum.ARABIC || formData?.language==RTLLanguageEnum.URDU
  return (
    <div className="d-flex gap-md-5 mx-3">
      <TemplateLayout
        leftItems={
          <CampaignDevice
            campType= {CampaignChannelTypeEnum.WHATSAPP} 
            bazel={{ top: 88, left: 30, bottom: 100, right: 32 }}
          >
            <WhatsAppContainer
              headerTitle={formData?.headerTitle}
              description={formData?.description}
              imageUrl={formData?.url}

              videoUrl={formData?.videoUrl}
              fileUrl={formData?.fileUrl}
              headerType={formData.headerType}
              actions={formData?.actions}
              variableInfo={formData?.variableInfo}
              uploadImage={handleImageUpload}
              hideUpload={false}
              imageLoading={image}
            />
          </CampaignDevice>
        }
        rightItems={
          <div className="w-100 whatsapp-device-container">
            <Personalization
              personalizationState={personalization}
              setFormData={setFormData}
              setPersonalizationState={setPersonalization}
            />

            <Form colon={false} labelAlign="left" layout="vertical" form={form}>
              <Row className="pt-3  justify-content-between">
                <h5> {type==CampaignChannelTypeEnum.WHATSAPP ? t("whatsAppLabel"):t("rcsLabel") }  {t("campaignSmallLabel")}</h5>
              </Row>
              <Row className="pt-3">
                <Col span={24}>
                  <FormItem
                    className="my-0 w-100"
                    label=""
                  >
                    <Selector                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      //mapping list of templates of type whats app
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row className="w-100 mt-3">
                <div className="d-flex w-100 gap-2">
                  <Col className="d-flex w-100 ">
                    <FormItem
                      className="m-0  w-100 "
                      initialValue={formData.language}
                      label={t("languageLabel")}
                    >
                      <Selector
                        className="w-100"
                        onChange={(selectedValue) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            language: selectedValue,
                          }));
                        }}
                        disabled={
                          selectedTemplate !== null ? formData?.language : false
                        }
                        options={languageVariants?.varaints?.map((langVariant: any) => ({
                          label: langVariant.name,
                          value: langVariant.name,
                        }))}
                        placeholder={t("selectLanguageLabel")}
                        value={formData?.language}
                      />
                    </FormItem>
                  </Col>
                  <Col className="d-flex w-100 ">
                    <FormItem
                      className="m-0 w-100 "
                      initialValue={formData.category}
                      label="Category"
                    >
                      <Selector
                        onChange={(selectedValue) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            category: selectedValue,
                          }));
                        }}
                        options={[
                          {
                            label: t("marketingLabel"),
                            value: "marketing",
                          },
                          {
                            label: t("utilityLabel"),
                            value: "utility",
                          },
                        ]}
                        value={formData?.category}
                        //disable if template is selected
                        disabled={
                          selectedTemplate !== null ? formData?.category : false
                        }
                        placeholder={t("selectCategoryMsg")}
                      />
                    </FormItem>
                  </Col>
                </div>
                <Col className="d-flex gap-1 " span={24}>
                  <FormItem
                    initialValue={template?.template_name}
                    className="w-100 m-0 mt-2  "
                    label={t("templateNameLabel")}
                    name={"template_name"}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}
                    ></TextInput>
                  </FormItem>
                </Col>
                <Col className="d-flex w-100 mt-3">
                  <FormItem
                    className=" w-100  m-0 whatsapp-device-vertical-form-item"
                    label={t("templateId")}
                    initialValue={formData.templateId}
                  >
                    <TextInput
                      
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          templateId: e.target.value,
                        }));
                      }}
                      value={formData?.templateId}
                    ></TextInput>
                  </FormItem>
                </Col>

                {formData?.headerType == "text" && (
                  <Col className="d-flex mt-3 w-100">
                    <FormItem
                      initialValue={formData.headerTitle}
                      className=" w-100 m-0 whatsapp-device-vertical-form-item"
                      label={
                        <span>
                          {t("headerTitleLabel")}
                          <span style={{ fontSize: "11px" }}>({t("optionalLabel")})</span>
                        </span>
                      }
                    >
                      <TextInput
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            headerTitle: e.target.value,
                          }));
                        }}
                        value={formData?.headerTitle}
                        dir={isRtl ? "rtl":""}

                      ></TextInput>
                    </FormItem>
                  </Col>
                )}

                <Col className="d-flex " span={24}>
                  <FormItem
                    className="w-100 m-0  mt-3"
                    label="Header"
                    initialValue={formData?.headerType}
                  >
                    <div className="d-flex gap-4 whatsapp-header-buttons-primary">
                      <HeaderButtons
                        type="file"
                        name="File"
                        imgSrc={fileIcon}
                        disabled={formData?.headerType == "file" ? false : true}
                        ghost={formData?.headerType == "file"}
                      />
                      <HeaderButtons
                        type="video"
                        name="video"
                        imgSrc={videoIcon}
                        disabled={
                          formData?.headerType == "video" ? false : true
                        }
                        ghost={formData?.headerType == "video"}
                      />
                      <HeaderButtons
                        type="text"
                        name="Text"
                        imgSrc={textIcon}
                        disabled={formData?.headerType == "text" ? false : true}
                        ghost={formData?.headerType == "text"}
                      />
                      <HeaderButtons
                        type="media"
                        name="Image"
                        imgSrc={imageIcon}
                        disabled={
                          formData?.headerType == "media" ? false : true
                        }
                        ghost={formData?.headerType == "media"}
                      />
                    </div>
                  </FormItem>
                </Col>

                <Col className="d-flex mt-3 " span={24}>
                
                  <FormItem
                    className="w-100 m-0 "
                    label=""
                    initialValue={formData?.description} //initial value for description
                  >
                    <div className=" whatsapp-device-emoji-icon d-flex align-items-center">
                      <span
                        className=""
                        style={{ fontSize: "13px", fontWeight: 600 }}
                      >
                        {t("bodyLabel")}
                      </span>
                      <div className="ms-auto">
                        <PrimaryButton onClick={handleToggleEmojiPicker}>
                          <SmileOutlined />
                        </PrimaryButton>

                        <MoreOutlined
                          onClick={() => {
                            setPersonalization({
                              open: true,
                              field: "description",
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="position-relative ">
                      <CustomTextArea
                        rows={4}
                        maxLength={charLimit?.message}
                        value={formData?.description} //body text
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            description: e.target.value,
                          }));
                        }}
                        style={{
                          resize: "none",
                          background: "#F4F8FF", // Change this to the desired background color
                        }}
                        dir={isRtl ? "rtl":""}
                      />
                    </div>
                    {showEmojiPicker && (
                      <div
                        className="emoji-picker-dropdown"
                        ref={emojiPickerRef}
                      >
                        <EmojiPicker
                          onEmojiClick={handleEmojiClick}
                          defaultSkinTone={undefined}
                        />
                      </div>
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem className="m-0 my-2" label={t("previewUrlLabel")}>
                    <SegmentedCustom
                      value={formData.preview_url === true}
                      onChange={(value: boolean) => {
                        setFormData((prevState: any) => {
                          return {
                            ...prevState,
                            preview_url: value,
                            shorten_url: !value, // Ensure shorten_url is false when preview_url is true
                          };
                        });
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem className="m-0 my-2" label={t("shortenUrlLabel")}>
                    <SegmentedCustom
                      value={formData.shorten_url === true}
                      onChange={(value: boolean) => {
                        setFormData((prevState: any) => {
                          return {
                            ...prevState,
                            shorten_url: value,
                            preview_url: !value, // Ensure preview_url is false when shorten_url is true
                          };
                        });
                      }}
                    />
                  </FormItem>
                </Col>
                <Col className="d-flex mt-3 w-100">
                  <FormItem
                    initialValue={formData.webHookDNId}
                    className=" w-100 m-0 whatsapp-device-vertical-form-item"
                    label="Webhook DNID"
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          webHookDNId: e.target.value,
                        }));
                      }}
                      value={formData?.webHookDNId}
                    ></TextInput>
                  </FormItem>
                </Col>
                <Col className="d-flex gap-1 " span={24}>
                  <FormItem
                    initialValue={formData?.url}
                    className="w-100 m-0 mt-2  "
                    label="Url"
                  >
                    <TextInput
                    maxLength={1000}
                     
                      onChange={(e: any) => {
                        setFormData((prevState:any) => ({
                          ...prevState,
                          url: e.target.value,
                          imageUrl: {
                            name: "image",
                            url: e.target.value, // or set a different URL if needed
                          },
                        }));
                      }}
                     
                      value={formData.url}

                    ></TextInput>
                  </FormItem>
                </Col>
                <Col className=" mt-3 " span={24}>
                  <FormItem>
                    <div className={"my-2"}>
                      <ActionButtons
                        maxButtons={2}
                        type={CampaignChannelTypeEnum.WHATSAPP}
                        buttonsState={formData.actions}
                        setButtonsState={(value: any) =>
                          setFormData((prevState: any) => ({
                            ...prevState,
                            actions: value,
                          }))
                        }
                      />
                    </div>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        }
        footerItem={
          <Row className="gap-2  d-flex justify-content-end">
            <PrimaryButton
              disabled={!template.template_name}
              type="primary"
              htmlType="submit"
              onClick={handleSaveOrUpdate}
            >
              {selectedTemplate ? t("updateLabel") : t("saveLabel")}
            </PrimaryButton>
            <PrimaryButton
              style={{
                color: "white",
                background: "var( --color-red-main)",
              }}
              onClick={() => {
                dispatch(setCampaignData({}));  //empty the state in redux when close is clicked
                dispatch(setCurrentStep(0)); //set current set tp 0 when close is clicked
              }}
            >
              {t("closeLabel")}
            </PrimaryButton>
          </Row>
        }
      />
    </div>
  );
};

export default WhatsApp;
