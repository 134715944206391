import CustomAvatar from "components/CustomAvatar/CustomAvatar";
import { FC, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import "./Customer360.css";
import ThreeSixtyCard from "./components/Customer360Card/Customer360Card";
import { t } from "i18next";
import { CONSTANTS } from "utils/constants/constants";
import { useLocation, useParams } from "react-router-dom";
import centralApi from "services/centralApi";
import { useSelector } from "react-redux";
import { formatDate } from "helperFunctions/date";
import { Helmet } from "react-helmet";
import { API_ENDPOINTS } from "utils/constants";
import UserInteractions from "./components/UsersInteractions/UsersInteractions";
import {UserProfile} from '../../utils/models/index'
import Selector from "components/Selector/Selector";
import CustomisedProfile from "./components/CustomisedProfile/CustomisedProfile";

const Customer360: FC = () => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const features = useSelector((state: any) => state.featuresSetting.data);
  const featuresSetting = features[0]?.features;
  const [userDetails, setUserDetails] = useState<any>();
  const [userInfo , setUserInfo] = useState<any>()
  const [activeCard , setActiveCard] = useState<{cardNumber: string; cardDetails: any}>({
    cardNumber : "" ,
    cardDetails : {}
})


  const params = useParams(); // This will get the ID from the URL path
  const location = useLocation();
  const { id } = useParams();
  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  
  //fetch user details from api
  const fetchAppUserDetails = async () => {
    let payload ;
    //checks if customer id is present in params it will send cci in payload for search
    if(type === 'ci'){
      payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        ci: id,
      };
    }
    //if customer id is not present in parasm it will sedn did as a payload for search
    else{
      payload = {
        api_key: loginUser.api_key,
        app_id: appId,
        did: id,
      };
    }   
    const response  : UserProfile = await centralApi(
      "GET",
      API_ENDPOINTS.GET_USER_DETAILS_URL,
      null,
      payload
    );
     setUserDetails(response);
  };
 
 
useEffect(() => {
  if(userDetails){
     // Process cProfile data
     const { filteredObject: filteredCProfile,nullObject: nullCProfile } = processObject(
      userDetails?._custom || {}, // Ensure cProfile exists
    );

    
      // Process Cards data if it exists
      const cardOptions = Object.keys(userDetails?.Cards || {}).map((cardNumber) => ({
        value: cardNumber,
        label: cardNumber,
      }));

      const userProfileKeys = Object.keys(userDetails);

    const modifiedEventData =userDetails?.eventHistory?.el
    .sort((a : any, b : any) => b.t - a.t) // Sort in descending order based on `t`
    .map((event : any) => {
        // Construct availableString by iterating over segment keys
        const availableString = Object.entries(event?.segment || {}).map(([key, value]) => {
            // Format the key and value pair as "key:value"
            return `${key}:${value}`;
        }).join(", "); // Join with comma without filtering out any values

        return {
            label: dayjs.unix(event.t).format("DD/MM/YY:HH:mm:ss"),
            children: `${event.k}, ${availableString}`,
        };
    });
    const modifiedTransactionData = userDetails?.customProperties?.transaction_info?.map((transaction : any) => {
      return {
        color : transaction.type == "D" ? "red" : "green" ,
        label: formatDate(transaction.date, "DD/MM/YY:HH:mm"),
        children: `${transaction.currency} ${transaction.amount},Trans_Mode: ${transaction.mode}`
      };
    });
  
    setUserInfo({
      cifId : userDetails?.userId ,
      name: userDetails?.name || "NA",
      icon : getInitials(userDetails?.name ) || "NA",
      gender: userDetails?.gender || "NA",
      isMarried : userDetails?.isMarried || "NA",
      dob : userDetails?.dob || "NA",
      email : userDetails?.email || "NA",
      phone : userDetails?.phone || "NA",
      nationality : userDetails?.nationality || "NA",
      eventList : modifiedEventData,
      transactionData : modifiedTransactionData,
      filteredCProfile,
      cardOptions,
      userProfileKeys

    });
  }
}, [userDetails]);

useEffect(() => {
  if (loginUser.api_key && appId) {
    fetchAppUserDetails();
  }
}, [loginUser.api_key, appId]);




      /**
     * Generates an abbreviation from the full name based on the initials.
     * @param fullName - The full name from which to generate the abbreviation.
     * @returns A string with the initials of the name parts.
     */
    function getInitials(fullName: string | undefined): string {
      // Check if fullName is undefined, null, or an empty string
      if (!fullName || typeof fullName !== 'string' || fullName.trim() === '') {
        return ''; // Return an empty string if the input is invalid
      }
      // Split the full name into parts
      const nameParts = fullName.split(' ');

      // Get the first letter of each part and join them
      const initials = nameParts
        .filter(part => part.length > 0) // Ensure the part is not empty
        .map(part => part.charAt(0).toUpperCase()) // Get the first character and convert to uppercase
        .join('');

      return initials;
    }
    const interactionItems = [];
    // Check if transaction data should be included based on featureSettings
    if (featuresSetting?.transactionalData) {
      // Only include behavioural data if transaction data is true
      interactionItems.push({
        title: t("behaviouralDataLabel"),
        eventData: userInfo?.eventList,
      });
    } else {
      // Include both behavioural and transactional data
      interactionItems.push(
        {
          title: t("behaviouralDataLabel"),
          eventData: userInfo?.eventList,
        },
        {
          title: t("transactionalDataLabel"),
          eventData: userInfo?.transactionData,
        }
      );
    }

      const cardFilteredDetails = useMemo(() => {
        if (activeCard?.cardNumber && activeCard.cardDetails) {
          const detailEntries = Object.entries(activeCard.cardDetails)
            .filter(([key, value]) => value !== null && value !== "")
            .map(([key, value]) => ({
              label: key, 
              value,
            }));
      
          // Divide the details into three chunks
          const chunkSize = Math.ceil(detailEntries.length / 3);
          return [
            detailEntries.slice(0, chunkSize),
            detailEntries.slice(chunkSize, chunkSize * 2),
            detailEntries.slice(chunkSize * 2),
          ];
        }
        return [];
      }, [activeCard]);
      

    function processObject(
      obj: Record<string, any>,
    ): {
      filteredObject: Record<string, any>;
      nullObject: Record<string, any>;
    } {
      const filteredObject: Record<string, any> = {};
      const extractedObject: Record<string, any> = {};
      const nullObject: Record<string, any> = {};
    
      // Iterate through the object
      for (const [key, value] of Object.entries(obj)) {
        if (value === null || value === "null" || value === "N/A") {
          nullObject[key] = value;
        }  else {
         
         const label = key
        .replace(/([A-Z])/g, " $1") // Add spaces before uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter

        if (key === "createdDate" || key === "updateDate") {
          // Format epoch timestamps
          filteredObject[label] = dayjs(new Date(value)).format("DD-MM-YYYY");
        } else if (
          key === "kycExpiryDate" ||
          key === "dateOfJoining"
        ) {
          filteredObject[label] = dayjs(new Date(value)).format("DD-MM-YYYY");
        }
        else{
          // Assign the value to the transformed key in filteredObject
         filteredObject[label] = value;
        }
        }
      }
    
      return { filteredObject,  nullObject };
    }

  return (
    <div className="c-three-sixty-main-container">
      <Helmet>
        <title>Appice | C360</title>
      </Helmet>
      {/* -----header container starts from here----- */}
      <div className="d-flex justify-content-between my-3">
        <div className="d-flex gap-lg-3 gap-2  c-three-sixty-header-container ">
          <div>
            <CustomAvatar
              className="c-three-sixty-header-avatar"
              shape="square"
              size={100}
            >
              {userInfo?.icon}
            </CustomAvatar>
          </div>
          <div className="d-flex justify-space-between  flex-column py-2 gap-3">
          <h6>{userInfo?.name}</h6>

            {/* First row with CIF ID */}
            <div className="d-flex justify-content-between">
            <span className="me-3">{t("cifIdLabel")}</span>
            <span className="text-dark">{userInfo?.cifId}</span>
          </div>

    
          </div>

        </div>
        <div className="d-flex w-50 justify-content-between">
          <ThreeSixtyCard
            cardType={CONSTANTS.HEADER}
            firstKey={"Gender"}
            firstValue={userInfo?.gender}
            secondKey={"Marital Status"}
            secondValue={userInfo?.isMarried}
            thirdKey={"DOB"}
            thirdValue={userInfo?.dob}
          />
          <ThreeSixtyCard
            cardType={CONSTANTS.HEADER}
            firstKey={"Email"}
            firstValue={userInfo?.email}
            secondKey={"Mobile"}
            secondValue={userInfo?.phone}
            thirdKey={"Nationality"}
            thirdValue={userInfo?.nationality}
          />
        </div>
        
      </div>

    { /**User info starts from here  */}
     {userInfo?.userProfileKeys.includes("_custom")  &&  
        Object.keys(userDetails._custom || {}).length > 0  && (
    <>
     <div className="d-flex gap-lg-3 my-2 gap-2  border-bottom ">
      <h6 className="m-2">Profile</h6>
      </div>

      <CustomisedProfile  labelStyle={{ fontWeight: "bold"}}  data={userInfo?.filteredCProfile} />
    </>
    )}
    { /**user info ends here  */}
     
     { /**CARDS overview starts from here  */}
     {userInfo?.userProfileKeys.includes("Cards")  && 
     Object.keys(userDetails?.Cards || {}).length > 0  && (
    <>
     <div className="d-flex gap-lg-3 mt-2 gap-2  border-bottom ">
      
        <h6 className="m-2">Cards</h6>
     
     </div>
     <div className="my-2">
      <Selector
        className="input-size-md"
        placeholder={"Select card number"}
        options={userInfo?.cardOptions}
        onChange={(value: string)=>{
          setActiveCard({
            cardNumber : value ,
            cardDetails : userDetails?.Cards[value as keyof typeof userDetails.Cards]
        })
        }}
        />
     </div>
       <ThreeSixtyCard
            cardType={CONSTANTS.CARDS}
            cardFilteredDetails={cardFilteredDetails}
            
        />
     </>
    )}
    { /**CARDS overview ends here  */}

      {/*-----------User/Interaction component starts from here*/}
      <div>
      <UserInteractions items={interactionItems} />
    </div>
      
    </div>
    
  );
};

export default Customer360;
