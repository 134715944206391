import { Divider } from "antd";
import "./Channel.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import centralApi from "../../../../services/centralApi";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import React from 'react';
import { getToken } from "redux/features/app/authTokenSlice";
interface ChannelObjProps {
  channelObj:any;
  updateCurrentAppKey: () => void;

}
const Channel: React.FC<ChannelObjProps> = ({  channelObj,updateCurrentAppKey }) => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmitFacebook = (values: any) => {
    const { account_id } = values;
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        channelObj: { type: "facebook", account_id },
      }),

      app_id: appId,
      api_key: api_key,
    };
    updateChannelAccount(payload);
    dispatch(getToken())
  };
  const onFormSubmitGoogle = (values: any) => {
    const { account_id } = values;
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        channelObj: { type: "google", account_id },
      }),

      app_id: appId,
      authToken,
      api_key: api_key,
    };
    updateChannelAccount(payload);
    dispatch(getToken())
  };
  async function updateChannelAccount(formData: any) {
    const url: string = `/i/apps/updateChannelAccount`;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      updateCurrentAppKey();
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
    } catch (error) {
      setShowMessage({
        type: "error",
        message: "Something Went Wrong",
        show: true,
      });
      setLoading(false);
    }
    hideMessage();
  }
  const facebook = channelObj?.channel_obj?.channel_facebook;
  const google =channelObj?.channel_obj?.channel_google;

  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };
 
  
  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      account_id: facebook,

    };
    form.setFieldsValue(updatedFormValues);

    }, [channelObj,form]);
  return (
    <div className="channel-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="channel-setting-container-heading">Channel Settings</h6>
        <Divider className="channel-setting-custom-divider" />
        <div>
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading?<Spinner/>:(
          <div className="  mt-4 align-items-center channel-setting-form ">
            <div className="mb-4">
              <p>
                Integrate Facebook ad network to enable remarketing messages
              </p>

              <Form
              form={form}
                onFinish={onFormSubmitFacebook}
                initialValues={{
                  account_id: facebook,
                }}
              >
                <div className=" col-sm-8 gap-2">
                  <Form.Item
                    // label="Access key ID"
                    name="account_id"
                    rules={[
                      {
                        required: true,
                        message: "Please input facebook ads account ID!",
                      },
                    ]}
                  >
                    <TextInput
                      placeholder="Enter facebook ads account ID"
                      maxLength={100}
                      addonAfter={
                        <div>
                          <PrimaryButton htmlType="submit" type="primary">
                            Connect
                          </PrimaryButton>
                        </div>
                      }
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>
            <div>
              <p>
                Integrate your Google Ads account to enable remarketing messages
              </p>
              <Form onFinish={onFormSubmitGoogle} initialValues={{account_id:google}}>
                <div className="col-sm-8 gap-2">
                  <Form.Item
                    // label="Access key ID"
                    name="account_id"
                    rules={[
                      {
                        required: true,
                        message: "Please input google ads account ID!",
                      },
                    ]}
                  >
                    <TextInput
                      placeholder="Enter Google ads account ID"
                      maxLength={100}
                      addonAfter={
                        <div>
                          <PrimaryButton htmlType="submit" type="primary">
                            Connect{" "}
                          </PrimaryButton>
                        </div>
                      }
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>)}
        </div>
      </div>
    </div>
  );
};
export default Channel;
