import React, { useState } from 'react';
import { Avatar, Form, List } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Comment as AntComment } from '@ant-design/compatible';
import { UserOutlined } from '@ant-design/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton/PrimaryButton';
import CustomTextArea from 'components/Inputs/CustomTextArea/CustomTextArea';
import CustomCollapse from 'components/CustomCollapse/CustomCollapse'; // Assuming you have a collapse component
import AccessControl from 'components/HOC/AccessControl/AccessControl';
import { roleCheckTypes } from 'utils/constants/constants';
import { t } from 'i18next';

dayjs.extend(relativeTime);

interface CustomComment {
  author: string;
  content: string;
  ct: number;
}

interface CustomCommentProps {
  initialComments: CustomComment[];
  onCommentAdd: (newComment: CustomComment) => void;
}

const CustomComment: React.FC<CustomCommentProps> = ({ initialComments, onCommentAdd }) => {
  const [value, setValue] = useState<string>('');

  const handleSubmit = () => {
    if (!value) return;

    const newComment: CustomComment = {
      author: '', // Author to be handled by backend
      content: value,
      ct: Math.floor(Date.now() / 1000),
    };

    onCommentAdd(newComment);
    setValue('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const renderComments = (comments: CustomComment[]) => {
    if (comments.length === 0) {
      return <p style={{ textAlign: 'center', color: '#888' }}>No reviews yet</p>;
    }

    return (
      <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={comment => (
          <AntComment
            author={
              <span>
                <strong>{comment.author.split(' @')[0]}</strong>
                <span style={{ marginLeft: '5px', color: '#888' }}>{comment.author.split(' @')[1]}</span>
              </span>
            }
            avatar={<Avatar style={{ backgroundColor: '#418AFE' }} icon={<UserOutlined />} />}
            content={<p>{comment.content}</p>}
            datetime={dayjs(comment.ct * 1000).fromNow()}
          />
        )}
      />
    );
  };

  return (
    <div className="mt-4 mb-3">
      <h6>{t("reviewSectionLabel")}</h6>
      <CustomCollapse
        header="Campaign Review's"
        body={
          <div>
            {renderComments(initialComments)}
            <AccessControl accessControl={{ role: [roleCheckTypes.Reviewer] }}>
              <AntComment
                avatar={<Avatar style={{ backgroundColor: '#418AFE' }} icon={<UserOutlined />} />}
                content={
                  <div>
                    <Form.Item>
                      <CustomTextArea rows={3} onChange={handleChange} value={value} />
                    </Form.Item>
                    <Form.Item>
                      <PrimaryButton htmlType="submit" onClick={handleSubmit} type="primary">
                        Add Comment
                      </PrimaryButton>
                    </Form.Item>
                  </div>
                }
              />
            </AccessControl>
          </div>
        }
        defaultActiveKey="1" 
      />
    </div>
  );
};

export default CustomComment;
