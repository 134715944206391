import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { PlusOutlined } from "@ant-design/icons";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import Search from "../../../../components/Inputs/Search/Search";
import { Link, useNavigate } from "react-router-dom";
import CustomBreadCrumb from "../../../../components/CustomBreadCrumb/CustomBreadCrumb";
import editIcon from "../../../../images/_edit.svg";
import removeIcon from "../../../../images/remove.svg";
import minusIcon from "../../../../images/minus.svg";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "helperFunctions/date";
import ConfirmPopUp from "components/PopUp/ConfirmPopUp/ConfirmPopUp";
import useMessage from "hooks/useMessage";
import { t } from "i18next";
import "./TraitsTable.css"
import { fetchComputedTraitList } from "redux/features/audience/computedTraitListSlice";
import { Helmet } from "react-helmet";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import { PAGE_OPTIONS } from "utils/constants/selectorOptions";
import Selector from "components/Selector/Selector";
import { STATUS } from "redux/constant";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { roleCheckTypes } from "utils/constants/constants";
import { DateFormatEnum } from "utils/enums";
const TraitsTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const { showError, showSuccess } = useMessage();
  const { appId } = useSelector((state: any) => state.activeApp);
  const [traitListCopy, setTraitListCopy] = useState<any>([]);
  const dispatch: any = useDispatch();
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const {data:traitList,status} = useSelector((state: any) => state.computedTraitList);
  const [pageData, setPageData] = useState({
    pageSize: PAGE_OPTIONS.DEFAULT_VALUE,
    simple: false,
  })
  /**
 * Handles the confirmation and execution of deleting a trait.
 *
 * @param id -  id of  trait to be deleted.
 */
const onTraitDeletedConfirm = async (id: string) => {
  try {
    // Construct parameters for the API request
    const params = {
      app_id: appId,            
      api_key: loginUser.api_key, 
      id,                     
    };

    // Make an  API request to delete the trait
    await centralApi("GET", API_ENDPOINTS.DELETE_TRAIT_URL, null, params);

    // Show a success message if the trait was deleted successfully
    showSuccess(t("traitDeletedSuccessMsg"));

    // Dispatch an action to fetch the updated list of computed traits
    dispatch(
      fetchComputedTraitList({ api_key: loginUser.api_key, app_id: appId })
    );
  } catch (error) {
    // Show an error message if there was a problem deleting the trait
    showError(t("somethingWrongLabel"));
  }
};
  //table columns
  const columns: any = [
    {
      title: t("nameLabel"),
      dataIndex: "name",
    },
    {
      title: t("rangeLabel"),
      dataIndex: "range",
      render: (range: number) => (
        <span className="d-flex flex-column">
          <p className="m-0 ">{range}</p>
        </span>
      ),
    },
    {
      title: t("statusLabel"),
      dataIndex: "status",
    },
    {
      title: t("createdOnLabel"),
      dataIndex: "createdOn",
      render: (createdOn: number) => {
        return createdOn && <div>{formatDate(createdOn * 1000, DateFormatEnum.ABBREVIATED_MONTH_TIME)}</div>;
      },
    },
    {
      title: t("createdByLabel"),
      dataIndex: "createdBy",
    },
    {
      title: t("modifiedOnLabel"),
      dataIndex: "modifiedOn",
      render: (createdOn: number,{modifiedBy}:{modifiedBy:string | undefined}) => {
        return modifiedBy && createdOn && <div>{formatDate(createdOn * 1000, DateFormatEnum.ABBREVIATED_MONTH_TIME)}</div>;
      },
    },
    {
      title: t("modifiedByLabel"),
      dataIndex: "modifiedBy",
    },
    {
      title: t("actionLabel"),
      dataIndex: "_id",
      className: "text-center",

      render: (id: string) => {
        return (
          <div className="d-flex justify-content-center  h-100">
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}}>  
            <ConfirmPopUp
              title={t("deleteTraitMsg")}
              icon={removeIcon}
              onConfirm={() => {
                onTraitDeletedConfirm(id);
              }}
              id={id}
            >
              <PrimaryButton title={t("deleteLabel")} className="mx-1">
                <img src={minusIcon} title={`${t("deleteLabel")}`} alt={"minus_icon"} className="ctrait-icons"></img>
              </PrimaryButton>
            </ConfirmPopUp>
            </AccessControl>
            <PrimaryButton
            title={t("editLabel")}
              className="mx-1"
              onClick={() => {
                navigate(ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE,{state:{id}});
              }}
            >
              <img src={editIcon} title={`${t("editLabel")}`}  alt={"edit_icon"} className="ctrait-icons"></img>
            </PrimaryButton>{" "}
          </div>
        );
      },
    },
  ];
  /**
 * Handles the change event when selection in a table or list is updated.
 *
 * @param newSelectedRowKeys - An array of keys representing the newly selected rows.
 */
const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
  // Update the state with the new selection keys
  setSelectedRowKeys(newSelectedRowKeys);
};
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    if (loginUser.api_key) {
      //fetch all traits
      dispatch(
        fetchComputedTraitList({ api_key: loginUser.api_key, app_id: appId })
      );
    }
  }, [loginUser]);
  useEffect(() => {
    setTraitListCopy(traitList);
  }, [traitList]);
/**
 * Handles the search input change event and filters the trait list based on the search query.
 *
 * @param e - The event object from the search input change event.
 */
const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  // Create a regular expression from the search input value
  const regex = new RegExp(e.target.value, "i");

  // Filter the trait list based on the regular expression
  setTraitListCopy(
    traitList?.filter(({ name }: { name: string }) => {
      // Return true if the name matches the regex, otherwise false
      return name ? regex.test(name) : false;
    })
  );
};
  return (
    <div className="container-fluid computed-trait-table-main-container px-1">
       <Helmet>
            <title>Appice | Traits</title>
          </Helmet>
      <div className="pb-4 pt-1">
        <CustomBreadCrumb
          items={[
            {
              title: <Link to={ROUTES.DASHBOARD_AUDIENCE}>{t("audienceLabel")}</Link>,
            },
            {
              title: <Link to={ROUTES.DASHBOARD_AUDIENCE_CREATE}>{t("createLabel")}</Link>,
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS}>{t("allTraitsLabel")}</Link>
              ),
            },
          ]}
        />
      </div>
      <div className="p-3 ctrait-table-wrapper">
        <div className="d-flex justify-content-between align-items-center py-4">
          <h6>{t("traitsLabel")}</h6>
          <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}}>  
          <PrimaryButton
            type="primary"
            onClick={() => {
              navigate(ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE);
            }}
          >
            <PlusOutlined />
            <span className="mx-2">{t("newTraitLabel")}</span>
          </PrimaryButton>
          </AccessControl>
        </div>
        <div>
          <Search
          className="ctrait-table-search"
            onChange={handleSearch}
          />
          <Selector
           onChange={(value) => {
            setPageData({ ...pageData, pageSize: value })
            }}
            options={PAGE_OPTIONS.DATA} 
            defaultValue={pageData.pageSize}
          />
        </div>
        <CustomTable
          columns={columns}
          loading={status==STATUS.LOADING}
          scroll={{ x: 600 }}
          rowKey={(trait: any) => trait._id}
          dataSource={traitListCopy}
          totalEntries={traitListCopy?.length}
          pagination={pageData}
          rowSelection={rowSelection}
          size="small"
        />
      </div>
    </div>
  );
};

export default TraitsTable;
