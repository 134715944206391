import { Divider } from "antd";
import "./AllowList.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import ShowMessage from "../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";
interface AllowListProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const AllowList: React.FC<AllowListProps> = ({ currentAppKey , updateCurrentAppKey}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();
  const authToken = useSelector((state: any) => state.authToken.token);


  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        allowList: values.allowList,
      }),
      authToken,
      api_key: api_key,
    };
    saveAllowList(payload);
    dispatch(getToken())
  };

  async function saveAllowList(formData: any) {
    const url: string = API_ENDPOINTS.SAVE_ALLOW_LIST_URL;

    try {
      setLoading(true);
      await centralApi("POST", url, formData,null);
      setShowMessage({
        type: "success",
        message: t("sucessfullySavedLabel"),
        show: true,
      });
      updateCurrentAppKey();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: t("somethingWrongLabel"),
        show: true,
      });
    }
    dispatch(getToken())
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  
  useEffect(() => {
    const updatedFormValues = {
      allowList: currentAppKey[0]?.allowList
    };
    form.setFieldsValue(updatedFormValues);

  }, [currentAppKey, form]);
  return (
    <div className="allow-list-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="allow-list-setting-container-heading"> {t("allowListSettingsLabel")}  </h6>
        <Divider className="allow-list-setting-custom-divider" />

        <div>
          <div className=" col-sm-8 mt-4 align-items-center allow-list-setting-form ">
            {showMessage.show && (
              <ShowMessage
                type={showMessage.type}
                content={showMessage.message}
              />
            )}
            {loading ? (
              <Spinner />
            ) : (
              <Form
                form={form}
                layout="horizontal"
                onFinish={onFormSubmit}
              >
                <Form.Item
                  label= {t("allowListLabel")} 
                  name="allowList"
                >
                  <TextInput />
                </Form.Item>
             

                <div >
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllowList;
