import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { beefree } from "../../helperFunctions/beefree";
import { CloseOutlined, DesktopOutlined, EditOutlined, MobileOutlined,SyncOutlined } from "@ant-design/icons";
import "./BeefreeEditor.css";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import logo from "../../images/ALogo.svg"
import FormItem from "antd/es/form/FormItem";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import { BeefreeViewModeEnum } from "utils/enums";
import { t } from "i18next";
import { useSelector } from "react-redux";
interface Workspace {
  onSave?: (jsonFile: string, htmlFile: string) => void;
  onChange?: (jsonFile: string, response: any) => void;
  onSaveAsTemplate?: (jsonFile: string) => void;
  onAutoSave?: (jsonFile: string) => void;
  onSend?: (htmlFile: string) => void;
  onLoad?: (jsonFile: string) => void;
  onError?: (errorMessage: string) => void;
}
export interface beefreeOptionType {
  existingTemplate?: any;
  workspace?: Workspace;
}
interface BeeFreeEditorProps {
  style?: React.CSSProperties;
  isUpdate?:boolean
  onClose?: () => void;
  options?: beefreeOptionType;
}
const BeeFreeEditor: FC<BeeFreeEditorProps> = ({ style, onClose, options,isUpdate }) => {
  const [bee, setBee] = useState<any>(null);
  const isSave=useRef(false)
  const [loading,setLoading]=useState(false)

  // get features api data
  const features = useSelector((state: any) => state.featuresSetting.data); 

const beefreeConfig = features[0]?.beefreeConfig || {};


  const [isPreveiw,setIsPreview]=useState(false)
  const onSave=(jsonFile: string, htmlFile: string)=>{
    if(!isSave.current && options?.workspace?.onChange){
      options?.workspace?.onChange(jsonFile, htmlFile)
    }
    else if(options?.workspace?.onSave){
      options?.workspace?.onSave(jsonFile, htmlFile)
    }
  }
  const handleSaveBtnClick=useCallback(()=>{
    isSave.current=true
    if(bee && bee?.instance){
      bee.save()
    }
  },[bee])
  useEffect(() => {
    if(beefreeConfig){
    beefree(options || {},beefreeConfig).then(({instance,conf}:any) => {
      conf.onChange=()=>{
        isSave.current = false;
        instance.save();
      }
      conf.onSave=onSave
      conf.onLoad=()=>{setLoading(false)}
      conf.onTogglePreview=(isPreveiw:boolean)=>{setIsPreview(isPreveiw)}
      conf.onPreview=(isPreveiw:boolean)=>{setIsPreview(isPreveiw)}

      setBee(instance);
    }).catch(()=>{});
  }
    return () => {
      //remove the beefree builder
      document
        .getElementById("bee-plugin-container__bee-plugin-loader")
        ?.remove();
      document
        .getElementById("bee-plugin-container__bee-plugin-frame")
        ?.remove();
    };
  }, [features]);
  //reload varaint / tenplate
  useEffect(()=>{
    if (bee && bee.instance) {
     bee?.load(options?.existingTemplate || {})
  }},[options?.existingTemplate,bee])
  return (
    <div className="beefree-editor-main-container" style={style}>
      {onClose && (
        <div className="d-flex justify-content-end mb-2">
          <CloseOutlined onClick={onClose} />
        </div>
      )}
      <div className="position-relative h-100">
        <div className={`custom-beefree-header`}>
          <div className="p-1 w-25">
            <img src={logo}></img>
          </div>
          <div className="w-25 d-flex justify-content-center enable-interaction">
           {bee  && <FormItem  className="m-0">
            <SegmentedCustom 
              onChange={(mode:string)=>{
                bee.loadStageMode({
                  mode,
                  display: 'hide',
                   })
                   if((mode!==BeefreeViewModeEnum.GLOBAL && isPreveiw)){
                     bee.preview()
                     
                   }
                   bee.preview()


              }}
              options={[
                {label:<EditOutlined className="mt-2"/>,value:BeefreeViewModeEnum.GLOBAL},
                {label:<MobileOutlined className="mt-2" />,value:BeefreeViewModeEnum.MOBILE},
                {label:<DesktopOutlined className="mt-2" />,value:BeefreeViewModeEnum.DESKTOP},
              ]}/>
            </FormItem>}
          </div>
          <div className="w-25 align-items-center d-flex justify-content-end gap-3">
            {isUpdate && <div >
          <SyncOutlined title="reload" spin={loading} className="mt-2"  onClick={()=>{
            if (bee && bee.instance) {
              setLoading(true)
              bee?.load(options?.existingTemplate || {})
           }
          }} />
            </div>}
        <PrimaryButton type="primary" disabled={!bee} onClick={handleSaveBtnClick}>
        {isUpdate?t("updateLabel"):t("saveLabel")}
        </PrimaryButton>
          </div>
        </div>
      <div id="bee-plugin-container"></div>
      </div>
    </div>
  );
};

export default React.memo(BeeFreeEditor);
