
import { Input, InputProps } from "antd";
import searchIcon from "../../../images/search.svg";

function Search({
  onChange,
  maxLength,
  ...props
}: InputProps) {
  return (
    <Input
      {...props}
      prefix={<img style={{ height: 16, width: 16 }} src={searchIcon} />}
      onChange={onChange}
      maxLength={maxLength}
    />
  );
}

export default Search;
Search.defaultProps = {
  placeholder: "Search",
  onChange: () => {},
  size: "middle",
  width: 100,
};
