import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import filterIcon from "../../../../images/_filter.svg";
import { Form, MenuProps, Steps} from "antd";
import { validateNotEmpty } from "../../../../validator/antdValidator";
import { SyncOutlined } from "@ant-design/icons";
import "./CreateTrait.css";
import TraitCondition from "../components/TraitCondition/TraitCondition";
import { generateNumbersInRange } from "../../../../helperFunctions/number";
import { t } from "i18next";
import CustomBreadCrumb from "components/CustomBreadCrumb/CustomBreadCrumb";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import TextInput from "components/Inputs/TextInput/TextInput";
import InnerNav from "components/InnerNav/InnerNav";
import Selector from "components/Selector/Selector";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import useMessage from "hooks/useMessage";
import { audienceItems } from "helperFunctions/audience";
import {
  addFilterCondition,
  fetchComputedTraitById,
  formatComputedTraitFilterInfo,
  removeFilterCondition,
  resetComputedTrait,
  restructureFilterConditions,
  setComputedTraitData,
  setFilterInfoData,
} from "redux/features/audience/computedTraitSlice";
import { STATUS } from "redux/constant";
import Spinner from "components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import ComputedTrait from "components/ComputedTrait/ComputedTrait";
import { API_ENDPOINTS, ROUTES } from "utils/constants";
import SegmentBlockLayout from "layout/SegmentBlockLayout/SegmentBlockLayout";
import { CONSTANTS, roleCheckTypes } from "utils/constants/constants";
import { getObjectId } from "services/getObjectId";
import { AudienceEnum, E_OperatorEnum } from "utils/enums";
import SegmentedCustom from "components/SegmentedCustom/SegmentedCustom";
import { FilterInfo, QueryPayload } from "utils/models";
import { eOperatorType } from "@customTypes/audience";
import { E_OPERATOR_OPTIONS } from "utils/constants/selectorOptions";
import { getToken } from "redux/features/app/authTokenSlice";
import { OperatorManager } from "utils/operators";
import AccessControl from "components/HOC/AccessControl/AccessControl";
/**
 * CreateTrait Component
 *
 * This component is used to create or update a trait . It provides a form interface
 * for defining trait details, including setting conditions and selecting relevant options. It interacts
 * with various Redux actions and API endpoints to fetch, submit, and manage trait data.
 *
 * @returns {JSX.Element} - The rendered component.
 */
function CreateTrait() {
  const navigate = useNavigate();
  const { showError, showSuccess } = useMessage();
  const [metaData, setMetaData] = useState<any>({});
  const [tabFilter, setTabFilter] = useState<string>(CONSTANTS.TRAIT);
  const [form] = Form.useForm();
  const [loading,setLoading]=useState({submit:false,columns:false})
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { data: trait, status: traitStatus } = useSelector(
    (state: any) => state.computedTrait
  );
  const { appId } = useSelector((state: any) => state.activeApp);
  const dispatch: any = useDispatch();
  const { state } = useLocation();
  // Memoized value for form validation
  const isFormValidForPreview = useMemo(() => {
    return (trait.tableInfo?.tableName && trait?.filterInfo.length) || false;
  }, [trait]);
  // Handle form submission to save or update the trait
  const onFormSubmit = async () => {
    try {
      // Set loading state to true while the API request is being processed
      setLoading((prevState)=>({...prevState,submit:true}));
      //call api only  if the id not found in redux
      const audid=trait?.audid || await getObjectId(appId,loginUser.api_key)
      // Prepare parameters for the API request, including user API key and app ID
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
        id: state?.id, // Optional ID for updating an existing trait
      };
  
      // format filter  info
      let filterInfo: FilterInfo[] = formatComputedTraitFilterInfo(trait.filterInfo)
      filterInfo=restructureFilterConditions(filterInfo)
      // Create a new TraitsPayload object with the necessary data
      const traitsPayload = new QueryPayload(
        trait.name,
        trait.tableInfo,  
        trait.projectionInfo,   
        filterInfo,     
        audid,          
        trait.days    
      );
      const authToken = await dispatch(getToken()).unwrap();
      // Combine parameters with the traits payload to form the final request payload
      const payloadWithParams = {
        ...params,
        data:traitsPayload.toString(),
        authToken
      };
      // Call the API to either update an existing trait or create a new one
      const res = await centralApi(
        "POST",
        state?.id
          ? API_ENDPOINTS.UPDATE_CTRAIT_URL // URL for updating an existing trait
          : API_ENDPOINTS.CREATE_CTRAIT_URL, // URL for creating a new trait
        payloadWithParams,
        params
      );
      // Check if the API request was acknowledged successfully
      if (res.acknowledged) {
        // Show success message based on whether it's an update or create operation
        showSuccess(
          !state?.id ? t("traitSavedSuccessMsg") : t("traitUpdatedSuccessMsg")
        );
      }
    } catch (error) {
      // Show error message if something goes wrong during the API call
      showError(t("somethingWrongLabel"));
    } finally {
      dispatch(getToken())
      // Reset loading state and navigate to the all traits page
      setLoading((prevState)=>({...prevState,submit:false}));
      navigate(ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS);
    }
  };
  
  const fetchTraitMetaData = async () => {
    try {
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
      };
      const data = await centralApi(
        "GET",
        API_ENDPOINTS.TRAIT_META_DATA_URL,
        null,
        params
      );
      setMetaData((prevState: any) => ({ ...prevState, ...data }));
    } catch (error) {}
  };
  /**
   * "fetchTableInfo" Fetches information about a table from  API.
   *
   * @param operand - name of column optional
   *  If both table and column are available, it returns column values.
   *  If only the table name is provided, it returns column names for that table.
   * @returns The data fetched from the API or an empty array in case of an error.
   */
  const fetchTableInfo = async (operand: string | undefined = undefined,jsonb: string | undefined = undefined) => {
    try {
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
        tableName: trait.tableInfo?.tableName,
        operand,
        jsonb
      };
      //call api
      const data = await centralApi(
        "GET",
        API_ENDPOINTS.GET_TRAIT_TABLE_INFO_URL,
        null,
        params
      );
      return data;
    } catch (error) {
      return [];
    }
  };
  /**
   * getColumns function calls the `fetchTableInfo` function to fetch column information for the current table.
   * It then updates the `metaData` state with the fetched columns information.
   */
  const getColumns = async () => {
    setLoading((prevState)=>({...prevState,columns:true}));
    const {columns,identityColumns} = await fetchTableInfo();
    //set state
    setMetaData((prevState: any) => ({ ...prevState, columns: columns,identityColumns }));
    setLoading((prevState)=>({...prevState,columns:false}));

  };
  /**
   * Generates an array of step items for rendering the list of conditions in the trait form.
   *
   * @returns {Array} - An array of step items where each item represents a condition block or an action button.
   */
  const stepItems = () => {
    return [
      ...(trait?.filterInfo || []).map(
        (condition: FilterInfo, index: number) => ({
          icon: (
            index ? <SegmentedCustom
            value={condition.e_operator}
            onChange={(value: eOperatorType) => {
            dispatch(setFilterInfoData({data:{...condition,e_operator:value},index,parentIndex:-1})) 
            }}
            options={E_OPERATOR_OPTIONS}
          />:
            <PrimaryButton className="trait-step-icon-btn" type="primary">
              {trait?.filterInfo?.[1]?.e_operator==E_OperatorEnum.OR?t("anyLabel"):t("allLabel")}
            </PrimaryButton>
          ),
          // Title of the step, including the condition details
          title: (
            <SegmentBlockLayout
              onDeleteClick={index==trait.filterInfo?.length-1?()=>dispatch(removeFilterCondition({index})):undefined}
            >
              <TraitCondition
                form={form}
                key={index}
                fetchTableInfo={fetchTableInfo}
                metaData={metaData}
                condition={condition}
                index={index}
              ></TraitCondition>
            </SegmentBlockLayout>
          ),
        })
      ),
      {
        icon: (
          <PrimaryButton
            disabled={!trait.tableInfo?.tableName}
            loading={loading.columns}
            onClick={() => dispatch(addFilterCondition({}))}
            type="primary"
          >
            {t("addConditionLabel")}
          </PrimaryButton>
        ),
      },
    ];
  };
  /**
   * Handles tab click events in the menu.
   * Updates the current tab filter and navigates to the appropriate route based on the clicked tab.
   *
   * @param {MenuProps["onClick"]} param - Object containing the key of the clicked menu item.
   * @param {string} param.key - The key of the clicked menu item, determining the navigation target.
   */
  const onTabClick: MenuProps["onClick"] = ({ key }: { key: string }) => {
    // Update the state to reflect the selected tab
    setTabFilter(key);

    // Navigate to different routes based on the selected tab
    if (key === CONSTANTS.QUERY) {
      // Navigate to the Audience Create page
      navigate(ROUTES.DASHBOARD_AUDIENCE_CREATE);
    } else if (key === CONSTANTS.TRAIT) {
      // Navigate to the All Traits page
      navigate(ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS);
    } else if (key === CONSTANTS.SYNC) {
      // Navigate to the Audience Sync page
      navigate(ROUTES.DASHBOARD_AUDIENCE_SYNC);
    }
      else if (key == AudienceEnum.MODELS) {
      navigate(ROUTES.DASHBOARD_AUDIENCE_MODELS);
    }
  };
  useEffect(() => {
    if (loginUser.api_key) {
      fetchTraitMetaData();
      dispatch(getToken())
      // If the component is in edit mode (i.e., state contains an ID), fetch the trait details by ID
      if (state) {
        dispatch(
          fetchComputedTraitById({
            app_id: appId,
            api_key: loginUser.api_key,
            id: state.id,
          })
        );
      }
    }
    return () => {
      //reset the state in redux on component unmount
      dispatch(resetComputedTrait());
    };
  }, [loginUser, state]);
  useEffect(() => {
    // Check if the tableName property is available in the `trait` object
    if (trait.tableInfo?.tableName) {
      // Call the getColumns function to fetch column data based on the table name
      getColumns();
    }
  }, [trait.tableInfo]);
  return (
    <div className="container-fluid px-1 computed-trait-create-main-container">
      <Helmet>
        <title>Appice | Traits | Create</title>
      </Helmet>
      <div className="pb-4 pt-1">
        <CustomBreadCrumb
          items={[
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE}>{t("audienceLabel")}</Link>
              ),
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE_CREATE}>
                  {t("createLabel")}
                </Link>
              ),
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS}>
                  {t("allTraitsLabel")}
                </Link>
              ),
            },
            {
              title: (
                <Link to={ROUTES.DASHBOARD_AUDIENCE_TRAIT_CREATE}>
                  {state?.id ? t("updateTraitLabel") : t("createTraitLabel")}
                </Link>
              ),
            },
          ]}
        />
      </div>
      <div className="computed-trait-inner-container  p-sm-4 p-2 py-3 d-flex flex-column ">
        {traitStatus !== STATUS.LOADING ? (
          <Form
            form={form}
            onFinish={onFormSubmit}
            labelCol={{ flex: "150px" }}
            colon={false}
            labelAlign="left"
          >
            <div className="d-flex position-relative  justify-content-between  align-items-center ">
              {/* for icon and title of header */}
              <div className="d-flex justify-content-between w-100 gap-3  flex-wrap">
                <div className="d-flex align-items-center ">
                  <div className="d-flex   align-items-center">
                    <span className="d-flex   computed-trait-create-filter-icon-container justify-content-center ">
                      <img
                        src={filterIcon}
                        height={"100%"}
                        width={"100%"}
                        alt="filter"
                      ></img>
                    </span>

                    <h4 className="m-0 mx-2 trait-heading">
                      {state?.id
                        ? t("updateTraitLabel")
                        : t("createTraitLabel")}
                    </h4>
                  </div>
                </div>

                {/* for buttons of header */}
                <div className="d-flex   mb-auto">
                  <PrimaryButton
                    onClick={() =>
                      navigate(ROUTES.DASHBOARD_AUDIENCE_ALLTRAITS)
                    }
                  >
                    {t("discardLabel")}
                  </PrimaryButton>
                </div>
              </div>
            <AccessControl accessControl={{role:[roleCheckTypes.Contributor]}}> 
              <div className="mb-auto ms-2 computed-trait-save-btn-container">
                <PrimaryButton
                  disabled={!isFormValidForPreview}
                  htmlType="submit"
                  loading={loading.submit}
                  type="primary"
                >
                  {state?.id ? t("updateTraitLabel") : t("saveTraitLabel")}
                </PrimaryButton>
              </div>
            </AccessControl>
            </div>
            <div className="d-flex justify-content-between py-3  flex-wrap gap-2 ">
              <div className="d-flex gap-3 flex-wrap w-75">
                <div className="col-md-4 col-12 ">
                  <strong>{t("traitNameLabel")}</strong>
                  <Form.Item
                    rules={[
                      {
                        validator: validateNotEmpty,
                        message: `${t("plzInputTraitNameMsg")}`,
                      },
                    ]}
                    name={"name"}
                    initialValue={trait.name || undefined}
                    className="m-0 "
                  >
                    <TextInput
                      className="mt-1 input-size-xlg"
                      onChange={(e: any) =>
                        dispatch(setComputedTraitData({ name: e.target.value }))
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            {/* inner nav starts from here */}
            <InnerNav
              menu={audienceItems.audienceCreateNavItems()}
              onTabClick={onTabClick}
              selected={tabFilter}
            />

            <div className="py-2 trait-create-body container m-0 p-0">
              <div className="my-2">
                <strong>{t("createTraitDescriptionMsg")}</strong>
              </div>
              <div>
                <div className="col-12">
                  <Form.Item
                    initialValue={trait?.tableInfo?.tableName || undefined}
                    rules={[
                      {
                        validator: validateNotEmpty,
                        message: `${t("selectTableLabel")}`,
                      },
                    ]}
                    name={"tableName"}
                    className="mt-3 mb-0 "
                    label={t("selectTableLabel")}
                  >
                    <Selector
                      className="input-size-lg"
                      onChange={async(value) => {
                       await dispatch(setComputedTraitData({tableInfo:{tableName: value},projectionInfo:[], filterInfo: [] }));
                       form.resetFields(["projectionInfo"])
                        
                      }}
                      options={metaData.tableValues}
                      defaultValue={trait.tableInfo?.tableName}
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Form.Item
                    className="mt-3 mb-0 "
                    label={t("identityColumnLabel")}
                    initialValue={trait.projectionInfo?.[0]?.operand}
                    name={"projectionInfo"}
                    rules={[
                      {
                        validator: validateNotEmpty,
                        message: `${t("selectIdentityColumnMsg")}`,
                      },
                    ]}
                  >
                    <Selector
                    loading={loading.columns}
                    disabled={loading.columns}
                      className="input-size-lg"
                      onChange={(val) => {
                        const selectedValue=metaData.identityColumns.find(({value}:{value:string})=>val==value)
                        const type = OperatorManager.getPgSqlOperatorOptions(
                          selectedValue.type
                        ).type;
                        dispatch(setComputedTraitData({ projectionInfo: [{operand:selectedValue.value,jsonb:(selectedValue.jsonb || undefined),type,name:selectedValue?.label }] }));
                      }}
                      options={metaData.identityColumns}
                    />
                  </Form.Item>
                </div>
                <div className="create-trait-conditions col-12 py-2 my-3">
                  <div>
                    <Steps
                      direction="vertical"
                      current={100}
                      items={stepItems()}
                    />
                  </div>
                </div>
                <div>
                  <Form.Item
                    labelCol={{ flex: "180px" }}
                    className="m-0"
                    label={t("runComputationMsg")}
                    rules={[
                      {
                        validator: validateNotEmpty,
                        message: `${t("plzSelectDaysMsg")}`,
                      },
                    ]}
                  >
                    <Selector
                      className="input-size-sm"
                      defaultValue={trait.days || undefined}
                      placeholder="value"
                      onChange={(value) => {
                        dispatch(setComputedTraitData({ days: value }));
                      }}
                      options={generateNumbersInRange(1, 360).map((num) => ({
                        label: num,
                        value: num,
                      }))}
                    ></Selector>
                    <span className="mx-2 w-25">{t("daysLabel")}</span>
                  </Form.Item>
                </div>
                {isFormValidForPreview && (
                  <div className="col-12  col-md-10  my-3 px-0">
                    <div className="create-trait-calculation-summary-container  ">
                      <h6 className="m-0 ">{t("calculationSummaryLabel")}</h6>
                      <ComputedTrait trait={trait} />
                    </div>
                    <div className="mt-4 d-flex justify-content-between align-items-center">
                      <div>
                        <span className="text-disabled me-2">
                          {t("expectedTimeComputeMsg")} | 2 hrs
                        </span>
                        <SyncOutlined />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

export default CreateTrait;
