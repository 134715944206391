import { FC } from "react";
import { Descriptions } from "antd";
import type { DescriptionsProps } from "antd";

interface CustomisedProfileProps {
  data: Record<string, string | number | boolean | JSX.Element>;
  title?: string;
  labelStyle?: React.CSSProperties; // Optional label style prop
}

const CustomisedProfile: FC<CustomisedProfileProps> = ({ data, title, labelStyle }) => {
  // Convert the object into Descriptions-compatible items
  const items: DescriptionsProps["items"] = Object.entries(data).map(([key, value], index) => ({
    key: `${index}`, // Use the index as the unique key
    label: key, // Use the object key as the label
    children: value, // Use the value directly
  }));

  return (
    <Descriptions
      title={title}
      layout="vertical"
      size="small"
      bordered
      column={{ xxl: 6, xl: 6, lg: 6, md: 4, sm: 3, xs: 3 }}
      labelStyle={labelStyle} 
    >
      {items.map((item) => (
        <Descriptions.Item key={item.key} label={item.label}>
          {item.children}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default CustomisedProfile;
