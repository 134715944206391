import { Divider } from "antd";
import "./Logs.css";
import { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import ShowMessage from "components/ShowMessage/ShowMessage";
import Spinner from "components/Spinner/Spinner";
import SwitchBtn from "components/Buttons/SwitchBtn/SwitchBtn";
import { BOOLEAN_OPTIONS, PAGE_OPTIONS } from "utils/constants/selectorOptions";
import Search from "components/Inputs/Search/Search";
import Selector from "components/Selector/Selector";
import CustomTable from "components/CustomTable/CustomTable";
import useDebouncedValue from "hooks/useDebouncedState";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";

const Logs = () => {
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const [data, setData] = useState<{ logs: any[]; logCounts: { total_count: string }[] } | null>(null);
  const [checked, setChecked] = useState(BOOLEAN_OPTIONS.FALSE);
  const [search, setSearch] = useDebouncedValue<string>("", 500);
  const [pageData, setPageData] = useState(PAGE_OPTIONS.PAGINATION);
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;

  const handleToggleChange = (checked: boolean) => {
    setSearch("");
    setChecked(checked);
  };

  // When the page number of the table changes
  const onPageChange = (pagination: any,) => {
    setSearch("");
    setPageData({
      ...pageData,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const columns = [
    {
      title: t("idLabel"),
      dataIndex: "Did",
      key: "Did",
    },
    {
      title: t("platformLabel"),
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: t("dateLabel"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("campaignIdLabel"),
      dataIndex: "campaign_id",
      key: "campaign_id",
    },
    {
      title: t("reachLabel"),
      dataIndex: "reach",
      key: "reach",
    },
    {
      title: t("pushedToLabel"),
      dataIndex: "pushed_to",
      key: "pushed_to",
    },
    {
      title: t("receivedToLabel"),
      dataIndex: "received_to",
      key: "received_to",
    },
    {
      title: t("failedToLabel"),
      dataIndex: "failed_to",
      key: "failed_to",
    },
  ];

  async function getLogs() {
    const url: string = API_ENDPOINTS.GET_LOGS;
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        page: pageData.current,
        length: pageData.pageSize,
        value: search,
      }),
      api_key: api_key,
    };

    try {
      setLoading(BOOLEAN_OPTIONS.TRUE);
      const response = await centralApi("GET", url, null, payload);
      setData(response);
      setLoading(BOOLEAN_OPTIONS.FALSE);
    } catch (e: any) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(checked){
      getLogs();
    }  
  }, [search, checked, pageData]);



  return (
    <Fragment>
      <div className="logs-container">
        <div className="mt-4 mb-4">
          <h6 className="logs-container-heading">{t("logProviderLabel")}</h6>
          <Divider className="logs-custom-divider" />
        </div>

        <div className="col-sm-8 mt-4 mb-4 align-items-center logs-setting-form">
          {showMessage.show && (
            <ShowMessage type={showMessage.type} content={showMessage.message} />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <div className="logs-inner-container">
              <div className="mt-2 logs-para">
                <p>{t("logsDescriptionLabel")}</p>
              </div>
              <div>
                <SwitchBtn
                  checked={checked}
                  onChange={handleToggleChange}
                  size="default"
                />
              </div>
              {checked && (
                <div className="logs-table mt-3">
                  <div className="logs-search">
                    <Search
                      placeholder={`${t("searchLabel")}`}
                      onChange={(e: any) => setSearch(e.target.value)}
                      style={{
                        width: "100%",
                        maxWidth: "300px",
                        marginRight: "10px",
                        marginBottom: "10px",
                        height: "100%",
                      }}
                    />
                    <Selector
                      onChange={(value) => {
                        setSearch("");
                        setPageData({ ...pageData, pageSize: value });
                      }}
                      options={PAGE_OPTIONS.DATA}
                      defaultValue={pageData.pageSize}
                    />
                  </div>
                  <CustomTable
                    rowKey="Did"
                    columns={columns}
                    scroll={{ x: 1000 }}
                    dataSource={data?.logs}
                    loading={loading}
                    size="small"
                    onChange={onPageChange}
                    pagination={{
                      pageSize: pageData.pageSize,
                      current: pageData.current,
                      simple: pageData.simple,
                      total:  data?.logCounts ? parseInt(data.logCounts[0].total_count, 10) : 0,
                    }}  
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Logs;
