export async function getFileDimensions(file: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = file
    img.onload = () => {
      const dimensions = { width: img.width, height: img.height }
      resolve(dimensions)
    }

    img.onerror = (error) => {
      reject(error)
    }
  })
}
export const getVideoFileSize = async (file:any) => {
  return file.size;
}
export const validateImageDimensions = async (
  type:
    | "pushExpanded"
    | "pushHeader"
    | "webPushExpanded"
    | "webPushHeader"
    | "inAppNotificationFull"
    | "inAppNotificationMini"
    | "inAppNotificationSmall"
    | "inAppNotificationHeader"
    | "webPopUp"
    |"imageWhatsApp"
    |"inAppNotificationHeaderFooterExpanded",
  file: any
) => {
  const requiredDimension = {
    pushExpanded: {
      width: { from: 600, to: 800 },
      height: { from: 300, to: 400 },
    },
    pushHeader: {
      width: { from: 60, to: 96 },
      height: { from: 60, to: 96 },
    },
    webPushExpanded: {
      width: { from: 600, to: 800 },
      height: { from: 300, to: 400 },
    },
    webPushHeader: {
      width: { from: 60, to: 96 },
      height: { from: 60, to: 96 },
    },
    inAppNotificationFull: {
      width: { from: 400, to: 800 },
      height: { from: 768, to: 1024 },
    },
    inAppNotificationMini: {
      width: { from: 400, to: 400 },
      height: { from: 400, to: 400 },
    },
    inAppNotificationSmall: {
      width: { from: 60, to: 96 },
      height: { from: 60, to: 96 },
    },
    inAppNotificationHeader: {
      width: { from: 60, to: 96 },
      height: { from: 60, to: 96 },
    },
    webPopUp: {
      width: { from: 200, to: 300 },
      height: { from: 100, to: 200 },
    },
    imageWhatsApp: {
      width: { from: 600, to: 800 },
      height: { from: 300, to: 400 },
    },
    inAppNotificationHeaderFooterExpanded: {
      width: { from: 800, to: 800 },
      height: { from: 200, to: 200 },
    },
    
  }
   // Check if the file is a video, and if so, validate its size
   if (file.type.startsWith("video/")) {
    const sizeValidation = validateFileSize(file);
    if (!sizeValidation.valid) {
      return sizeValidation; // Return file size error for videos

    }
    // If the file is a video and passes the size check, return valid
    return { valid: true, message: "" };
  }
  const imageDimensions: any = await getFileDimensions(
    URL.createObjectURL(file)
  )

  if (
    imageDimensions.width >= requiredDimension[`${type}`].width.from &&
    imageDimensions.width <= requiredDimension[`${type}`].width.to &&
    imageDimensions.height >= requiredDimension[`${type}`].height.from &&
    imageDimensions.height <= requiredDimension[`${type}`].width.to
  ) {
    return { valid: true, message: "" }
  } else {
    return {
      valid: false,
      message: `Image dimensions need to be in between (w)${
        requiredDimension[`${type}`].width.from
      } x ${requiredDimension[`${type}`].height.from}(h) and (w)${
        requiredDimension[`${type}`].width.to
      } x ${requiredDimension[`${type}`].height.to}(h)`,
    }
  }
}
// Helper function to get video dimensions
export const validateFileSize = async (file: any) => {
  const MAX_FILE_SIZE = 700 * 1024; // 700 KB in bytes

  // Check the file size
  if (file.size <= MAX_FILE_SIZE) {
    return { valid: true, message: "" };
  } else {
    return {
      valid: false,
      message: "File size must be less than or equal to 700 KB.",
    };
  }
};
