import { Divider } from "antd";
import "./FileEncryption.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import Selector from "../../../../components/Selector/Selector";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../services/centralApi";
import Spinner from "../../../../components/Spinner/Spinner";
import { t } from "i18next";
import { BOOLEAN_OPTIONS, STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import useMessage from "hooks/useMessage";
import { API_ENDPOINTS } from "utils/constants";
import { getToken } from "redux/features/app/authTokenSlice";

interface FileEncryptionProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const FileEncryption: React.FC<FileEncryptionProps> = ({
  currentAppKey,updateCurrentAppKey

}) => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const [encryptionStatus, setEncryptionStatus]= useState<boolean>(BOOLEAN_OPTIONS.FALSE);
  const [loading, setLoading] = useState(false);
  const {showError,showSuccess}=useMessage();
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);

  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;

  /**
   * 
   * @param values will get encryptionstatus and encryptionkey from input values
   */
  const onFormSubmit = (values: any) => {
    const payload = {
      args: JSON.stringify({
        ...values,
        encryptionstatus : encryptionStatus,
        app_id: appId,
      }),

      authToken,
      app_id: appId,
      api_key: api_key,
    };
    saveEncryptionStatus(payload);
    dispatch(getToken())

  };

  /**
   * 
   * @param formData will be the payload which we send to post route for saving encryption
   */
  async function saveEncryptionStatus(formData: any) {
    const url: string = API_ENDPOINTS.SAVE_ENCRYPTION_STATUS;

    try {
      setLoading(BOOLEAN_OPTIONS.TRUE);
       await centralApi("POST", url, formData, null);
      showSuccess(t("sucessfullySavedLabel"))
      setLoading(BOOLEAN_OPTIONS.FALSE);
      updateCurrentAppKey();

    } catch (error) {
      setLoading(BOOLEAN_OPTIONS.FALSE);
      showError(t("somethingWrongLabel"))
    }

  }


  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      encryptionstatus:currentAppKey[0]?.encryptionstatus,
      encryptionkey:currentAppKey[0]?.encryptionkey
    };
    setEncryptionStatus(updatedFormValues.encryptionstatus);
    form.setFieldsValue({...updatedFormValues , encryptionstatus: JSON.stringify(updatedFormValues?.encryptionstatus)});

    }, [currentAppKey,form]);
  return (
    <div className="file-encryption-setting-container">
      <div className="mt-4 mb-4  ">
        <h6 className="file-encryption-setting-container-heading">
          {t("fileEncryptionSettingLabel")}
        </h6>
        <Divider className="file-encryption-setting-custom-divider" />
      </div>

      <div className="  mt-4 align-items-center file-encryption-setting-form ">
        <div className="mb-4">
          <p>{t("encryptionStatusLabel")}</p>
          <div className="col-sm-4 ">
            {loading ? (
              <Spinner />
            ) : (
            <Form
              form={form}
              layout="horizontal"
              onFinish={onFormSubmit}
            >
              <Form.Item
                name={"encryptionstatus"}
                rules={[{ required: true, message: "Please select  Mode" }]}
              >
                <Selector
                    onChange={(value: string) =>
                      setEncryptionStatus(JSON.parse(value))
                  }
                  size="middle"
                  style={{ width: "100%" }}
                  // defaultValue={"Select"}
                  options={[
                    {
                      value: "",
                      label: t("selectLabel")
                    },
                    {
                      value: STRING_BOOLEAN_OPTIONS.TRUE,
                      label: t("yesLabel"),
                    },
                    {
                      value: STRING_BOOLEAN_OPTIONS.FALSE,
                      label: t("noLabel"),
                    },
                  ]}
                />
              </Form.Item>
              {encryptionStatus && ( // Conditional rendering of the encryption key field
                <Form.Item
                  name="encryptionkey"
                  rules={[
                    {
                      required: true,
                      message: t("plzInputLabel") as string,
                    },
                  ]}
                >
                  <TextInput placeholder= {t("encryptionKeyLabel")}/>
                </Form.Item>
              )}
              <PrimaryButton
                size="middle"
                type="primary"
                htmlType="submit"
                style={{ border: "none", width: "70px" }}
              >
                {t("saveLabel")}
              </PrimaryButton>
            </Form>)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FileEncryption;
