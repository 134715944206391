import { Divider } from "antd";
import "./CampaignConfigurator.css";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../components/Spinner/Spinner";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import { t } from "i18next";
import centralApi from "services/centralApi";
import Selector from "components/Selector/Selector";
import ConfiguratorTable from "components/ConfiguratorTable/ConfiguratorTable";
import useMessage from "hooks/useMessage";
import { API_ENDPOINTS } from "utils/constants";
import TreeSelector from "components/Selector/TreeSelector/TreeSelector";
import { getToken } from "redux/features/app/authTokenSlice";

interface CampaignConfiguratorProps {
  currentAppKey: any;
}

const CampaignConfigurator: React.FC<CampaignConfiguratorProps> = () => {
  
  const [form] = Form.useForm();
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const dispatch: any = useDispatch();

  const { showError, showSuccess } = useMessage();
  const [loading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState<any>({});
  const [partnerNames, setPartnerNames] = useState<any[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
const initialFormData = {
    partner: "",
    providerType: "",
    url: "",
    callBackUrl: "",
    payload_key: "",
    responseMappings: [],
    requestMappings: [],
  };
  const [formData, setFormData] = useState<any>(initialFormData);
  const handleCampaignConfigurator = async (actionType: "save" | "remove") => {
    // Construct the base payload
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        ...(actionType === "save" && { ...formData, api_key: api_key, appKey: api_key, date: new Date() }), // Add formData and extra fields only when saving
      }),
      api_key: api_key,
      app_id: appId,
      authToken
    };
  
    try {
      setLoading(true);
  
      // Determine the API endpoint based on the actionType
      const endpoint =
        actionType === "save"
          ? API_ENDPOINTS.SAVE_CAMP_CONFIGURATOR
          : API_ENDPOINTS.REMOVE_CAMP_CONFIGURATOR;
  
      // Make the API call
      await centralApi("POST", endpoint, payload, null);
  
      // Show success message based on the action type
      if (actionType === "save") {
        showSuccess(t("sucessfullySavedLabel"));
      } else {
        showSuccess(t("campConfRemoveLabel"));
      }
  
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(t("somethingWrongLabel"));
    }
  
    // Call common functions after the API call
    getPartnerInfo();
    dispatch(getToken())
  };
  
 
  
  async function getPartnerInfo() {
    const payload = {
      args: JSON.stringify({
        app_id: appId,
      }),
      app_id: appId,

      api_key: api_key,
    };

    try {
      setLoading(true);
      const data = await centralApi("GET", API_ENDPOINTS.GET_PARTNER_INFO, null, payload);
      setPartnerData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      showError(t("somethingWrongLabel"));
    }
  }
  const handleChannelChange = (selectedChannel: string) => {
      // Update the state with the newly selected channel

    setSelectedChannel(selectedChannel);
 // Find the selected partner
    const selectedPartners = partnerData?.partnerInfo?.partnerData.filter(
      (partner:any) => partner.channel === selectedChannel
    );
      // Map the selected partners to an array of objects containing their names

      const partnerNames = selectedPartners.map((partner:any) => ({
        value: partner.partnerName,
        label: partner.partnerName
      }));
    setPartnerNames(partnerNames);

    // Update the form data with the selected partner's name
    
    setFormData((prevState: any) => ({
      ...prevState,
   channel:selectedChannel
    }));
  };
  useEffect(() => {
    // Check if a channel is selected
    if (selectedChannel) {
      // Check if the selected channel exists in the campaignConfigurator data
      if (partnerData?.campaignConfigurator?.[selectedChannel]) {
        // Destructure the required fields from the selected channel's campaignConfigurator
        const {
          campaignConfigurator: {
            [selectedChannel]: {
              verb,              // HTTP request method (e.g., GET, POST)
              responseMappings,  // Mappings for response data
              requestMappings,   // Mappings for request data
              callBackUrl,       // Callback URL for the campaign
              key_value,         // Request key-value pairs
              providerType,      // Type of provider (e.g., internal, external)
              url,               // The endpoint URL
              payload_key,       // The payload key
              partner,           // The partner name
            },
          },
        } = partnerData || {}; // Fallback to empty object if partnerData is undefined
  
        // Update the form data with the campaign configuration for the selected channel
        setFormData((prevState: any) => ({
          ...prevState,         // Spread previous form data to retain other values
          httpRequest: verb,    // Set HTTP request method
          responseMappings,     // Set response mappings
          requestMappings,      // Set request mappings
          callBackUrl,          // Set callback URL
          request: key_value,   // Set request key-value pairs
          providerType,         // Set provider type
          url,                  // Set endpoint URL
          payload_key,          // Set payload key
          partner,              // Set partner name
        }));
      } else {
        // Show an error if no data is available for the selected channel
        showError(t("noDataAvailableChannelLabel"));
  
        // Reset the form data to initial values if no data is found for the channel
        setFormData((prevFormData: any) => ({
          ...prevFormData,      // Retain previous form data
          ...initialFormData,   // Reset to the initial form data structure
        }));
      }
    }
  }, [selectedChannel, partnerData]);  // The effect runs whenever selectedChannel or partnerData changes
  const providerTypeOptions = [
    {
      title: t("queueLabel"),
      value: "Queue",
      children: [
        { title: t("pgqueueLabel"), value: "pgqueue" },
        { title: t("kafkaLabel"), value: "kafka" }
      ],
    },
    {
      title:t("apiLabel"),
      value: "API",
      children: [
        { title: t("sesLabel"), value: "ses" },
        { title:t("smtpLabel") , value: "smtp" },
        { title: t("cnsLabel") , value: "cns" }
      ],
    },
    {
      title: t("DbLabel"),
      value: "DB",
      children: [],
    },
  ];
  useEffect(() => {
    getPartnerInfo();
  }, []);
  return (
    <div className="campaign-configurator-setting-container">
      <div className="mt-4 mb-4  ">
        <div className="d-flex ">
          <h6 className="campaign-configurator-setting-container-heading">
            {t("campaignConfiguratorHeadingLabel")}
          </h6>
        </div>
        <Divider className="campaign-configurator-setting-custom-divider" />
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div className=" col-sm-12 mt-4 align-items-center campaign-configurator-setting-form ">
              <h6 className="campaign-configurator-setting-container-heading">
                {t("partnerLabel")}
              </h6>
              <Form form={form} layout="horizontal" labelCol={{flex:"100px"}} colon={false}>
                <div className="d-flex flex-column col-sm-7">
                  <Form.Item className="w-100" label={t("selectChannelLabel")} >
                    <Selector
                      value={selectedChannel || undefined}
                      size="middle"
                      onChange={handleChannelChange}
                      options={partnerData?.partnerInfo?.partnerData.map(
                        (item: any) => ({
                          value: item.channel,
                          label: item.channel,
                        })
                      )}
                    />
                  </Form.Item>
                  <Form.Item label={t("selectPartnerLabel")} className="w-100" >
                    <Selector
                      onChange={(value) => {
                        setFormData((prevState:any)=>({
                          ...prevState,
                          partner:value
                        }));
                      }}
                      value={formData?.partner}
                      size="middle"
                      options={partnerNames}
                    />{" "}
                  </Form.Item>
                </div>
                <div>
                  <h6 className="campaign-configurator-setting-container-heading">
                    {t("requestLabel")}
                  </h6>

                  <div className="d-flex flex-column col-sm-7">
                    <Form.Item label={t("providerTypeLabel")} className="w-100">
                    <TreeSelector
      treeData={providerTypeOptions}
      onChange={(value:string) => {
        setFormData((prevState: any) => ({
          ...prevState,
          providerType: value,
        }));
      }}
      size="middle"
      value={formData?.providerType || undefined}
     
      placeholder={t("providerTypeLabel")}
    />

                     
                    </Form.Item>
                    <Form.Item
                      label={t("urlLabel")}
                      className="w-100"
                      
                    >
                      <TextInput
                        value={formData?.url}
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            url: e.target.value,
                          }));
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <ConfiguratorTable
                    tableData={formData?.requestMappings}
                    type="request"
                    setTableData={(value: any) =>
                      setFormData((prevState: any) => ({
                        ...prevState,
                        requestMappings: value,
                      }))
                    }
                  />
                </div>
                <div className="mt-3">
                  <h6 className="campaign-configurator-setting-container-heading">
                    {t("responseLabel")}
                  </h6>
                  {/* <Divider className="campaign-configurator-setting-custom-divider" /> */}

                  <div className="d-flex flex-column  col-sm-7 ">
                    <Form.Item
                      label={t("httpMethodLabel")}
                      className="w-100"
                    
                    >
                      <Selector
                        onChange={(value) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            httpRequest: value,
                          }));
                        }}
                        value={formData?.httpRequest}
                        size="middle"
                        options={[
                          {
                            value: "GET",
                            label: t("getLabel"),
                          },
                          {
                            value: "POST",
                            label: t("postLabel"),
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      className="w-100"
                      // 
                      label={t("destinationLabel")}
                    >
                      <TextInput
                        disabled={true}
                        value={formData?.callBackUrl}
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            callBackUrl: e.target.value,
                          }));
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="w-100"
                      label={t("payloadKeyLabel")}
                    >
                      <TextInput
                        value={formData?.payload_key}
                        placeholder="payload key"
                        onChange={(e: any) => {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            payload_key: e.target.value,
                          }));
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <ConfiguratorTable
                    type="response"
                    tableData={formData.responseMappings}
                    setTableData={(value: any) =>
                      setFormData((prevState: any) => ({
                        ...prevState,
                        responseMappings: value,
                      }))
                    }
                  />
                </div>

                <div className="mt-4 d-flex gap-2">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                    onClick={()=>handleCampaignConfigurator("save") //when saving
                    }
                  >
                    {t("saveLabel")}
                  </PrimaryButton>
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    style={{ border: "none", width: "70px" }}
                    onClick={()=>handleCampaignConfigurator("remove") //for removing the values
                    }
                  >
                 {  t("clearAllLabel")}
                  </PrimaryButton>
                </div>
              </Form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CampaignConfigurator;
