import deleteIcon from "images/trashpink.svg";
import editIcon from "images/_edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import CustomTable from "components/CustomTable/CustomTable";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import "./Personalisation.css";
import React, { useEffect, useRef, useState } from "react";
import useMessage from "hooks/useMessage";
import centralApi from "services/centralApi";
import TextInput from "components/Inputs/TextInput/TextInput";
import { Space } from "antd";
import ConfirmPopUp from "components/PopUp/ConfirmPopUp/ConfirmPopUp";
import { API_ENDPOINTS } from "utils/constants";
import Spinner from "components/Spinner/Spinner";
import { removeUnderscoreAddSpace } from "helperFunctions/common";
import { getToken } from "redux/features/app/authTokenSlice";
import Selector from "components/Selector/Selector";
import { STRING_BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { Form } from "antd";

const Personalisation: React.FC = () => {
  const { showError, showSuccess } = useMessage();
  const dispatch: any = useDispatch();
  const { appId } = useSelector((state: any) => state.activeApp);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [form] = Form.useForm();
  const formRefs = useRef<{ [key: string]: any }>({});

  const [loading, setLoading] = useState(false);
//saving table data
  const [tableData, setTableData] = useState([]);
  const tableName="personalizationmapping"

  async function getMappingList(payload: {
    app_id: string;
    api_key: string;
    tableName:string;
  }) {
    try {
      setLoading(true);
          // Make an API call to retrieve customer 360 mapping data

      const res = await centralApi(
        "GET",
        API_ENDPOINTS.GET_MAPPING_LIST,
        null,
        payload
      );
          // Update the table data with the response from the API

      setTableData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (loginUser?.api_key) {
          // Make an function call to retrieve customer 360 mapping data

      getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName
      });

    }
  }, [loginUser?.api_key]);

  async function updateMappingRowById(formData: any) {
    const payload = {
      args: JSON.stringify(formData),  // Send only fields to update (excluding ID)
      tableName,    // The table you're updating
      authToken,                             // Authorization token if needed
      app_id: appId,                         // App ID for the specific app
      api_key: loginUser?.api_key,           // API key for the user
      key: "id",                                // Include the ID directly in the root for identification
    };
  
    try {
          // Set loading state to true to indicate that the update process has started

      setLoading(true);
//updating the table
      await centralApi(
        "POST",  
        API_ENDPOINTS.UPDATE_MAPPING_ROW_BY_ID,
        payload,
        null
      );
      showSuccess(t("sucessfullyUpdatedLabel"));
      dispatch(getToken())

      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(getToken())

      showError(t("somethingWrongLabel"));
    }
  }

const handleRowUpdate = async (formData: any, rowIndex: number) => {
  const updatedRow = formData[rowIndex]; // Get the specific row using its index
  //covert " boolean values to boolean"
  const parsedRow = Object.fromEntries(
    Object.entries(updatedRow).map(([key, value]) =>
      [key, value === STRING_BOOLEAN_OPTIONS.TRUE || value === STRING_BOOLEAN_OPTIONS.FALSE? JSON.parse(value) : value]
    )
  );

  const updatedData={id:rowIndex,...parsedRow}
  updateMappingRowById(updatedData)

 
};

 
  const getColumnTitle = (key: string) => {
    ///check if key is destination_col_key remove replace with the new name
    const specialTitles: Record<string, string> = {
      destination_col_key: 'Destination column root key',
    };

    return specialTitles[key] || removeUnderscoreAddSpace(key);
  };
  
  const generateColumns = (data: any[]) => {
    if (!data || data.length === 0) return [];
  
    const excludeKeys = ['id', 'description'];
    const keys = Object.keys(data[0]).filter((key) => !excludeKeys.includes(key));
  
    const columnStyles: Record<string, { width: number }> = {
      source_table: { width: 130 },
      source_col: { width: 320 },
      file_table: { width: 130 },
      source_fields: { width: 320 },
      destination_col: { width: 140 },
      display_name: { width: 320 },
      destination_col_child_key: { width: 100 },
    };
  
    return keys.map((key) => ({
      title: getColumnTitle(key),
      dataIndex: key,
      key,
      width: columnStyles[key]?.width || 130,
      render: (_: any, record: any, index: number) => (
      
       
        <Form
        form={form}

        ref={(el) => (formRefs.current[record.id] = el)}  // Assign unique form reference per row
     
        >
         
        <Form.Item
         name={[record.id, key]}  // Unique name for each row, using record id and key
         initialValue={
          typeof record[key] === STRING_BOOLEAN_OPTIONS.BOOLEAN 
            ? String(record[key]) 
            : record[key] // For non-boolean values, keep the original value
        }
          style={{ marginBottom: 0 }} // Remove margin to avoid spacing issues
        >
          {typeof record[key] === STRING_BOOLEAN_OPTIONS.BOOLEAN ? (
            
            <Selector
              value={String(record[key]) ?? undefined} // Convert boolean to string for compatibility
              style={{ fontSize:12 }}

              options={[
                { label: t("TrueLabel"), value: STRING_BOOLEAN_OPTIONS.TRUE },
                { label: t("FalseLabel"), value: STRING_BOOLEAN_OPTIONS.FALSE },
              ]}
              placeholder={t("Select an option")}
            />
          ) : (
            <TextInput
            style={{ fontSize:12 }}

              defaultValue={record[key]} // Use the Input component for non-boolean fields
            />
          )}
        </Form.Item>
         
          </Form>
      
       
      ),
    }));
  };

  // Adding the action column  for edit and update
const actionColumn = {
    // Title of the action column, translated using the provided key

  title: t("actionLabel"),
  key: "action",
  width: 150,
    // Render function for the column, providing action buttons for each record

  render: (_: any, record: any) => (
    <Space>
      <ConfirmPopUp
        title={t("deleteLabel")}
        onConfirm={() => deleteRowById(record.id)} // Function to call on confirm
        description={t("areYouSureMsg")}  // Message displayed in the confirmation popup
        placement="left"
        id={record._id} // Unique ID for the record
        icon={deleteIcon} // Icon to display in the popup
  >
        <PrimaryButton className="action-btn mx-1">
          <img src={deleteIcon} alt={"close"} style={{ width: 18 }}></img> {/* Icon for delete button */}
        </PrimaryButton>
      </ConfirmPopUp>
      <ConfirmPopUp
        title={t("updateLabel")}
        
        onConfirm={async () => {
          try {
            // Validate and retrieve updated values for the row
            const updatedValues = await formRefs.current[record.id].validateFields();  // Get the updated values

            // Update the row using the handler
            handleRowUpdate(updatedValues, record.id);
          } catch (error) {
            showError(t("somethingWrongLabel"));
          }
        }}
       description={t("areYouSureMsg")} // Message displayed in the confirmation popup
        placement="left"
        id={record._id}
        icon={editIcon}

      >
        <PrimaryButton className="action-btn mx-1">
          {t("updateLabel")}
        </PrimaryButton>
      </ConfirmPopUp>
    </Space>
  ),
};
  // Generate dynamic columns based on the table data
const dynamicColumns = Array.isArray(tableData) && tableData.length > 0 // Check if tableData is an array and has items
? generateColumns(tableData) // Call generateColumns function to create columns if data exists
: []; // If no data, set dynamicColumns to an empty array

// Combine dynamic columns with the action column to create the complete set of columns for rendering
const columns = [...dynamicColumns, actionColumn]; // Spread operator to include all dynamic columns and append the actionColumn

  async function deleteRowById(id: number) {
      // Create payload containing the ID and authentication details

    const payload = {
      args: JSON.stringify({
        value: id,
        key:"id",
        tableName,
      }),
      app_id: appId,
      api_key: loginUser?.api_key,
    };

    try {
      setLoading(true); // Set loading state to true while the API call is in progress

      // Make an API call to delete the row by ID
        await centralApi(
        "GET",
        API_ENDPOINTS.DELETE_MAPPING_ROW_BY_ID,
        null,
        payload
      );
      showSuccess(t("sucessfullyDeletedLabel")); // Show success message after deletion
    setLoading(false); // Set loading state to false after the API call completes

    // Refresh the customer 360 mapping data to reflect the changes
    getMappingList({
        app_id: appId,
        api_key: loginUser.api_key,
        tableName

      });
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap my-3">
        <h4 className="fw-semi-bold">{t("personalisaionLabel")}</h4>
      </div>
      <div className=" personalisation-container">
        <div
          className={`${
            tableData.length == 0 ? "personalisation-container-table" : ""
          } personalisation-body p-3 mt-3`}
        >
              {dynamicColumns.length === 0 ? <Spinner/> :

         
          <CustomTable
            dataSource={tableData}
            columns={columns}
            loading={loading}
            rowKey="id"
            size="small"
            scroll={{ x: 2500, y: 400 }}  
          /> 
          }
        </div>
      </div>
    </div>
  );
};
export default Personalisation;
