import { PlusOutlined } from "@ant-design/icons";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import Selector from "components/Selector/Selector";
import { t } from "i18next";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTemplateMetaData } from "redux/features/templates_/variantsSlice";
import VariantList from "../VariantList/VariantList";
import { getObjectId } from "services/getObjectId";
import { FeatureSettingsFlagTypes } from "utils/constants/constants";
import AccessControl from "components/HOC/AccessControl/AccessControl";
interface CreateVariantProps {
  variants: Record<any, any>[];
  selectedVariant: any;
  template: Record<any, any>;
  setSelectedVariant: React.Dispatch<React.SetStateAction<any>>;
  formData: Record<any, any>;
  setVariants: React.Dispatch<React.SetStateAction<any>>;
}

const CreateVariant: FC<CreateVariantProps> = ({
  variants,
  selectedVariant,
  template,
  setSelectedVariant,
  formData,
  setVariants,
}: any) => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const languageVariants = useSelector(
    (state: any) => state.templateMetaData.data
  );
  const dispatch: any = useDispatch();
  //fetch language variants
  useEffect(() => {
    dispatch(
      fetchTemplateMetaData({
        app_id: appId,
        api_key: loginUser.api_key,
        args: {},
      })
    );
  }, []);
  //handle delete varaint
  const handleDeleteVariant = (id: string) => {
    const afterVariantDelete = variants.filter(
      (variant: any) => variant.variance_id !== id
    );
    setVariants(afterVariantDelete);
  };
  //handle variant
  const handleVariantClick = (id: string) => {
    variants.forEach((variant: any) => {
      if (variant.variance_id == id) {
        setSelectedVariant(variant);
      }
    });
  };
  const handleCreateVariant = async (
    type: "variant" | "language" = "variant",
    language: any = {}
  ) => {
    const id =
      type == "variant"
        ? await getObjectId(appId, loginUser.api_key)
        : language?._id;
    const variance_top_name =
      type == "variant"
        ? `Variant ${
            variants.filter((variant: any) =>
              variant?.variance_top_name.includes("Variant")
            ).length + 1
          }`
        : language?.name;
    const payloadData: any = {
      variance_top_name,
      variance_id: id,
      variant_type: "Multivariate",
      ...formData,
      lang_id:language?.lang_id,
      variance_name: template.template_name,
    };
    setVariants((prevState: any) => [...prevState, payloadData]);
  };
  useEffect(() => {
    if (selectedVariant) {
      const modifiedVariants: any = [];
      variants.forEach((variant: any) => {
        if (variant.variance_id == selectedVariant.variance_id) {
          let targetVariant = variant;
          targetVariant = { ...targetVariant, ...formData };
          modifiedVariants.push(targetVariant);
        } else {
          modifiedVariants.push(variant);
        }
      });
      setVariants(modifiedVariants);
    }
  }, [formData, selectedVariant]);
  return (
    <div className="d-flex flex-column my-2">
      <VariantList
        variants={variants}
        selectedVariant={selectedVariant}
        handleDeleteVariant={handleDeleteVariant}
        handleVariantClick={handleVariantClick}
      />
      <div className="d-flex gap-1  align-items-center mt-2">
        <AccessControl
          accessControl={{
            features: [FeatureSettingsFlagTypes.AB_TESTING_VARIANT],
          }}
        >
          <Selector
            style={{ width: 110 }}
            onChange={handleVariantClick}
            disabled={!template?.template_name || variants.length >= 6}
            value={
              selectedVariant?.variance_top_name?.includes("Variant") &&
              selectedVariant?.variance_id
            }
            placeholder={t("variantLabel")}
            dropdownRender={(menu) => (
              <>
                <PrimaryButton
                  type="link"
                  className="w-100"
                  onClick={() => handleCreateVariant()}
                >
                  <PlusOutlined />
                  <span className="ms-2 me-3">Create</span>
                </PrimaryButton>
                {menu}
              </>
            )}
            options={variants
              ?.filter((variant: any) =>
                variant?.variance_top_name?.includes("Variant")
              )
              .map((variant: any) => ({
                label: variant.variance_top_name,
                value: variant?.variance_id,
              }))}
          />
        </AccessControl>
        <AccessControl
          accessControl={{
            features: [FeatureSettingsFlagTypes.LANGUAGE_VARIANT],
          }}
        >
          <Selector
            style={{ width: 110 }}
            disabled={!template?.template_name || variants.length >= 6}
            onChange={(jsonData) => {
              const language = JSON.parse(jsonData);
              if (
                selectedVariant &&
                selectedVariant.variance_top_name.startsWith("Variant")
              ) {
                setVariants((prevVariants: any[]) => {
                  return prevVariants.map((variant) =>
                    variant.variance_top_name ===
                    selectedVariant.variance_top_name
                      ? { ...variant, variance_language: language.name }
                      : variant
                  );
                });
              } else {
                if (
                  !variants.some((variant: any) => {
                    return variant.variance_id == language._id;
                  })
                ) {
                  handleCreateVariant("language", language);
                }
              }
            }}
            options={languageVariants?.varaints?.map((langVariant: any) => ({
              label: langVariant.name,
              value: JSON.stringify(langVariant),
            }))}
            placeholder={t("languageLabel")}
          />
        </AccessControl>
      </div>
    </div>
  );
};

export default CreateVariant;
