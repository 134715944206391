import { FC, useMemo } from "react";
import "./ComputedTrait.css";
import { t } from "i18next";
import { OperatorManager } from "utils/operators";
import { FilterInfo, QueryPayload } from "utils/models";
interface ComputedTraitProps {
  trait: QueryPayload;
}
/**
 * ComputedTrait Component
 *
 * This component is used to display the summary of a computed trait's calculation.
 * It generates a human-readable string that summarizes the conditions and parameters 
 * used for the trait's computation, including the operand, operator, value, and the 
 * time frame for the calculation. The component uses memoization to optimize the 
 * generation of this summary string based on the trait's data.
 *
 * @param {Object} props - Component props.
 * @param {any} props.trait - The trait object containing information for calculation summary.
 *
 * @returns {JSX.Element} - The rendered component displaying the calculation summary.
 */
const ComputedTrait: FC<ComputedTraitProps> = ({ trait }) => {
  //generate calculation summary string
  const calculationSummary = useMemo(() => {
    let substring = "";
    
    trait?.filterInfo?.forEach((element: FilterInfo, index: number) => {
      const operatorLabel = OperatorManager.getOperatorLabel(element?.operator || "");
      
      // Add the main condition to the substring
      substring = substring.concat(
        `${!index ? t("countOfCustomerLabel"): element.e_operator} ${element.group?.length?"(":""} ${
          element?.func ? element?.func + ` ${t("ofLabel")}` : ""
        } ${element?.operand || ""} ${operatorLabel || ""} ${element?.value || ""} ${element?.interval || ""} ${element?.comparison || ""} ${element?.period || ""}  `
      );
  
      // Handle group conditions if they exist
      element.group?.forEach((groupCondition: FilterInfo) => {
        const groupOperatorLabel = OperatorManager.getOperatorLabel(groupCondition?.operator || "");
        
        substring = substring.concat(
          `${groupCondition.e_operator} ${
            groupCondition?.func ? groupCondition?.func + ` ${t("ofLabel")}` : ""
          } ${groupCondition?.operand || ""} ${groupOperatorLabel || ""} ${groupCondition?.value || ""} ${groupCondition?.interval || ""} ${groupCondition?.comparison || ""} ${groupCondition?.period || ""} `
        );
      });
  
      // Close group conditions if they exist
      if (element.group && element.group.length > 0) {
        substring = substring.concat(") ");
      }
    });
  
    // Add days condition if present
    substring = substring.concat(trait?.days ? `, ${t("overTheLastLabel")} ${trait?.days} ${t("daysLabel")}` : "");
  
    return substring;
  }, [trait]);
  
  return (
    <div className="computed-trait-main-container  mt-4 d-flex align-items-center py-2 px-3">
      {calculationSummary}
    </div>
  );
};

export default ComputedTrait;
