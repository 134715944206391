import { FC, useState, useEffect, useRef } from "react";
import { Col, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import Selector from "../../../../../../components/Selector/Selector";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import CampaignDevice from "../../../../../../components/CampaignDevice/CampaignDevice";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import "./SMS.css";
import CustomTextArea from "../../../../../../components/Inputs/CustomTextArea/CustomTextArea";
import EmojiPicker from "../../../../../../components/EmojiPicker/EmojiPicker";
import { SmileOutlined, MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import TemplateLayout from "../../../layout/TemplateLayout/TemplateLayout";
import { setCampaignData } from "../../../../../../redux/features/campaign/campaignDataSlice";
import { setCurrentStep } from "../../../../../../redux/features/campaign/campaignCreateCurrentStepSlice";
import { useForm } from "antd/es/form/Form";
import { t } from "i18next";
import SMSContainer from "../../NotificationContainer/SMSContainer/SMSContainer";
import Personalization from "../../Personalization/Personalization";
import { formatDate, getCurrentDate } from "helperFunctions/date";
import useMessage from "hooks/useMessage";
import { fetchTemplateMetaData } from "redux/features/templates_/variantsSlice";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import { CampaignChannelTypeEnum, RTLLanguageEnum } from "utils/enums";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import { API_ENDPOINTS } from "utils/constants";
import { validateName } from "services/validateName";
import { getToken } from "redux/features/app/authTokenSlice";
import CreateVariant from "../../CreateVariant/CreateVariant";

interface SMSProps {}
const SMS: FC<SMSProps> = () => {
  const dispatch: any = useDispatch();
  const [form] = useForm();
  const [template, setTemplate] = useState({
    template_name: "",
    template_type: CampaignChannelTypeEnum.SMS,
    isTemplateDeleted: "false",
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const features = useSelector((state: any) => state.featuresSetting.data);
  const charLimit = features[0]?.pushNotificationCharLimit;
  const [variants, setVariants] = useState<any[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const authToken = useSelector((state: any) => state.authToken.token);
  const [oldTemplateName, setOldTemplateName] = useState(""); //old template name store to avoid validation if remplate name does not change

  const templates = useSelector((state: any) => state.templatesList.data); //fetching templlate list
  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  

  const campaignData = useSelector((state: any) => state.campaignData.data);
  const [formData, setFormData] = useState<any>({
    message: "",
    dltTemplateId: "",
    sender: "",
    language: "",
  });
  const { showError } = useMessage();
  const [personalization, setPersonalization] = useState({
    open: false,
    field: "",
  });
  const emojiPickerRef = useRef(null);
  const languageVariants = useSelector((state: any) => state.templateMetaData.data);
//chec the language selected
  const isRtl= formData?.language==RTLLanguageEnum.ARABIC || formData?.language==RTLLanguageEnum.URDU

  useEffect(() => {
    const { template, template_name, template_type } =
      selectedTemplate || campaignData.data || formData;

    if (template || selectedVariant) {
      const { message, language, sender, variableInfo, dltTemplateId } =
      Array.isArray(template) || selectedVariant
      ? selectedVariant || template[0]
      : template || formData;
      setFormData((prevState: any) => ({
        ...prevState,
        message,
        language,
        sender,
        variableInfo,
        dltTemplateId,
      }));
      if (template_name) {
        setTemplate((prevState) => ({
          ...prevState,
          template_name,
          template_type,
        }));
      }
      setOldTemplateName(template_name); // save template name for validation purpose 

    }
  }, [selectedTemplate, campaignData,selectedVariant]);
 
  useEffect(() => {
    if (loginUser.api_key) {
      //fetching all templates list
      dispatch(
        fetchTemplatesList({
          app_id: appId,
          api_key: loginUser.api_key,
          template_type: CampaignChannelTypeEnum.SMS ,
        })
      );

//calling token API
dispatch(getToken())
}
    if (campaignData.data) {
      //fetching selected template and saving in redux also
      dispatch(setSelectedTemplate(campaignData.data));
    }
  }, [loginUser.api_key]);
  
  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        //save campaign data in redux for edit
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: selectedTemplate._id,
          t: selectedTemplate.template_type,
        })
      );
      if (Array.isArray(selectedTemplate?.template)) {
        setVariants(selectedTemplate.template);
        setSelectedVariant(selectedTemplate.template[0]);
      } else {
        setVariants([]);
        setSelectedVariant(null);
      }
    }
  }, [selectedTemplate]);
  
  const handleSaveOrUpdate = async () => {
    try {
      // Perform name validation
      let isValidTemplateName;
      // Call validateName only when template name is changed if same template selected and name not changed then dont call api
      if (template.template_name !== oldTemplateName) {
        try {
          //name validation API
          isValidTemplateName = await validateName(
            template.template_name,
            CampaignChannelTypeEnum.SMS,
            loginUser.api_key,
            appId
          );
          // Proceed with form validation and submission
        } catch (error) {
      showError(t("somethingWrongLabel"));
        }
      }

      // Validate the form fields for checking template name exists or not
      if (isValidTemplateName) {
        form.setFields([
          {
            name: "templateName",
            errors: [t("templateNameExistsErrorMessage")], //show error
          },
        ]);
      } else {
        //check other fields validation
        await form.validateFields();
        
        const payload = {
          api_key: loginUser.api_key,
          app_id: appId,
          authToken,
          template_id: selectedTemplate ? selectedTemplate._id : undefined, //template id if template is selected otherwise undefined
          args: JSON.stringify({
            ...template,
            template: variants.length > 0 ? variants : formData,
                createdOn: selectedTemplate
                  ? undefined
                  : formatDate(getCurrentDate(), "MM/DD/YYYY"), // if new template then add created on
                modifiedOn: selectedTemplate //if existing template set modified on
                  ? formatDate(getCurrentDate(), "MM/DD/YYYY")
                  : undefined,
       
          }),
        };
       
        // Determine the endpoint and method based on the presence of a selected template
        const endpoint = selectedTemplate
          ? API_ENDPOINTS.UPDATE_CAMPAIGN_TEMPLATE
          : API_ENDPOINTS.CREATE_CAMPAIGN_TEMPLATE;
        //call the Api with the selected endpoint
        const response = await centralApi("POST", endpoint, payload);
      // response from API setting its value in redux 
        dispatch(
          setCampaignData({
            ...campaignData,
           data: { ...response },

            tid: response._id,
            t: response.template_type,
          })
        );
        dispatch(getToken())

        //moving to next step
        dispatch(setCurrentStep(currentStep + 1));
     
    
      }
    } catch (errors) {

    dispatch(getToken())
      showError(t("somethingWrongLabel"));
    }
  };

  const handleToggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
//handle emoji
  const handleEmojiClick = (emojiObject: any) => {
    setFormData((prevState: any) => ({
      ...prevState,

      message: `${prevState?.message || ""}${emojiObject?.emoji}`,
    }));
    setShowEmojiPicker(false);
  };
  useEffect(() => {
    // Set initial values when template changes
    if (selectedTemplate) {
      form.setFieldsValue({
        templateName: template.template_name,
      });
    }
  }, [template]);
  return (
    <div className="d-flex gap-md-5 mx-3">
      <TemplateLayout
        leftItems={
          <CampaignDevice
            campType="sms"
            bazel={{ top: 109, left: 28, bottom: 244, right: 58 }}
          >
            <SMSContainer
              message={formData?.message}
              variableInfo={formData?.variableInfo}
            />
          </CampaignDevice>
        }
        // </div>
        rightItems={
          <div className="w-100 sms-device-container">
            <Personalization
              personalizationState={personalization}
              setFormData={setFormData}
              setPersonalizationState={setPersonalization}
            />
           
            <Form
              colon={false}
              labelAlign="left"
              labelCol={{ flex: "110px" }}
              layout="horizontal"
              form={form}
            >
              <Row className="pt-3  justify-content-between">
                <h5> SMS campaign</h5>
              </Row>
              <Row className="pt-3">
                <Col span={24}>
                  <FormItem className="">
                    <Selector
                      onChange={(tempString) =>
                        dispatch(
                          fetchTemplateById({
                            app_id: appId,
                            api_key: loginUser.api_key,
                            template_id: JSON.parse(tempString)._id,
                          })
                        )
                      }
                      //mapping list of templates of type whats app
                      options={templates.map(
                        ({
                          template_name,
                          _id,
                        }: {
                          template_name: string;
                          _id: string;
                        }) => ({
                          label: template_name,
                          value: JSON.stringify({ _id, template_name }),
                        })
                      )}
                      placeholder={t("selectingExistingTemplateLabel")}
                    
                    />
                  </FormItem>
                  <CreateVariant template={template} formData={formData} variants={variants} setSelectedVariant={setSelectedVariant} selectedVariant={selectedVariant} setVariants={setVariants}  />
                </Col>
              </Row>
              <Row className="py-2 position-relative d-flex justify-content-center">
                <Col span={24} className="d-flex gap-2">
                  <FormItem
                    className=""
                    label={t("senderLabel")}
                    style={{ width: "100%" }}
                  >
                    <Selector
                      onChange={(selectedSender: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          sender: selectedSender,
                        }));
                      }}
                      value={formData?.sender}
                      options={[
                        {
                          label: "Twilio",
                          value: "twilio",
                        },
                        {
                          label: "CellCast",
                          value: "cellcast",
                        },
                        {
                          label: "Exotel",
                          value: "exotel",
                        },
                        {
                          label: "GupShup",
                          value: "Sender",
                        },
                        // Add more options as needed
                      ]}
                    />
                  </FormItem>
                  <FormItem
                    labelCol={{ flex: "71px" }} // Adjust the width as needed
                    className=""
                    label={t("languageLabel")}
                    style={{ width: "100%", marginLeft: "12px" }}
                  >
                    <Selector
                      onChange={(selectedLanguage: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          language: selectedLanguage,
                        }));
                      }}
                      value={formData?.language}
                      options={languageVariants?.varaints?.map((langVariant: any) => ({
                        label: langVariant.name,
                        value: langVariant.name,
                      }))}
                   
                      // placeholder="Select language"
                    />
                  </FormItem>
                </Col>
                <Col className="d-flex " span={24}>
                  <FormItem
                    className="w-100 m-0  "
                    label={t("templateNameLabel")}
                    name={"templateName"}

                  >
                    <TextInput
                      onChange={(e: any) => {
                        setTemplate((prevState) => ({
                          ...prevState,
                          template_name: e.target.value,
                        }));
                      }}
                      placeholder={t("templateNameLabel")}
                      value={template.template_name}
                    ></TextInput>
                  </FormItem>
                </Col>

                <Col className="d-flex mt-3" span={24}>
                  <FormItem
                    className="w-100 m-0 "
                    label={t("messageTextLabel")}
                  >
                    <div className=" sms-device-emoji-icon text-end">
                      <PrimaryButton onClick={handleToggleEmojiPicker}>
                        <SmileOutlined />
                      </PrimaryButton>

                      <MoreOutlined
                        onClick={() => {
                          setPersonalization({
                            open: true,
                            field: "message",
                          });
                        }}
                      />
                    </div>
                    <div className="position-relative ">
                      <CustomTextArea
                      dir={isRtl?"rtl":""}
                      maxLength={charLimit?.message}
                       rows={4}
                        value={formData?.message}
                        onChange={(e: any) => {
                          const message = e.target.value;
                          setFormData((prevState: any) => ({
                            ...prevState,

                            message: message,
                          }));
                        }}
                        showCount
                        style={{
                          resize: "none",
                        }}

                      />
                    </div>
                    {showEmojiPicker && (
                      <div
                        className="emoji-picker-dropdown"
                        ref={emojiPickerRef}
                      >
                        <EmojiPicker
                          onEmojiClick={handleEmojiClick}
                          defaultSkinTone={undefined}
                        />
                      </div>
                    )}
                  </FormItem>
                </Col>
                <Col
                  className="d-flex  "
                  style={{ marginTop: "30px" }}
                  span={24}
                >
                  <FormItem
                    className="w-100 m-0  "
                    label={t("dltTemplateLabel")}
                  >
                    <TextInput
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          dltTemplateId: e.target.value,
                        }));
                      }}
                      value={formData?.dltTemplateId}
                    ></TextInput>
                    <span className="sms-device-container-dlt-text">
                      {t("dltMessageInfo")}
                    </span>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        }
        footerItem={
          <div>
            <Row className="gap-2  d-flex justify-content-end">
              <PrimaryButton
                disabled={!template.template_name}
                type="primary"
                htmlType="submit"
                onClick={handleSaveOrUpdate}
              >
              {selectedTemplate ? t("updateLabel") : t("saveLabel")}
              </PrimaryButton>
              <PrimaryButton
                style={{
                  color: "white",
                  background: "var( --color-red-main)",
                }}
               
                onClick={() => {
                  //reset campaign state in redux
                  dispatch(setCampaignData({}))
                  dispatch(setSelectedTemplate(null))
                  dispatch(setCurrentStep(0))
                }}
              >
                {t("closeLabel")}
              </PrimaryButton>
            </Row>
          </div>
        }
      />
    </div>
  );
};

export default SMS;
