import { Divider } from "antd";
import "./GlobalFrequencyCapping.css";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Form } from "antd";
import Selector from "../../../../../../components/Selector/Selector";
import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import ShowMessage from "../../../../../../components/ShowMessage/ShowMessage";
import Spinner from "../../../../../../components/Spinner/Spinner";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import SwitchBtn from "../../../../../../components/Buttons/SwitchBtn/SwitchBtn";
import React from 'react';
import { getToken } from "redux/features/app/authTokenSlice";
interface GlobalFrequencyCappingProps {
  currentAppKey: any;
  Mode: string;
  fetchCurrentAppKey: any;
}

const GlobalFrequencyCapping: React.FC<GlobalFrequencyCappingProps> = ({
  currentAppKey,
  Mode,
  fetchCurrentAppKey,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const mode =
    Mode === "Development" ? "dev" : Mode === "Production" ? "prod" : "";
    let dndDataValues: any = {};
    if (currentAppKey[0]?.frequency_capping) {
      const fetchedModeData =
        Mode === "Development"
          ? "capping_dev"
          : Mode === "Production"
          ? "capping_prod"
          : "";
      dndDataValues = currentAppKey[0]?.frequency_capping[fetchedModeData];
    }
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);

  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const initialIsTotalPushEnabled = dndDataValues?.is_totalPush;

  const [isTotalPush, setIsTotalPush] = useState(initialIsTotalPushEnabled);
  const initialRefreshFrequency = dndDataValues?.is_frequency;

  const [isRefreshFrequency, setIsRefreshFrequency] = useState(
    initialRefreshFrequency
  );

  const onPushSwitchChange = (checked: boolean) => {
    setIsTotalPush(checked);
  };
  const onRefreshSwitchChange = (checked: boolean) => {
    setIsRefreshFrequency(checked);
  };
  const onFormSubmit =async (values: any) => {
    const formData: { [key: string]: any } = {};
    for (const key in values) {
      if (values[key] !== undefined && values[key] !== "") {
        formData[key] = values[key];
      }
    }
   
    const payload = {
      args: JSON.stringify({
        app_id: appId,
        cappingObj: {
          ...formData,
        },
        mode,
      }),
      authToken,
      app_id: appId,
      api_key: api_key,
    };
    await updateFrequencyCapping(payload);
    dispatch(getToken())

  };

  async function updateFrequencyCapping(formData: any) {
    const url: string = `/i/apps/updateFrequencyCapping`;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      fetchCurrentAppKey();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowMessage({
        type: "error",
        message: "Something went wrong",
        show: true,
      });
    }
    hideMessage();
  }
  const pushNotificationValidation = () => {
    if (isTotalPush) {
      return [{ required: true, message: "This field is required" }];
    }
    return [];
  };
  const refreshFrquencyValidation = () => {
    if (isRefreshFrequency) {
      return [{ required: true, message: "This field is required" }];
    }
    return [];
  };
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };
  useEffect(() => {
    if (!isTotalPush) {
      form.setFieldsValue({ total_Push: "", total_Days: "" });
    }
  }, [isTotalPush]);
  useEffect(() => {
    if (!isRefreshFrequency) {
      form.setFieldsValue({ delay_count: "", freq_Time: "" });
    }
  }, [isRefreshFrequency]);
  useEffect(() => {
    if (initialIsTotalPushEnabled) {
      form.resetFields();
      setIsTotalPush(true);
    } else {
      setIsTotalPush(false);
    }
  }, [initialIsTotalPushEnabled]);
  useEffect(() => {
    if (initialRefreshFrequency) {
      setIsRefreshFrequency(true);
    } else {
      setIsRefreshFrequency(false);
    }
  }, [initialRefreshFrequency]);
  useEffect(() => {
    const updatedFormValues = {
      is_totalPush: dndDataValues?.is_totalPush,

      total_Push: dndDataValues?.total_Push,
      total_Days: dndDataValues?.total_Days,
      is_frequency: dndDataValues?.is_frequency,
      delay_count: dndDataValues?.delay_count,
      freq_Time: dndDataValues?.freq_Time,
    };
    form.setFieldsValue(updatedFormValues);
  }, [mode, currentAppKey, form]);

  return (
    <Fragment>
      <div className="global-frequency-capping-container">
        <div className="mt-4 mb-4  ">
          <h6 className="global-frequency-capping-container-heading">
            Common Setting -- {Mode}
          </h6>
          <Divider className="global-frequency-capping-custom-divider" />
        </div>

        <div className=" col-sm-9 mt-4 mb-4 align-items-center global-frequency-capping-form ">
          {showMessage.show && (
            <ShowMessage
              type={showMessage.type}
              content={showMessage.message}
            />
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Form
              form={form}
              layout="horizontal"
              initialValues={{
                is_totalPush: false,
                is_frequency: false,
              }}
              // onValuesChange={onFormValueChange}
              // requiredMark={journeyData}
              onFinish={onFormSubmit}
            >
              <div className="d-flex align-items-baseline gap-2">
                <Form.Item
                  // label="Total push notifications send up to"
                  name="is_totalPush"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select Total Push",
                  //   },
                  // ]}
                >
                  <SwitchBtn
                    checked={isTotalPush}
                    onChange={onPushSwitchChange}
                  />
                </Form.Item>
                <span> Total push notifications send up to</span>
              </div>
              <Form.Item label="" style={{ marginBottom: 0 }}>
                <Form.Item
                  name="total_Push"
                  rules={pushNotificationValidation()}
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                >
                  <TextInput
                    placeholder="Total People"
                    disabled={!isTotalPush}
                  />
                </Form.Item>
                <Form.Item
                  name="total_Days"
                  rules={pushNotificationValidation()}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <TextInput
                    placeholder=" In Total Days"
                    disabled={!isTotalPush}
                  />
                </Form.Item>
              </Form.Item>
              <div className="d-flex align-items-baseline gap-2">
                <Form.Item
                  name="is_frequency"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Refresh",
                  //   },
                  // ]}
                  label=""
                >
                  <SwitchBtn
                    checked={isRefreshFrequency}
                    onChange={onRefreshSwitchChange}
                  />
                </Form.Item>
                <span> Refresh the Frequency Capping Daily at 00:00 GMT</span>
              </div>

              <Form.Item
                label="Minimun delay between two notification"
                style={{ marginBottom: 0 }}
              >
                <Form.Item
                  name="delay_count"
                  rules={refreshFrquencyValidation()}
                  style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                >
                  <TextInput
                    placeholder="Hours"
                    disabled={!isRefreshFrequency}
                  />
                </Form.Item>

                <Form.Item
                  name="freq_Time"
                  rules={refreshFrquencyValidation()}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    margin: "0 8px",
                  }}
                >
                  <Selector
                    onChange={(value) => {}}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={"Select"}
                    options={[
                      {
                        value: "",
                        label: "Select",
                      },
                      {
                        value: "seconds",
                        label: "Second",
                      },
                      {
                        value: "minutes",
                        label: "Minutes",
                      },
                      {
                        value: "hours",
                        label: "Hour",
                      },
                    ]}
                    disabled={!isRefreshFrequency}
                  />
                </Form.Item>
              </Form.Item>

              <div className="">
                <PrimaryButton
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  style={{ border: "none", width: "80px" }}
                >
                  Save
                </PrimaryButton>
              </div>
            </Form>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default GlobalFrequencyCapping;
