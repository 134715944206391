"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Kochava = exports.DeliveryMethodDetails = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
// Delivery method details for Kochava (all keys are optional)
let DeliveryMethodDetails = class DeliveryMethodDetails {
    constructor(region, bucket, accessKey, secretKey) {
        this.region = region;
        this.bucket = bucket;
        this.accessKey = accessKey;
        this.secretKey = secretKey;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeliveryMethodDetails.prototype, "region", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeliveryMethodDetails.prototype, "bucket", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeliveryMethodDetails.prototype, "accessKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], DeliveryMethodDetails.prototype, "secretKey", void 0);
DeliveryMethodDetails = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, String, String])
], DeliveryMethodDetails);
exports.DeliveryMethodDetails = DeliveryMethodDetails;
// Kochava configuration object for attribution (all keys are optional)
let Kochava = class Kochava {
    constructor(apiKey, appguid, deliveryMethod, deliveryMethodDetails) {
        this.apiKey = apiKey;
        this.appguid = appguid;
        this.deliveryMethod = deliveryMethod;
        this.deliveryMethodDetails = deliveryMethodDetails;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Kochava.prototype, "apiKey", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Kochava.prototype, "appguid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], Kochava.prototype, "deliveryMethod", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)({ type: DeliveryMethodDetails }),
    __metadata("design:type", DeliveryMethodDetails)
], Kochava.prototype, "deliveryMethodDetails", void 0);
Kochava = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, String, DeliveryMethodDetails])
], Kochava);
exports.Kochava = Kochava;
