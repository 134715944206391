"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueStatusEnum = exports.ValidatorOptionsEnum = exports.RTLLanguageEnum = exports.PeriodEnum = exports.ComparisonEnum = exports.IntervalEnum = exports.PlatformEnum = exports.CERTPATH = void 0;
// Constant representing the path to the directory where application certificates are stored
exports.CERTPATH = "/certs/appcertificates/";
var PlatformEnum;
(function (PlatformEnum) {
    PlatformEnum["ALL"] = "all";
    PlatformEnum["ANDROID"] = "Android";
    PlatformEnum["IOS"] = "iOS";
    PlatformEnum["WEB"] = "Web";
    PlatformEnum["UNKNOWN"] = "Unknown";
})(PlatformEnum = exports.PlatformEnum || (exports.PlatformEnum = {}));
var IntervalEnum;
(function (IntervalEnum) {
    IntervalEnum["DAYS"] = "d";
    IntervalEnum["HOURS"] = "h";
    IntervalEnum["MINUTES"] = "m";
    IntervalEnum["SECONDS"] = "s";
    IntervalEnum["DAYS_OR_MORE"] = "dm";
    IntervalEnum["DAYS_OR_LESS"] = "dl";
})(IntervalEnum = exports.IntervalEnum || (exports.IntervalEnum = {}));
var ComparisonEnum;
(function (ComparisonEnum) {
    ComparisonEnum["FROM"] = "from";
    ComparisonEnum["BEFORE"] = "before";
})(ComparisonEnum = exports.ComparisonEnum || (exports.ComparisonEnum = {}));
var PeriodEnum;
(function (PeriodEnum) {
    PeriodEnum["YESTERDAY"] = "Yesterday";
    PeriodEnum["TODAY"] = "Today";
    PeriodEnum["TOMORROW"] = "Tomorrow";
    PeriodEnum["CURRENT_MONTH"] = "CURRENT_MONTH";
    PeriodEnum["CURRENT_DAY"] = "CURRENT_DAY";
    PeriodEnum["CURRENT_DATE"] = "CURRENT_DATE";
    PeriodEnum["THIS_WEEK"] = "This Week";
    PeriodEnum["LAST_WEEK"] = "Last Week";
    PeriodEnum["THIS_MONTH"] = "This Month";
    PeriodEnum["LAST_MONTH"] = "Last Month";
    PeriodEnum["CUSTOM"] = "Custom";
})(PeriodEnum = exports.PeriodEnum || (exports.PeriodEnum = {}));
var RTLLanguageEnum;
(function (RTLLanguageEnum) {
    RTLLanguageEnum["ARABIC"] = "Arabic";
    RTLLanguageEnum["URDU"] = "Urdu";
})(RTLLanguageEnum = exports.RTLLanguageEnum || (exports.RTLLanguageEnum = {}));
var ValidatorOptionsEnum;
(function (ValidatorOptionsEnum) {
    ValidatorOptionsEnum["REGEX"] = "regex";
    ValidatorOptionsEnum["CUSTOM"] = "custom";
    ValidatorOptionsEnum["FILE"] = "file";
})(ValidatorOptionsEnum = exports.ValidatorOptionsEnum || (exports.ValidatorOptionsEnum = {}));
var QueueStatusEnum;
(function (QueueStatusEnum) {
    QueueStatusEnum["INITIATED"] = "initiated";
    QueueStatusEnum["FAILED"] = "failed";
    QueueStatusEnum["PROCESSED"] = "processed";
})(QueueStatusEnum = exports.QueueStatusEnum || (exports.QueueStatusEnum = {}));
