import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

// Define the prop types for the CustomErrorBoundary
interface CustomErrorBoundaryProps {
  children: React.ReactNode;
  FallbackComponent: React.FC<FallbackProps>;
  onError?: (error: Error, info: { componentStack: string }) => void;
  onReset?: () => void;
}

const CustomErrorBoundary: React.FC<CustomErrorBoundaryProps> = ({ 
  children, 
  FallbackComponent, 
  onError, 
  onReset 
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={onError}
      onReset={onReset}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
