import { DatePicker, DatePickerProps } from 'antd';
import React, { FC } from 'react'
import { disabledDate } from '../../../helperFunctions/date';
import CustomErrorBoundary from 'components/HOC/CustomErrorBoundary/CustomErrorBoundary';
const SingleDateSelector: FC<DatePickerProps> = ({ ...props }) => {
  return (
    <div>
      <CustomErrorBoundary FallbackComponent={()=><></>}>
     <DatePicker {...props} disabledDate={props.disabledDate || disabledDate}/>
      </CustomErrorBoundary>
    </div>
  )
}

export default SingleDateSelector;