"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryPayload = exports.FilterInfo = exports.ProjectionInfo = exports.TableInfo = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
const base_1 = require("../common/base");
const constants_1 = require("../../constants");
const constants_2 = require("../../constants");
/**
 * Represents the information about the table.
 */
let TableInfo = class TableInfo {
    constructor(tableName) {
        this.tableName = tableName;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], TableInfo.prototype, "tableName", void 0);
TableInfo = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String])
], TableInfo);
exports.TableInfo = TableInfo;
/**
 * Represents information about a projection.
 */
let ProjectionInfo = class ProjectionInfo {
    constructor(operand, type, func, name, jsonb) {
        this.operand = operand;
        this.type = type;
        this.func = func;
        this.name = name;
        this.jsonb = jsonb;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "operand", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "func", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], ProjectionInfo.prototype, "jsonb", void 0);
ProjectionInfo = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, String, String, String])
], ProjectionInfo);
exports.ProjectionInfo = ProjectionInfo;
/**
 * Represents information about a filter condition.
 */
let FilterInfo = class FilterInfo {
    constructor(operand, operator, value, type, e_operator, precedence, group, jsonb, compute, func, interval, comparison, period) {
        this.operand = operand;
        this.operator = operator;
        this.value = value;
        this.type = type;
        this.e_operator = e_operator;
        this.precedence = precedence;
        this.group = group;
        this.jsonb = jsonb;
        this.compute = compute;
        this.func = func;
        this.interval = interval;
        this.comparison = comparison;
        this.period = period;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "operand", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "operator", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], FilterInfo.prototype, "value", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "e_operator", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], FilterInfo.prototype, "precedence", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], FilterInfo.prototype, "group", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "jsonb", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "jsonb_type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Boolean)
], FilterInfo.prototype, "compute", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "func", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "interval", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "comparison", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], FilterInfo.prototype, "period", void 0);
FilterInfo = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, String, Array, String, String, Number, Array, String, Boolean, String, String, String, String])
], FilterInfo);
exports.FilterInfo = FilterInfo;
/**
 * Represents the payload for querying different types of data.
 */
let QueryPayload = class QueryPayload extends base_1.BaseModel {
    constructor(name, tableInfo, projectionInfo, filterInfo, audid, days, status) {
        super();
        this.name = name;
        this.tableInfo = tableInfo;
        this.projectionInfo = projectionInfo;
        this.filterInfo = filterInfo;
        this.audid = audid;
        this.days = days;
        this.status = status;
    }
};
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", TableInfo)
], QueryPayload.prototype, "tableInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], QueryPayload.prototype, "projectionInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Array)
], QueryPayload.prototype, "filterInfo", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", Number)
], QueryPayload.prototype, "days", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "audid", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)(),
    __metadata("design:type", String)
], QueryPayload.prototype, "status", void 0);
QueryPayload = __decorate([
    (0, typescript_json_serializer_1.JsonObject)(),
    __metadata("design:paramtypes", [String, TableInfo, Array, Array, String, Number, String])
], QueryPayload);
exports.QueryPayload = QueryPayload;
