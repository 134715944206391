import { DateRangeData } from "@customTypes/dateRange";
import { DateRangeSelector } from "components/DateTimeSelectors/DateRangeSelector/DateRangeSelector";
import Spinner from "components/Spinner/Spinner";
import "./AttributeReports.css";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import WebPushReportCard from "components/Cards/WebPushReportCard/WebPushReportCard";
import { formatDate } from "helperFunctions/date";
import CustomHighChart from "components/Charts/CustomHighChart/CustomHighChart";
import CustomTable from "components/CustomTable/CustomTable";
import InnerNav from "components/InnerNav/InnerNav";
import { MenuProps } from "antd";
import { DonutPieChart } from "components/Charts/DonutPieChart/DonutPieChart";
import { colorSchema } from "components/Charts/DonutPieChart/colorSchema";
import { BOOLEAN_OPTIONS } from "utils/constants/selectorOptions";
import { useSelector } from "react-redux";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";

export const AttributeReports = () => {
  const [dateRange, setDateRange] = useState<DateRangeData | null>(null);
  const [attributeReports, setAttributeReports] = useState<any>();
  const [performanceTabFilter, setPerformanceTabFilter] =
    useState<string>("city");
  const [performanceData, setPerformanceData] = useState<[]>([]);
  const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
  const loginUser = useSelector((state: any) => state.loginUser);
  const { appId } = useSelector((state: any) => state.activeApp);

  //get the campaign reports from api and set the state
  async function getAttributionReports() {
    try {
      const apiKey = loginUser.data.api_key;
      if (apiKey && appId) {
        setLoading(BOOLEAN_OPTIONS.TRUE);
        const params = {
          app_id: appId,
          api_key: apiKey,
          sd: dateRange?.startDate_C ? dateRange?.startDate_C : undefined,
          ed: dateRange?.endDate_C ? dateRange?.endDate_C : undefined,
        };

        const data = await centralApi(
          "GET",
          API_ENDPOINTS.GET_CAMPAIGN_ATTRIBUTION_REPORTS,
          null,
          params
        );
        setAttributeReports(data);
        setPerformanceData(data?.clicksByCity); //at first set it to city data
        setLoading(BOOLEAN_OPTIONS.FALSE);
      }
    } catch (error) {
      setLoading(BOOLEAN_OPTIONS.FALSE);
    }
  }

  //handles date range and saves it in use State
  const handleDateRange = (dateRangeData: any) => {
    setDateRange(dateRangeData);
  };

  // Memoize the data processing to avoid unnecessary recalculations
  const weeklyTrendData = useMemo(() => {
    // Only process if the weeklyTrendsData exists and has data
    if (
      attributeReports?.weeklyTrendsData &&
      attributeReports.weeklyTrendsData.length > 0
    ) {
      const categories: string[] = [];
      const clicksData: number[] = [];
      const installsData: number[] = [];

      // Process the weekly trends data to populate categories and data series
      attributeReports.weeklyTrendsData.forEach((entry: any, index: any) => {
        // Format the week number as "Week X"
        const weekNumber = `Week ${index + 1}`;
        categories.push(weekNumber);

        // Check the metric type and add corresponding data to clicks or installs
        if (entry.metric === "clicks") {
          clicksData.push(parseInt(entry.total)); // Add click data
        } else if (entry.metric === "installs") {
          installsData.push(parseInt(entry.total)); // Add install data
        }
      });

      return {
        categories,
        clicksData,
        installsData,
      };
    }
    return { categories: [], clicksData: [], installsData: [] };
  }, [attributeReports?.weeklyTrendsData]); // Re-run when weeklyTrendsData changes

  //options for weeklyTrend highChart
  const weeklyTrend0ptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: weeklyTrendData.categories,
    },
    yAxis: [
      {
        title: {
          text: "values",
        },
        opposite: false, // Display on the left side
      },
    ],
    series: [
      {
        name: t("clickedLabel"), // Series name for clicked data
        type: "spline",
        data: weeklyTrendData.clicksData, // Data for clicked series
      },
      {
        name: "installs", // Series name for pushed data
        type: "spline",
        data: weeklyTrendData.installsData, // Data for pushed series
      },
    ],
  };

  //columns for performance breakdown table
  const performanceBreakDownColumns = [
    {
      title:
        performanceTabFilter === "city"
          ? "City"
          : performanceTabFilter === "country"
          ? "Country"
          : "Device",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
  ];

  const onTabClick: MenuProps["onClick"] = (e) => {
    setPerformanceTabFilter(e.key);

    // Map selected key to corresponding data
    const dataMap: any = {
      city: attributeReports?.clicksByCity,
      country: attributeReports?.clicksByCountry,
      device: attributeReports?.clicksByDevice,
    };

    setPerformanceData(dataMap[e.key] || []);
  };

  const transformedData = performanceData?.map(({ value, clicks }) => ({
    id: value || "Unknown",
    value: parseInt((clicks as string)?.replace(/,/g, ""), 10) || 0, // Ensure clicks is treated as a string
  }));

  const totalEvents = transformedData?.reduce(
    (sum, data) => sum + data.value,
    0
  );

  // Table Columns for campaign Performance Page
  const columns = [
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: 250, // Fixed width for the first column
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: "Installs",
      dataIndex: "installs",
      key: "installs",
    },
    {
      title: "CVR",
      dataIndex: "cvr",
      key: "cvr",
    },
  ];

  useEffect(() => {
    if (dateRange) {
      getAttributionReports();
    }
  }, [dateRange]);

  return (
    <div className="container-fluid">
      <div className="attribute-reports-container">
        <Helmet>
          <title>Appice | Attribute Reports</title>
        </Helmet>
        <div className="w-100 h-100 d-flex justify-content-between flex-wrap my-3">
          <div>
            <h4>{t("marketingAnalyticsLabel")}</h4>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <strong className="">{t("periodLabel")} </strong>
            <DateRangeSelector
              className="w-75"
              onDateChange={handleDateRange}
              disableRange={{ range: 1, rangeType: "year" }}
              disableType="years"
            />
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center  attribute-reports-spinner">
            <Spinner />
          </div>
        ) : (
          <div className="attribute-reports-inner-container">
            <div className="attribute-reports-cards">
              <WebPushReportCard
                heading={"Total clicks"}
                number={attributeReports?.totalClicks}
                percentageTwo={attributeReports?.clickChange}
                timeString={`w.r.t. ${formatDate(
                  dateRange?.startDate_P,
                  "D MMM, YY"
                )} - ${formatDate(dateRange?.endDate_P, "D MMM, YY")}`}
              />
              <WebPushReportCard
                heading={"Total installs"}
                number={attributeReports?.totalInstalls}
                percentageTwo={attributeReports?.installChange}
                timeString={`w.r.t. ${formatDate(
                  dateRange?.startDate_P,
                  "D MMM, YY"
                )} - ${formatDate(dateRange?.endDate_P, "D MMM, YY")}`}
              />
              <WebPushReportCard
                heading={"Total cvr"}
                number={`${attributeReports?.currentCVR} %`}
                percentageTwo={attributeReports?.cvrChange}
                timeString={`w.r.t. ${formatDate(
                  dateRange?.startDate_P,
                  "D MMM, YY"
                )} - ${formatDate(dateRange?.endDate_P, "D MMM, YY")}`}
              />
            </div>
            <div className="pt-3 ps-3 mt-3 mb-1 attribute-reports-weekly-trends">
              <h6 className="fw-bold pb-3">Weekly Trends</h6>
              <CustomHighChart options={weeklyTrend0ptions} />
            </div>
            <div className="p-3 mt-3 mb-1 attribute-reports-weekly-trends">
              <h6 className="fw-bold pb-3">Campaign Performance</h6>
              <CustomTable
                columns={columns}
                dataSource={attributeReports?.campaignPerformanceData}
                pagination={{ pageSize: 5, position: ["bottomCenter"] }}
                scroll={{ x: "max-content" }} // For horizontal scrolling
              />
            </div>
            <div className="p-3 mt-3 mb-1 attribute-reports-weekly-trends">
              <h6 className="fw-bold pb-3">{t("performanceBreakdownLabel")}</h6>
              <div className="w-100 border-bottom">
                <InnerNav
                  theme="light"
                  style={{ border: "none" }}
                  onTabClick={onTabClick}
                  selected={performanceTabFilter}
                  menu={[
                    {
                      label: <span className="mx-1">{t("byCityLabel")}</span>,
                      key: "city",
                    },
                    {
                      label: (
                        <span className="mx-1">{t("byCountryLabel")}</span>
                      ),
                      key: "country",
                    },
                    {
                      label: <span className="mx-1">{t("byDeviceLabel")}</span>,
                      key: "device",
                    },
                  ]}
                />
              </div>
              <div className="d-flex">
                <div className="w-25 mt-3">
                  <CustomTable
                    columns={performanceBreakDownColumns}
                    dataSource={performanceData}
                    pagination={false}
                    scroll={{ y: 190 }}
                    bordered
                  />
                </div>
                <div className="w-50 mt-0">
                  <DonutPieChart
                    data={transformedData ? transformedData :[]}
                    colorSchema={colorSchema}
                    totalEvents={totalEvents}
                    enableArcLinkLabels={false} // Matches no arc links in HighCharts
                    innerRadius={0.001}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
