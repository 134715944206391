import { FC, useEffect, useRef, useState } from "react";
import BeefreeEditor from "../../../../../../components/BeefreeEditor/BeefreeEditor";
import Selector from "../../../../../../components/Selector/Selector";
import { Col, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import centralApi from "../../../../../../services/centralApi";
import {
  formatDate,
  getCurrentDate,
} from "../../../../../../helperFunctions/date";
import { setCampaignData } from "../../../../../../redux/features/campaign/campaignDataSlice";
import { setCurrentStep } from "../../../../../../redux/features/campaign/campaignCreateCurrentStepSlice";
import { t } from "i18next";
import { API_ENDPOINTS } from "utils/constants";
import { CampaignChannelTypeEnum } from "utils/enums";
import { fetchTemplatesList } from "redux/features/templates_/templatesByTypeSlice";
import { getToken } from "redux/features/app/authTokenSlice";
import {
  fetchTemplateById,
  setSelectedTemplate,
} from "redux/features/templates_/templateByIdSlice";
import AccessControl from "components/HOC/AccessControl/AccessControl";
import { FeatureSettingsFlagTypes } from "utils/constants/constants";
import CreateVariant from "../../CreateVariant/CreateVariant";
import { QueryPayload } from "utils/models";
interface EmailProps {
  type?:string;
  viewMode?:boolean

}

const Email: FC<EmailProps> = ({type, viewMode}) => {
  const loginUser = useSelector((state: any) => state.loginUser.data);
  const { appId } = useSelector((state: any) => state.activeApp);
  const [tagsData,setTagsData]=useState({data:[],loading:true})
  const dispatch: any = useDispatch();
  const currentStep = useSelector(
    (state: any) => state.campaignCreateCurrentStep
  );
  const [variants, setVariants] = useState<any>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const features = useSelector((state: any) => state.featuresSetting.data);
  const selectedPartners = features[0]?.partnerInfo?.partnerData?.filter(
    (partner:any) => partner?.channel === CampaignChannelTypeEnum.EMAIL
  );
  const partnerId = selectedPartners?.find((partner:any) => partner.default === true)?.partnerId || "";
  const campaignData = useSelector((state: any) => state.campaignData.data);
  const selectedTemplate = useSelector((state: any) => state.templateById.data);
  const variantsRef = useRef(variants);
  const templateId=useRef(undefined)
  const [template, setTemplate] = useState({
    template_name: "",
    sender:partnerId||"",
    template_type:type === CampaignChannelTypeEnum.EMAIL ? CampaignChannelTypeEnum.EMAIL :CampaignChannelTypeEnum.LANDING_PAGE, // type of template

  });
  
  const partnerNames = selectedPartners?.map((partner:any) => ({
    value: partner?.partnerId,
    label: partner?.partnerName
  }));
  
  const templates = useSelector((state: any) => state.templatesList.data); //fetching templlate list
  const [formData,setFormData]=useState({
    subjectData:"",
    bodyHtmlData:"",
    jsonData:""
  })

  const senderRef = useRef(template.sender);  // Initialize the ref with the initial sender value
 
  const fetchVariables = async () => {
    try {

      const queryPayload = new QueryPayload(
            "",
            {
              tableName:`app_users_${appId}`,
            },  
            [],   
            [],     
          );
      const params = {
        api_key: loginUser.api_key,
        app_id: appId,
        data:queryPayload.toString()
      };
      //call api
      const {columns} = await centralApi(
        "GET",
        API_ENDPOINTS.GET_TRAIT_TABLE_INFO_URL,
        null,
        params
      );
      let data=columns.map((col:any)=>({name:col.label,value:`{{${col?.value?.replace("->","")}}}`}))
      setTagsData((prevState)=>({...prevState,data,loading:false}));
    } catch (error) {
      setTagsData((prevState)=>({...prevState,data:[],loading:false}));
    }
    // setLoading(false);
  };
  useEffect(() => {
    const { template, template_name, template_type,sender } =
      selectedTemplate || campaignData.data || formData;
    if (template || selectedVariant) {
      const { bodyHtmlData, jsonData ,subjectData} =
      Array.isArray(template) || selectedVariant
      ? selectedVariant || template[0]
      : template || formData;
      setFormData((prevState: any) => ({
        ...prevState,
        subjectData,
        bodyHtmlData,
        jsonData
      }));
      if (template_name) {
        setTemplate((prevState) => ({
          ...prevState,
          template_name,
          template_type,
          sender
        }));
      }
    }
  }, [selectedTemplate, campaignData,selectedVariant]);
  useEffect(() => {
    if (loginUser){
      dispatch(
        fetchTemplatesList({
          app_id: appId,
          api_key: loginUser.api_key,
          template_type: CampaignChannelTypeEnum.EMAIL ,
        })
      );
      //calling token API
    } 
    if (campaignData.data) {
      //fetching selected template and saving in redux also
      dispatch(setSelectedTemplate(campaignData.data));
    }
    
  }, [loginUser]);
  useEffect(() => {
    if (selectedTemplate ) {
      templateId.current=selectedTemplate._id

       !viewMode  && dispatch(
        setCampaignData({
          ...campaignData,
          data: selectedTemplate,
          tid: templateId.current,
          t: selectedTemplate.template_type,
      
        })
      );
      if (Array.isArray(selectedTemplate?.template)) {
        setVariants(selectedTemplate.template);
        setSelectedVariant(selectedTemplate.template[0]);
      } else {
        setVariants([]);
        setSelectedVariant(null);
      }
    
    }
  }, [selectedTemplate]);
  useEffect(() => {
    variantsRef.current = variants;
  }, [variants]);
  useEffect(()=>{
fetchVariables()
  },[])
 
  const onSave =
  //  useCallback(
    async (jsonFile: string, htmlFile: string) => {
      try {
        const token = await dispatch(getToken()).unwrap();
        const tempInput: any = document.getElementById("email_template_name");
     
        const subInput: any = document.getElementById(
          "email_template_subject_data"
        );
        const data = {
          template_name: tempInput?.value,
          template_type: template?.template_type,
          template:variantsRef.current.length > 0 ? variantsRef.current : {...formData,jsonData:jsonFile,bodyHtmlData:htmlFile,subjectData:subInput.value},
          isTemplateDeleted: "false",
          sender:senderRef.current,
          createdOn: templateId.current
            ? undefined
            : formatDate(getCurrentDate(), "DD/MM/YYYY"),
          modifiedOn: templateId.current
            ? formatDate(getCurrentDate(), "DD/MM/YYYY")
            : undefined,
        };
        const payload: any = {
          api_key: loginUser?.api_key,
          app_id: appId,
          authToken:token,
          args: JSON.stringify(data),
          template_id:templateId.current
        };
        const url = templateId.current
          ? API_ENDPOINTS.UPDATE_CAMPAIGN_TEMPLATE
          : API_ENDPOINTS.CREATE_CAMPAIGN_TEMPLATE;
        const result = await centralApi("POST", url, payload);
        dispatch(
          setCampaignData({
            ...campaignData,
            data: result,
            tid: result?._id,
            t: result?.template_type,
          })
        );
         
        //calling token API
        dispatch(setCurrentStep(currentStep + 1));
      } catch (error:any) {
       await dispatch(getToken())
      }

    
  }
  
  function onChange(jsonFile:string, htmlFile:string) {
    setFormData((prevState)=>({...prevState,jsonData:jsonFile,bodyHtmlData:htmlFile}))
}
  return (
    <div className="p-2">
      <div className="d-flex justify-content-between">
        <div >

        <h5> {type==CampaignChannelTypeEnum.EMAIL ? t("emailLabel"):t("landingPageLabel") }  {t("campaignSmallLabel")}</h5>
      
          <CreateVariant template={template} formData={formData} variants={variants} setSelectedVariant={setSelectedVariant} selectedVariant={selectedVariant} setVariants={setVariants}  />
              
        
        <FormItem className="w-100  m-0" >
        <Selector
        style={{width:224,
          marginTop:7,
        }}
                        id="email_sender"

                      onChange={(selectedSender: any) => {
                        setTemplate((prevState: any) => ({
                          ...prevState,
                          sender: selectedSender,
                        }));
                        senderRef.current = selectedSender; //  update the ref here

                      }}
                   
                      options={partnerNames}
                
                      placeholder={t("selectSenderLabel")}
                      value={template?.sender || undefined}
                    
                      />
        </FormItem>
        </div>
        <FormItem className="w-25 m-0">
          <Selector
          showSearch
          onChange={(tempString) =>
            dispatch(
              fetchTemplateById({
                app_id: appId,
                api_key: loginUser.api_key,
                template_id: JSON.parse(tempString)._id,
              })
            )
          }
          //mapping list of templates of type whats app
          options={templates.map(
            ({
              template_name,
              _id,
            }: {
              template_name: string;
              _id: string;
            }) => ({
              label: template_name,
              value: JSON.stringify({ _id, template_name }),
            })
          )}
          
          placeholder={t("selectingExistingTemplateLabel")}
          />
        </FormItem>
      </div>
      <Form colon={false} layout="vertical">
        <Row
          gutter={20}
          wrap
          className="py-3 position-relative d-flex justify-content-center"
        >
          <Col className="d-flex" span={12}>
            <FormItem className="w-100 m-0" label="Template name">
              <TextInput
                id="email_template_name"
                value={template.template_name}
                onChange={(e: any) =>
                  setTemplate((prevState) => ({
                    ...prevState,
                    template_name: e.target.value,
                  }))
                }
              />
            </FormItem>
          </Col>
             <Col className="d-flex " span={12}>
             {type==CampaignChannelTypeEnum.EMAIL &&   <FormItem className="w-100 m-0  " label="Email subject">
              <TextInput
                id="email_template_subject_data"
                value={formData.subjectData}
                onChange={(e: any) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    subjectData: e.target.value,
                  }))
                }
              />
            </FormItem>}
          </Col>
        </Row>
      </Form>
     <AccessControl accessControl={{features:[FeatureSettingsFlagTypes.EMAIL_EDITOR]}}>
     {!tagsData.loading && <BeefreeEditor
        style={{ height: "70vh" }}
        isUpdate={templateId.current}
        options={{
          existingTemplate: selectedVariant?.jsonData || selectedTemplate?.template?.jsonData,
          workspace: { onSave,onChange},
          mergeTags:tagsData.data
        }}
        />}
        </AccessControl>
    </div>
  );
};

export default Email;