import { t } from "i18next";
import mikeIcon from "../../../../images/mike_icon.png";
import { data } from "../../data";
import "./Campaigns.css";
function Campaigns({ campaignData }: {campaignData:typeof data.campaigns}) {
  return (
    <div className="c-three-sixty-campaigns-container h-100 pb-4  d-flex flex-column">
      <div
        className="d-flex justify-content-between  w-50 py-3 "
        style={{ borderBottom: "1px solid var(--color-other-divider)" }}
      >
        <strong>{t("totalSentLabel")}</strong>
        <strong>{campaignData.ts}</strong>
      </div>
      <div className="d-flex justify-content-between py-3 align-items-center  ">
        <div className="d-flex ">
          <div className="c-three-sixty-campaigns-mike-icon-wrapper">
            <img src={mikeIcon} alt="" />
          </div>
          <div className="">
            <p className=" m-0 ">{t("lastCampaingLabel")}</p>
            <strong>{campaignData.lc}</strong>
          </div>
        </div>
        <div>
          <p className=" m-0">{t("channelLabel")}</p>
          <strong>{campaignData.ch}</strong>
        </div>
        <div>
          <p className=" m-0">{t("resultLabel")}</p>
          <strong>{campaignData.rs}</strong>
        </div>
      </div>
      <div className="d-flex justify-content-between w-100 mt-auto">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="">{t("pushLabel")}</p>
          <strong>{campaignData.push}</strong>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="">{t("inappLabel")}</p>
          <strong>{campaignData.inApp}</strong>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="">{t("webLabel")}</p>
          <strong>{campaignData.web}</strong>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="">{t("emailLabel")}</p>
          <strong>{campaignData.email}</strong>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="">{t("WALabel")}</p>
          <strong>{campaignData.WA}</strong>
        </div>
      </div>
    </div>
  );
}

export default Campaigns;
