import CustomBreadCrumb from "components/CustomBreadCrumb/CustomBreadCrumb";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Inputs/Search/Search";
import Selector from "components/Selector/Selector";
import useDebouncedValue from "hooks/useDebouncedState";
import useMessage from "hooks/useMessage";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import centralApi from "services/centralApi";
import { API_ENDPOINTS } from "utils/constants";
import { BOOLEAN_OPTIONS, PAGE_OPTIONS, TABLE_OPTIONS } from "utils/constants/selectorOptions";
import { PlatformTypeEnum } from "utils/enums";


interface ColumnIndexMap {
    User: number;
    User_Since: number;
    Last_Seen: number;
    // Add more columns if necessary
  }

  interface Sorter {
    field: string;
    order: string;
  }

const Customer360List = () => {
const loginUser = useSelector((state: any) => state.loginUser);
const { appId } = useSelector((state: any) => state.activeApp);
const [loading, setLoading] = useState(BOOLEAN_OPTIONS.FALSE);
const [pageData, setPageData] = useState(PAGE_OPTIONS.PAGINATION);
const [type, setType] = useState("I");
const [platform, setPlatform] = useState<"all" | "Android" | "IOS" | "Web">("all");
const [search, setSearch] = useDebouncedValue<string | undefined>(undefined, 500);
const [usersData, setUsersData] = useState<any>([]);
const [noOfUsers, setNoOfUsers] = useState(0);
const {showError,showSuccess}=useMessage();

const apiKey = loginUser.data.api_key;


  const [sorter, setSorter] = useState<Sorter>({ field: "User_Since", order: "desc" });
  const columnIndexMap: ColumnIndexMap = {
    User: 0,
    User_Since: 1,
    Last_Seen: 2,
    // Add mappings for other columns
  };


const columns: any = [
    {
      title: t("userLabel"),
      dataIndex: "User",
      sorter: true,

      render: (user: any) => {
  // Construct the link URL based on the idToUse
  return <Link to={`/dashboard/customer/${user.did}?type=did`}>{user.did}</Link>;
      },
    },
    {
      title: t("userSinceLabel"),
      dataIndex: "User_Since",
      sorter: true,
      defaultSortOrder: 'descend',

    },
    {
      title: t("lastSeenLabel"),
      dataIndex: "Last_Seen",
      sorter: true,
    },
    {
      title: t("hashOfSessionsLabel"),
      dataIndex: "of_Sessions",
    },
    {
      title: t("averageDurationLabel"),
      dataIndex: "Average_Duration",
    },
    {
      title: t("clientIdLabel"),
      dataIndex: "Client_ID",

      render: (clientId: string) => {
        return clientId ? (
          <Link to={`/dashboard/customer/${clientId}?type=ci`}>{clientId}</Link>
        ) : (
          "" // Handle the case where `Client_ID` might be empty
        );
      },
    },
  ];

     // When the page number of the table changes
     const onPageChange = (pagination: any,) => {
        setPageData({
          ...pageData,
          current: pagination.current,
          pageSize: pagination.pageSize,
        });
      };


  async function getAppUsersData() {
    try {
     
      const payload = {
     
        app_id: appId,
        api_key: apiKey,
        draw: pageData.current,
        start: pageData.pageSize * (pageData.current - 1),
        length: pageData.pageSize,
        "search[value]": search,
        "search[regex]": false,
        // "_": endDate_C,
        order: [
          {
            column: columnIndexMap[sorter.field as keyof ColumnIndexMap],
            dir: sorter?.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : 'desc',
          },
        ],
        type,
        platform,
      };
      if (apiKey && appId) {
        // setLoading(true);
        const url: string = API_ENDPOINTS.GET_APP_USERS_URL;
        const response = await centralApi("GET", url, null, payload);
        const data = response?.data.map((user: any, index: number) => ({
          Key: index,
          User: user ,
          User_Since: user.fs || "", // Using logical OR to handle undefined user.fs
          Last_Seen: user.ls || "", // Using logical OR to handle undefined user.ls
          of_Sessions: user.sc || "", // Using logical OR to handle undefined user.sc
          Average_Duration: user.ad || "", // Using logical OR to handle undefined user.ad
          Client_ID: user.ci || "" // Using logical OR to handle undefined user.ci
        }));
        setUsersData(data);
        setNoOfUsers(response?.recordsTotal);
        setLoading(false);
      }
    } catch (error:any) {
      showError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAppUsersData();
  }, [pageData, search, type, platform, loginUser]);

  const styleBtn = {
    width: "auto",
    marginRight: "10px",
    marginBottom: "10px",
  };

  return (
    <div className="container-fluid px-sm-3 p-1">
    <Helmet>
      <title>Appice | Customer360</title>
    </Helmet>

    <div className="d-flex justify-content-between flex-wrap">
      <div className="pb-4 pt-1">
        <CustomBreadCrumb
          items={[
            {
              title: <Link to={"/dashboard/customer"}>{"Customer 360"}</Link>,
            }
          ]}
        />
      </div>
      <div>
            <strong className="me-2">{t("platformLabel")}</strong>
            <Selector
              onChange={(value) => {
                setPlatform(value);
              }}
              size="middle"
              defaultValue={platform}
              style={{width:"100px"}}
              options={[
                {
                  value: PlatformTypeEnum.ALL,
                  label: t("allLabel"),
                },
                {
                  value: PlatformTypeEnum.ANDROID,
                  label: t("androidLabel"),
                },
                {
                  value: "IOS",
                  label: t("iosLabel"),
                },
                {
                  value: PlatformTypeEnum.WEB,
                  label: t("webLabel"),
                },
              ]}
            />
      </div>
     </div>

     <div className="px-3 py-4" style={{ background: "var(--color-other-white)" }}>
     <div>
          <Search
            placeholder={`${t("searchLabel")}`}
            style={{
              width: "100%",
              maxWidth: "300px",
              marginRight: "10px",
              marginBottom: "10px",
              height: "100%",
            }}
            onChange={(e: any) => setSearch(e.target.value)}
          />
          <Selector
           onChange={(value) => {
            setPageData({ ...pageData, pageSize: value })
            }}
            options={PAGE_OPTIONS.DATA} 
            className="me-2"
            defaultValue={pageData.pageSize}
          />

           <Selector
              onChange={(value) => {
                setType(value);
              }}
              size="middle"
              defaultValue={type}
             
              options={[
                {
                  value: "",
                  label: "All",
                },
                {
                  value: "I",
                  label: "Active",
                },
                {
                  value: "U",
                  label: "InActive",
                },
              ]}
            />
     </div>
     <CustomTable
          columns={columns}
          dataSource={usersData.map((user: any) => ({
            ...user,
            key: user.Key,
          }))}
          size={TABLE_OPTIONS.SIZE}
          loading={loading}
          onChange={onPageChange}
          pagination={{
            pageSize: pageData.pageSize,
            simple: false,
            total: noOfUsers,
          }}
          style={{ width: "100%" }}
          scroll={{ x: 1000 }}
        />
     </div>
    </div>
  )
}

export default Customer360List