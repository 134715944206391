import { useState, Fragment, useEffect } from "react";
import TextInput from "components/Inputs/TextInput/TextInput";
import PrimaryButton from "components/Buttons/PrimaryButton/PrimaryButton";
import Selector from "components/Selector/Selector";
import { Form } from "antd";
import centralApi from "services/centralApi";
import { useDispatch, useSelector } from "react-redux";
import ShowMessage from "components/ShowMessage/ShowMessage";
import Spinner from "components/Spinner/Spinner";
import { getToken } from "redux/features/app/authTokenSlice";
interface BudgetSettingProps {
  currentAppKey: any;
  updateCurrentAppKey: () => void;
}

const BudgetSetting: React.FC<BudgetSettingProps> = ({
  currentAppKey,
  updateCurrentAppKey,
}) => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const loginUser = useSelector((state: any) => state.loginUser);
  const activeApp = useSelector((state: any) => state.activeApp);
  const authToken = useSelector((state: any) => state.authToken.token);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState<any>({
    type: "success",
    message: "",
    show: false,
  });
  const appId = activeApp.appId;
  const api_key = loginUser.data.api_key;
  const onFormSubmit = (values: any) => {
    const { total_bdgt, cost_per_email } = values;

    // Perform your validation here
    if (parseFloat(total_bdgt) < parseFloat(cost_per_email)) {
      setShowMessage({
        type: "error",
        message: "Total Budget cannot be Less than per campaign cost",
        show: true,
      });
      hideMessage();
    } else {
      const payload = {
        args: JSON.stringify({
          ...values,

          app_id: appId,
        }),
        authToken,
        app_id: appId,
        api_key: api_key,
      };

      saveEmailConfiguration(payload);
      dispatch(getToken())
    }
  };
  async function saveEmailConfiguration(formData: any) {
    const url: string = `/i/apps/saveEmailBudget`;

    try {
      setLoading(true);
      await centralApi("POST", url, formData, null);
      updateCurrentAppKey();
      setShowMessage({
        type: "success",
        message: "Successfully Saved",
        show: true,
      });
      setLoading(false);
    } catch (error) {
      setShowMessage({
        type: "Error",
        message: "Something Went Wrong",
        show: true,
      });
      setLoading(false);
    }
    hideMessage();
  }
  const hideMessage = () => {
    setTimeout(() => {
      setShowMessage({ type: "success", message: "", show: false });
    }, 2000);
  };

  useEffect(() => {
    // Set initial form values when currentAppKey changes
    const updatedFormValues = {
      email_currency: currentAppKey[0]?.email_Config?.email_currency,
      total_bdgt: currentAppKey[0]?.email_Config?.total_bdgt,
      cost_per_email: currentAppKey[0]?.email_Config?.cost_per_email,
    };
    form.setFieldsValue(updatedFormValues);
  }, [currentAppKey, form]);
  return (
    <Fragment>
      <div className="budget-setting-container">
        <div className="">
          <div className="align-items-center budget-setting-form ">
            {showMessage.show && (
              <ShowMessage
                type={showMessage.type}
                content={showMessage.message}
              />
            )}
            {loading ? (
              <Spinner />
            ) : (
              <Form form={form} layout="horizontal"
            labelCol={{flex:"120px"}}
            labelAlign="left"
              onFinish={onFormSubmit}>
                <Form.Item
                  label="Currency"
                  name={"email_currency"}
                  rules={[{ required: true, message: "Please input Currency" }]}
                >
                  <Selector
                    onChange={(value) => {}}
                    size="middle"
                    style={{ width: "100%" }}
                    defaultValue={"Select"}
                    options={[
                      {
                        value: "",
                        label: "Select Currency",
                      },
                      {
                        value: "rupees",
                        label: "Rupee",
                      },
                      {
                        value: "paise",
                        label: "Paisa",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Total Budget"
                  name={"total_bdgt"}
                  rules={[
                    { required: true, message: "Please input Total Budget!" },
                  ]}
                >
                  <TextInput />
                </Form.Item>

                <Form.Item
                  label="Cost Per Email"
                  name={"cost_per_email"}
                  rules={[
                    { required: true, message: "Please input Cost Per Email" },
                  ]}
                >
                  <TextInput />
                </Form.Item>

                <div className="">
                  <PrimaryButton
                    size="middle"
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default BudgetSetting;
