import { CampaignChannelTypeEnum } from "utils/enums";
import centralApi from "../services/centralApi";
import { API_ENDPOINTS } from "utils/constants";

export const validateName = async (
  templateName: string,
  type:CampaignChannelTypeEnum,
  apiKey: string,
  appId: string
) => {
  const payload = {
    api_key: apiKey,
    app_id: appId,
    template_name: templateName,
    type: type,
  };

  return await centralApi("GET", API_ENDPOINTS.VALIDATE_TEMPLATE_NAME, null, payload);
};
