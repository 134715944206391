import { Progress } from "antd"

function LineProgress({...props}:any) {
  return (
    
    <Progress {...props} />
      
    
  )
}

export default LineProgress
LineProgress.defaultProps={
    percent:90,
    strokeWidth:8,
    showInfo:false,
    strokeColor:"#95B5FE"
}
