"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IWhatsAppPayload = exports.ITemplate = exports.IActions = exports.IImageUrl = exports.IFileUrl = void 0;
const typescript_json_serializer_1 = require("typescript-json-serializer");
/**
 * Represents a file URL with a name and URL.
 */
class IFileUrl {
    constructor(name, url) {
        this.name = name;
        this.url = url;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('name') // Maps the 'name' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IFileUrl.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('url') // Maps the 'url' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IFileUrl.prototype, "url", void 0);
exports.IFileUrl = IFileUrl;
class IImageUrl {
    constructor(name, url) {
        this.name = name;
        this.url = url;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('name') // Maps the 'name' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IImageUrl.prototype, "name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('url') // Maps the 'url' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IImageUrl.prototype, "url", void 0);
exports.IImageUrl = IImageUrl;
/**
 * Represents an action with relevant details like name, external URL, type, and country code.
 */
class IActions {
    constructor(actionName, actionExternalUrl, actionType, urlType, countryCode) {
        this.actionName = actionName;
        this.actionExternalUrl = actionExternalUrl;
        this.actionType = actionType;
        this.urlType = urlType;
        this.countryCode = countryCode;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('actionName') // Maps the 'actionName' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IActions.prototype, "actionName", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('actionExternalUrl') // Maps the 'actionExternalUrl' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IActions.prototype, "actionExternalUrl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('actionType') // Maps the 'actionType' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IActions.prototype, "actionType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('urlType') // Maps the 'urlType' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IActions.prototype, "urlType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('countryCode') // Maps the 'countryCode' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IActions.prototype, "countryCode", void 0);
exports.IActions = IActions;
/**
 * Represents a template with various attributes such as language, header type, category, and actions.
 */
class ITemplate {
    constructor(language, headerType, category, headerTitle, description, templateId, preview_url, type, shorten_url, url, webHookDNId, imageUrl, videoUrl, fileUrl, actions) {
        this.language = language;
        this.headerType = headerType;
        this.category = category;
        this.headerTitle = headerTitle;
        this.description = description;
        this.templateId = templateId;
        this.preview_url = preview_url;
        this.type = type;
        this.shorten_url = shorten_url;
        this.url = url;
        this.webHookDNId = webHookDNId;
        this.imageUrl = imageUrl;
        this.videoUrl = videoUrl;
        this.fileUrl = fileUrl;
        this.actions = actions;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('language') // Maps the 'language' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "language", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('headerType') // Maps the 'headerType' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "headerType", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('category') // Maps the 'category' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "category", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('headerTitle') // Maps the 'headerTitle' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "headerTitle", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('description') // Maps the 'description' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "description", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('templateId') // Maps the 'templateId' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "templateId", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('preview_url') // Maps the 'preview_url' boolean property to the corresponding JSON field.
    ,
    __metadata("design:type", Boolean)
], ITemplate.prototype, "preview_url", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('type') // Maps the 'type' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('shorten_url') // Maps the 'shorten_url' boolean property to the corresponding JSON field.
    ,
    __metadata("design:type", Boolean)
], ITemplate.prototype, "shorten_url", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('url') // Maps the 'url' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "url", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('webHookDNId') // Maps the 'webHookDNId' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "webHookDNId", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('imageUrl') // Maps the 'imageUrl' property to the corresponding JSON field.
    ,
    __metadata("design:type", IImageUrl)
], ITemplate.prototype, "imageUrl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('videoUrl') // Maps the 'videoUrl' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], ITemplate.prototype, "videoUrl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('fileUrl') // Maps the 'fileUrl' property (IFileUrl object) to the corresponding JSON field.
    ,
    __metadata("design:type", IFileUrl)
], ITemplate.prototype, "fileUrl", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('actions') // Maps the 'actions' array (IActions objects) to the corresponding JSON field.
    ,
    __metadata("design:type", Array)
], ITemplate.prototype, "actions", void 0);
exports.ITemplate = ITemplate;
/**
 * Represents a WhatsApp payload, containing details about a template, such as name, type, and modification date.
 */
class IWhatsAppPayload {
    constructor(template_name, template_type, isTemplateDeleted, template, modifiedOn, createdOn) {
        this.template_name = template_name;
        this.template_type = template_type;
        this.isTemplateDeleted = isTemplateDeleted;
        this.template = template;
        this.modifiedOn = modifiedOn;
        this.createdOn = createdOn;
    }
}
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('template_name') // Maps the 'template_name' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IWhatsAppPayload.prototype, "template_name", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('template_type') // Maps the 'template_type' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IWhatsAppPayload.prototype, "template_type", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('isTemplateDeleted') // Maps the 'isTemplateDeleted' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IWhatsAppPayload.prototype, "isTemplateDeleted", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('template') // Maps the 'template' property (ITemplate object) to the corresponding JSON field.
    ,
    __metadata("design:type", ITemplate)
], IWhatsAppPayload.prototype, "template", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('modifiedOn') // Maps the 'modifiedOn' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IWhatsAppPayload.prototype, "modifiedOn", void 0);
__decorate([
    (0, typescript_json_serializer_1.JsonProperty)('createdOn') // Maps the 'createdOn' property to the corresponding JSON field.
    ,
    __metadata("design:type", String)
], IWhatsAppPayload.prototype, "createdOn", void 0);
exports.IWhatsAppPayload = IWhatsAppPayload;
