import { CloseOutlined } from "@ant-design/icons";
import { Card } from "antd";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import FormItem from "antd/es/form/FormItem";
import TextInput from "../../Inputs/TextInput/TextInput";
import Selector from "../../Selector/Selector";
import { pushActionButtonType } from "@customTypes/template";
import { CampaignChannelTypeEnum, PushActionBtnTypes } from "utils/enums";
import { t } from "i18next";

export const ActionButtons = ({
  buttonsState, //button state
  setButtonsState, //update state
  type, //type of button to show
  maxButtons, //maximum buttons
}: {
  buttonsState: pushActionButtonType[];
  setButtonsState: (data: pushActionButtonType[]) => void;
  type: CampaignChannelTypeEnum.PUSH| CampaignChannelTypeEnum.WEB_PUSH | CampaignChannelTypeEnum.WHATSAPP;  //type of action button to use 
  maxButtons: number;
}) => {
  //initial state of values
  const types = {
    PUSH: { actionName: "", actionType: "", actionExternalUrl: "" },  
    WEB_PUSH: { actionName: "", actionType: "", actionExternalUrl: "" },
    WHATSAPP: {
      actionName: "",
      actionType: "",
      urlType: "",
      actionExternalUrl: "",
      countryCode: "",
    },
  };
  //adding another button
  const handleAddClick = () => {
    setButtonsState([...buttonsState, types[type]]);
  };
  //removing the button
  const handleRemoveClick = (indexToRemove: number) => {
      // Create a new array by filtering out the action button at the specified index (indexToRemove).

    const modifiedButtons = buttonsState.filter(
      (_: any, index: number) => index !== indexToRemove
    );
      // Update the buttonsState with the new array, excluding the removed action button.

    setButtonsState(modifiedButtons);
  };
//saving the button values
  const handleActionChange = (key: string, index: number, value: string) => {
      // Create a shallow copy of the buttonsState array to avoid mutating the original state directly.
    const actions = [...buttonsState];
      // Update the action at the specified index with the new key-value pair.
    actions[index] = { ...actions[index], [key]: value };
      // Update the buttonsState with the modified actions array.
    setButtonsState(actions);
  };

  return (
    <>
  
      {buttonsState?.map((button: any, index: number) => { //mapping data if selected template
        const isLastButton = buttonsState.length - 1 == index;
        return (
          <>
            <ActionButtonCard
              key={index}
              index={index}
              onCloseClick={isLastButton ? handleRemoveClick : undefined}
            >
              {(type == CampaignChannelTypeEnum.PUSH || type == CampaignChannelTypeEnum.WEB_PUSH) && (
                <div>
                  <div className="d-flex w-100 gap-2">
                    <FormItem className="my-1 w-100">
                      <TextInput
                        value={button.actionName}
                        onChange={(e: any) => {
                          handleActionChange(
                            "actionName",
                            index,
                            e.target.value
                          );
                        }}
                        placeholder={ t("buttonNameLabel")}
                      />
                    </FormItem>
                    <FormItem className="my-1 w-100">
                      <Selector
                        placeholder= { t("buttonTypeLabel")}
                        className="w-100"
                        value={button.actionType}
                        style={{ width: 140 }}
                        onChange={(value) => {
                          handleActionChange("actionType", index, value);
                        }}
                        options={[
                            { label: t("remindMeLaterLabel"), value: PushActionBtnTypes.REMIND_ME_LATER },
                            { label: t("replyLabel"), value: PushActionBtnTypes.REPLY },
                            { label: t("deepLinkLabel"), value: PushActionBtnTypes.DEEP_LINK },
                            { label: t("dismissLabel"), value: PushActionBtnTypes.DISMISS },
                            { label: t("landingPageLabel"), value: PushActionBtnTypes.LANDING_PAGE },
                            { label: t("customLabel"), value: PushActionBtnTypes.CUSTOM }
                        ]}
                      />
                    </FormItem>
                  </div>
                  <FormItem className="my-1">
                    <TextInput
                      value={button.actionExternalUrl}
                      onChange={(e: any) => {
                        handleActionChange(
                          "actionExternalUrl",
                          index,
                          e.target.value
                        );
                      }}
                      placeholder={
                        button.actionType == "call" ? "Phone" : "Url"
                      }
                    />
                  </FormItem>
                </div>
              )}
              {type == CampaignChannelTypeEnum.WHATSAPP && (
                <div>
                  <div className="d-flex w-100 gap-2">
                    <FormItem className="my-1 w-100">
                      <Selector
                        placeholder={t("buttonTypeLabel")}
                        className="w-100"
                        value={button.actionType}
                        style={{ width: 140 }}
                        onChange={(value) => {
                          handleActionChange("actionType", index, value);
                        }}
                        options={[
                          {
                            value: "visit_website",
                            label: t("visitWebsiteLabel"),
                          },
                          {
                            value: "call",
                            label: t("callPhoneLabel"),
                          },
                        ]}
                      />
                    </FormItem>
                    <FormItem className="my-1 w-100">
                      <TextInput
                        value={button.actionName}
                        onChange={(e: any) => {
                          handleActionChange(
                            "actionName",
                            index,
                            e.target.value
                          );
                        }}
                        placeholder={t("buttonNameLabel")}
                      />
                    </FormItem>
                  </div>
                  <div className="d-flex w-100 gap-2">
                  
                    {button.actionType == "call" ? (
                      <FormItem className="my-1 w-100">
                        <Selector

                          value={button.countryCode}
                          onChange={(value) => {
                            handleActionChange("countryCode", index, value);
                          }}
                          placeholder="Country code"
                          options={[
                            {
                              value: "+91",
                              label: "+91",
                            },
                            {
                              value: "+974",
                              label: "+974",
                            },
                            {
                              value: "+966",
                              label: "+966",
                            },
                          ]}
                        />
                      </FormItem>
                    ) : (
                      <FormItem className="my-1 w-100">
                        <Selector
                          placeholder=""
                          // style={{ width: 140 }}

                          // className="w-100"
                          value={button.urlType}
                          onChange={(value) => {
                            handleActionChange("urlType", index, value);
                          }}
                          options={[
                            {
                              value: "Dynamic",
                              label: t("dynamicLabel"),
                            },
                            {
                              value: "url",
                              label: t("urlLabel"),
                            },
                          ]}
                        />
                      </FormItem>
                    )}
                    <FormItem className="my-1 w-100">
                      <TextInput
                        value={button.actionExternalUrl}
                        maxLength={2000}
                        onChange={(e: any) => {
                          handleActionChange(
                            "actionExternalUrl",
                            index,
                            e.target.value
                          );
                        }}
                        placeholder={
                          button.actionType == "call" ? "Phone" : "Url"
                        }
                      />
                    </FormItem>
                  </div>
                </div>
              )}
            </ActionButtonCard>
            {isLastButton && maxButtons !== buttonsState?.length && (
              <PrimaryButton onClick={handleAddClick}>{t("addButtonLabel")}</PrimaryButton>
            )}
          </>
        );
      })}
    </>
  );
};
interface ActionButtonCardProps {
  onCloseClick?: (name: number) => void;
  index: number;
  children: React.ReactNode;
}

const ActionButtonCard = ({
  onCloseClick,
  index,
  children,
}: ActionButtonCardProps) => {
  return (
    <Card
      style={{ width: "100%" }}
      className="my-1"
      size="small"
      title={`Button ${index + 1}`}
      extra={
        onCloseClick && (
          <CloseOutlined
            onClick={() => {
              onCloseClick(index);
            }}
          />
        )
      }
    >
      {children}
    </Card>
  );
};

export default ActionButtonCard;
